// Package imports:
import ContentLoader from "react-content-loader"

const Loading = (props: any) => (
    <ContentLoader 
        speed={2}
        width="100%"
        height={110}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="0" rx="8" ry="8" width="100%" height="15" /> 
        <rect x="0" y="30" rx="8" ry="8" width="100%" height="15" /> 
        <rect x="0" y="60" rx="8" ry="8" width="100%" height="15" /> 
        <rect x="0" y="90" rx="8" ry="8" width="50%" height="15" />
    </ContentLoader>
)

export default Loading;