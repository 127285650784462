// Package imports:
import React, { useState } from 'react';
import { NumberFormatValues } from 'react-number-format';
// Component imports:
import Flag, {FlagNames} from '../../ui-elements/Flag/Flag';
import ColoredNumber from '../../ui-elements/ColoredNumber/ColoredNumber';
import Link from '../../ui-elements/Link/Link';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import DatePicker from '../../ui-elements/DatePicker/DatePicker';
import Label from '../../ui-elements/Label/Label';
import Input from '../../ui-elements/Input/Input';
import LmdTable from '../../ui-elements/Table/LmdTable';
// Service imports:
import { convertToPercentage, formatNumber, getAPItoday } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdCurrencyAlmenntGengi } from '../../types/CurrencyTypes';



const CURRENCIES_TO_SHOW = ['ISK', 'EUR', 'USD', 'GBP','DKK', 'SEK', 'NOK', 'JPY', 'CHF', 'CAD', 'PLN'];

const getFractionDigits = (symbol: string) => {
    const symbolDecimalMap: {
        [key: string]: number
    } = {
        'DKK': 3,
        'NOK': 3,
        'SEK': 3,
        'JPY': 4
    };
    return symbolDecimalMap[symbol] ?? 2;
}

export interface ICurrencyInputState {
    value: number | null,
    currency: string | null,
    averagerate: number | null,
}

interface IOwnProps {
    basecurrency?: string,
    onSymbolClick: (symbol: string) => void
}
type Props = IDefaultProps & IOwnProps & React.HTMLAttributes<HTMLDivElement>;

const CurrenciesSingleBase: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    onSymbolClick,
    ...props
}) => {
    const [ input, setInput ] = useState<ICurrencyInputState | null>({
        value: 1000,
        currency: 'ISK',
        averagerate: 1,
    });
    const [ date, setDate ] = useState<string>(getAPItoday());
    const [ focusedSymbol, setFocusedSymbol ] = useState<string | null>(null);
    const [ currencies ] = useApiLmdData<IApiLmdCurrencyAlmenntGengi[]>(
        `/v1/market_data/v1/currency/base_currency/ISK/cross_currency/[${CURRENCIES_TO_SHOW.join(';')}]/sources/NBX/rate_types/spotgengi/currency_rate?date_rate=${date}`,
        accessToken,
        refreshRateMs
    );

    const handleData = () => {
        const { data, error } = currencies;
        if (data === null) {
            return currencies;
        } else {
            let newData: {[key: string]: IApiLmdCurrencyAlmenntGengi} = {'ISK': {Crosscurrency: 'ISK', Averagerate: 1, Buyrate: 1, Sellrate: 1, LastValue: 1, ChangePer: 0, Days7ValueChangePer: 0, Weeks4ValueChangePer: 0, YearValueChangePer: 0, Months12ValueChangePer: 0}};
            data.forEach((element) => {
                if (newData[element.Crosscurrency] === undefined) {
                    newData[element.Crosscurrency] = element;
                }
            });
            return {
                error,
                data: CURRENCIES_TO_SHOW.map((symbol) => newData[symbol]).filter((element) => element !== undefined)
            }
        }
    };

    return (
        <DisplayBox 
            className='KCL_CurrenciesSingleBase'
            title="Stundargengi"
            asideComponent={
                <DatePicker 
                    maxDate={new Date()} 
                    size="sm" 
                    selectedDate={new Date(date)} 
                    setSelectedDate={(newDate) => setDate(getAPItoday(newDate))}
                /> 
            }
            footerRight={
                <Label
                    text="Landsbankinn"
                    labelType="origin"
                    url='https://landsbankinn.is'
                />
            }
            {...props}
        >
            <LmdTable 
                apiData={handleData()}  
                columns={[{
                title: 'Mynt',
                renderCell: ({Crosscurrency}) => (
                        <div
                            className="avatar-and-text"
                            onClick={() => onSymbolClick('CBI' + Crosscurrency)}
                        >
                            <Flag name={Crosscurrency as FlagNames} />
                            <Link linkSize='15'>
                                {Crosscurrency}
                            </Link>
                        </div>
                    ),
                    textAlign: 'left',
                    simpleSortable: ({Crosscurrency}) => Crosscurrency
                }, {
                    title: 'Miðgengi',
                    renderCell: ({Averagerate, Crosscurrency}) => (Crosscurrency === 'ISK') ? '1,00' : formatNumber(Averagerate),
                    simpleSortable: ({Averagerate}) => Averagerate
                }, {
                    title: 'Dagsbr.',
                    renderCell: ({ChangePer}) => (
                        <ColoredNumber
                            hasArrow
                            sign={(ChangePer === null) ? 'neutral' : (ChangePer < 0) ? 'neg' : (ChangePer > 0) ? 'pos' : 'neutral'}
                        >
                            {convertToPercentage(ChangePer,true, 2)}
                        </ColoredNumber>
                    ),
                    simpleSortable: ({ChangePer}) => ChangePer
                }, {
                    title: '1 Mán.',
                    renderCell: ({Weeks4ValueChangePer}) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Weeks4ValueChangePer === null) ? 'neutral' : (Weeks4ValueChangePer < 0) ? 'neg' : (Weeks4ValueChangePer > 0) ? 'pos' : 'neutral'}
                        >
                            {convertToPercentage(Weeks4ValueChangePer,true, 2)}
                        </ColoredNumber>
                    ),
                    simpleSortable: ({Weeks4ValueChangePer}) => Weeks4ValueChangePer
                }, {
                    title: 'Innan árs',
                    renderCell: ({YearValueChangePer}) => (
                        <ColoredNumber
                            hasArrow
                            sign={(YearValueChangePer === null) ? 'neutral' : (YearValueChangePer < 0) ? 'neg' : (YearValueChangePer > 0) ? 'pos' : 'neutral'}
                        >
                            {convertToPercentage(YearValueChangePer, true, 2)}
                        </ColoredNumber>
                    ),
                    simpleSortable: ({YearValueChangePer}) => YearValueChangePer
                }, {
                    title: '1 ár',
                    renderCell: ({Months12ValueChangePer}) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Months12ValueChangePer === null) ? 'neutral' : (Months12ValueChangePer < 0) ? 'neg' : (Months12ValueChangePer > 0) ? 'pos' : 'neutral'}
                        >
                            {convertToPercentage(Months12ValueChangePer, true, 2)}
                        </ColoredNumber>
                    ),
                    simpleSortable: ({Months12ValueChangePer}) => Months12ValueChangePer
                }, {
                    title: 'Myntbreyta',
                    renderCell: ({Crosscurrency, Averagerate}) => {
                        let avgRate: any;
                        if (Crosscurrency === 'ISK') {
                            avgRate = 1;
                        } else {
                            avgRate = Averagerate;
                        }
                        return(
                            <Input
                                currency={Crosscurrency}
                                inputType="numberFormat"
                                inputSize="sm"
                                textAlign="right"
                                autoComplete="off"
                                value={
                                    (input === null)
                                    ? undefined // No input, display nothing.
                                    : (Crosscurrency === input.currency)
                                    ? (input.value ?? undefined) // Inputting currency, show value.
                                    : (!Averagerate || (input.value === null) || (input.averagerate === null))
                                    ? undefined // Not enough data, display nothing.
                                    : (((input.value) * (input.averagerate)) / avgRate)
                                }
                                thousandSeparator='.'
                                decimalSeparator=','
                                decimalScale={getFractionDigits(Crosscurrency ?? '')}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                allowLeadingZeros={false}
                                onClick={e => e.currentTarget.select()}
                                onFocus={() => setFocusedSymbol(Crosscurrency)}
                                onValueChange={(values: NumberFormatValues) => {
                                    if (focusedSymbol === Crosscurrency) {
                                        setInput({
                                            value: values.floatValue ||  null,
                                            currency: Crosscurrency,
                                            averagerate: avgRate || null
                                        });
                                    }
                                }}
                                aria-label={`${Crosscurrency} gildi`}
                            />
                        )
                    }
                }]}
                tableSize='lg'
            />
        </DisplayBox>
    );
}

export default CurrenciesSingleBase;