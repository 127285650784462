// Package imports:
import React from 'react';
import cx from 'classnames';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Loading from '../../ui-elements/Loading/Loading';
import Alert from '../../ui-elements/Alert/Alert';
import ColoredNumber, { Sign } from '../../ui-elements/ColoredNumber/ColoredNumber';
// Service imports:
import { formatNumber, convertToPercentage, getAPItoday } from '../../services/utils';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdStatistics } from '../../types/HlutabrefTypes';
import { useApiLmdData } from '../../services/apiHooks';

interface IOwnProps {
    symbol: string
}

type Props = IOwnProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

interface IChangeItem {
    title: string,
    change_per: number | null,
    change_nom: number | null
}

const ChangeItem: React.FC<IChangeItem> = ({title, change_per, change_nom}) => {
    const sign: Sign = !change_nom ? 'neutral' : change_nom > 0 ? 'pos' : 'neg';
    return <div className={cx('change-item', sign)}>
        <span className="title">{title}</span>
        <span className="numbers">
            <span className="pct">
                <ColoredNumber
                    sign={sign}
                    hasArrow
                >
                    {convertToPercentage(change_per, true)}
                </ColoredNumber>
            </span>
            <span className="nom">
                <ColoredNumber
                    sign={sign}
                >
                    {formatNumber(change_nom)}
                </ColoredNumber>
            </span>
        </span>
    </div>;
}

const TradablesChanges: React.FC<Props> = ({
    symbol,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [ apiData ] = useApiLmdData<IApiLmdStatistics[]>(
        `/v1/market_data/v1/exchanges/*/markets/*/tradables/${symbol}/statistics_delayed?trading_date=${getAPItoday()}`,
        accessToken,
        refreshRateMs
    );

    const { data, error } = apiData;
    
    const displayData = () => {
        if (error !== null) {
            return <Alert type="error" headText={error.message} />;
        }
        if (data === null) {
            return <Loading />;
        }
        if (data.length === 0) {
            return <p>Engar breytingar fundust</p>;
        }
        return <div>
            <ChangeItem
                title="Í gær"
                change_per={data[0].yesterdays_price_change_per}
                change_nom={data[0].yesterdays_price_change_nom}
            />
            <ChangeItem
                title="Vika"
                change_per={data[0].days7_price_change_per}
                change_nom={data[0].days7_price_change_nom}
            />
            <ChangeItem
                title="Mánuður"
                change_per={data[0].weeks4_price_change_per}
                change_nom={data[0].weeks4_price_change_nom}
            />
            <ChangeItem
                title="Þetta ár"
                change_per={data[0].year_price_change_per}
                change_nom={data[0].year_price_change_nom}
            />
            <ChangeItem
                title="12 mánuðir"
                change_per={data[0].months12_price_change_per}
                change_nom={data[0].months12_price_change_nom}
            />
            <ChangeItem
                title="Frá upphafi"
                change_per={data[0].life_price_change_per}
                change_nom={data[0].life_price_change_nom}
            />
        </div>
    }
    return (
        <DisplayBox
            className='KCL_tradables-changes'
            title="Breytingar"
            {...props}
        >
            {displayData()}
        </DisplayBox>
    );
}

export default TradablesChanges;