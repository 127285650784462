// Package imports:
import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import Link from '../../../ui-elements/Link/Link';
import Avatar, { AvatarNames } from '../../../ui-elements/Avatar/Avatar';
import CalculatedColoredNumber from '../../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
import MiniGraph from '../../../ui-elements/MiniGraph/MiniGraph';
import LmdTable from '../../../ui-elements/Table/LmdTable';
// import Tooltip from '../../../ui-elements/Tooltip/Tooltip';
// Service imports:
import { convertToPercentage, formatNumber, displayQuantityShorthand } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IApiLmdIndex, IApiLmdSnapshot, IOmxAndSnapshot } from '../../../types/HlutabrefTypes';
import { ApiLmdData, IDefaultProps } from '../../../types/Types';

type Props = IDefaultProps;

const OMX: React.FC<Props>= ({
    refreshRateMs,
    accessToken
}) => {
    const [ equities, prevEquities] = useApiLmdData<IApiLmdSnapshot[]>(
        '/v1/market_data/v1/keldan/exchange/XICE/market/ISEQ%20SHR/symbol/*/delayed_equities',
        accessToken,
        refreshRateMs
    );
    const [ omx, prevOmx ] = useApiLmdData<IApiLmdIndex[]>(
        'v1/market_data/v1/keldan/index/OMXI15/snapshot',
        accessToken,
        refreshRateMs
    );

    const combinedData: IOmxAndSnapshot[] | null = (omx.data === null || equities.data === null)
        ? null
        : [
            ...omx.data.map(omx => ({
                symbol: omx.Symbol,
                last_price: omx.CurrentValue,
                intraday_per_change: omx.DiffDayPer,
                bid: null,
                ask: null,
                intraday_accumulated_turnover: null
            })),
            ...equities.data
        ];

    const apiData: ApiLmdData<IOmxAndSnapshot[]> = {
        data: combinedData,
        error: equities.error ?? omx.error ?? null
    }

    const combinedPrevData: IOmxAndSnapshot[] = [
        ...(prevOmx.data?.map(omx => ({
            symbol: omx.Symbol,
            last_price: omx.CurrentValue,
            intraday_per_change: omx.DiffDayPer,
            bid: null,
            ask: null,
            intraday_accumulated_turnover: null
        })) ?? []),
        ...(prevEquities?.data ?? [])
    ];

    return (
        <div className='KCL_OMX'>
            <LmdTable
                apiData={apiData}
                columns={[{
                    title: 'Auðkenni',
                    renderCell: ({symbol, notifications}) => (
                        <div className="avatar-and-text">
                            <a href={`/Markadir/${symbol === 'OMXI15' ? 'Visitolur' : 'Hlutabref'}/${symbol}`} aria-label={`${symbol === 'OMXI15' ? 'Vísitölur -' : 'Hlutabréf -'} ${symbol}`}>
                                {(symbol === null)
                                    ? null
                                    : (
                                        <Avatar
                                            size='sm'
                                            backgroundGrey
                                            name={(symbol === 'OMXI15') ? 'OMX' : symbol.toUpperCase() as AvatarNames}
                                        />
                                    )
                                }
                            </a>
                            <Link url={`/Markadir/${symbol === 'OMXI15' ? 'Visitolur' : 'Hlutabref'}/${symbol}`} linkSize='13'>
                                {symbol}
                            </Link>
                            {/* {notifications !== undefined && notifications !== null &&
                                <Tooltip
                                    text={<FontAwesomeIcon icon={faExclamationCircle} />}
                                    tooltip={<>{notifications}</>}
                                    noUnderline
                                />
                            } */}
                        </div>
                    ),
                    textAlign: 'left',
                    simpleSortable: ({symbol}) => symbol
                }, {
                    title: 'Verð',
                    renderCell: ({last_price, symbol}) => {
                        return (
                            <td style={{ padding: 0 }}>
                                <CalculatedColoredNumber 
                                    hasBackground
                                    currentValue={last_price}
                                    previousValue={combinedPrevData.find(pd => pd.symbol === symbol)?.last_price ?? null}
                                >
                                    {formatNumber(last_price)}
                                </CalculatedColoredNumber>
                            </td>
                        )
                    },
                    overrideTd: true,
                    simpleSortable: ({last_price}) => last_price
                }, {
                    title: 'Breyting',
                    renderCell: ({intraday_per_change, symbol}) => {
                        return (
                            <td style={{ padding: 0 }}>
                                <CalculatedColoredNumber
                                    hasColor
                                    hasBackground
                                    currentValue={intraday_per_change}
                                    previousValue={combinedPrevData.find(pd => pd.symbol === symbol)?.intraday_per_change ?? null}
                                >
                                    {convertToPercentage(intraday_per_change, true, 2)}
                                </CalculatedColoredNumber>
                            </td>
                        )
                    },
                    overrideTd: true,
                    simpleSortable: ({intraday_per_change}) => intraday_per_change
                }, {
                    title: 'Sl. 3 Mán',
                    renderCell: ({symbol}) => (symbol) && (
                        <MiniGraph
                            symbol={symbol}
                            folder={(symbol === 'OMXI15') ? 'Indices' : 'Tradables'}
                        />
                    )
                }, {
                    title: 'Kaup',
                    renderCell: ({bid, symbol}) => {
                        return (
                            <td style={{ padding: 0 }}>
                                <CalculatedColoredNumber
                                    hasBackground
                                    currentValue={bid}
                                    previousValue={combinedPrevData.find(pd => pd.symbol === symbol)?.bid ?? null}
                                >
                                    {formatNumber(bid)}
                                </CalculatedColoredNumber>
                            </td>
                        )
                    },
                    overrideTd: true,
                    simpleSortable: ({bid}) => bid
                }, {
                    title: 'Sala',
                    renderCell: ({ask, symbol}) => {
                        return (
                            <td style={{ padding: 0}}>
                                <CalculatedColoredNumber
                                    hasBackground
                                    currentValue={ask}
                                    previousValue={combinedPrevData.find(pd => pd.symbol === symbol)?.ask ?? null}
                                    >
                                    {formatNumber(ask)}
                                </CalculatedColoredNumber>
                            </td>
                        )
                    },
                    overrideTd: true,
                    simpleSortable: ({ask}) => ask
                }, {
                    title: 'Velta',
                    renderCell: ({intraday_accumulated_turnover}) => displayQuantityShorthand(intraday_accumulated_turnover),
                    simpleSortable: ({intraday_accumulated_turnover}) => intraday_accumulated_turnover
                }]}
            />
        </div>
    );
}

export default OMX;