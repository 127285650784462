// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import ColoredNumber from '../../ui-elements/ColoredNumber/ColoredNumber';
import Link from '../../ui-elements/Link/Link';
import TandemTable from '../../ui-elements/Table/TandemTable';
import Alert from '../../ui-elements/Alert/Alert';
import Loading from '../../ui-elements/Loading/Loading';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
// Service imports:
import { formatNumber, convertToPercentage, getTableDate, getLastDayOfLastMonth } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdFundPrice } from '../../types/FundsTypes';

interface IOwnProps {
    basecurrency?: string
}
type Props = IDefaultProps & IOwnProps & React.HTMLAttributes<HTMLDivElement>;

const SpecialFundsTable: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    ...props
}) => {

    const [ data ] = useApiLmdData<IApiLmdFundPrice[]>(
        `/v1/market_data/v1/specialfunds/keldan/by_date?date=${getLastDayOfLastMonth()}`,
        accessToken,
        refreshRateMs
    );
    const [dataFrjalsi, setDataFrjalsi ] = useState<IApiLmdFundPrice[]>([]);
    const [dataIsl, setDataIsl ] = useState<IApiLmdFundPrice[]>([]);
    const [dataLif, setDataLif ] = useState<IApiLmdFundPrice[]>([]);

    useEffect(() => {
        if (data.data !== null)
            handleData(data.data);
    }, [data]);

    const handleData = (body: IApiLmdFundPrice[]) => {
        setDataFrjalsi(body.filter(x => x.Symbol?.startsWith('frjalsi')))
        setDataIsl(body.filter(x => x.Symbol?.startsWith('isl')))
        setDataLif(body.filter(x => x.Symbol?.startsWith('lif')))
    }

    const displayData = () => {
        if (data instanceof Error) {
            return <Alert type='error' headText={data.message} />
        }
        if (data === null) {
            return <Loading />
        }
        return <TandemTable
            data={[
                dataIsl,
                dataFrjalsi,
                dataLif
            ]}
            columns={[[
                {
                    title: 'Framtíðarauður Íslandsbanka',
                    renderCell: ( {Name, Symbol} ) => (
                        <Link url={`/Markadir/Sjodir/${Symbol}` ?? ''} linkSize='15'>
                            {Name}
                        </Link>
                    ),
                    textAlign: 'left'
                }, {
                    title: 'Gengi',
                    renderCell: ( {LastPrice} ) => (formatNumber(LastPrice, '-', 2)),
                }, {
                    title: 'Dagsetning',
                    renderCell: ( {ValueDate } ) => (ValueDate && getTableDate(ValueDate.toString(), 'DD/MM/YYYY', '.')),
                    
                }, {
                    title: '1 Ár',
                    renderCell: ( {Change12m} ) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Change12m === null) ? 'neutral' : (Change12m < 0) ? 'neg' : (Change12m > 0) ? 'pos' : 'neutral'}
                        >
                            {convertToPercentage(Change12m, true, 2)}
                        </ColoredNumber>
                    ),
                }, {
                    title: '2 Ár',
                    renderCell: ( {Change24m} ) => (
                        <ColoredNumber  
                            sign={(Change24m === null) ? 'neutral' : (Change24m < 0) ? 'neg' : (Change24m > 0) ? 'pos' : 'neutral'}>
                                {convertToPercentage(Change24m, true, 2)}
                        </ColoredNumber>
                    ),
                }, {
                    title: '3 Ár',
                    renderCell: ( {Change36m} ) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Change36m === null) ? 'neutral' : (Change36m < 0) ? 'neg' : (Change36m > 0) ? 'pos' : 'neutral'}>
                                {convertToPercentage(Change36m, true, 2)}
                        </ColoredNumber>
                    ),
                }, {
                    title: '4 Ár',
                    renderCell: ( {Change48m} ) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Change48m === null) ? 'neutral' : (Change48m < 0) ? 'neg' : (Change48m > 0) ? 'pos' : 'neutral'}>
                                {convertToPercentage(Change48m, true, 2)}
                        </ColoredNumber>
                    ),
                }, {
                    title: '5 Ár',
                    renderCell: ( {Change60m} ) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Change60m === null) ? 'neutral' : (Change60m < 0) ? 'neg' : (Change60m > 0) ? 'pos' : 'neutral'}>
                                {convertToPercentage(Change60m, true, 2)}
                        </ColoredNumber>
                    ),
                }
            ], [
                {
                    title: 'Frjálsi lífeyrissjóðurinn',
                    renderCell: ( {Name, Symbol} ) => (
                        <Link url={`/Markadir/Sjodir/${Symbol}` ?? ''} linkSize='15'>{Name}</Link>
                    ),
                    textAlign: 'left'
                }, {
                    title: '',
                    renderCell: ( {LastPrice} ) => (formatNumber(LastPrice, '-', 2)),
                }, {
                    title: '',
                    renderCell: ( {ValueDate } ) => (ValueDate && getTableDate(ValueDate.toString(), 'DD/MM/YYYY', '.')),
                    
                }, {
                    title: '',
                    renderCell: ( {Change12m} ) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Change12m === null) ? 'neutral' : (Change12m < 0) ? 'neg' : (Change12m > 0) ? 'pos' : 'neutral'}>
                                {convertToPercentage(Change12m, true, 2)}
                        </ColoredNumber>
                    ),
                }, {
                    title: '',
                    renderCell: ( {Change24m} ) => (
                        <ColoredNumber  
                            sign={(Change24m === null) ? 'neutral' : (Change24m < 0) ? 'neg' : (Change24m > 0) ? 'pos' : 'neutral'}>
                                {convertToPercentage(Change24m, true, 2)}
                        </ColoredNumber>
                    ),
                }, {
                    title: '',
                    renderCell: ( {Change36m} ) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Change36m === null) ? 'neutral' : (Change36m < 0) ? 'neg' : (Change36m > 0) ? 'pos' : 'neutral'}>
                                {convertToPercentage(Change36m, true, 2)}
                        </ColoredNumber>
                    ),
                }, {
                    title: '',
                    renderCell: ( {Change48m} ) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Change48m === null) ? 'neutral' : (Change48m < 0) ? 'neg' : (Change48m > 0) ? 'pos' : 'neutral'}>
                                {convertToPercentage(Change48m, true, 2)}
                        </ColoredNumber>
                    ),
                }, {
                    title: '',
                    renderCell: ( {Change60m} ) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Change60m === null) ? 'neutral' : (Change60m < 0) ? 'neg' : (Change60m > 0) ? 'pos' : 'neutral'}>
                                {convertToPercentage(Change60m, true, 2)}
                        </ColoredNumber>
                    ),
                }
            ], [
                {
                    title: 'Íslenski lífeyrissjóðurinn',
                    renderCell: ( {Name, Symbol} ) => (
                        <Link url={`/Markadir/Sjodir/${Symbol}` ?? ''} linkSize='15'>{Name}</Link>
                    ),
                    textAlign: 'left'
                }, {
                    title: '',
                    renderCell: ( {LastPrice} ) => (formatNumber(LastPrice, '-', 2)),
                }, {
                    title: '',
                    renderCell: ( {ValueDate } ) => (ValueDate && getTableDate(ValueDate.toString(), 'DD/MM/YYYY', '.')),
                    
                }, {
                    title: '',
                    renderCell: ( {Change12m} ) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Change12m === null) ? 'neutral' : (Change12m < 0) ? 'neg' : (Change12m > 0) ? 'pos' : 'neutral'}>
                                {convertToPercentage(Change12m, true, 2)}
                        </ColoredNumber>
                    ),
                }, {
                    title: '',
                    renderCell: ( {Change24m} ) => (
                        <ColoredNumber  
                            sign={(Change24m === null) ? 'neutral' : (Change24m < 0) ? 'neg' : (Change24m > 0) ? 'pos' : 'neutral'}>
                                {convertToPercentage(Change24m, true, 2)}
                        </ColoredNumber>
                    ),
                }, {
                    title: '',
                    renderCell: ( {Change36m} ) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Change36m === null) ? 'neutral' : (Change36m < 0) ? 'neg' : (Change36m > 0) ? 'pos' : 'neutral'}>
                                {convertToPercentage(Change36m, true, 2)}
                        </ColoredNumber>
                    ),
                }, {
                    title: '',
                    renderCell: ( {Change48m} ) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Change48m === null) ? 'neutral' : (Change48m < 0) ? 'neg' : (Change48m > 0) ? 'pos' : 'neutral'}>
                                {convertToPercentage(Change48m, true, 2)}
                        </ColoredNumber>
                    ),
                }, {
                    title: '',
                    renderCell: ( {Change60m} ) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Change60m === null) ? 'neutral' : (Change60m < 0) ? 'neg' : (Change60m > 0) ? 'pos' : 'neutral'}>
                                {convertToPercentage(Change60m, true, 2)}
                        </ColoredNumber>
                    ),
                }
            ]]}
            tableSize="lg"
        />
    }

    return (
        <DisplayBox 
            className='KCL_special-funds-table'
            title="Séreignarsjóðir"
            {...props}
        >
            {displayData()}
        </DisplayBox>
    );
}

export default SpecialFundsTable;