// Package imports:
import React, { useState } from "react";
import { SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
// Components imports:
import Slider from "../Slider/Slider";
import FullScreenImage from "../../ui-elements/FullScreenImage/FullScreenImage";
import TextImageBlock from "../TextImageBlock/TextImageBlock";
import PriceCard from "../PriceCard/PriceCard";
import Button from "../../ui-elements/Button/Button";
import Link from "../../ui-elements/Link/Link";
import HeadSection from "../../ui-elements/HeadSection/HeadSection";
import CheckList from "../../ui-elements/Lists/CheckList";
import Tabs from "../../ui-elements/Tabs/Tabs";
import DisplayBox from "../../ui-elements/DisplayBox/DisplayBox";
import Tooltip from "../../ui-elements/Tooltip/Tooltip";
// Service imports:
import { gtmPush } from "../../services/utils";

const KeldanMarkadirPage: React.FC = () => {
    const [fullScreenImageUrl, setFullScreenImageUrl] =
        useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<number>(0);
    return (
        <div className="main KCL_keldan-markadir-page">
            {fullScreenImageUrl && (
                <FullScreenImage
                    imgUrl={fullScreenImageUrl}
                    imgAlt="Fullscreen Keldan App screenshot"
                    onExit={() => setFullScreenImageUrl(null)}
                />
            )}
            <div className="main__inner-fluid grid-items">
                <div className="shell">
                    <HeadSection
                        title={
                            <object
                                data="https://cdn.livemarketdata.com/KeldanImages/Keldan_markadir_logo.svg"
                                aria-label="Keldan | Markaðir"
                            >
                                Keldan | Markaðir
                            </object>
                        }
                        summary={<p>Vertu með sömu sýn á markaðinn og verðbréfamiðlarar</p>}
                        actions={
                            <>
                            <Button
                                buttonType="secondary"
                                anchorProps={{
                                href: "https://markadir.keldan.is/login",
                                }}
                            >
                                Innskráning
                            </Button>
                            <Button
                                buttonType="primary"
                                //scroll to section id="Askriftarleidir"
                                onClick={() =>
                                document
                                    .getElementById("Askriftarleidir")
                                    ?.scrollIntoView({ behavior: "smooth" })
                                }
                            >
                                Áskrift
                            </Button>
                            </>
                        }
                        textAlign="center"
                    />
                </div>
                <div className="section__body">
                    <div className="shell">
                        <TextImageBlock
                            imgSrc="https://cdn.livemarketdata.com/KeldanImages/Keldan_markadir_cover_image.png"
                            imgSide="right"
                        >
                            <h2 id="KeldanApp_Summary">Markaðurinn í rauntíma</h2>
                            <div className="text-image-block__entry">
                                <p className="paragraph--bold">
                                    Keldan markaðir er vefur og app sem veitir viðskiptavinum
                                    beinan aðgang að Kauphöll Íslands.
                                </p>
                                <p className="paragraph">
                                    Vefurinn er sérstaklega hannaður fyrir fjárfesta og þá sem
                                    þurfa meiri og betri upplýsingar en þær sem eru í boði á opnum
                                    vef Keldunnar. Á vefnum eru allar upplýsingar í rauntíma.
                                </p>
                                <p className="paragraph">
                                    Appið er notað af þúsundum Íslendinga á degi hverjum til þess
                                    að fylgjast með því sem er að gerast á markaðnum hverju sinni.
                                    Appið er hægt að fá með rauntímagögnum eða með 15 mínútna
                                    seinkuðum gögnum.
                                </p>
                            </div>
                        </TextImageBlock>
                    </div>
                </div>
                <div
                    id="Askriftarleidir"
                    className="section section--gray section--subscription"
                >
                    <div className="section__head">
                        <h2 id="KeldanApp_Subscriptions">Áskriftarleiðir</h2>
                        <p className="summary-paragraph">
                            Keldan býður upp á nokkrar áskriftarleiðir til að mæta ólíkum
                            þörfum viðskiptavina.
                            <br />
                            Athugið að öll verð eru birt án virðisaukaskatts.
                        </p>
                    </div>
                    <div className="section__body">
                        <div className="mini_shell">
                            <div>
                                <Tabs
                                    tabsSize="lg"
                                    onTabChange={(e) => setActiveTab(e)}
                                    tabs={[
                                        {
                                        id: "almennir",
                                        label: "Almennir fjárfestar",
                                        component: <KeldanMarkadirSlidersAlmennirFjarfestar />,
                                        },
                                        {
                                        id: "fagadilar",
                                        label: "Fagaðilar",
                                        component: <KeldanMarkadirSlidersFagadilarFjarfestar />,
                                        },
                                    ]}
                                />
                                {/* APP STORE SECTION */}
                                <div className="section--subscription section--appStoreLinks">
                                    <p className="paragraph--bold">Þú sækir Keldu appið hér:</p>
                                    <div className="app-download-links">
                                        <a
                                            className="google-play"
                                            href="https://play.google.com/store/apps/details?id=is.kodi.keldanMobile"
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={() =>
                                                gtmPush(
                                                "buttonClick",
                                                "download_app",
                                                "link_to_store",
                                                "google_play"
                                                )
                                            }
                                            aria-label="Keldu appið á Google Play Store"
                                        >
                                            <img
                                                src="https://cdn.livemarketdata.com/Images/google-play.png"
                                                alt="Google play store logo"
                                                width="132"
                                                height="39"
                                            />
                                        </a>
                                        <a
                                            className="app-store"
                                            href="https://apps.apple.com/is/app/keldan-app/id1162095731"
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={() =>
                                                gtmPush(
                                                "buttonClick",
                                                "download_app",
                                                "link_to_store",
                                                "app_store"
                                                )
                                            }
                                            aria-label="Keldu appið á Apple App Store"
                                        >
                                            <img
                                                src="https://cdn.livemarketdata.com/Images/app-store.png"
                                                alt="Apple app store logo"
                                                width="132"
                                                height="44"
                                            />
                                        </a>
                                    </div>
                                    <div className="price-card-notice appStoreNoticeText">
                                        <p
                                            className="paragraph-small"
                                            style={{ maxWidth: "535px" }}
                                        >
                                            {activeTab === 0
                                                ? "Almennur fjárfestir er einstaklingur eða lögaðili sem ekki flokkast sem fagaðili, fagfjárfestir eða viðurkenndur gagnaðili."
                                                : "Fagaðilar, fagfjárfestar og viðurkenndir gagnaðilar. Einstaklingar sem starfa sem fagaðilar til dæmis við verðbréfamiðlun eða fjárfestingaráðgjöf."
                                            }
                                        </p>
                                    </div>
                                    <Link
                                        url="/Keldan-Markadir/Skilmalar"
                                        linkSize="16"
                                        icon="forward"
                                    >
                                        Lesa skilmála Keldan markaðir
                                    </Link>
                                </div>
                            </div>
                            <KeldanMarkadirVerdskra activeTab={activeTab} />
                        </div>
                    </div>
                </div>
                <AppScreenshotsSection setFullScreenImageUrl={setFullScreenImageUrl} />
                <DesktopScreenshotsSection
                    setFullScreenImageUrl={setFullScreenImageUrl}
                />
            </div>
        </div>
    );
};

export default KeldanMarkadirPage;
const KeldanMarkadirVerdskra: React.FC<{ activeTab: number }> = ({
    activeTab,
}) => {
    const PRICES =
        activeTab === 0
        ? [{
            //ALMENNIR
            category: "Hlutabréf og skuldabréf",
            description: "Hagstæðasta kaup- og sölutilboð",
            price: "2€",
        }, {
            category: "Hlutabréf og skuldabréf",
            description: "Hagstæðustu 5 kaup- og sölutilboð",
            price: "10€",
        }, {
            category: "Hlutabréf og skuldabréf",
            description: "Öll kaup- og sölutilboð",
            price: "16€",
        }]
        : [{
            //FAGAÐILAR
            category: "Hlutabréf",
            description: "Hagstæðasta kaup- og sölutilboð",
            price: "58€",
        }, {
            category: "Hlutabréf",
            description: "Hagstæðustu 5 kaup- og sölutilboð",
            price: "98€",
        }, {
            category: "Hlutabréf",
            description: "Öll kaup- og sölutilboð",
            price: "125€",
        }, {
            category: "Skuldabréf",
            description: "Öll kaup- og sölutilboð",
            price: "85€",
        }];
    return (
        <div id="Verdskra" className="section section--primary price-table-section">
            <DisplayBox
                title="Verðskrá fyrir rauntímagögn"
                description={`Öll verð eru sýnd án virðisaukaskatts og eru samkvæmt gjaldskrá Nasdaq OMX fyrir aðgang ${activeTab === 0 ? "almennra fjárfesta" : "fagaðila"} að rauntímagögnum.`}
                displayBoxSize="xl"
            >
                <h6>{activeTab === 0 ? "Almennir fjárfestar" : "Fagaðilar"}</h6>
                <div className="price_list">
                    {PRICES.map((item, index) => (
                        <div className="price_list_item" key={index}>
                            <p className="paragraph-small">
                                <strong className="paragraph-small--bold">
                                    Leið {index + 1}: {item.category} -{" "}
                                </strong>{" "}
                                {item.description}
                            </p>
                            <div className="price">
                                <span className="paragraph--bold">{item.price}</span>
                                <span className="paragraph-small per_month">á mánuði</span>
                            </div>
                        </div>
                    ))}
                </div>
            </DisplayBox>
        </div>
    );
};
const KeldanMarkadirSlidersAlmennirFjarfestar: React.FC = () => {
    return (
        <Slider
            perView={3}
            centerSlides={true}
            loop={false}
            hideActions={true}
            initialSlide={1}
            // spaceBetween={26}
            breakpointOptions={{
                320: {
                    slidesPerView: 1,
                    noSwiping: false,
                },
                768: {
                    slidesPerView: 2,
                    noSwiping: false,
                },
                1024: {
                    slidesPerView: 3,
                    noSwiping: true,
                },
            }}
            onSlideChange={() => {}}
            onInit={() => {}}
            className="price-cards__slider swiper-no-swiping"
        >
            <SwiperSlide>
                {/* FIRST PRICE CARD */}
                <PriceCard
                    keldanMarkadirPriceCard
                    type="Frír aðgangur að appi"
                    key="basic"
                >
                    <h6 className="description">
                        Frí áskrift inniheldur aðgang að Keldu appinu með seinkuðum gögnum
                    </h6>
                    <CheckList
                        items={["15 mínútna seinkun á markaðsgögnum.", "Viðskiptafréttir."]}
                        size="sm"
                    />
                </PriceCard>
            </SwiperSlide>
            <SwiperSlide>
                {/* SECOND PRICE CARD */}
                <PriceCard
                    keldanMarkadirPriceCard
                    variant="primary"
                    type="App"
                    key="individual"
                >
                    <div className="storePriceContainer">
                        <div className="storePriceWrapper">
                            <span className="storePriceWrapper__price">
                                <strong>19.83</strong>
                                <span className="storePriceWrapper__currency">USD</span>
                            </span>
                            <span className="storePriceWrapper__store">
                                <strong>App Store á mánuði</strong>
                            </span>
                        </div>
                        {/* Vertical line */}
                        <div className="vertical_line"></div>
                        <div className="storePriceWrapper">
                            <span className="storePriceWrapper__price">
                                <strong>19.99</strong>
                                <span className="storePriceWrapper__currency">USD</span>
                            </span>
                            <span className="storePriceWrapper__store">
                                <strong>Google Play á mánuði</strong>
                            </span>
                        </div>
                    </div>
                    <h6
                        className="description"
                        style={{
                        color: "white",
                        marginRight: "-2px",
                        }}
                    >
                        Aðgangur að rauntímagögnum í appinu
                        <Disclaimer type="App" />
                    </h6>
                    <CheckList
                        items={[
                        "Markaðsgögn í rauntíma.",
                        "Yfirlit yfir viðskipti.",
                        "Tilkynningar og rauntímavakt á hlutabréf að eigin vali.",
                        ]}
                        size="sm"
                    />
                </PriceCard>
            </SwiperSlide>
            <SwiperSlide>
                {/* THIRD PRICE CARD */}
                <PriceCard
                    variant="secondary"
                    type="Vefur og app"
                    key="individual"
                    noPriceLabel
                    keldanMarkadirPriceCard
                >
                    <div className="price-card__price">
                        <p>
                            <strong>3.280</strong>kr. á mánuði + VSK
                        </p>
                    </div>
                    <span className="small-disclaimer">
                        Ásamt mánaðargjaldi Nasdaq OMX*
                    </span>
                    <h6 className="description" style={{ color: "white" }}>
                        Aðgangur að <a href="https://markadir.keldan.is">Keldan markaðir</a>{" "}
                        vef og appi ásamt rauntímagögnum eftir þörfum
                        <Disclaimer type="Sub" />
                    </h6>
                    <CheckList
                        items={[
                            "Markaðsgögn í rauntíma.",
                            "Yfirlit yfir viðskipti.",
                            "Tilkynningar og rauntímavakt.",
                            "Tilkynningar og rauntímavakt í appinu",
                        ]}
                        size="sm"
                    />
                    <div className="price-card__actions">
                        <Button
                            buttonType="primary"
                            anchorProps={{
                                href: `/Hafa-Samband/Keldan-Markadir-Prufa`,
                            }}
                        >
                            Fá prufuaðgang
                        </Button>
                        <Button
                            buttonType="buy"
                            anchorProps={{
                                href: `/Hafa-Samband/Keldan-Markadir`,
                            }}
                        >
                            Kaupa áskrift
                        </Button>
                    </div>
                </PriceCard>
                <div className="price-card-notice">
                    <p className=" paragraph-small--italic">*Sjá verðskrá fyrir neðan.</p>
                </div>
            </SwiperSlide>
        </Slider>
    );
};

const KeldanMarkadirSlidersFagadilarFjarfestar: React.FC = () => {
    return (
        <Slider
            perView={3}
            centerSlides={true}
            loop={false}
            hideActions={true}
            initialSlide={1}
            breakpointOptions={{
                320: {
                    slidesPerView: 1,
                    noSwiping: false,
                },
                768: {
                    slidesPerView: 2,
                    noSwiping: false,
                },
                1024: {
                    slidesPerView: 3,
                    noSwiping: true,
                },
            }}
            onSlideChange={() => {}}
            onInit={() => {}}
            className="price-cards__slider swiper-no-swiping"
        >
            <SwiperSlide>
                {/* FIRST PRICE CARD */}
                <PriceCard
                    keldanMarkadirPriceCard
                    type="Frír aðgangur að appi"
                    key="basic"
                >
                    <h6 className="description">
                        Frí áskrift inniheldur aðgang að Keldu appinu með seinkuðum gögnum
                        líkt og er á opnum vef Keldunnar
                    </h6>
                    <CheckList
                        items={["15 mínútna seinkun á markaðsgögnum.", "Viðskiptafréttir."]}
                        size="sm"
                    />
                </PriceCard>
            </SwiperSlide>
            <SwiperSlide>
                {/* SECOND PRICE CARD */}
                <PriceCard
                    keldanMarkadirPriceCard
                    variant="primary"
                    type="App"
                    key="individual"
                >
                    <div className="price-card__price">
                        <p>
                            <strong>3.280</strong>kr. á mánuði + VSK
                        </p>
                    </div>
                    <span className="small-disclaimer">
                        Ásamt mánaðargjaldi Nasdaq OMX*
                    </span>
                    <h6
                        className="description"
                        style={{
                            color: "white",
                            marginRight: "-2px",
                        }}
                    >
                        Aðgangur að rauntímagögnum í appinu
                        <Disclaimer type="Sub" />
                    </h6>
                    <CheckList
                        items={[
                            "Markaðsgögn í rauntíma.",
                            "Yfirlit yfir viðskipti.",
                            "Tilkynningar og rauntímavakt á hlutabréf að eigin vali.",
                        ]}
                        size="sm"
                    />
                    <div className="price-card__actions">
                        {/* SENDA NOTANDA  */}
                        <Button
                            buttonType="buy"
                            anchorProps={{
                                href: `/Hafa-samband/keldan-app`,
                            }}
                        >
                            Kaupa áskrift
                        </Button>
                    </div>
                </PriceCard>
            </SwiperSlide>
            <SwiperSlide>
                {/* THIRD PRICE CARD */}
                <PriceCard
                    variant="secondary"
                    type="Vefur og KODIAK Pro"
                    key="individual"
                    noPriceLabel
                    keldanMarkadirPriceCard
                >
                    <div className="price-card__price">
                        <p>
                            <strong>15.900</strong>kr. á mánuði + VSK
                        </p>
                    </div>
                    <span className="small-disclaimer">
                        Ásamt mánaðargjaldi Nasdaq OMX*
                    </span>
                    <h6 className="description" style={{ color: "white" }}>
                        Aðgangur að <a href="https://markadir.keldan.is">Keldan markaðir</a>{" "}
                        vef og <a href="https://www.kodi.is/product/kodiak-pro/">KODIAK Pro</a>{" "}
                        kerfinu ásamt rauntímagögnum eftir þörfum
                        <Disclaimer type="Sub" />
                    </h6>
                    <CheckList
                        items={[
                            "Markaðsgögn í rauntíma.",
                            "Yfirlit yfir viðskipti.",
                            "Tilkynningar og rauntímavakt.",
                            "Upplýsingar um öll verð í tilboðabókum hlutabréfa og skuldabréfa",
                        ]}
                        size="sm"
                    />
                    <div className="price-card__actions">
                        <Button
                            buttonType="primary"
                            anchorProps={{
                                href: `/Hafa-Samband/Keldan-Markadir-Prufa`,
                            }}
                        >
                            Fá prufuaðgang
                        </Button>
                        <Button
                            buttonType="buy"
                            anchorProps={{
                                href: `/Hafa-Samband/Keldan-Markadir`,
                            }}
                        >
                            Kaupa áskrift
                        </Button>
                    </div>
                </PriceCard>
                <div className="price-card-notice">
                    <p className="paragraph-small--italic">*Sjá verðskrá fyrir neðan.</p>
                </div>
            </SwiperSlide>
        </Slider>
    );
};

const Disclaimer: React.FC<{ type: "App" | "Sub" }> = ({ type }) => {
    return (
        <Tooltip
            noUnderline
            tooltip={
                type === "App" ? (
                    <span>
                        Þú sækir appið í App Store eða Google Play og velur áskriftina í
                        appinu sjálfu (in-app-purchase). Markaðsgögn sem fylgja þessari
                        áskrift er <br /> <strong>Leið 1: Hlutabréf og skuldabréf</strong> -
                        Hagstæðasta kaup- og sölutilboð.
                    </span>
                ) : (
                    <span>
                        Þú velur eina leið af þrem fyrir áskrift að rauntímagögnum frá
                        Nasdaq OMX.
                    </span>
                )
            }
            text={
                <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCircleInfo} />
            }
            trigger="hover"
        />
    );
};
const AppScreenshotsSection: React.FC<{
    setFullScreenImageUrl: (e: string) => void;
}> = ({ setFullScreenImageUrl }) => {
    const KELDAN_APP_IMAGES_URLS = [
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample1.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample2.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample3.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample4.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample5.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample6.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample7.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample8.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample9.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample10.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample11.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample12.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample13.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample14.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample15.png",
        "https://cdn.livemarketdata.com/KeldanImages/KeldanAppExample16.png",
    ];

    return (
        <div className="section screenshotSection">
            <div className="section__head">
                <div className="shell">
                    <h2 id="KeldanApp_Screenshots">Skjáskot úr Keldu appinu</h2>
                </div>
            </div>
            <div className="section__body">
                <div className="shell">
                    <Slider
                        className="slider-screenshots"
                        perView={5}
                        centerSlides={false}
                        loop={true}
                        breakpointOptions={{
                            320: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                            1440: {
                                slidesPerView: 5,
                            },
                        }}
                        onInit={() => {}}
                        onSlideChange={() => {}}
                    >
                        {KELDAN_APP_IMAGES_URLS.map((url) => (
                            <SwiperSlide key={url} onClick={() => setFullScreenImageUrl(url)}>
                                <div>
                                    <img
                                        src={url}
                                        alt="Keldan App screenshot"
                                        className="slide-image"
                                        width="1080"
                                        height="2340"
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};
const DesktopScreenshotsSection: React.FC<{
    setFullScreenImageUrl: (e: string) => void;
}> = ({ setFullScreenImageUrl }) => {
    const KELDAN_MARKADIR_IMAGES_URLS = [
        "https://cdn.livemarketdata.com/KeldanImages/Keldan_markadir_desktop_screenshot1.png",
        "https://cdn.livemarketdata.com/KeldanImages/Keldan_markadir_desktop_screenshot2.png",
    ];
    return (
        <div className="section screenshotSection desktop-screenshot-section">
            <div className="section__head">
                <div className="shell">
                    <h2 id="KeldanMarkadir_Screenshots">
                        Skjáskot frá Keldan markaðir veflausn
                    </h2>
                </div>
            </div>
            <div className="section__body">
                <div className="shell">
                    <Slider
                        className="slider-screenshots"
                        perView={2}
                        centerSlides={false}
                        loop={false}
                        hideActions={true}
                        breakpointOptions={{
                            400: {
                                slidesPerView: 1,
                                centerSlides: false,
                            },
                            768: {
                                slidesPerView: 2,
                                centerSlides: true,
                            },
                        }}
                        onInit={() => {}}
                        onSlideChange={() => {}}
                    >
                        {KELDAN_MARKADIR_IMAGES_URLS.map((url) => (
                            <SwiperSlide key={url} onClick={() => setFullScreenImageUrl(url)}>
                                <img
                                    src={url}
                                    alt="Keldan markadir screenshot"
                                    className="desktopScreenshots"
                                />
                            </SwiperSlide>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};
