// Package imports:
import React, { useMemo, useState } from 'react';
import cx from 'classnames';
// Component imports:
import Loading from '../../../ui-elements/Loading/Loading';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';
import CalculatedColoredNumber from '../../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
import GEMMAQData from '../GemmaqData/Data';
// Service imports:
import { formatNumber } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../../types/Types';
import { IApiLmdGenderLensSnapshot } from '../../../types/HlutabrefTypes';

function calcAverage(arr: IApiLmdGenderLensSnapshot[]) {
    let total = 0;
    let count = 0;

    arr.forEach((item: IApiLmdGenderLensSnapshot) => {
        total += item.GenderLensScore ?? 0;
        count++;
    });

    const average = total / count;
    return average;
}

interface IOwnProps {
    category: 'all' | 'XICE' | 'XICE-BOND-ISSUERS' | 'OTC' | 'top10' | 'bottom10',
}

type Props = IDefaultProps & IOwnProps;

const GemmaqContainer: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    category
}) => {
    const [apiData] = useApiLmdData<IApiLmdGenderLensSnapshot[]>(
        '/v1/market_data/v1/company_reports/exchange/*/symbol/*/gender_lens_snapshot/',
        accessToken,
        refreshRateMs
    );
    const [selected, setSelected] = useState(0);

    const sortData = (d: IApiLmdGenderLensSnapshot[], sortType: 'alphabet' | 'score', dir: 'asc' | 'desc' = 'asc') => {
        return d.sort((a, b) => {
            const aval: (string | number | null) = sortType === 'alphabet' ? a.IssuerName : a.GenderLensScore;
            const bval: (string | number | null) = sortType === 'alphabet' ? b.IssuerName : b.GenderLensScore;
            let ret: number;
            if (aval === null && bval === null) ret = 0;
            else if (aval === null) ret = -1;
            else if (bval === null) ret = 1;
            else if (aval > bval) ret = 1;
            else if (aval < bval) ret = -1;
            else ret = 0;
            return dir === 'desc' ? ret * -1 : ret;
        });
    };

    const data = useMemo(() => {
        if (apiData.data === null) {
            return null;
        } else if (apiData.error) {
            return apiData.error;
        } else {
            if (category === 'all') {
                return sortData(apiData.data, 'alphabet');
            } else if (category === 'XICE') {
                const filtered = apiData.data.filter((gender: any) => {
                    return gender.Exchange === "XICE";
                });
                return sortData(filtered, 'alphabet');
            } else if (category === 'XICE-BOND-ISSUERS') {
                const filtered = apiData.data.filter((gender: any) => {
                    return gender.Exchange === "XICE-BOND-ISSUERS";
                });
                return sortData(filtered, 'alphabet');
            } else if (category === 'OTC') {
                const filtered = apiData.data.filter((gender: any) => {
                    return gender.Exchange === "OTC";
                });
                return sortData(filtered, 'alphabet');
            } else if (category === 'top10') {
                const filtered = [...apiData.data].sort((a: any, b: any) => {
                    return b.GenderLensScore - a.GenderLensScore;
                }).slice(0, 10);
                return sortData(filtered, 'score', 'desc');
            } else if (category === 'bottom10') {
                const filtered = [...apiData.data].sort((a: any, b: any) => {
                    return a.GenderLensScore - b.GenderLensScore;
                }).slice(0, 10);
                return sortData(filtered, 'score', 'asc');
            } else {
                return apiData.data;
            }
        }
    }, [apiData]);

    if (data === null) {
        return <div><Loading /></div>
    }
    if (data instanceof Error) {
        return <div><ErrorAlert error={data} /></div>
    }
    return (
        <div>
            <div className='KCL_table lg' id="gemma__table">
                <div className='table__body'>
                    <table>
                        <thead>
                            <tr>
                                <th className='align-left'>fyrirtæki</th>
                                <th className='align-right'>GemmaQ einkunn</th>
                                <th className='align-right'>Breyting</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={cx("average-row", { 'near__rowSelected': selected === 0 })}>
                                <td className="align-left">Meðaleinkunn</td>
                                <td className="align-right">{formatNumber(calcAverage(data), null, 1)}</td>
                                <td className="align-left" />
                            </tr>
                            {data.map((row, i) => {
                                return (
                                    <>
                                        <tr
                                            key={2*i}
                                            className={cx('clickable-row', {
                                                'rowSelected': selected === i,
                                                'near__rowSelected': selected === i + 1
                                            })}
                                            id={`tableRow_${i}`}
                                            onClick={() => setSelected(i)}
                                        >
                                            <td className='align-left'>{row.IssuerName}</td>
                                            <td className='align-right'>{formatNumber(row.GenderLensScore, null, 1)}</td>
                                            <td className='align-right'>
                                                {(row.GenderLensScore && row.PreviousGenderLensScore)
                                                    ? <CalculatedColoredNumber
                                                        currentValue={row.GenderLensScore - row.PreviousGenderLensScore}
                                                        previousValue={null}
                                                        hasArrow
                                                        hasColor
                                                    >
                                                        {formatNumber(row.GenderLensScore - row.PreviousGenderLensScore, null, 1)}
                                                        </CalculatedColoredNumber>
                                                    : ''
                                                }
                                            </td>
                                            <td className={cx('padding-cell', {'hideME': selected !== i})}></td>
                                            <td 
                                                className={cx('chart', {'hideME': selected !== i})}
                                            >
                                                {selected === i
                                                    ? <GEMMAQData
                                                        data={[row]}
                                                    />
                                                    : null
                                                }
                                            </td>
                                        </tr>
                                        <tr
                                            key={2*i+1}
                                            className={cx('chartInMinimumView', {
                                                'rowSelected': selected === i,
                                                'near__rowSelected': selected === i + 1
                                            })}
                                            id={`_tableRow_${i}`}
                                        >
                                            <td
                                                className={cx('chart-small', { 'hideME': selected !== i })}
                                                colSpan={3}
                                            >
                                                {selected === i
                                                    ? <GEMMAQData
                                                        data={[row]}
                                                    />
                                                    : null
                                                }
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default GemmaqContainer