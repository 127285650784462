// Package imports:
import React, { useEffect, useMemo, useState } from 'react';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Button from '../../../ui-elements/Button/Button';
import Label from '../../../ui-elements/Label/Label';
import Link from '../../../ui-elements/Link/Link';
import Loading from '../../../ui-elements/Loading/Loading';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import DropSelect from '../../../ui-elements/DropSelect/DropSelect';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { getTableDate } from '../../../services/utils';
import { usePageCounter } from '../../../services/hooks';
import { useBuyingProcessUrl } from '../../../services/buyhook';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched } from '../../../types/Types';
import { ICompanyViewModel, KeldanPaper, ReportsSearchModel } from '../../../types/CompanyTypes';

interface IOwnProps {
    limit?: number,
}

const CompanyPapers: React.FC<ICompanyViewModel & IOwnProps> = ({
    id,
    isAuthenticated,
}) => {
    const [data, setData] = useState<Fetched<ReportsSearchModel>>(null);

    const {
        currentPageIndex,
        itemsPerPage,
        setItemsPerPage,
        setItemCount,
        PageCounter
    } = usePageCounter();
    
    const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Company/GetCompanyPapersAndStatements/${id}`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const body = await response.json();
                    setData(body);
                    setItemCount(body.files.length);
                }
            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setData(new Error(ErrorMessages.OtherShort));
        }
    }

    useEffect(() => {
        fetchData();
    }, [isAuthenticated]);


    const { tryPurchaseItem, isItemLoading, ModalsAndToasts } = useBuyingProcessUrl(
        (paper: KeldanPaper, modalReference: string) => {
            const url = `${GET_KELDAN_API_URL()}/Pdf/Fyrirtaekjaskra-Kaupa?fileId=${paper.id}&ssn=${id}&paperType=${paper.type}&reference=${modalReference}`;
            return url;
        },
        () => {
            window.setTimeout(() => {
                fetchData();
            }, 5*1000);
        },
        'fyrirtækjaskrá'
    );

    const sortPapers = (papers: KeldanPaper[]) => {
        return papers.sort((a, b) => {
            const aDate = a.collected;
            const bDate = b.collected;
            if (aDate === null && bDate === null) return 0;
            if (aDate === null) return 1;
            if (bDate === null) return -1;
            if (aDate < bDate) return 1;
            if (aDate > bDate) return -1;
            return 0;
        });
    }

    const papersToShow = useMemo(() => {
        if (data instanceof Error) return null;
        if (data?.papers === undefined || data.papers === null) return null;
        return sortPapers(data?.papers[0]?.papers)?.slice(currentPageIndex * itemsPerPage, (currentPageIndex + 1) * itemsPerPage);
    }, [data, currentPageIndex, itemsPerPage]);

    const getOwned = (paper: KeldanPaper) => {
        if (data instanceof Error) return undefined;
        if (isAuthenticated) {
            const or = data?.existing?.find((e) => e.fileId === paper.id);
            return or;
        }
        return null;
    }
    return (
        <DisplayBox
            className='KCL_company-papers KCL_company-papers_large'
            title="Gögn úr fyrirtækjaskrá"
            asideComponent={<Label labelType='origin' text='RSK' />}
        >
            {(data instanceof Error)
                ? <Alert type='error' headText={data.message} />
                : (data === null)
                ? <Loading />
                : (papersToShow?.length === 0)
                ? <Alert type='info' headText='Engin gögn í boði' />
                : (
                    <>
                        <div className="page-size-selector">
                            <span className='extra-info'>Sýna:</span>
                            <DropSelect
                                options={[{
                                    label: '10 á síðu',
                                    value: '10'
                                }, {
                                    label: '50 á síðu',
                                    value: '50'
                                }, {
                                    label: '100 á síðu',
                                    value: '100'
                                },{
                                    label: 'Allt á síðu',
                                    value: '10000'
                                }]}
                                defaultValue={itemsPerPage.toString()}
                                size='sm'
                                onChange={(option) => { if (typeof option === 'string') setItemsPerPage(parseInt(option)); }}
                            />
                        </div>
                        {papersToShow?.map((paper, index ) => {
                            const owned = getOwned(paper);
                            return <div key={index} className='company-paper-line'>
                                <div className="main-line">
                                    {(owned)
                                        ? <>
                                            <div className='linkAndInfoWrapper'>
                                                <span className='linkWrapper'>
                                                    <Link
                                                        linkSize='15'
                                                        targetBlank
                                                        url={isAuthenticated && owned?.balls !== undefined
                                                            ? `/Pdf/Fyrirtaekjaskra?eventId=${owned?.balls}&ssn=${id}`
                                                            : undefined
                                                        }
                                                        icon='pdf'
                                                    >
                                                        {paper.type}
                                                    </Link>
                                                </span>
                                                <div className="dates-line">
                                                    <span className='collected-date'>
                                                        Safnað {getTableDate(paper.collected, 'DD/MM/YYYY', '.')}
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                        : <>
                                            <div className='linkAndInfoWrapper'>
                                                <span className='linkWrapper'>
                                                    <Link
                                                        linkSize='15'
                                                        disabled
                                                        icon='pdf'
                                                    >
                                                        {paper.type}
                                                    </Link>
                                                </span>
                                                <div className="dates-line">
                                                    <span className='collected-date'>
                                                        Safnað {getTableDate(paper.collected, 'DD/MM/YYYY', '.')}
                                                    </span>
                                                </div>
                                            </div>
                                            <Button
                                                showLoader={isItemLoading(paper)}
                                                size='sm'
                                                buttonType='buy'
                                                onClick={() => tryPurchaseItem(paper, 'confirm-modal-with-reference')}
                                            >
                                                Kaupa
                                            </Button>
                                        </>
                                    }
                                </div>
                            </div>
                        })}
                        <PageCounter />
                    </>
                )
            }
            <ModalsAndToasts />
        </DisplayBox>
    );
}

export default CompanyPapers;