// Package imports:
import React from 'react';
import { SwiperSlide } from 'swiper/react';
// Component imports:
import Slider from '../Slider/Slider';
import Button from '../../ui-elements/Button/Button';
import TextImageBlock from '../TextImageBlock/TextImageBlock';
import Link from '../../ui-elements/Link/Link';
import ExcellentContactForm from '../ContactForms/ExcellentContactForm/ExcellentContactForm';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import ExcellentSearch from './ExcellentSearch';
import BulletList from '../../ui-elements/Lists/BulletList';
import CheckList from '../../ui-elements/Lists/CheckList';

// To update:
// Update these numbers and imgSrc in TextImageBlock
const CURRENT_YEAR = 2024;
const PRICE = '99.900';
// Update this after list is published
const YEARS: IYears[] = [
    // { year: 2024, companies: 0},
    { year: 2023, companies: 1408},
    { year: 2022, companies: 1174},
    { year: 2021, companies: 1032 },
    { year: 2020, companies: 1108 },
    { year: 2019, companies: 1087 },
    { year: 2018, companies: 1165 },
    { year: 2017, companies: 863 }
];

interface IYears {
    year: number,
    companies: number
}
// IMPORTANT: Search disclaimer needs to be shown before the list is ready and removed after publishing!
// IMPORTANT: update available years in dotnet_keldan > listController > Excellent:183
// IMPORTANT: update the getCompanyAwrs method in CompanyController AND make sure that the companyAwards.tsx is also updated. 
const ExcellentCompanies: React.FC = () => {

    return (
        <div className='main KCL_excellent-companies'>
            <div className="main__inner-fluid">
                <div className='shell'>
                    <HeadSection
                        title="Fyrirmyndarfyrirtæki í rekstri"
                        summary={
                            <p>Keldan í samstarfi við Viðskiptablaðið tekur saman lista á hverju rekstrarári yfir þau fyrirtæki sem teljast til fyrirmyndar í rekstri.</p>
                        }
                        actions={
                            <Button
                                size='lg'
                                buttonType='primary'
                                anchorProps={{href: '#vottun'}}
                            >
                                Panta vottun
                            </Button>
                        }
                        textAlign='center'
                    />

                    <div className="section__body">
                        <TextImageBlock
                            imgSrc="https://cdn.livemarketdata.com/Images/Fyrirmyndar/fyrirmyndarfyrirtaeki-2024.jpg"
                            imgSide="right"
                        >            
                            <h2 id="Excellent_CriteriaList">Hvað þarf til að teljast til fyrirmyndar?</h2>
                            <BulletList
                                items={[
                                    `Rekstrarárin ${CURRENT_YEAR - 1} og ${CURRENT_YEAR - 2} liggja til grundvallar en tekið er tillit til rekstrarársins ${CURRENT_YEAR - 3}.`,
                                    'Afkoma þarf að hafa verið jákvæð.',
                                    'Tekjur þurfa að hafa verið umfram 45 milljónir króna.',
                                    'Eignir þurfa að hafa verið umfram 80 milljónir króna.',
                                    'Eiginfjárhlutfall þarf að hafa verið umfram 20%.',
                                    'Aðrir þættir metnir af Viðskiptablaðinu og Keldunni. Til dæmis skil á ársreikningi og rekstrarform.'
                                ]}
                            />
                            <div className='excellentSearchContainer'>
                                <ExcellentSearch showDisclaimer year={CURRENT_YEAR} />
                            </div>
                        </TextImageBlock>
                    </div>
                </div>

                <div className="section section--primary section--gray">
                    <div className="section__head">
                        <h2 id="Excellent_RecentYears">Viðurkenningar síðustu ára</h2>
                    </div>

                    <div className="section__body">
                        <div className="shell">
                                <div className="slid">
                                    <div className="desktop__version">
                                        <Slider
                                            className="slider-years"
                                            perView={4}
                                            centerSlides={false}
                                            loop={false}
                                            breakpointOptions={{
                                                320: {
                                                    slidesPerView: 1
                                                },
                                                768: {
                                                    slidesPerView: 3
                                                }
                                            }}
                                            onInit={() => {}}
                                            onSlideChange={() => {}}
                                        >
                                            {YEARS.map((year) => (
                                                <SwiperSlide
                                                    key={year.year}
                                                >
                                                    <DisplayBox title={`Árið ${year.year}`}>
                                                        <p className='paragraph-small'>
                                                            {year.companies} fyrirtæki fengu viðurkenninguna árið {year.year}.
                                                        </p>
                                                        <Link
                                                            url={`/Listar/Fyrirmyndarfyrirtaeki/${year.year}`}
                                                            linkSize='14'
                                                            icon='forward'
                                                        >
                                                            Skoða listann
                                                        </Link>
                                                    </DisplayBox>
                                                </SwiperSlide>
                                            ))}
                                        </Slider>
                                    </div>
                                    <div className="mobile__version">
                                        {YEARS.map((year) => (
                                            <DisplayBox title={`Árið ${year.year}`} key={year.year}>
                                                <p className='paragraph-small'>
                                                    {year.companies} fyrirtæki fengu viðurkenninguna árið {year.year}.
                                                </p>
                                                <Link
                                                    url={`/Listar/Fyrirmyndarfyrirtaeki/${year.year}`}
                                                    linkSize='16'
                                                    icon='forward'
                                                >
                                                    Skoða listann
                                                </Link>
                                            </DisplayBox>
                                        ))}
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>

                <div className="section section--contact" id="vottun">
                    <div className="shell">
                        <div className="section__inner">
                            <div className="section__head">
                                <h2 id="Excellent_Contact">Vottun fyrir fyrirmyndarfyrirtæki</h2>
                                <p className='summary-paragraph'>Þau fyrirtæki sem hljóta viðurkenninguna geta keypt vottun en með henni fylgir útprentað viðurkenningarskjal sem kjörið er til þess að hafa innrammað á sýnilegum stað á skrifstofu fyrirtækisins.</p>
                                <p className="list-heading paragraph">Með vottuninni fæst meðal annars:</p>
                                <CheckList
                                    items={[
                                        'Viðurkenningarskjal á íslensku og ensku',
                                        'Leyfi til að nota merkið í kynningarefni.',
                                        `Listi yfir fyrirmyndarfyrirtæki ${CURRENT_YEAR} og lykiltölur.`
                                    ]}
                                    size='lg'
                                />
                                <p className="price paragraph">Verð: {PRICE} kr. án vsk.</p>
                            </div>
                            <div className="section__body">
                                <div className='section__content form form--contact'>
                                    <ExcellentContactForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    )
}

export default ExcellentCompanies;