// Package imports:
import React, { useEffect, useState } from 'react';
import AOS from "aos";
import { SwiperSlide } from 'swiper/react';
// Component imports:
import PriceCard from '../PriceCard/PriceCard';
import Slider from '../Slider/Slider';
import Button from '../../ui-elements/Button/Button';
import Table from '../../ui-elements/Table/Table';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import Loading from '../../ui-elements/Loading/Loading';
import Alert from '../../ui-elements/Alert/Alert';
import SubscriptionPricingTable from '../../ui-elements/SubscriptionPricingTable/SubscriptionPricingTable';
// Service imports:
import { ErrorMessages } from '../../services/errorMessages';
import { GET_KELDAN_API_URL } from '../../services/config';
import { getSubscriptionPrice } from '../../services/utils';
// Type imports:
import { IKeldanApiPricingViewModel } from '../../types/HomeTypes';
import { Fetched } from '../../types/Types';
// Static data imports:
import { pricingPageData } from '../../assets/staticData/pricingPlansTable';

const PricingPage: React.FC = () => {
    AOS.init();

    const [ data, setData ] = useState<Fetched<IKeldanApiPricingViewModel>>(null);

    const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Home/GetPriceList`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                const body = await response.json();
                setData(body);
            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            
            setData(new Error(ErrorMessages.NetworkError));
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const displayPricingTable = () => {
        if (data === null) return <Loading />;
        if (data instanceof Error) return <Alert type='error' headText={data.message} />;
        return <SubscriptionPricingTable groups={{products: {...data.products, "Tilkynningarskyldir aðilar": pricingPageData.pepAndDdPrices}}} />;
    }

    return (
        <div className="main KCL_pricing-page">
            <div className="main__inner">
                <div className="section section--pricing mini_shell">
                    <HeadSection
                        title='Áskriftarleiðir'
                        summary={
                            <p>
                                Keldan veitir þér aðgang að öllum helstu skrám sem reknar eru af opinberum aðilum á Íslandi. Athugið að keyptar skýrslur eru samkvæmt verðskrá. 
                                Veldu þá leið sem hentar þér eða þínu fyrirtæki.
                            </p>
                        }
                        actions={
                            <Button
                                buttonType="primary"
                                size="lg"
                                anchorProps={{ href: '#verdskra' }}
                            >
                                Skoða verðskrá
                            </Button>
                        }
                        textAlign='center'
                    />
                    <div className="section__body">
                        <div className="section__inner">
                            <div 
                                className="section__list"
                                data-aos="fade-in"
                                data-aos-delay="50"
                            >
                                <div className="subscription-plans">
                                    <div className="subscription-plans__slider">
                                        <Slider
                                            perView={3}
                                            centerSlides={true}
                                            loop={false}
                                            hideActions={true}
                                            initialSlide={1}
                                            breakpointOptions={{
                                                320: {
                                                    slidesPerView: 1,
                                                    noSwiping: false
                                                },
                                                768: {
                                                    slidesPerView: 3,
                                                    noSwiping: true
                                                }
                                            }}
                                            onSlideChange={() => {}}
                                            onInit={() => {}}
                                            className="price-cards__slider swiper-no-swiping"
                                        >
                                            <SwiperSlide>
                                                <PriceCard
                                                    variant="tertiary"
                                                    type="Frítt"
                                                    price="0"
                                                    priceInfo="kr. á mánuði "
                                                    key="Frítt"
                                                >
                                                    <div className="price-card__info">
                                                        <p>Fyrir þá sem vilja skoða gögn og kaupa einstakar skýrslur.</p>
                                                    </div>

                                                    <div className="price-card__actions">
                                                        <Button anchorProps={{href: "/Nyskraning"}}>
                                                            Stofna aðgang
                                                        </Button>
                                                    </div>
                                                </PriceCard>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <PriceCard
                                                    variant="primary"
                                                    type="Áskrift"
                                                    price={getSubscriptionPrice()}
                                                    priceInfo="kr. á mánuði + VSK"
                                                    key="Áskrift"
                                                    showRibbon
                                                >                            
                                                    <div className="price-card__info">
                                                        <p>Fyrir þá sem vilja skoða gögn, vakta fyrirtæki og kaupa ódýrari skýrslur.<br/> Fyrir allt að 4 notendur.</p>
                                                    </div>
                            
                                                    <div className="price-card__actions">
                                                        <Button
                                                            buttonType='buy'
                                                            anchorProps={{ href: `/Askrift` }}
                                                        >
                                                            Kaupa áskrift
                                                        </Button>
                                                    </div>
                                                </PriceCard>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <PriceCard
                                                    variant="secondary"
                                                    type="Sérsamningur"
                                                    priceText="Umsamið"
                                                    key="Sérsamningur"
                                                >
                                                    <div className="price-card__info">
                                                        <p>Fyrir kröfuharða notendur sem þurfa aðgang fyrir fimm eða fleiri í sínu fyrirtæki.</p>
                                                    </div>

                                                    <div className="price-card__actions">
                                                        <Button anchorProps={{href: "/Hafa-Samband"}}>
                                                            Hafðu samband
                                                        </Button>
                                                    </div>
                                                </PriceCard>
                                            </SwiperSlide>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                            <div 
                                className="section__group"
                                data-aos="fade-in"
                                data-aos-delay="50"
                            >
                                <Table 
                                    columns={[{
                                        title: '',
                                        renderCell: ({label}) => label,
                                        textAlign: 'left'
                                    }, {
                                        title: 'Frítt',
                                        renderCell: ({forFreeUser}) => forFreeUser,
                                        textAlign: 'center'
                                    }, {
                                        title: 'Áskrift',
                                        renderCell: ({forSubscriber}) => forSubscriber,
                                        textAlign: 'center'
                                    }]}
                                    data={pricingPageData.pricingPlansTable}
                                    className="subscription-plans-table"
                                />
                            </div>
                            <div 
                                className="section__group"
                                data-aos="fade-in"
                                data-aos-delay="50"
                                id="verdskra"
                            >
                                {displayPricingTable()}
                                    
                                <div className="section__footnote">
                                    <p className='paragraph--italic'>
                                        Allar skýrslur sem innskráður notandi kaupir eru aðgengilegar á síðunni Keyptar skýrslur án frekari kostnaðar.
                                    </p>
                                    <p className='paragraph--italic'>
                                        Þar er einnig að finna nákvæmt yfirlit yfir allan kostnað vegna kaupa á skýrslum.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PricingPage;
