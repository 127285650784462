// Package imports:
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Alert from '../../../ui-elements/Alert/Alert';
import Loading from '../../../ui-elements/Loading/Loading';
import BlurredCompanyInfoBox from '../BlurredCompanyInfoBox/BlurredCompanyInfoBox';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
import { convertToPercentage } from '../../../services/utils';
// Type imports:
import { ICompanyOwners, ICompanyViewModel } from '../../../types/CompanyTypes';
import { IKeldanApiResponse } from '../../../types/KeldanTypes';
import { Fetched } from '../../../types/Types';

const CompanyOwners: React.FC<ICompanyViewModel> = ({
    id,
    isAuthenticated
}) => {

    const [data, setData] = useState<Fetched<ICompanyOwners[]>>(null);

    const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Company/GetCompanyOwners/${id}`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                const responseBody: IKeldanApiResponse<ICompanyOwners[]> = await response.json();
                if (responseBody.success) {
                    if(responseBody.result) setData(responseBody.result);
                } else {
                    const { message } = responseBody;
                    if (message) {
                        setData(new Error(message));
                    } else {
                        setData(new Error(ErrorMessages.ErrorInRequest));
                    }
                }
            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setData(new Error(ErrorMessages.NetworkError));
        }
    }
    useEffect(() => {
        if (isAuthenticated) {
            fetchData();
        }
    }, [isAuthenticated])

    return (
        <DisplayBox
            className='KCL_company-owners'
            title='Raunverulegir eigendur'
        >
            {(isAuthenticated)
                ? (data instanceof Error)
                    ? <Alert type='alert' headText={data.message} />
                    : (data === null)
                    ? <Loading />
                    : (data.length === 0)
                    ? <Alert type='info' headText='Engin gögn fundust' />
                    : <div className={cx('owners-boxes-wrapper', { 'odd-owners': data.length % 2 === 1 })}>
                        {data.sort((a, b) => b.ownedPercentage - a.ownedPercentage).map((owner, index) => !owner.name && !owner.faedingarManudur && !owner.nationality && !owner.ownedPercentage
                            ? null
                            : <div className='company-owner-box' key={index}>
                                <div>
                                    <div className='owner-name'>{owner.name}</div>
                                    <div className='owner-info'>Fæðingarár/mánuður: {owner.faedingarManudur?.replace('-', '/')}</div>
                                    <div className='owner-info'>Búseta: {owner.busetuLand} Ríkisfang: {owner.nationality}</div>
                                    <div className='owner-percentage'>Eignarhlutur: {convertToPercentage(owner.ownedPercentage / 100, true, 2)}</div>
                                </div>
                            </div>
                        )}
                    </div>
                : <BlurredCompanyInfoBox boxType='owners' />
            }
        </DisplayBox>
    );
}

export default CompanyOwners;