// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Link from '../../../ui-elements/Link/Link';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Fyrirvari from '../../Fyrirvari/Fyrirvari';
import ViewCompanyCompareBody from './ViewCompanyCompareBody';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched } from '../../../types/Types';
import { IKeldanApiResponse } from '../../../types/KeldanTypes';
import { IAuthenticationWithIdAndEventId, IViewCompanyCompare } from '../../../types/CompanyTypes';

const ViewCompanyCompare: React.FC<IAuthenticationWithIdAndEventId> = ({
    id,
    eventId,
}) => {
    const [data, setData] = useState<Fetched<IViewCompanyCompare>>(null);

    const [ disclaimerOpen, setDisclaimerOpen ] = useState(false);
    const openDisclaimer = () => setDisclaimerOpen(true);
    const closeDisclaimer = () => setDisclaimerOpen(false);

    const fetchData = async () => {
        try {
            const url =  `${GET_KELDAN_API_URL()}/Company/GetViewComparisonData/?id=${id}&eventId=${eventId}`
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (!response.ok) {
                setData(new Error(ErrorMessages.RequestFailed));
                return;
            }
            if (response.redirected) {
                window.location.href = response.url;
            }
            const responseBody: IKeldanApiResponse<Fetched<IViewCompanyCompare>> = await response.json();
            if (responseBody.success) {
                if (responseBody.result) {
                    setData(responseBody.result);
                }
            } else {
                const { message } = responseBody; 
                if (message) {
                    setData(new Error(message));
                } else {
                    setData(new Error(ErrorMessages.ErrorInRequest));
                }
            }
        } catch (e) {
            setData(new Error(ErrorMessages.NetworkError));
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        (data instanceof Error)
            ? <DisplayBox
                className='KCL_view-company-compare'
                title="Rekstur"
                asideComponent={<>
                    <Link url={`/Excel/Fyrirtaeki-Samanburdur?ssn=${id}&eventId=${eventId}`} icon='excel'>
                        Gögn í Excel
                    </Link>
                    <Fyrirvari
                        show={disclaimerOpen}
                        open={openDisclaimer}
                        close={closeDisclaimer}
                    >
                        <p className='paragraph'>
                            Keldan vinnur gögn úr opinberum upplýsingum eftir bestu getu. 
                            Keldan ber ekki ábyrgð á röngum upplýsingum sem geta komið til vegna innsláttarvillna eða annarra þátta.
                            Ábendingar um villur eða rangar upplýsingar eru vel þegnar með tölvupósti á <Link linkSize='18' url='mailto:help@keldan.is'>help@keldan.is.</Link>
                        </p>
                    </Fyrirvari>
                </>}
            >
                <Alert type='error' headText={data.message} />
            </DisplayBox>
            : <ViewCompanyCompareBody
                id={id}
                eventId={eventId}
                data={data}
            />
    );
}

export default ViewCompanyCompare;