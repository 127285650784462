// Package imports:
import React, { useEffect, useMemo, useRef, useState } from 'react';
// Component imports:
import SmallSearch from '../../SmallSearch/SmallSearch';
import FrettavaktItem from '../FrettavaktItem/FrettavaktItem';
import Loading from '../../../ui-elements/Loading/Loading';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';
import Button from '../../../ui-elements/Button/Button';
import FilterItem from '../../../ui-elements/Filter/FilterItem/FilterItem';
import Filter from '../../../ui-elements/Filter/Filter';
// Service imports:
import { getNewsDateOrTime, sortIcelandic, updateDoubleMapValue } from '../../../services/utils';
import { GET_NEWS_LMD_URL } from '../../../services/config';
import { getSourceListFromSourceString, getSourceStringFromSourceList } from '../../../services/newsUtils';
import { useStateRef } from '../../../services/hooks';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched, IDefaultProps } from '../../../types/Types';
import { INewsFeedCategoryDetails, INewsFeedSourceDetails, INewsFeedSourceFilter, INewsLmdNewsItem, INewsLmdResponse, SearchInputToSourceFilterToResultsDoubleMap, SearchResultsInfo, SourceFilterToResultsMap, NewsItemFeedIdMapToBoolean } from '../../../types/NewsTypes';

/**
 * Here is what these words mean:
 * - incomplete: There are less items being displayed than should be. Must fetch more.
 * - complete: There are enough items being displayed, but more can be loaded if the user wants.
 * - fully loaded: We are displaying literally all news for the given search query.
 * - no data: It is still loading or there is an error. No data is present.
 */
type DataStatus = 'incomplete' | 'complete' | 'fully loaded' | 'no data' ;

interface IDataMemo {
    dataToDisplay: INewsLmdNewsItem[] | null,
    dataStatus: DataStatus
};

const ITEMS_TO_DISPLAY_PER_FETCH = 30;
const INTIAL_REFRESH_RATE_NEWS_ITEMS_TO_FETCH = 50;
const NEWS_ITEMS_FETCH_ON_INDEX_CHANGE = 100;

const MINIMUM_LETTER_SEARCH = 3;

interface IOwnProps {
    // setHasNew(hasNewNewsItems: boolean): void,
    newsFeedCategoryDetails: INewsFeedCategoryDetails
}

type Props = IDefaultProps & IOwnProps;

const FrettavaktContainer: React.FC<Props> = ({
    // setHasNew,
    newsFeedCategoryDetails,
    refreshRateMs
}) => {
    // State variables:
    // News items visible to the user.
    const [ defaultNewsItems, setDefaultNewsItems ] = useState<INewsLmdNewsItem[] | null>(null);
    // How many news items should be displayed. newsItemsData will fetch more than the user wants to use the effciency of the request.
    const [ itemsPerPage, setItemsPerPage ] = useState(ITEMS_TO_DISPLAY_PER_FETCH);
    // Any error if it would occur
    const [newsError, setNewsError] = useState<Error | null>(null);
    // Sources used to filter by.
    const [sourceFilters, setSourceFilters] = useState<Fetched<INewsFeedSourceFilter[]>>(null);
    // Search word
    const [searchInput, setSearchInput] = useState('');
    // Search word results map
    const [searchResultsDoubleMap, setSearchResultsDoubleMap] = useState<SearchInputToSourceFilterToResultsDoubleMap>({});
    // While loading new data, show the last displayable data. Makes searching more UX friendly
    const [lastDisplayableData, setLastDisplayableData] = useState<INewsLmdNewsItem[] | null>(null);
    
    // Memo variables:
    const usingSourceFilter = useMemo(() => {
        if (sourceFilters === null || sourceFilters instanceof Error) return 'all';
        if (sourceFilters.every(sourceFilter => !sourceFilter.isOn)) return 'none';
        if (sourceFilters.every(sourceFilter => sourceFilter.isOn)) return 'all';
        return 'some';
    }, [ sourceFilters ]);

    const usingSearchWord = useMemo(() => {
        return (searchInput.length >= MINIMUM_LETTER_SEARCH);
    }, [ searchInput ]);
    
    const usingDefaultNews = useMemo(() => {
        return (!usingSearchWord && usingSourceFilter === 'all');
    }, [ usingSearchWord, usingSourceFilter ]);

    const { dataToDisplay, dataStatus } = useMemo(() => {
        const noDataState: IDataMemo = {
            dataToDisplay: [],
            dataStatus: 'no data'
        }
        if (usingSourceFilter === 'none') return noDataState;

        if (usingDefaultNews) {
            if (defaultNewsItems === null) return noDataState;
            const dataSlice = defaultNewsItems.slice(0, itemsPerPage);
            const dataMemo: IDataMemo = {
                dataToDisplay: dataSlice,
                dataStatus: (dataSlice.length < itemsPerPage) ? 'incomplete' : 'complete'
            }
            return dataMemo;
        }

        const searchWord = (usingSearchWord)
            ? searchInput
            : '';
        const sourceFilterString = (usingSourceFilter === 'some')
            ? getSourceStringFromSourceList(sourceFilters, 'on')
            : '';

        if (sourceFilterString === null) return noDataState;
        const searchResults = searchResultsDoubleMap?.[searchWord]?.[sourceFilterString];

        if (searchResults === undefined || searchResults.results instanceof Error) return noDataState;

        const dataSlice = searchResults.results.slice(0, itemsPerPage);
        let dataStatus: DataStatus;
        if (dataSlice.length === searchResults.lastResultsLength || dataSlice.length === searchResults.totalCount) {
            dataStatus = 'fully loaded';
        } else if (dataSlice.length === itemsPerPage) {
            dataStatus = 'complete';
        } else {
            dataStatus = 'incomplete';
        }

        const dataMemo: IDataMemo = {
            dataToDisplay: dataSlice,
            dataStatus
        };
        return dataMemo;
    }, [ sourceFilters, searchInput, searchResultsDoubleMap, defaultNewsItems, itemsPerPage, usingSearchWord, usingSourceFilter ]);

    const localStorageKey = useMemo(() => {
        return `KELDAN_NEWS_SETTINGS_${newsFeedCategoryDetails.category}_OFF`;
    }, [newsFeedCategoryDetails]);

    // Maps to track if news are new or read.
    const [ newsItemFeedIdToIsSeenMap, setNewsItemFeedIdToIsSeenMap ] = useState<NewsItemFeedIdMapToBoolean>({});
    const [ newsItemFeedIdToIsNewMap, setNewsItemFeedIdToIsNewMap ] = useState<NewsItemFeedIdMapToBoolean>({});
    const [ newsItemFeedIdToIsHighlightedMap, setNewsItemFeedIdToIsHighlightedMap ] = useState<NewsItemFeedIdMapToBoolean>({});

    // Interval functions can't read state correctly. Needs ref's instead.
    const defaultNewsItemsRef = useStateRef(defaultNewsItems)
    const newsItemFeedIdToIsNewMapRef = useStateRef(newsItemFeedIdToIsNewMap);
    const newsItemFeedIdToIsHighlightedMapRef = useStateRef(newsItemFeedIdToIsHighlightedMap);
    const usingDefaultNewsRef = useStateRef(usingDefaultNews);
    const usingSearchWordRef = useStateRef(usingSearchWord);
    const searchResultsDoubleMapRef = useStateRef(searchResultsDoubleMap);
    const sourceFiltersRef = useStateRef(sourceFilters);

    // Ref for timeout id.
    const timeoutFunctionIdRef = useRef<number | null>(null);

    // HELPER FUNCTIONS:
    const combineNewAndOldNews = (newNews: INewsLmdNewsItem[], oldNews: INewsLmdNewsItem[]) => {
        const oldNewsIdMap = oldNews.reduce((obj: NewsItemFeedIdMapToBoolean, newsItem) => {
            const { id } = newsItem;
            if (!id) return obj;
            return {
                ...obj,
                [id]: true
            };
        }, {});
        const pureNewNews = newNews.filter(newsItem => (newsItem.id !== null && !oldNewsIdMap[newsItem.id]));

        // Append new news items onto visible news.
        const joinedListOfOldAndNewNews = newNews.concat(oldNews);
        // There could be some overlap between the end of visiblenews and the start of newnews items.
        // So we remove duplicates.
        const joinedNewsWithoutDuplicates = [];
        // Map newsitem.id to boolean. Faster timecomplexity than "visibleNewsWithoutDuplicates.includes()";
        const hasNewsItemBeenAdded: {
            [feedId in string]?: boolean
        } = {};

        for (const newsItem of joinedListOfOldAndNewNews) {
            if (newsItem.id === null) continue;
            if (hasNewsItemBeenAdded[newsItem.id]) continue;
            hasNewsItemBeenAdded[newsItem.id] = true;
            joinedNewsWithoutDuplicates.push(newsItem);
        }

        return {
            combinedNews: joinedNewsWithoutDuplicates,
            pureNewNews
        };
    }

    const newsItemOnOpen = (newsItem: INewsLmdNewsItem) => {
        const { id } = newsItem;
        if (id === null) return;
        // Set to "not new" if new
        setNewsItemFeedIdToIsNewMap({
            ...newsItemFeedIdToIsNewMap,
            [id]: false
        })
        setNewsItemFeedIdToIsHighlightedMap({
            ...newsItemFeedIdToIsHighlightedMap,
            [id]: false
        })
        // Set to seen if not seen
        setNewsItemFeedIdToIsSeenMap({
            ...newsItemFeedIdToIsSeenMap,
            [id]: true
        })
    }

    // Fetch helper function
    const fetchNews = (
        numberOfNewsItemsToFetch: number,
        onSuccess: (newsItems: INewsLmdNewsItem[], totalCount: number) => void,
        onFail: (err: Error) => void,
        startPublishDate?: number,
        searchQuery?: string,
        sourceFilterString?: string
    ) => {
        // Fetch items
        let baseUrl = GET_NEWS_LMD_URL();
        const urlSearchParams = new URLSearchParams({
            start: '0',
            limit: numberOfNewsItemsToFetch.toString(),
        });

        if(searchQuery)urlSearchParams.set('sortByField', 'score');
        // Search query has special URL ending.
        if (searchQuery || sourceFilterString) {
            baseUrl += '/search/query';
            urlSearchParams.set('f', 'title;body');
            urlSearchParams.set('q', searchQuery?.toLowerCase() ?? '*');
            if (sourceFilterString) {
                urlSearchParams.set('filter', `symbol.ticker=${sourceFilterString}`);
            } else {
                urlSearchParams.set('filter', `topics=${newsFeedCategoryDetails.category}`);
            }
        }
        // If not search query, get the default url
        else {
            baseUrl += '/search/keldan';
            urlSearchParams.set('category', newsFeedCategoryDetails.category);
            if (startPublishDate) {
                urlSearchParams.set('startPublishDate', startPublishDate.toString());
            }
        }

        const url = `${baseUrl}?${urlSearchParams.toString()}`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.ok) return res.json();
            else throw new Error(ErrorMessages.RequestFailed);
        })
        .then((resBody: INewsLmdResponse) => {
            if (resBody === null || resBody.feedItems === null) throw new Error('Server did not return any news.')
            onSuccess(resBody.feedItems, resBody.totalCount);
        })
        .catch(err => {
            if (err instanceof Error) {
                onFail(err);
            } else {
                onFail(new Error(ErrorMessages.NetworkError));
            }
        });
    }

    // Update lastDisplayableData to last viable data.
    useEffect(() => { if (dataToDisplay !== null) setLastDisplayableData(dataToDisplay) }, [dataToDisplay]);

    // Reset items per page on search input change
    useEffect(() => {
        setItemsPerPage(ITEMS_TO_DISPLAY_PER_FETCH);
    }, [ searchInput, sourceFilters ]);

    // Sources fetch.
    useEffect(() => {
        const sourcesFetch = async () => {
            try {
                // Fetch items
                const url = `${GET_NEWS_LMD_URL()}/search/sources`;

                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const body: INewsFeedSourceDetails[] = await response.json();
                    // Filter body by category.
                    const filteredResults = body.filter(r => {
                        return newsFeedCategoryDetails.category === r.category;
                    });
                    // Sort by name
                    filteredResults.sort((source1, source2) => sortIcelandic(source1.fullSource, source2.fullSource))
                    // Add boolean value to the filter.
                    const sourceFiltersForCategory: INewsFeedSourceFilter[] = filteredResults.map(newsFeedSource => ({
                        newsFeedSource,
                        isOn: true
                    }));
                    // Set default settings from local storage.
                    try {
                        const dontUseSettingsString = localStorage.getItem(localStorageKey);
                        if (dontUseSettingsString === null) {
                            setSourceFilters(sourceFiltersForCategory);
                            return;
                        }
                        const dontUseTitles = dontUseSettingsString.split(';');
                        const sourceFiltersForCategoryWithSavedSettings: INewsFeedSourceFilter[] = sourceFiltersForCategory.map(({ newsFeedSource }) => ({
                            newsFeedSource,
                            isOn: !dontUseTitles.includes(newsFeedSource.title)
                        }));
                        setSourceFilters(sourceFiltersForCategoryWithSavedSettings);
                        return;
                    } catch (e) {
                        // Localstorage error
                        setSourceFilters(sourceFiltersForCategory);
                    }
                } else {
                    setSourceFilters(new Error(ErrorMessages.RequestFailed));
                }
            } catch (e) {
                if (e instanceof Error) {
                    setSourceFilters(e);
                } else {
                    setSourceFilters(new Error(ErrorMessages.NetworkError));
                }
            }
        }
        sourcesFetch();
    }, []);

    // Initial data fetch:
    useEffect(() => {
        fetchNews(
            NEWS_ITEMS_FETCH_ON_INDEX_CHANGE,
            (newsItems) => {
                setDefaultNewsItems(newsItems);
            },
            (err) => {
                setNewsError(err);
            }
        )
    }, []);

    // DefaultNewsItems: "Load more news" based fetch
    useEffect(() => {
        // Can only load more if defaultnewsitems has been fetched, we are not searching, and there isn't enough news yet fetched.
        if (defaultNewsItems !== null && usingDefaultNews && dataStatus === 'incomplete') {
            const lastVisibleNewsitem = defaultNewsItems.slice(-1).pop();
            if (!lastVisibleNewsitem?.publish_timestamp) return;
            fetchNews(
                NEWS_ITEMS_FETCH_ON_INDEX_CHANGE,
                (olderNews) => {
                    const { combinedNews } = combineNewAndOldNews(defaultNewsItems, olderNews);
                    setDefaultNewsItems(combinedNews);
                },
                (err) => {
                    setNewsError(err);
                },
                lastVisibleNewsitem?.publish_timestamp
            );
        }
    }, [ dataStatus, usingDefaultNews, defaultNewsItems ]);

    // Refresh rate based fetch
    const newNewsRefreshFunction = () => {
        const defaultNewsItems = defaultNewsItemsRef.current;
        const newsItemFeedIdToIsNewMap = newsItemFeedIdToIsNewMapRef.current;
        const newsItemFeedIdToIsHighlightedMap = newsItemFeedIdToIsHighlightedMapRef.current;
        const usingDefaultNews = usingDefaultNewsRef.current;
        const usingSearchWord = usingSearchWordRef.current;
        const searchResultsDoubleMap = searchResultsDoubleMapRef.current;
        const sourceFilters = sourceFiltersRef.current;

        const combineOldAndNewNews = (newNewsItems: INewsLmdNewsItem[], oldNews: INewsLmdNewsItem[], sources: string[] | null) => {
            const newNewsItemsForSources = (sources === null)
                ? newNewsItems
                : newNewsItems.filter(newsItem => sources.includes(newsItem.source ?? ''));

            const firstOldNews = oldNews[0];
            let indexWhereOldNewsBegins = newNewsItemsForSources.findIndex(newsItem => (
                (
                    newsItem.id !== null
                    && newsItem.id === firstOldNews.id
                )
                || (
                    newsItem.publish_timestamp !== null
                    && firstOldNews.publish_timestamp !== null
                    && newsItem.publish_timestamp < firstOldNews.publish_timestamp
                )
            ));

            const addedNews = indexWhereOldNewsBegins !== -1 ? newNewsItemsForSources.slice(0, indexWhereOldNewsBegins) : [];
            const combinedNews = [...addedNews, ...oldNews];
            return {
                combinedNews,
                addedNews
            };
        }

        // Always update default news and non-searchword,yes-sourcefilter news.
        if (defaultNewsItems !== null) {
            const recursionStoppingNewsItem = (defaultNewsItems[0] ?? null) as INewsLmdNewsItem | null;
            if (recursionStoppingNewsItem === null || recursionStoppingNewsItem.id === null) return;
            const fetchNewNews = (numberToFetch: number, recursionTime = 0) => {
                fetchNews(
                    numberToFetch,
                    (newNewsItems) => {
                        // Check if it has retreived all new news.
                        // Do this by finding index of the first old news item. (old as in already fetched).
                        let indexWhereOldNewsBegins = newNewsItems.findIndex(newsItem => (
                            (
                                newsItem.id !== null
                                && newsItem.id === recursionStoppingNewsItem.id
                            )
                            || (
                                newsItem.publish_timestamp !== null
                                && recursionStoppingNewsItem.publish_timestamp !== null
                                && newsItem.publish_timestamp < recursionStoppingNewsItem.publish_timestamp
                            )
                        ));
                        // If not try again with higher limit.
                        if (indexWhereOldNewsBegins === -1) {
                            // RECURSION ALERT:
                            // If for some reason something goes wrong, this will break us out of recursion after 3 loops:
                            if (recursionTime < 3) fetchNewNews(numberToFetch*2, recursionTime+1);
                            return;
                        }

                        let newsThatGetNotification: INewsLmdNewsItem[] = [];

                        // Handle default news:
                        const { combinedNews, addedNews } = combineOldAndNewNews(newNewsItems, defaultNewsItems, null);
                        
                        // If usin
                        if (usingDefaultNews) newsThatGetNotification = addedNews;
                        setDefaultNewsItems(combinedNews);

                        // Handle each source filter case:
                        const sourceFilterToResultsMap = searchResultsDoubleMap?.[''];
                        const newSourceFilterToResultsMap: SourceFilterToResultsMap = { ...sourceFilterToResultsMap };
                        const currentSourceFilterString = getSourceStringFromSourceList(sourceFilters, 'on');
                        if (sourceFilterToResultsMap !== undefined) {
                            for (const sourceFilterString in sourceFilterToResultsMap) {
                                const searchResultInfo = sourceFilterToResultsMap?.[sourceFilterString];
                                if (searchResultInfo === undefined) continue;
                                const { results, totalCount } = searchResultInfo;
                                if (results instanceof Error) continue;
                                const { combinedNews, addedNews } = combineOldAndNewNews(newNewsItems, results, getSourceListFromSourceString(sourceFilterString));
                                
                                if (!usingSearchWord && currentSourceFilterString === sourceFilterString) {
                                    newsThatGetNotification = addedNews;
                                }
                                newSourceFilterToResultsMap[sourceFilterString] = {
                                    results: combinedNews,
                                    totalCount: totalCount + addedNews.length
                                }
                            }
                        }
                        // Initialize new states here, that will be updated
                        const newSearchResultsDoubleMap = {
                            ...searchResultsDoubleMap,
                            '': newSourceFilterToResultsMap
                        };
                        
                        // Update isNew map.
                        const newNewsItemsToTrueMap: NewsItemFeedIdMapToBoolean = {};
                        const newNewsItemsToFalseMap: NewsItemFeedIdMapToBoolean = {};
                        newsThatGetNotification.forEach(newsItem => {
                            const { id } = newsItem;
                            if (id) {
                                newNewsItemsToTrueMap[id] = true;
                                newNewsItemsToFalseMap[id] = false;
                            }
                        });
                        
                        const newNewsItemFeedIdToIsNewMap = {
                            ...newsItemFeedIdToIsNewMap,
                            ...newNewsItemsToTrueMap
                        };
                        const newNewsItemFeedIdToIsHighlightedMap = {
                            ...newsItemFeedIdToIsHighlightedMap,
                            ...newNewsItemsToTrueMap
                        };

                        // Finish by setting all new states and time outs
                        setSearchResultsDoubleMap(newSearchResultsDoubleMap);
                        // Set timeouts for resetting isNew notifier + highlight.
                        setNewsItemFeedIdToIsHighlightedMap(newNewsItemFeedIdToIsHighlightedMap);
                        setNewsItemFeedIdToIsNewMap(newNewsItemFeedIdToIsNewMap);
                        window.setTimeout(() => {
                            const newsItemFeedIdToIsHighlightedMap = newsItemFeedIdToIsHighlightedMapRef.current;
                            setNewsItemFeedIdToIsHighlightedMap({
                                ...newsItemFeedIdToIsHighlightedMap,
                                ...newNewsItemsToFalseMap
                            });
                        }, 15*1000 /*15 sek.*/);
                        window.setTimeout(() => {
                            const newsItemFeedIdToIsNewMap = newsItemFeedIdToIsNewMapRef.current;
                            setNewsItemFeedIdToIsNewMap({
                                ...newsItemFeedIdToIsNewMap,
                                ...newNewsItemsToFalseMap
                            });
                        }, 15*60*1000 /* 15 min. */);
                    },
                    (err) => {
                        setNewsError(err);
                    }
                )
            }
            fetchNewNews(INTIAL_REFRESH_RATE_NEWS_ITEMS_TO_FETCH);
        }
    }
    useEffect(() => {
        if (refreshRateMs) {
            // If refresh rate, set fetchData on an interval
            const intervalId = window.setInterval(() => {
                newNewsRefreshFunction();
            }, refreshRateMs);
            // Clean up: clear interval to avoid memory leaks
            return () => window.clearInterval(intervalId);
        }
    }, [ refreshRateMs ]);

     // Search based fetch.
     useEffect(() => {
        if (usingDefaultNews || dataStatus === 'complete' || dataStatus === 'fully loaded' || usingSourceFilter === 'none') return;

        // Helper delay function.
        const withDelay = (func: () => void) => {
            const timeoutFunctionId = timeoutFunctionIdRef.current;
            if (timeoutFunctionId) window.clearTimeout(timeoutFunctionId);
            timeoutFunctionIdRef.current = window.setTimeout(func, 400);
        }
        
        const searchWord = (usingSearchWord)
            ? searchInput
            : '';
        const sourceFilterString = (usingSourceFilter === 'some')
            ? getSourceStringFromSourceList(sourceFilters, 'on')
            : '';
        if (sourceFilterString === null) return;

        const searchResults = searchResultsDoubleMap?.[searchWord]?.[sourceFilterString];
        if (searchResults && searchResults.results instanceof Error) return;

        const lastResultsLength = (searchResults && Array.isArray(searchResults.results)) ? searchResults.results.length : 0;
        const desiredAmountOfNews = (searchResults && searchResults.totalCount < itemsPerPage) ? searchResults.totalCount : itemsPerPage;

        const fetchNewsFunction = () => fetchNews(
            desiredAmountOfNews,
            (results, totalCount) => {
                const newSearchResultsDoubleMap = updateDoubleMapValue<SearchResultsInfo>(
                    searchResultsDoubleMap,
                    searchWord,
                    sourceFilterString,
                    {
                        results,
                        totalCount,
                        lastResultsLength
                    }
                );
                setSearchResultsDoubleMap(newSearchResultsDoubleMap);
            },
            (err) => {
                const newSearchResultsDoubleMap = updateDoubleMapValue<SearchResultsInfo>(
                    searchResultsDoubleMap,
                    '',
                    sourceFilterString,
                    {
                        results: err,
                        totalCount: 0,
                        lastResultsLength: 0
                    }
                );
                setSearchResultsDoubleMap(newSearchResultsDoubleMap);
            },
            undefined,
            (searchWord === '') ? undefined : searchWord,
            (sourceFilterString === '') ? undefined : sourceFilterString
        )

        if (usingSearchWord) withDelay(fetchNewsFunction);
        else fetchNewsFunction();

    }, [ usingDefaultNews, searchInput, sourceFilters, searchResultsDoubleMap, dataStatus, usingSourceFilter, itemsPerPage, usingSearchWord ]);

    const displayNewsItems = () => {
        const dataOrLastData = dataToDisplay ?? lastDisplayableData ?? null;
        if (dataOrLastData === null) {
            if (newsError === null) return <Loading />;
            else return <ErrorAlert error={newsError} />
        }
        return dataOrLastData.map((feedItem) => (
                <FrettavaktItem
                    key={feedItem.id}
                    title={feedItem.title}
                    description={feedItem.body ?? 'Ekki fundust frekari upplýsingar um frétt' }
                    date={(feedItem.publish_date)
                        ? getNewsDateOrTime(new Date(feedItem.publish_date))
                        : '-'
                    }
                    source={feedItem.source?.split('_')[0]}
                    fullSource={feedItem.fullSource}
                    link={feedItem.link}
                    isNew={feedItem.id !== null && (newsItemFeedIdToIsNewMap[feedItem.id] ?? false)}
                    isHighlighted={feedItem.id !== null && (newsItemFeedIdToIsHighlightedMap[feedItem.id] ?? false)}
                    isSeen={feedItem.id !== null && (newsItemFeedIdToIsSeenMap[feedItem.id] ?? false)}
                    paywall={feedItem.paywall}
                    onOpen={() => newsItemOnOpen(feedItem)}
                />
            // </Fragment>
        ))
    }

    return (
        <div className="KCL_frettavakt-container">
            <div className="row">
                <div className="col-lg-8 main-content-wrapper">
                    {/* Search Input */}
                    <div className="search__box">
                        <div className='input-and-filter'>
                            <SmallSearch
                                search={searchInput}
                                setSearch={setSearchInput}
                                id={`Search_News_NewsPage_${newsFeedCategoryDetails.category}`}
                            />
                            <div className='filter-wrapper'>
                                {
                                    (sourceFilters === null || sourceFilters instanceof Error)
                                    ? null
                                    : <Filter
                                        itemStyle={{
                                            size: 'lg',
                                            showCheck: true
                                        }}
                                        notAllSelectedBubble
                                        itemValues={sourceFilters.map(({newsFeedSource, isOn }, i) => ({
                                            selected: isOn,
                                            text: newsFeedSource.fullSource,
                                            locked: newsFeedSource.paywall,
                                            toggleSelected: () => {
                                                // Copy to prevent corruption of state.
                                                const sourcesCopy = [...sourceFilters];
                                                sourcesCopy.splice(i, 1, {
                                                    newsFeedSource,
                                                    isOn: !isOn
                                                });
                                                // Save current settings to local storage.
                                                const localStorageSettingsString = getSourceStringFromSourceList(sourcesCopy, 'off');
                                                try {
                                                    if (localStorageSettingsString !== null) {
                                                        localStorage.setItem(localStorageKey, localStorageSettingsString);
                                                    } else {
                                                        localStorage.removeItem(localStorageKey);
                                                    }
                                                } catch (e) {
                                                    // Local Storage Error.
                                                }
                                                setSourceFilters(sourcesCopy);
                                            }
                                        }))}
                                        columnStyle='flow'
                                        toggleAll={() => {
                                            const sourcesCopy: INewsFeedSourceFilter[] = sourceFilters.map(sourceFilter => ({
                                                newsFeedSource: sourceFilter.newsFeedSource,
                                                isOn: usingSourceFilter !== 'all'
                                            }));
                                            // Save current settings to local storage.
                                            const localStorageSettingsString = getSourceStringFromSourceList(sourcesCopy, 'off');
                                            try {
                                                if (localStorageSettingsString !== null) {
                                                    localStorage.setItem(localStorageKey, localStorageSettingsString);
                                                } else {
                                                    localStorage.removeItem(localStorageKey);
                                                }
                                            } catch (e) {
                                                // Local Storage Error.
                                            }
                                            setSourceFilters(sourcesCopy);
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    </div>

                    {/* List of news items */}
                    {displayNewsItems()}

                    {/* "Get more news" Button */}
                    <div className='load-more-button-wrapper'>
                    {(dataStatus !== 'fully loaded') && (
                        <Button
                            size='lg'
                            buttonType='secondary'
                            onClick={() => {
                                setItemsPerPage(itemsPerPage + ITEMS_TO_DISPLAY_PER_FETCH)
                            }}
                            disabled={dataStatus !== 'complete' || usingSourceFilter === 'none'}
                        >
                            {(usingSourceFilter === 'none')
                                ? 'Hætt að sækja. Veldu fleiri veitur.'
                                : (dataStatus === 'no data' || dataStatus === 'incomplete')
                                ? 'Hleður...'
                                : 'Sýna fleiri fréttir'}
                        </Button>
                    )}
                    </div>
                </div>
                <div className="col-lg-4 source-filter-wrapper">
                    <h4 className='veitur'>Veitur</h4>
                    {(sourceFilters === null)
                    ? <Loading />
                    : (sourceFilters instanceof Error)
                    ? <ErrorAlert error={sourceFilters} />
                    : (
                        <>
                            <div className='source-filters'>
                                <FilterItem
                                    showCheck
                                    size='lg'
                                    text="Velja allt"
                                    selected={usingSourceFilter === 'all'}
                                    toggleSelected={() => {
                                        const sourcesCopy: INewsFeedSourceFilter[] = sourceFilters.map(sourceFilter => ({
                                                newsFeedSource: sourceFilter.newsFeedSource,
                                                isOn: usingSourceFilter !== 'all'
                                        }));
                                        // Save current settings to local storage.
                                        const localStorageSettingsString = getSourceStringFromSourceList(sourcesCopy, 'off');
                                        try {
                                            if (localStorageSettingsString !== null) {
                                                localStorage.setItem(localStorageKey, localStorageSettingsString);
                                            } else {
                                                localStorage.removeItem(localStorageKey);
                                            }
                                        } catch (e) {
                                            // Local Storage Error.
                                        }
                                        setSourceFilters(sourcesCopy);
                                    }}
                                />
                                {sourceFilters.map(({newsFeedSource, isOn }, i) =>
                                    <FilterItem
                                        key={i}
                                        showCheck
                                        size='lg'
                                        selected={isOn}
                                        text={newsFeedSource.fullSource}
                                        locked={newsFeedSource.paywall}
                                        toggleSelected={() => {
                                            // Copy to prevent corruption of state.
                                            const sourcesCopy = [...sourceFilters];
                                            sourcesCopy.splice(i, 1, {
                                                newsFeedSource,
                                                isOn: !isOn
                                            });
                                            // Save current settings to local storage.
                                            const localStorageSettingsString = getSourceStringFromSourceList(sourcesCopy, 'off');
                                            try {
                                                if (localStorageSettingsString !== null) {
                                                    localStorage.setItem(localStorageKey, localStorageSettingsString);
                                                } else {
                                                    localStorage.removeItem(localStorageKey);
                                                }
                                            } catch (e) {
                                                // Local Storage Error.
                                            }
                                            setSourceFilters(sourcesCopy);
                                        }}
                                    />
                                )}
                            </div>
                            <div className='vaktarinn-wrapper'>
                                <object width="200" data="https://cdn.livemarketdata.com/KeldanImages/VaktarinnLogo.svg" aria-label="Vaktarinn">Vaktarinn</object>
                                <p className='vaktarinn-description paragraph-small'>Þarft þú ítarlegri fréttavöktun? Tilkynningar Vaktarans gera þér kleift að bregðast við umræðu um fyrirtækið þitt á meðan hún á sér stað.</p>
                                <div className='vaktarinn-button-wrapper'>
                                    <Button
                                        buttonType="secondary"
                                        size="sm"
                                        anchorProps={{
                                            href: 'https://portal.vaktarinn.is/login',
                                            target: '_blank',
                                            rel: 'noopener noreferrer'
                                        }}
                                    >
                                        Innskráning í Vaktarann
                                    </Button>
                                    <Button
                                        buttonType="primary"
                                        size="sm"
                                        anchorProps={{href: '/Vaktarinn#askrift'}}
                                    >
                                        Áskrift að Vaktaranum
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className='vaktarinn-wrapper tabletView'>
                    <object width="200" data="https://cdn.livemarketdata.com/KeldanImages/VaktarinnLogo.svg" aria-label="Vaktarinn">Vaktarinn</object>
                    <p className='vaktarinn-description paragraph-small'>Þarft þú ítarlegri fréttavöktun? Tilkynningar Vaktarans gera þér kleift að bregðast við umræðu um fyrirtækið þitt á meðan hún á sér stað.</p>
                    <div className='vaktarinn-button-wrapper'>
                        <Button
                            buttonType="secondary"
                            size="sm"
                            anchorProps={{
                                href: 'https://portal.vaktarinn.is/login',
                                target: '_blank',
                                rel: 'noopener noreferrer'
                            }}
                        >
                            Innskráning í Vaktarann
                        </Button>
                        <Button
                            buttonType="primary"
                            size="sm"
                            anchorProps={{href: '/Vaktarinn#askrift'}}
                        >
                            Áskrift að Vaktaranum
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FrettavaktContainer;