// Package imports:
import React, { useState } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
// Component imports:
import Input from '../../../ui-elements/Input/Formik/Input';
import Button from '../../../ui-elements/Button/Button';
import Link from '../../../ui-elements/Link/Link';
import Checkbox from '../../../ui-elements/Input/Formik/Checkbox';
import Alert from '../../../ui-elements/Alert/Alert';
// Service imports:
import { formatNumber, gtmPush, isSSN, generatePassword, isPerson } from '../../../services/utils';
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { SignUpType, KeldanSubOption, AppSubOption, IPriceInfo } from '../../../types/SignUpTypes';


interface IProps {
    signUpType?: SignUpType,
    subscriptionChoice: KeldanSubOption | AppSubOption,
    price: number,
    activeStep: number,
    setActiveStep(n: number): void,
    addOnItems?: (IPriceInfo | undefined)[]
}

interface IFormProps {
    values: IFormValues
}
interface IFormValues {
    userSSN?: string,
    userFullName?: string,
    email: string,
    emailAgain: string,
    password: string,
    passwordAgain: string,
    ssn?: string,
    noSsn?: boolean,
    name?: string,
    address?: string,
    postalCode?: string,
    city?: string,
    phone?: string,
    cardNumber?: string,
    cardExp?: string,
    cvc?: string,
    consent?: boolean,
    discountCode?: string
}

type DiscountCodeValidity = 'valid' | 'wrong' | 'expired' | null;

const SignUpForm: React.FC<IProps> = ({
    subscriptionChoice,
    price,
    activeStep,
    setActiveStep
}) => {
    const [successful, setSuccessful] = useState(false);
    const [userExists, setUserExists] = useState<boolean | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [discountCodeValidity, setDiscountCodeValidity] = useState<DiscountCodeValidity>(null);

    
    async function _usernameCheck(username: string, discountCode?: string) {
        try {
            const requestQuery = new URLSearchParams({
                username
            });
            const url = '/Home/UsernameCheck';
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (response.ok) {
                const responseBody = await response.json();
                const { exists, error } = responseBody;
                if (error) {
                    setErrorMessage(error);
                } else {
                    setUserExists(exists);
                }
                if (!exists) {
                    if (discountCode) {
                        _checkDiscountCodeValidity(discountCode);
                    } else {
                        setActiveStep(activeStep+1);
                    }
                }
            } else {
                setErrorMessage(ErrorMessages.RequestFailed);
            }
        } catch (e) {
            
            setErrorMessage(ErrorMessages.NetworkError);
        }
    }

    async function _checkDiscountCodeValidity(discountCode: string) {
        try {
            const requestQuery = new URLSearchParams({
                discountCode
            });
            const url = '/Home/CheckDiscountCodeValidity';
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (!response.ok) {
                setErrorMessage(ErrorMessages.RequestFailed);
                return;
            }
            const responseBody = await response.json();
            const { codeExists, codeValid, error } = responseBody;
            if (error) {
                setErrorMessage(error);
            }
            if (codeExists) {
                if (codeValid) {
                    setDiscountCodeValidity('valid');
                    setActiveStep(activeStep+1);
                } else {
                    setDiscountCodeValidity('expired');
                }
            } else {
                setDiscountCodeValidity('wrong');
            }
        } catch (e) {
            setErrorMessage(ErrorMessages.NetworkError);
        }
    }
    
    async function _submitForm(val: IFormValues, actions: any) {
        setLoading(true);
        try {
            const requestQuery = buildRequestQuery(val);
            const url = `${GET_KELDAN_API_URL()}/Nyskraning`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (!response.ok) {
                setLoading(false);
                setErrorMessage(ErrorMessages.RequestFailed);
                return;
            }
            const responseBody = await response.json();
            setLoading(false);
            const { success, usernameExists, error } = responseBody;
            setSuccessful(success);
            setUserExists(usernameExists);
            if (error) {
                setErrorMessage(error);
            }
            if (userExists === false) {
                if (success) {
                    gtmPush('buttonClick', 'nyskra', 'success')
                };
                setActiveStep(activeStep+1);
            }
        } catch (e) {
            setLoading(false);
            setErrorMessage(ErrorMessages.NetworkError);
        }
    }
    
    function buildRequestQuery(val: IFormValues): URLSearchParams {
        const requestQuery = new URLSearchParams({
            userSSN: val.userSSN ?? '',
            userFullName: val.userFullName ?? '',
            email: val.email,
            password: val.password,
            ssn: val.ssn ?? '',
            name: val.name !== undefined && val.name !== null && val.name.length > 0 ? val.name : val.email,
            address: val.address ?? '',
            postalCode: val.postalCode ?? '',
            city: val.city ?? '',
            phone: val.phone ?? '',
            cardNumber: val.cardNumber ?? '',
            cardExp: val.cardExp ?? '',
            cvc: val.cvc ?? '',
            plan: (subscriptionChoice === 'premium1' || subscriptionChoice === 'premium2') ? '1' : '3',
            discountCode: val.discountCode ?? ''
        });
        return requestQuery;
    }
    async function _handleSubmit(val: IFormValues, actions: any) {
        if (activeStep === 0) {
            await _usernameCheck(val.email, val.discountCode);
        } else {
            await _submitForm(val, actions);
        }
		window.scrollTo(0, 0);
    }
    const vat = price * 0.24;

    return (
        <div className={cx('KCL_sign-up-form', 'form--signup', {
            'user-exists': userExists,
            'error-message': errorMessage
        })}>
            <Formik
                initialValues={{
                    userFullName: '',
                    userSSN: '',
                    email: '', 
                    emailAgain: '',
                    password: '',
                    passwordAgain: '',
                    ssn: '',
                    noSsn: false,
                    name: '',
                    address: '',
                    postalCode: '',
                    city: '',
                    phone: '',
                    cardNumber: '',
                    cardExp: '',
                    cvc: '',
                    consent: false,
                    discountCode: ''
                }}
                validationSchema={Yup.object({
                    userFullName: Yup.string(),
                    userSSN: (subscriptionChoice === 'free')
                        ? Yup.string()
                            .matches(/^(\d{6}-\d{4})|^(\d+)$/, 'Kennitala má einungis innihalda tölustafi')
                            .min(10, 'Kennitala þarf að innihalda 10 tölustafi')
                            .max(11, 'Kennitala má ekki vera meira en 10 tölustafir')
                            .test('ssn-test', 'Ógild kennitala', (value) => isSSN(value, true))
                        : Yup.string()
                            .required('Kennitala verður að fylgja')
                            .matches(/^(\d{6}-\d{4})|^(\d+)$/, 'Kennitala má einungis innihalda tölustafi')
                            .min(10, 'Kennitala þarf að innihalda 10 tölustafi')
                            .max(11, 'Kennitala má ekki vera meira en 10 tölustafir')
                            .test('ssn-test', 'Ógild einstaklings kennitala', (value) => isPerson(value)),
                    email: Yup.string()
                        .email('Ógilt netfang')
                        .required('Netfang verður að fylgja'),
                    emailAgain: Yup.string()
                        .email('Ógilt netfang')
                        .oneOf([Yup.ref('email')], 'Netföng verða að vera eins')
                        .required('Netföng verða að vera eins'),
                    password: Yup.string()
                        .required('Lykilorð verður að fylgja')
                        .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).{12,}$/, 'Lykilorð of veikt, þarf að innihalda a.m.k. 12 stafi, einn tölustaf, einn hástaf og einn lágstaf.'),
                    passwordAgain: Yup.string()
                        .oneOf([Yup.ref('password')], 'Lykilorð verða að vera eins')
                        .required('Lykilorð verða að vera eins'),
                    ssn: (subscriptionChoice === 'free')
                        ? Yup.string()
                            .matches(/^(\d{6}-\d{4})|^(\d+)$/, 'Kennitala má einungis innihalda tölustafi')
                            .min(10, 'Kennitala þarf að innihalda 10 tölustafi')
                            .max(11, 'Kennitala má ekki vera meira en 10 tölustafir')
                            .test('ssn-test', 'Ógild kennitala', (value) => isSSN(value, true))
                        : Yup.string()
                            .required('Kennitala verður að fylgja')
                            .matches(/^(\d{6}-\d{4})|^(\d+)$/, 'Kennitala má einungis innihalda tölustafi')
                            .min(10, 'Kennitala þarf að innihalda 10 tölustafi')
                            .max(11, 'Kennitala má ekki vera meira en 10 tölustafir')
                            .test('ssn-test', 'Ógild kennitala', (value) => isSSN(value)),
                    noSsn: Yup.bool(),
                    name: Yup.string(),
                    address: Yup.string(),
                    postalCode: Yup.number().typeError('Póstnúmer má einungis innihalda tölustafi'),
                    city: Yup.string(),
                    phone: Yup.string()
                        .matches(/^[+]?\d+$/, 'Símanúmer má einungis innihalda tölustafi')
                        .min(7, 'Lágmarkslengd eru 7 tölustafir')
                        .max(11, 'Hámarkslengd eru 10 tölustafir'),
                    cardNumber: subscriptionChoice === 'free'
                        ? Yup.string()
                        : Yup.string()
                            .required('Kortanúmer verður að fylgja'),
                    cardExp: subscriptionChoice === 'free'
                        ? Yup.string()
                            .matches(/(\d{2}\/\d{2})?/, 'Gildistími verður að vera á forminu MM/YY')
                        : Yup.string()
                            .required('Gildistími verður að fylgja')
                            .matches(/\d{2}\/\d{2}/, 'Gildistími verður að vera á forminu MM/YY'),
                    cvc: subscriptionChoice === 'free'
                        ? Yup.string()
                            .matches(/(\d{3})?/, 'CVC verður að vera 3 tölustafir')
                        : Yup.string()
                            .required('CVC verður að fylgja')
                            .matches(/(\d{3})/, 'CVC verður að vera 3 tölustafir'),
                    consent: Yup.bool()
                        .oneOf([true], 'Samþykktu skilmálana'),
                    discountCode: Yup.string()
                })}
                validateOnBlur={true}
                onSubmit={_handleSubmit}
                component={(props: { values: IFormValues }) => (
                    <MyForm
                        values={props.values}
                        subscriptionChoice={subscriptionChoice}
                        activeStep={activeStep}
                        price={price}
                        vat={vat}
                        loading={loading}
                        setActiveStep={setActiveStep}
                        discountCodeValidity={discountCodeValidity}
                        setDiscountCodeValidity={setDiscountCodeValidity}
                        userExists={userExists}
                        setUserExists={setUserExists}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        successful={successful}
                    />
                )}
            />
        </div>
    );
}

interface ISubFormProps extends IFormProps {
    subscriptionChoice: KeldanSubOption | AppSubOption,
    activeStep?: number,
    price: number,
    vat: number,
    loading?: boolean,
    setActiveStep?(n: number): void,
    setFieldValue?(field: string, value: any, shouldValidate?: boolean): void,
    discountCodeValidity?: 'valid' | 'wrong' | 'expired' | null,
    setDiscountCodeValidity?(validity: 'valid' | 'wrong' | 'expired' | null): void,
    userExists?: boolean | null,
    setUserExists?(exists: boolean | null): void,
    errorMessage?: string,
    setErrorMessage?(message: string): void,
    successful?: boolean
}

const MyForm: React.FC<ISubFormProps> = ({
    values,
    subscriptionChoice,
    activeStep,
    price,
    vat,
    loading,
    setActiveStep = () => {},
    discountCodeValidity,
    setDiscountCodeValidity = () => {},
    userExists,
    setUserExists = () => {},
    errorMessage,
    setErrorMessage = () => {},
    successful
}) => {
    const { setFieldValue } = useFormikContext();
    const displayFormBody = () => {
        if (userExists) {
            return (<>
                <Alert
                    type='alert'
                    headText='Netfang er nú þegar skráð. Farðu til baka til að breyta eða innskráðu þig.'
                />
                <div className="signup-error__actions">
                    <Link
                        onClick={() => {
                            setUserExists(null); 
                            values.consent = false;
                            setDiscountCodeValidity(null);
                            setActiveStep(0);
                        }}
                        linkSize='16'
                        icon='back'
                    >
                        Fara til baka og breyta
                    </Link>
                    <span className='user-exists__actions'>
                        <Link
                            url="/Innskraning"
                            linkSize='16'
                            icon='forward'
                        >
                            Innskrá á Kelduna
                        </Link>
                        <Link
                            url="/Nytt-Lykilord"
                            linkSize='16'
                            icon='forward'
                        >
                            Nýtt lykilorð
                        </Link>
                    </span>
                </div>
            </>)
        }
        if (errorMessage) {
            return (
                <div>
                    <Alert
                        type='error'
                        headText='Villa'
                        text={<p>{errorMessage}</p>}
                    />
                    <div className="signup-error__actions">
                        <Link
                            onClick={() => {
                                setActiveStep(0);
                                setErrorMessage('');
                                values.consent = false;
                            }} 
                            linkSize='14'
                            icon='back'
                        >
                            Fara til baka og breyta
                        </Link>
                    </div>
                </div>
            )
        }
        switch (activeStep) {
            case 0:
                return (
                    <StepZero
                        values={values}
                        subscriptionChoice={subscriptionChoice}
                        price={price}
                        vat={vat}
                        setFieldValue={setFieldValue}
                        discountCodeValidity={discountCodeValidity}
                        setDiscountCodeValidity={setDiscountCodeValidity}
                    />
                )
            case 1:
                return (
                    <StepOne
                        values={values}
                        subscriptionChoice={subscriptionChoice}
                        price={price}
                        vat={vat}
                        loading={loading}
                        setActiveStep={setActiveStep}
                    />
                )
            case 2:
                if (successful) {
                    return (
                        <StepTwo
                            values={values}
                            subscriptionChoice={subscriptionChoice}
                            price={price}
                            vat={vat}
                        />
                    )
                }
                return null;
        }
    }
    return <Form>
        {displayFormBody()}
    </Form>
}

const StepZero: React.FC<ISubFormProps> = ({
    values,
    subscriptionChoice,
    price,
    vat,
    setFieldValue = () => {},
    discountCodeValidity,
    setDiscountCodeValidity = () => {}
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(!showPassword);
    return <div className="sign-up-step--0">
        <div className="form__body">
            <div className="form__section">
                <h3>Upplýsingar um notanda</h3>
                <div className='form__row'>
                    <div className='form__col'>
                        <Input
                            label="Kennitala"
                            name="userSSN"
                            id="userSSN"
                            value={values.userSSN}
                        />
                    </div>
                    <div className='form__col'>
                        <Input
                            label="Nafn"
                            name="userFullName"
                            id="userFullName"
                            value={values.userFullName}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__col">
                        <Input 
                            label="Netfang"
                            name="email"
                            id="email"
                            autoComplete='new-password'
                            value={values.email}
                        />
                    </div>
                    <div className="form__col">
                        <Input
                            label="Netfang aftur"
                            name="emailAgain"
                            id="emailAgain"
                            autoComplete='new-password'
                            value={values.emailAgain}
                        />
                    </div>
                </div>
                <div className="form__row">
                    <div className="form__col">
                        <Input 
                            label="Lykilorð"
                            inputType='password'
                            name="password"
                            id="password"
                            autoComplete='new-password'
                            value={values.password}
                            visible={showPassword}
                            toggleVisible={togglePassword}
                        />
                    </div>
                    <div className="form__col">
                        <Input
                            label="Lykilorð aftur"
                            inputType='password'
                            name="passwordAgain"
                            id="passwordAgain"
                            autoComplete='new-password'
                            value={values.passwordAgain}
                            visible={showPassword}
                            toggleVisible={togglePassword}
                        />
                    </div>
                </div>
                <div className='password-info-and-generator'>
                    <div className="password-info">
                        <FontAwesomeIcon icon={faInfoCircle} className="icon-fa" />
                        <p className='paragraph-small--italic'>
                            Athugið að lykilorð þarf að innihalda að minnsta kosti 12 stafi; einn tölustaf, einn hástaf og einn lágstaf.
                        </p>
                    </div>
                    <Button
                        buttonType='secondary'
                        size='sm'
                        type="button"
                        onClick={() => {
                            const pwd = generatePassword();
                            setFieldValue('password', pwd, false);
                            setFieldValue('passwordAgain', pwd, false);
                        }}
                    >
                        Búa til lykilorð
                    </Button>
                </div>
            </div>
            <div className="form__section">
                <h3>Greiðsluupplýsingar</h3>
                {subscriptionChoice === 'free' &&
                    <p className="paragraph">
                        Til að geta keypt skýrslur inni á Keldunni þurfa greiðsluupplýsingar að vera til staðar.
                        Þú getur valið að fylla inn eftirfarandi reiti núna eða síðar inni á síðunni <span className='paragraph--bold'>Stillingar</span>.
                    </p>
                }
                <div className="form__group">
                    <h4>Upplýsingar um greiðanda</h4>
                    <div className="form__row">
                        <div className="form__col">
                            <Input
                                label="Kennitala"
                                name="ssn"
                                id="ssn"
                                disabled={values.noSsn}
                                value={values.ssn}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Nafn"
                                name="name"
                                id="name"
                                disabled={values.noSsn}
                                value={values.name}
                            />
                        </div>
                    </div>
                    <div className="form__row form__row--alt">
                        <div className="form__col">
                            <Checkbox 
                                label="Er ekki með íslenska kennitölu"
                                name="noSsn"
                                id="noSsn"
                                value={values.noSsn?.toString()}
                            />
                        </div>
                    </div>
                    {values.noSsn && (
                        <div className="form__additional-info">
                            <p className='paragraph--bold'>Hafðu samband við Kelduna</p>
                            <Link
                                url="mailto:info@keldan.is"
                                linkSize='16'
                                icon='mail'
                            >
                                info@keldan.is
                            </Link>
                            <Link
                                url="tel:5101050"
                                linkSize='16'
                                icon='phone'
                            >
                                510-1050
                            </Link>
                        </div>
                    )}
                    <div className="form__row">
                        <div className="form__col">
                            <Input
                                label="Heimilisfang"
                                name="address"
                                id="address"
                                disabled={values.noSsn}
                                value={values.address}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Póstnúmer"
                                name="postalCode"
                                id="postalCode"
                                disabled={values.noSsn}
                                value={values.postalCode}
                            />
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__col">
                            <Input
                                label="Sveitarfélag"
                                name="city"
                                id="city"
                                disabled={values.noSsn}
                                value={values.city}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Farsími"
                                name="phone"
                                id="phone"
                                disabled={values.noSsn}
                                value={values.phone}
                            />
                        </div>
                    </div>
                </div>
                {subscriptionChoice === 'premium1' &&
                    <div className="form__group form__group--discount">
                        <h4>Afsláttur</h4>
                        <div className="form__row">
                            <div className="form__col">
                                <Input
                                    label="Afsláttarkóði"
                                    name="discountCode"
                                    id="discountCode"
                                    value={values.discountCode}
                                    onBlur={() => setDiscountCodeValidity(null)}
                                    disabled={values.noSsn}
                                />
                                {discountCodeValidity === 'expired'
                                    ? <Alert
                                        type="error"
                                        headText="Kóði útrunninn"
                                    />
                                    : discountCodeValidity === 'wrong' &&
                                        <Alert
                                            type="error"
                                            headText="Rangur kóði"
                                        />
                                }
                            </div>
                            <div className="form__col"></div>
                        </div>
                    </div>
                }
                <div className="form__group">
                    <h4>Kortaupplýsingar</h4>
                    <div className="form__row">
                        <div className="form__col">
                            <Input
                                label="Kortanúmer"
                                name="cardNumber"
                                id="cardNumber"
                                disabled={values.noSsn}
                                value={values.cardNumber}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="MM/YY"
                                name="cardExp"
                                id="cardExp"
                                disabled={values.noSsn}
                                value={values.cardExp}
                            />
                        </div>
                        <div className="form__col form__col--cvc">
                            <Input
                                label="CVC"
                                name="cvc"
                                id="cvc"
                                disabled={values.noSsn}
                                value={values.cvc}
                            />
                            <div className="form__icon">
                                <object data="https://cdn.livemarketdata.com/KeldanImages/cvc.svg" aria-label="CVC">CVC</object>
                            </div>
                        </div>
                    </div>
                </div>
                {subscriptionChoice !== 'free' && !values.noSsn && (
                    <div className="form__group form__group-details">
                        <dl>
                            <dt>Mánaðarleg áskrift að Keldunni fyrir fyrirtæki</dt>
                            <dd>{formatNumber(price, null, 0)} kr.</dd>
                        </dl>
                        <dl>
                            <dt>Virðisauki 24%</dt>
                            <dd>{formatNumber(vat, null, 0)} kr.</dd>
                        </dl>
                        <dl className="form__group-total">
                            <dt>Samtals til greiðslu</dt>
                            <dd>{formatNumber(price + vat, null, 0)} kr.</dd>
                        </dl>
                    </div>
                )}
            </div>
            <div className="form__section">
                <div className="form__group form__group--consent">
                    <Checkbox
                        id="consent"
                        label={<span>Ég hef lesið og skilið <Link url="/Skilmalar" linkSize='16' targetBlank>skilmála Keldunnar</Link></span>}
                        name="consent"
                        value={values.consent?.toString()}
                    />
                </div>
            </div>
        </div>
        <div className="form__actions">
            <Button
                buttonType="primary"
                disabled={values.noSsn && subscriptionChoice !== 'free'}
            >
                Halda áfram
            </Button>
        </div>
    </div>
}

const StepOne: React.FC<ISubFormProps> = ({
    values,
    subscriptionChoice,
    price,
    vat,
    loading,
    setActiveStep = () => {}
}) => {
    return <div className="sign-up-step--1">
        <p className='paragraph--bold'>
            Eru allar upplýsingar rétt fylltar út? Ef ekki getur þú farið til baka og breytt.
        </p>
        <div className="form__body confirmation-step">
            <div className="form__section">
                <h4>Upplýsingar um notanda</h4>
                <dl>
                    <dt>Netfang:</dt>
                    <dd>{values.email}</dd>
                </dl>
            </div>
            {values.ssn && <div className="form__section">
                <h4>Upplýsingar um greiðanda</h4>
                <dl>
                    <dt>Kennitala:</dt>
                    <dd>{values.ssn}</dd>
                </dl>
                <dl>
                    <dt>Nafn:</dt>
                    <dd>{values.name}</dd>
                </dl>
                <dl>
                    <dt>Heimilisfang:</dt>
                    <dd>{values.address}</dd>
                </dl>
                <dl>
                    <dt>Póstnúmer:</dt>
                    <dd>{values.postalCode}</dd>
                </dl>
                <dl>
                    <dt>Sveitarfélag:</dt>
                    <dd>{values.city}</dd>
                </dl>
                <dl>
                    <dt>Farsími:</dt>
                    <dd>{values.phone}</dd>
                </dl>
            </div>}
            {values.discountCode && <div className="form__section">
                <h4>Afsláttur</h4>
                <dl>
                    <dt>Afsláttarkóði:</dt>
                    <dd>{values.discountCode}</dd>
                </dl>
            </div>}
            {values.cardNumber && <div className="form__section">
                <h4>Kortaupplýsingar</h4>
                <dl>
                    <dt>Kreditkort:</dt>
                    <dd>************ {values.cardNumber?.substring(12)}</dd>
                    <dd>{values.cardExp}</dd>
                    <dd>{values.cvc}</dd>
                </dl>
            </div>}
            <div>
            {subscriptionChoice === 'free'
                ? <p className='paragraph-small--bold'>Frí aðgangur að Keldunni</p>
                : <>
                    <p className='paragraph-small--bold'>Mánaðarleg áskrift að Keldunni fyrir fyrirtæki</p>
                    <p className='paragraph-small--bold'>Samtals til greiðslu: {formatNumber(price + vat, null, 0)} kr. með VSK</p>
                </>
            }
            </div>
        </div>
        <div className="form__actions">
            <Link
                onClick={() => {
                    setActiveStep(0);
                    values.consent = false;
                }}
                linkSize='14'
                icon='back'
            >
                Fara til baka og breyta
            </Link>
            <Button showLoader={loading} buttonType="primary" size="lg">Staðfesta</Button>
        </div>
    </div>
}

const StepTwo: React.FC<ISubFormProps> = ({
    values,
    subscriptionChoice,
    price,
    vat
}) => {
    return <div className="sign-up-step--2">
        <p><strong>Þú hefur fengið sendan tölvupóst á uppgefið netfang þar sem þú þarft að staðfesta netfangið þitt.</strong></p>
        <p>Pósturinn berst frá admin@keldan.is en skoðaðu ruslpóst ef tölvupósturinn hefur ekki borist í innhólf.</p>
        <div className="form__body final-step">
            <div className="form__section">
                <h4>Upplýsingar um notanda</h4>
                <dl>
                    <dt>Netfang:</dt>
                    <dd>{values.email}</dd>
                </dl>
            </div>
            {values.ssn && <div className="form__section">
                <h4>Upplýsingar um greiðanda</h4>
                <dl>
                    <dt>Kennitala:</dt>
                    <dd>{values.ssn}</dd>
                </dl>
                <dl>
                    <dt>Nafn:</dt>
                    <dd>{values.name}</dd>
                </dl>
                <dl>
                    <dt>Heimilisfang:</dt>
                    <dd>{values.address}</dd>
                </dl>
                <dl>
                    <dt>Póstnúmer:</dt>
                    <dd>{values.postalCode}</dd>
                </dl>
                <dl>
                    <dt>Sveitarfélag:</dt>
                    <dd>{values.city}</dd>
                </dl>
                <dl>
                    <dt>Farsími:</dt>
                    <dd>{values.phone}</dd>
                </dl>
            </div>}
            {values.cardNumber && <div className="form__section">
                <h4>Kortaupplýsingar</h4>
                <dl>
                    <dt>Kreditkort:</dt>
                    <dd>************ {values.cardNumber?.substring(12)}</dd>
                    <dd>{values.cardExp}</dd>
                    <dd>{values.cvc}</dd>
                </dl>
            </div>}
            <div>
                {subscriptionChoice === 'free'
                    ? <p><strong>Frí aðgangur að Keldunni</strong></p>
                    : <>
                        <p><strong>Mánaðarleg áskrift að Keldunni fyrir fyrirtæki</strong></p>
                        <p><strong>Samtals til greiðslu: {formatNumber(price + vat, null, 0)} kr. með VSK</strong></p>
                    </>
                }
            </div>
        </div>
    </div>
}

export default SignUpForm;