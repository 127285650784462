// Package imports:
import React, { useMemo } from 'react';
// Component imports:
import ColoredNumber, { BackgroundSign, Sign } from '../ColoredNumber/ColoredNumber';

interface IProps {
    hasArrow?: boolean,
    hasBackground?: boolean,
    hasColor?: boolean,
    currentValue: number | null,
    previousValue: number | null
}

const CalculatedColoredNumber: React.FC<IProps>= ({
    children,
    currentValue,
    previousValue,
    hasArrow,
    hasBackground,
    hasColor
}) => {
    const sign: Sign = useMemo(() => {
        if (currentValue === null) {
            return 'neutral';
        }
        if (currentValue > 0) {
            return 'pos';
        }
        if (currentValue < 0) {
            return 'neg';
        }
        return 'neutral';
    }, [currentValue]);
    const backgroundSign: BackgroundSign | undefined = useMemo(() => {
        if (previousValue === null || currentValue === null) {
            return undefined;
        }
        if (currentValue > previousValue) {
            return 'pos';
        }
        if (currentValue < previousValue) {
            return 'neg';
        }
        return undefined;
    }, [currentValue, previousValue]);
    
    return (
        <ColoredNumber
            sign={hasColor ? sign : undefined}
            background={hasBackground ? backgroundSign : undefined}
            hasArrow={hasArrow}
        >
            {children}
        </ColoredNumber>
    );
}

export default CalculatedColoredNumber;