// Package imports:
import React, { useState } from 'react';
import cx from 'classnames'
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import Icon from '../CustomIcon/CustomIcon';
import Label from '../../../ui-elements/Label/Label';
import Button from '../../../ui-elements/Button/Button';

export interface INavItem {
    link: string
    label: string | JSX.Element
    lock?: boolean
    isNew?: boolean
}

interface IButtonItem {
    label: string
    link: string
    icon: IconDefinition
}

interface IProps {
    label: string | JSX.Element,
    navItems: INavItem[][],
    buttonItems?: IButtonItem[],
    highlighted?: boolean,
    openByDefault?: boolean
}

const NavDropdown: React.FC<IProps> = ({
    label,
    navItems,
    buttonItems,
    highlighted = false,
    openByDefault = false
}) => {
    const [isOpen, setIsOpen] = useState(openByDefault);
    
    return (
        <li className={cx('nav__main-list', {'is-open': isOpen, 'highlighted': highlighted})}>
            <button type="button" onClick={() => setIsOpen(!isOpen || openByDefault)}>
                {label}
                {!openByDefault &&
                    <span className="icon">
                        <Icon type="dropdown-arrow" />
                    </span>
                }
            </button>
            <ul className='nav__sub'>
                <li className='nav__sub-col'>
                    <ul>
                        {navItems[0].map(({link, label, lock, isNew}, index) => (
                            <li key={index}>
                                <a href={link} className={cx({'is-disabled': lock})}>
                                    {label}
                                    {lock &&
                                        <span className="lock-icon">
                                            <FontAwesomeIcon icon={faLock} />
                                        </span>
                                    }
                                    {isNew && <Label labelType='new' text='Nýtt' />}
                                </a>
                            </li>
                        ))}
                    </ul>
                </li>
                {navItems.length > 1 &&
                    <li className='nav__sub-col'>
                        <ul>
                            {navItems[1].map(({link, label, lock, isNew}, index) => (
                                <li key={index}>
                                    <a href={link} className={cx({'is-disabled': lock})}>
                                        {label}
                                        {lock &&
                                            <span className="lock-icon">
                                                <FontAwesomeIcon icon={faLock} />
                                            </span>
                                        }
                                        {isNew && <Label labelType='new' text='Nýtt' />}
                                    </a>
                                </li>
                            ))}
                        </ul>
                        {buttonItems &&
                            <div className='call-to-action-wrapper'>
                                {buttonItems.map(({label, link, icon}, index) => (
                                    <Button anchorProps={{ href: link }} icon={icon} key={index}>
                                        {label}
                                    </Button>
                                ))}
                            </div>
                        } 
                    </li>
                }
            </ul>
        </li>
    );
}

export default NavDropdown;