// Package imports:
import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import { faFileMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import Button from '../../ui-elements/Button/Button';
import Modal from '../../ui-elements/Modal/Modal';
import IndividualMembershipReportExample from '../Individual/Hlutafelagathatttaka/ExampleReport';
import PropertyExampleReport from '../Property/ViewReport/ExampleReport';
import AssetsReportExample from '../Property/AssetsSearch/ExampleReport';
import GangverdReportExample from '../Property/GangverdReport/ExampleReport';
import VehicleProfileExample from '../Okutaeki/VehicleSearch/ExampleReport';
import ConstructionReportExample from '../Okutaeki/ConstructionsSearch/ExampleReport';
import VehiclesOwnedReportExample from '../Okutaeki/SsnSearch/ExampleReport';
import CompanyMembersReportExample from '../Company/ViewMembersReport/ExampleReport';
import CompanyCompareExample from '../Company/CompanyCompare/ExampleReport';
import Link from '../../ui-elements/Link/Link';

type ReportType = 'individual-membership' | 'property' | 'assets' | 'gangverd' | 'vehicle' | 'construction' | 'constructions-owned' | 'vehicles-owned' | 'vehicle-history' | 'company-members' | 'company-compare';

interface IProps {
    size: 'sm' | 'lg',
    reportType: ReportType
}

interface IExampleReportProperties {
    report: JSX.Element,
    className?: string,
    sourceForButton?: string,
    sourceForHeader?: string
}

const ExampleReportButton: React.FC<IProps> = ({
    size,
    reportType
}) => {
    const [ exampleOpen, setExampleOpen ] = useState(false);

    const properties: IExampleReportProperties = useMemo(() => {
        switch (reportType) {
            case 'individual-membership':
                return {
                    report: <IndividualMembershipReportExample />,
                    className: 'KCL_individual-profile'
                }
            case 'property':
                return {
                    report: <PropertyExampleReport />,
                    className: 'KCL_property-page',
                    sourceForButton: 'úr Fasteignaskrá',
                    sourceForHeader: 'úr Fasteignaskrá'
                }
            case 'assets':
                return {
                    report: <AssetsReportExample />,
                    sourceForButton: 'úr Fasteignaskrá',
                    sourceForHeader: 'úr Fasteignaskrá'
                }
            case 'gangverd':
                return {
                    report: <GangverdReportExample />,
                    className: 'KCL_property-page',
                    sourceForButton: 'úr Verðvísi',
                    sourceForHeader: 'úr Verðvísi'
                }
            case 'vehicle':
                return {
                    report: <VehicleProfileExample />,
                    className: 'KCL_vehicle-profile',
                    sourceForButton: 'úr Ökutækjaskrá',
                    sourceForHeader: 'úr Ökutækjaskrá'
                }
            case 'construction':
                return {
                    report: <ConstructionReportExample type='profile' />,
                    className: 'KCL_construction-profile',
                    sourceForButton: 'úr Vinnuvélaskrá - Vinnuvél',
                    sourceForHeader: 'úr Vinnuvélaskrá'
                }
            case 'constructions-owned':
                return {
                    report: <ConstructionReportExample type='owned' />,
                    sourceForButton: 'úr Vinnuvélaskrá - Eignastaða',
                    sourceForHeader: 'úr Vinnuvélaskrá'
                }
            case 'vehicles-owned':
                return {
                    report: <VehiclesOwnedReportExample />,
                    sourceForButton: 'úr Ökutækjaskrá',
                    sourceForHeader: 'úr Ökutækjaskrá'
                }
            case 'vehicle-history':
                return {
                    report: <img src="https://cdn.livemarketdata.com/KeldanImages/VehicleHistoryExample.png" alt="Ferilskrá ökutækis - dæmi" />,
                    sourceForButton: 'úr Ökutækjaskrá',
                    sourceForHeader: 'úr Ökutækjaskrá'
                }
            case 'company-members':
                return {
                    report: <CompanyMembersReportExample />,
                    sourceForHeader: 'úr Hlutafélagaskrá'
                }
            case 'company-compare':
                return {
                    report: <CompanyCompareExample />,
                    sourceForHeader: 'fyrir samanburð fyrirtækja'
                }
        }
    }, [reportType])

    const { report, className, sourceForButton, sourceForHeader } = properties;

    return <>
        {size === 'sm'
            ? <Link
                linkSize='14'
                className='example-link'
                onClick={(e) => {
                    e.preventDefault();
                    setExampleOpen(true);
                }}
                icon='image'
            >
                Sýnidæmi
            </Link>
            : <Button
                buttonType='secondary'
                size='lg'
                icon={faFileMagnifyingGlass}
                onClick={() => setExampleOpen(true)}
                className='example-button'
            >
                Sýnidæmi {sourceForButton ?? ''}
            </Button>
        }
        <Modal
            show={exampleOpen}
            hasCloseButton
            onHide={() => setExampleOpen(false)}
            header={<p className='example-label paragraph--bold'>Sýnidæmi {sourceForHeader ?? ''}</p>}
            body={report}
            className={cx(className, 'KCL_report--example')}
        />
    </>;
}

export default ExampleReportButton;