// Package imports:
import React from "react";
// Component imports:
import VehiclesOwnedReportBody from "./VehiclesOwnedReportBody";
// Type imports:
import { IVehicleReport } from "../../../types/VehicleTypes";


const EXAMPLE_DATA: IVehicleReport = {
    "eventDate": "15.02.2023 15:59:26",
    "carsReport": {
        "ssn": "4910090200",
        "name": "Keldan ehf",
        "address": "Borgartúni 25",
        "poststation": "105 Reykjavík",
        "vehicleList": [{
            "isCurrent": "1",
            "permno": "KOD14",
            "regno": "KOD14",
            "vin": "WP1KOD12ZGLA59822",
            "type": "SKODA KODIAQ",
            "color": "Svartur",
            "firstregdate": "01.03.2023",
            "modelyear": null,
            "productyear": null,
            "registrationtype": "Nýskráð - Almenn",
            "role": "Umráðamaður",
            "startdate": "19.03.2023",
            "enddate": null,
            "outofuse": "0",
            "otherowners": "0",
            "termination": "",
            "buyer": null,
            "owner": "5901091420",
            "vehiclestatus": "Í lagi",
            "usegroup": "Almenn notkun",
            "vehgroup": "Fólksbifreið (M1)",
            "platestatus": "Á ökutæki"
        }, {
            "isCurrent": "1",
            "permno": "KEL11",
            "regno": "KEL11",
            "vin": "VNKELD4N10A210787",
            "type": "KODI KELDAN HYBRID",
            "color": "Blár",
            "firstregdate": "13.05.2016",
            "modelyear": null,
            "productyear": null,
            "registrationtype": "Nýskráð - Almenn",
            "role": "Eigandi",
            "startdate": "10.02.2020",
            "enddate": null,
            "outofuse": "0",
            "otherowners": "0",
            "termination": "Núverandi eigandi",
            "buyer": null,
            "owner": "4910090200",
            "vehiclestatus": "Í lagi",
            "usegroup": "Almenn notkun",
            "vehgroup": "Fólksbifreið (M1)",
            "platestatus": "Á ökutæki"
        }, {
            "isCurrent": "0",
            "permno": "BRG25",
            "regno": "BRG25",
            "vin": "YV4B0RGL8H1121958",
            "type": "BORG TN25",
            "color": "Svartur",
            "firstregdate": "01.07.2016",
            "modelyear": null,
            "productyear": null,
            "registrationtype": "Nýskráð - Almenn",
            "role": "Eigandi",
            "startdate": "01.04.2019",
            "enddate": null,
            "outofuse": "0",
            "otherowners": "0",
            "termination": "Núverandi eigandi",
            "buyer": null,
            "owner": "4910090200",
            "vehiclestatus": "Í lagi",
            "usegroup": "Almenn notkun",
            "vehgroup": "Fólksbifreið (M1)",
            "platestatus": "Á ökutæki"
        }]
    }
}

const VehiclesOwnedReportExample: React.FC = () => {
    return <VehiclesOwnedReportBody vehicleReport={EXAMPLE_DATA} isExample />
};

export default VehiclesOwnedReportExample;