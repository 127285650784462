// Package imports:
import React from 'react';
// Component imports:
import ViewCompanyCompareBody from './ViewCompanyCompareBody';
// Service imports:
// Type imports:
import { IViewCompanyCompare } from '../../../types/CompanyTypes';

const EXAMPLE_DATA: IViewCompanyCompare = {
    "compareParameters": {
        "result": 0,
        "descr": null,
        "exception": null,
        "msg": null,
        "frontmsg": null,
        "errorCode": 0,
        "errorMessage": null,
        userName: '',
        productName: '',
        reply: '',
        eventDate: '',
        overview: {
            ssn: '5101050510',
            year: 2022,
            ssnList: ['7003973459', '4512673489']
        }
    },
    "companyData": [{
        "income_isk": 567890123,
        "expenses_isk": -444444444,
        "ebitda_isk": 125211111,
        "ebit_isk": 123445679,
        "profit_isk": 158789910,
        "assets_isk": 398765432,
        "equity_isk": 211111111,
        "liabilities_isk": 187654321,
        "cashfromoa_isk": 117203372,
        "cashfromia_isk": 0,
        "cashfromfa_isk": -48829997,
        "numbers_in": 1,
        "currency": "ISK",
        "fiscal_year": 2021,
        "count_employees": null,
        "company_id": "5101050510",
        "name": "Keldan á Íslandi ehf."
    }, {
        "income_isk": 512345678,
        "expenses_isk": -321098765,
        "ebitda_isk": 193356789,
        "ebit_isk": 191246913,
        "profit_isk": 218811447,
        "assets_isk": 287654321,
        "equity_isk": 164197532,
        "liabilities_isk": 123456789,
        "cashfromoa_isk": 99177059,
        "cashfromia_isk": 60000000,
        "cashfromfa_isk": -133501841,
        "numbers_in": 1,
        "currency": "ISK",
        "fiscal_year": 2020,
        "count_employees": null,
        "company_id": "5101050510",
        "name": "Keldan á Íslandi ehf."
    }, {
        "income_isk": 500914325,
        "expenses_isk": -389601988,
        "ebitda_isk": 115532445,
        "ebit_isk": 111312337,
        "profit_isk": 97882970,
        "assets_isk": 181662667,
        "equity_isk": 110101319,
        "liabilities_isk": 71561348,
        "cashfromoa_isk": 110517892,
        "cashfromia_isk": 2692221,
        "cashfromfa_isk": -114000000,
        "numbers_in": 1,
        "currency": "ISK",
        "fiscal_year": 2019,
        "count_employees": null,
        "company_id": "5101050510",
        "name": "Keldan á Íslandi ehf."
    }, {
        "income_isk": 442044322,
        "expenses_isk": -337391931,
        "ebitda_isk": 109905170,
        "ebit_isk": 104652391,
        "profit_isk": 92710102,
        "assets_isk": 192860473,
        "equity_isk": 126218349,
        "liabilities_isk": 66642124,
        "cashfromoa_isk": 81967817,
        "cashfromia_isk": -3251352,
        "cashfromfa_isk": -80000000,
        "numbers_in": 1,
        "currency": "ISK",
        "fiscal_year": 2018,
        "count_employees": null,
        "company_id": "5101050510",
        "name": "Keldan á Íslandi ehf."
    }],
    "comparablesData": {
        "Kodiak bílaleiga ehf.": [{
            "income_isk": 16968015,
            "expenses_isk": -16400604,
            "ebitda_isk": 1577870,
            "ebit_isk": 567411,
            "profit_isk": 925561,
            "assets_isk": 7416070,
            "equity_isk": 4077418,
            "liabilities_isk": 3338652,
            "cashfromoa_isk": null,
            "cashfromia_isk": null,
            "cashfromfa_isk": null,
            "numbers_in": 1,
            "currency": "ISK",
            "fiscal_year": 2018,
            "count_employees": 1,
            "company_id": "7003973459",
            "name": "Kodiak bílaleiga ehf."
        }, {
            "income_isk": 35647790,
            "expenses_isk": -48685781,
            "ebitda_isk": -9628418,
            "ebit_isk": -13037991,
            "profit_isk": -14474714,
            "assets_isk": 13721310,
            "equity_isk": -10397296,
            "liabilities_isk": 24118607,
            "cashfromoa_isk": null,
            "cashfromia_isk": null,
            "cashfromfa_isk": null,
            "numbers_in": 1,
            "currency": "ISK",
            "fiscal_year": 2019,
            "count_employees": 5,
            "company_id": "7003973459",
            "name": "Kodiak bílaleiga ehf."
        }, {
            "income_isk": 67890123,
            "expenses_isk": -64208642,
            "ebitda_isk": 8002579,
            "ebit_isk": 3681481,
            "profit_isk": 2450250,
            "assets_isk": 21234567,
            "equity_isk": -13333323,
            "liabilities_isk": 34567890,
            "cashfromoa_isk": null,
            "cashfromia_isk": null,
            "cashfromfa_isk": null,
            "numbers_in": 1,
            "currency": "ISK",
            "fiscal_year": 2020,
            "count_employees": 5,
            "company_id": "7003973459",
            "name": "Kodiak bílaleiga ehf."
        }, {
            "income_isk": 109876543,
            "expenses_isk": -147698777,
            "ebitda_isk": -22143333,
            "ebit_isk": -37822234,
            "profit_isk": -48933345,
            "assets_isk": 111222333,
            "equity_isk": -66667567,
            "liabilities_isk": 177889900,
            "cashfromoa_isk": null,
            "cashfromia_isk": null,
            "cashfromfa_isk": null,
            "numbers_in": 1,
            "currency": "ISK",
            "fiscal_year": 2021,
            "count_employees": null,
            "company_id": "7003973459",
            "name": "Kodiak bílaleiga ehf."
        }],
        "Dæmi ehf.": [{
            "income_isk": 229506168,
            "expenses_isk": -176896267,
            "ebitda_isk": 55741520,
            "ebit_isk": 52609901,
            "profit_isk": 43056070,
            "assets_isk": 100133272,
            "equity_isk": 65142884,
            "liabilities_isk": 34990388,
            "cashfromoa_isk": null,
            "cashfromia_isk": null,
            "cashfromfa_isk": null,
            "numbers_in": 1,
            "currency": "ISK",
            "fiscal_year": 2018,
            "count_employees": 1,
            "company_id": "4512673489",
            "name": "Dæmi ehf."
        }, {
            "income_isk": 268281058,
            "expenses_isk": -219143884,
            "ebitda_isk": 52952015,
            "ebit_isk": 49137174,
            "profit_isk": 41098233,
            "assets_isk": 97691988,
            "equity_isk": 49852010,
            "liabilities_isk": 47839978,
            "cashfromoa_isk": null,
            "cashfromia_isk": null,
            "cashfromfa_isk": null,
            "numbers_in": 1,
            "currency": "ISK",
            "fiscal_year": 2019,
            "count_employees": 5,
            "company_id": "4512673489",
            "name": "Dæmi ehf."
        }, {
            "income_isk": 345678901,
            "expenses_isk": -222222222,
            "ebitda_isk": 124691246,
            "ebit_isk": 123456679,
            "profit_isk": 134738284,
            "assets_isk": 332211000,
            "equity_isk": 221099889,
            "liabilities_isk": 111111111,
            "cashfromoa_isk": null,
            "cashfromia_isk": null,
            "cashfromfa_isk": null,
            "numbers_in": 1,
            "currency": "ISK",
            "fiscal_year": 2020,
            "count_employees": 5,
            "company_id": "4512673489",
            "name": "Dæmi ehf."
        }, {
            "income_isk": 420864208,
            "expenses_isk": -345345345,
            "ebitda_isk": 77842095,
            "ebit_isk": 75518863,
            "profit_isk": 85839962,
            "assets_isk": 333222111,
            "equity_isk": 233334345,
            "liabilities_isk": 99887766,
            "cashfromoa_isk": null,
            "cashfromia_isk": null,
            "cashfromfa_isk": null,
            "numbers_in": 1,
            "currency": "ISK",
            "fiscal_year": 2021,
            "count_employees": null,
            "company_id": "4512673489",
            "name": "Dæmi ehf."
        }]
    },
    "totals": {
        "max_year": 2021,
        "years": [ 2018, 2019, 2020, 2021 ],
        "income_count": [ 0, 0, 3, 3 ],
        "income_total": [ null, null, 925914702, 1098630874 ],
        "income_me": [ null, null, 518201716, 560209717 ],
        "expenses_count": [ 0, 0, 3, 3 ],
        "expenses_total": [ null, null, -607529629, -937488566 ],
        "ebitda_count": [ 0, 0, 3, 3 ],
        "ebitda_total": [ null, null, 326050614, 180909873 ],
        "ebit_count": [ 0, 0, 3, 3 ],
        "ebit_total": [ null, null, 318385073, 161142308 ],
        "profit_count": [ 0, 0, 3, 3 ],
        "profit_total": [ null, null, 355999981, 195696527 ],
        "assets_count": [ 0, 0, 3, 3 ],
        "assets_total": [ null, null, 641099888, 843209876 ],
        "assets_me": [ null, null, 287995661, 394174494 ],
        "liabilities_count": [ 0, 0, 3, 3 ],
        "liabilities_total": [ null, null, 269135790, 465431987 ],
        "equity_count": [ 0, 0, 3, 3 ],
        "equity_total": [ null, null, 371964098, 377777889 ],
        "ebitda_ratio_count": [ 0, 0, 3, 3 ],
        "ebitda_ratio_ebitda_total": [ null, null, 133277778, 99726492 ],
        "ebitda_ratio_income_total": [ null, null, 580892139, 664320770 ],
        "equity_ratio_count": [ 0, 0, 3, 3 ],
        "equity_ratio_equity_total": [ null, null, 146395414, 150735534 ],
        "equity_ratio_assets_total": [ null, null, 309742374, 505857418 ],
        "roe_ratio_count": [ 0, 0, 3, 3 ],
        "roe_ratio_profit_total": [ null, null, 152800018, 98040120 ],
        "roe_ratio_equity_total": [ null, null, 146395414, 150735534 ],
        "income_delta_count": [ 0, 0, 3, 3 ],
        "income_delta_current": [ null, null, 925914702, 1098630874 ],
        "income_delta_last": [ null, null, 804843173, 925914702 ],
        "expenses_delta_count": [ 0, 0, 3, 3 ],
        "expenses_delta_current": [ null, null, -607529629, -937488566 ],
        "expenses_delta_last": [ null, null, -657431653, -607529629 ]
    }
};

const CompanyCompareExample: React.FC = () => {
    return <ViewCompanyCompareBody id={'5101050510'} data={EXAMPLE_DATA} isExample />
}

export default CompanyCompareExample;