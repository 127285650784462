// Package imports:
import React from 'react';
import cx from 'classnames';
// Component imports:
// Service imports:
// Type imports:

interface IProps {
    data: number[]
}

const SmallColumnChart: React.FC<IProps> = ({
    data
}) => {
    const maximum = Math.max(...data);
    const minimum = Math.min(...data);
    const range = maximum >= 0 ? maximum - Math.min(0, minimum) : Math.abs(minimum);
    return (
        <div className='KCL_small-column-chart'>
            {data.map((value, index) => (
                <div
                    key={index}
                    className={cx('column', { 'negative': value < 0, 'positive': value > 0 })}
                    style={{
                        height: `${(Math.abs(value)/range)*100}%`,
                        top: value >= 0
                            ? `${((maximum-value)/range)*100}%`
                            : maximum >= 0
                            ? `${(maximum/range)*100}%`
                            : 0,
                        bottom: value >= 0
                            ? minimum >= 0
                            ? 0
                            : `${(Math.abs(minimum)/range)*100}%`
                            : `${(Math.abs(minimum-value)/range)*100}%`
                    }}
                />
            ))}
        </div>
    );
}

export default SmallColumnChart;