// Package imports:
import React, { useEffect, useState } from 'react';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Loading from '../../../ui-elements/Loading/Loading';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../../ui-elements/Link/Link';
import BulletList from '../../../ui-elements/Lists/BulletList';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched } from '../../../types/Types';
import { IKeldanApiResponse } from '../../../types/KeldanTypes';
import { ICompanyViewModel, ComparableCompany } from '../../../types/CompanyTypes';


const CompanySimilar: React.FC<ICompanyViewModel> = ({
    id
}) => {
    const [data, setData] = useState<Fetched<ComparableCompany[]>>(null);

    const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Company/GetSimilarCompanies/${id}`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                const responseBody: IKeldanApiResponse<ComparableCompany[]> = await response.json();
                if (responseBody.success) {
                    if(responseBody.result) setData(responseBody.result);
                } else {
                    const { message } = responseBody;
                    if (message) {
                        setData(new Error(message));
                    } else {
                        setData(new Error(ErrorMessages.ErrorInRequest));
                    }
                }
            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setData(new Error(ErrorMessages.NetworkError));
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <DisplayBox 
            className='KCL_company-similar'
            title="Svipuð fyrirtæki"
        >
            {data instanceof Error
                ? <Alert type='error' headText={data.message} />
                : data === null || data === undefined
                ? <Loading />
                : <BulletList
                    items={data.map((item) => (
                        <Link
                            splitLines
                            linkSize='15'
                            url={`/Fyrirtaeki/Yfirlit/${item.company_id}`}
                        >
                            <span>{item.name}</span>
                        </Link>
                    ))}
                />
            }
        </DisplayBox>
    );
}

export default CompanySimilar;