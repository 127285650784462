// Package imports:
import React, { useEffect, useMemo, useState } from 'react';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Button from '../../../ui-elements/Button/Button';
import Label from '../../../ui-elements/Label/Label';
import Link from '../../../ui-elements/Link/Link';
import Loading from '../../../ui-elements/Loading/Loading';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { getTableDate } from '../../../services/utils';
import { useBuyingProcessUrl } from '../../../services/buyhook';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched } from '../../../types/Types';
import { ICompanyViewModel, KeldanPaper, ReportsSearchModel } from '../../../types/CompanyTypes';

interface IOwnProps {
    limit?: number,
}

const CompanyPapers: React.FC<ICompanyViewModel & IOwnProps> = ({
    id,
    isAuthenticated,
    limit,
}) => {
    const [data, setData] = useState<Fetched<ReportsSearchModel>>(null);
    
    const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Company/GetCompanyPapersAndStatements/${id}`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const body = await response.json();
                    setData(body);
                }
            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setData(new Error(ErrorMessages.OtherShort));
        }
    }

    useEffect(() => {
        fetchData();
    }, [isAuthenticated]);

    const { tryPurchaseItem, isItemLoading, ModalsAndToasts } = useBuyingProcessUrl(
        (paper: KeldanPaper, modalReference: string) => {
            const url = `${GET_KELDAN_API_URL()}/Pdf/Fyrirtaekjaskra-Kaupa?fileId=${paper.id}&ssn=${id}&paperType=${paper.type}&reference=${modalReference}`;
            return url;
        },
        () => {
            window.setTimeout(() => {
                fetchData();
            }, 5*1000);
        },
        'fyrirtækjaskrá'
    );

    const sortPapers = (papers: KeldanPaper[]) => {
        return papers.sort((a, b) => {
            const aDate = a.collected;
            const bDate = b.collected;
            if (aDate === null && bDate === null) return 0;
            if (aDate === null) return 1;
            if (bDate === null) return -1;
            if (aDate < bDate) return 1;
            if (aDate > bDate) return -1;
            return 0;
        });
    }

    const papersToShow = useMemo(() => {
        if (data instanceof Error) return null;
        if (data?.papers === undefined || data.papers === null) return null;
        return sortPapers(data?.papers[0]?.papers)?.slice(0, limit);
    }, [data]);

    const getOwned = (paper: KeldanPaper) => {
        if (data instanceof Error) return undefined;
        if (isAuthenticated) {
            const or = data?.existing?.find((e) => e.fileId === paper.id);
            return or;
        }
        return null;
    }

    const getPaperLine = (paper: KeldanPaper) => {
        const owned = getOwned(paper);
        if (!isAuthenticated) {
            return <>
                <Link
                    linkSize='15'
                    disabled
                    icon='pdf'
                >
                    {paper.type}
                </Link>
                <Button
                    size='sm'
                    buttonType='buy'
                    anchorProps={{href: `/Innskraning?ReturnUrl=${window.location.pathname}`}}
                >
                    Kaupa
                </Button>
            </>
        }
        if (owned) {
            return <Link
                linkSize='15'
                url={isAuthenticated && owned?.balls !== undefined
                    ? `/Pdf/Fyrirtaekjaskra?eventId=${owned?.balls}&ssn=${id}`
                    : undefined
                }
                targetBlank
                style={{marginBottom: '10px'}}
                icon='pdf'
            >
                {paper.type}
            </Link>
        }
        return <>
            <Link
                linkSize='15'
                disabled
                icon='pdf'
            >
                {paper.type}
            </Link>
            <Button
                showLoader={isItemLoading(paper)}
                size='sm'
                buttonType='buy'
                onClick={() => tryPurchaseItem(paper, 'confirm-modal-with-reference')}
            >
                Kaupa
            </Button>
        </>
    }

    return (
        <DisplayBox 
            className='KCL_company-papers'
            title="Gögn úr fyrirtækjaskrá"
            asideComponent={<Label labelType='origin' text='RSK' />}
            footerLeft={
                <Link
                    linkSize='14'
                    url={`/Fyrirtaeki/Fyrirtaekjaskra/${id}`}
                    icon='forward'
                >
                    Fleiri gögn
                </Link>
            }
        >
            {(data instanceof Error)
                ? <Alert type='error' headText={data.message} />
                : (data === null)
                ? <Loading />
                : papersToShow?.length === 0
                ? <Alert type='info' headText='Engin gögn í boði' />
                : <div>
                    {papersToShow?.map((paper, index ) => {
                        return <div key={index} className='company-paper-line'>
                            <div className="main-line">
                                {getPaperLine(paper)}
                            </div>
                            <div className="dates-line">
                                <span className='extra-info'>
                                    Safnað {getTableDate(paper.collected, 'DD/MM/YYYY', '.')}
                                </span>
                            </div>
                        </div>
                    })}
                </div>
            }
            <ModalsAndToasts />
        </DisplayBox>
    );
}

export default CompanyPapers;