// Type imports:
import { INewsFeedSourceFilter } from "../types/NewsTypes";
import { Fetched } from "../types/Types";

const DELIMITER = ';'

export const getSourceStringFromSourceList = (sourceFilters: Fetched<INewsFeedSourceFilter[]>, which: 'on' | 'off') => {
    if (sourceFilters === null || sourceFilters instanceof Error) return null;
    const filteredSources = sourceFilters.filter(filter => (which === 'on' ? filter.isOn : !filter.isOn));
    if (filteredSources.length === 0) return null;
    const filteredSourceTitles = filteredSources.map(sourceFilter => sourceFilter.newsFeedSource.title);
    filteredSourceTitles.sort();
    const sourceFilterString = filteredSourceTitles.join(DELIMITER);
    return sourceFilterString;
}

export const getSourceListFromSourceString = (sourceFilterString: string) => {
    return sourceFilterString.split(DELIMITER);
}