// Package imports:
import React from 'react';
import download from 'downloadjs';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../ui-elements/Link/Link';
// Type imports:
import { INewsAttachment } from '../../types/NewsTypes';

interface IProps {
    attachments: INewsAttachment[]
}

const getFileIcon = (fileEnding: string) => {
    switch (fileEnding) {
        case 'pdf':
            return 'pdf'
        case 'xls':
        case 'xlsx':
            return 'excel'
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        case 'svg':
        case 'bmp':
        case 'tiff':
        case 'webp':
        case 'ico':
            return 'image'
        default:
            return 'document'
    }
}

const NewsAttachments: React.FC<IProps> = ({
    attachments
}) => {
    const getFile = async (url: string, fileName: string, mimeType: string) => {
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });
            if (response.ok) {
                const responseBody: { fileContents: string, contentType: string} = await response.json();
                // Decode base64 (convert base64 string to binary string)
                const binaryString = window.atob(responseBody.fileContents);

                // Convert binary string to Uint8Array
                const len = binaryString.length;
                const bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }

                // Create a Blob from the Uint8Array
                const blob = new Blob([bytes]);
                download(blob, fileName, mimeType);
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <DisplayBox
            className='KCL_news-attachments'
            title='Viðhengi'
            displayBoxSize='lg'
        >
            <ul>
                {attachments.map(({fileTitle, href, fn, mimeType}) => (
                    <li key={fileTitle}>
                        <Link
                            icon={getFileIcon(fn.split('.').pop() ?? '')}
                            linkSize='16'
                            onClick={() => { getFile(href, fn, mimeType) }}
                            splitLines
                        >
                            {fileTitle}
                        </Link>
                    </li>
                ))}
            </ul>
        </DisplayBox>
    );
}

export default NewsAttachments;