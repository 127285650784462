// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import LmdTable from '../../ui-elements/Table/LmdTable';
import ColoredNumber from '../../ui-elements/ColoredNumber/ColoredNumber';
import PriceRange from '../../ui-elements/PriceRange/PriceRange';
// Service imports:
import { convertToPercentage, displayQuantityShorthand, getTableDate } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Types imports:
import { IDefaultProps } from '../../types/Types';
import { IApiIrSnapshot } from '../../types/MarketTypes';

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>

const FxMarketTable: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    ...props
}) => {

    const [EURISKData] = useApiLmdData<IApiIrSnapshot[]>(
        '/v1/market_data/v1/ir/equities/symbol/EURISK:KELD/snapshot/',
        accessToken,
        refreshRateMs
    );
    const [USDISKData] = useApiLmdData<IApiIrSnapshot[]>(
        '/v1/market_data/v1/ir/equities/symbol/USDISK:KELD/snapshot/',
        accessToken,
        refreshRateMs
    );

    const handleData = () => {
        if (EURISKData.error) return EURISKData;
        if (USDISKData.error) return USDISKData;
        const dataToReturn = [];
        if(EURISKData.data !== null) {
            dataToReturn.push(EURISKData.data[0])
        }
        if(USDISKData.data !== null) {
            dataToReturn.push(USDISKData.data[0])
        }
        return {
            error: null,
            data: dataToReturn
        };
    }

    return (
        <DisplayBox 
            title="Gjaldeyrismarkaður"
            className='KCL_fx-market-table'
            {...props}
        >
            <LmdTable 
                apiData={handleData()} 
                columns={[{
                    title: 'Auðkenni',
                    renderCell: ({symbol}) => (symbol?.replace(':KELD', '')),
                    textAlign: 'left',
                }, {
                    title: 'Verð',
                    renderCell: ({last_price}) => (last_price),
                    textAlign: 'left',
                }, {
                    title: 'Kaup',
                    renderCell: ({bid_price}) => (bid_price) ? (bid_price) : '0,00',
                    textAlign: 'left',
                }, {
                    title: 'Sala',
                    renderCell: ({ask_price}) => (ask_price) ? (ask_price) : '0,00',
                    textAlign: 'left',
                }, {
                    title: 'Velta mynt',
                    renderCell: ({intraday_accumulated_volume}) => (displayQuantityShorthand(intraday_accumulated_volume)),
                    textAlign: 'left',
                }, {
                    title: 'Velta ISK',
                    renderCell: ({intraday_accumulated_turnover}) => (displayQuantityShorthand(intraday_accumulated_turnover)),
                    textAlign: 'left',
                }, {
                    title: 'FJ. Viðskipta',
                    renderCell: ({intraday_no_of_trades}) => (intraday_no_of_trades),
                    textAlign: 'left',
                }, {
                    title: 'Dagsbreyting',
                    renderCell: ({intraday_price_change_per}) => (
                        <ColoredNumber
                            displayInline
                            hasArrow
                            sign={(intraday_price_change_per === null) ? 'neutral' : (intraday_price_change_per < 0) ? 'neg' : (intraday_price_change_per > 0) ? 'pos' : 'neutral'}
                        >
                            {convertToPercentage(intraday_price_change_per, true, 2)}
                        </ColoredNumber>
                    ),
                    textAlign: 'left',
                }, {
                    title: '1 Mánuður',
                    renderCell: ({weeks4_price_change_per}) => (
                        <ColoredNumber
                            hasArrow
                            displayInline
                            sign={(weeks4_price_change_per === null) ? 'neutral' : (weeks4_price_change_per < 0) ? 'neg' : (weeks4_price_change_per > 0) ? 'pos' : 'neutral'}
                        >
                            {convertToPercentage(weeks4_price_change_per, true, 2)}
                        </ColoredNumber>
                    ),
                    textAlign: 'left',
                }, {
                    title: 'Innan árs',
                    renderCell: ({year_price_change_per}) => (
                        <ColoredNumber
                            hasArrow
                            displayInline
                            sign={(year_price_change_per === null) ? 'neutral' : (year_price_change_per < 0) ? 'neg' : (year_price_change_per > 0) ? 'pos' : 'neutral'}
                        >
                            {convertToPercentage(year_price_change_per, true, 2)}
                        </ColoredNumber>
                    ),
                    textAlign: 'left',
                }, {
                    title: '1 Ár',
                    renderCell: ({months12_price_change_per}) => (
                        <ColoredNumber
                            hasArrow
                            displayInline
                            sign={(months12_price_change_per === null) ? 'neutral' : (months12_price_change_per < 0) ? 'neg' : (months12_price_change_per > 0) ? 'pos' : 'neutral'}
                        >
                            {convertToPercentage(months12_price_change_per, true, 2)}
                        </ColoredNumber>
                    ),
                    textAlign: 'left',
                }, {
                    title: 'Síðustu viðskipti',
                    renderCell: ({last_trade_dateutc}) => getTableDate(last_trade_dateutc?.toString() ?? null,"HH:MM:SS", "."),
                    textAlign: 'left',
                }, {
                    title: 'Árs bil',
                    renderCell: ({months12_low_price, months12_high_price, last_price}) => <PriceRange low={months12_low_price} high={months12_high_price} value={last_price} />,
                    textAlign: 'right',
                }]}
                tableSize='lg'
            />
        </DisplayBox>
    )
}

export default FxMarketTable;