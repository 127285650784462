// Package imports:
import { useState } from "react"
import Bugsnag from '@bugsnag/js';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
// Component imports:
import SearchConfirmModalWithReference from "../components/SearchConfirmModal/SearchConfirmModalWithReference";
import SearchConfirmModalWithReferenceAndBool from "../components/SearchConfirmModal/SearchConfirmModalWithReferenceAndBool";
import LoginConfirmModal from "../components/LoginConfirmModal/LoginConfirmModal";
import Alert, { AlertType } from "../ui-elements/Alert/Alert";
import Link from "../ui-elements/Link/Link";
// Service imports:
import { GET_KELDAN_API_URL, TOAST_DELAY } from "./config";
import { useStateRef } from "./hooks";
import { ErrorMessages } from "./errorMessages";

interface IToastSettings {
    headText: string,
    text?: JSX.Element | string
    type?: AlertType
}

interface ICustomSearchModalInfo {
    customHeader: string,
    customText: string | JSX.Element
}

type PrecookedToastMessage = 'no card' | 'bad request' | 'response not ok' | 'network error'
type ToastState = PrecookedToastMessage | IToastSettings | null;

type ConfirmPhase = 'confirm-modal-with-reference' | 'confirm-modal-with-reference-and-bonds' | 'confirm-modal-with-reference-and-addresses' | 'confirm-modal-with-reference-and-history'
type Phase = 'initial' | ConfirmPhase | 'checking-if-expired' | 'login-modal'

export const useBuyingProcess = <T extends any>(
    onSuccessfulPurchase: (tryToBuyItem: T, modalReference: string, modalBool: boolean) => void,
    searchModalDataSource: string | ICustomSearchModalInfo,
    resetStateCallback: (() => void) = () => {}
) => {
    const [ tryToBuyItem, setTryToBuyItem ] = useState<T | null>(null);
    const [ phase, setPhase ] = useState<Phase>('initial');
    const [ toastError, setToastError ] = useState<ToastState>(null);

    // Optional states depending on type of search confirm modal.
    const [ modalReference, setModalReference ] = useState('');
    const modalReferenceRef = useStateRef(modalReference);
    const [ modalBool, setModalBool ] = useState(false);
    const modalBoolRef = useStateRef(modalBool);

    const resetState = (toastError: ToastState) => {
        setModalReference('');
        setTryToBuyItem(null);
        setPhase('initial');
        setToastError(toastError);
        resetStateCallback();
    }

    const purchase = () => {
        if (tryToBuyItem !== null) {
            onSuccessfulPurchase(tryToBuyItem, modalReferenceRef.current, modalBoolRef.current);
            resetState(null);
        }
    }

    const loginCheck = async () => {
        setPhase('checking-if-expired');
        try {
            const url = `${GET_KELDAN_API_URL() + '/Home/IsExpired'}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            });
            if (response.ok) {
                const responseBody: {expired: boolean} = await response.json();
                const { expired } = responseBody;
                //engar niðurstöður fundust
                if (expired) setPhase('login-modal')
                else purchase();
            } else {
                resetState('response not ok');
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            resetState('network error')
        }
    }
    
    const tryPurchaseItem = (
        item: T,
        confirmPhase: ConfirmPhase = 'confirm-modal-with-reference',
        reference?: string,
        boolValue?: boolean
    ) => {
        setTryToBuyItem(item);
        setModalReference(reference || '');
        setModalBool(boolValue || false);
        setPhase(confirmPhase);
    }

    const getPrecookedAlert = (precookedToastMessage: PrecookedToastMessage) => {
        if (precookedToastMessage === 'no card') {
            return (
                <Alert
                    type='alert'
                    withCloseIcon
                    closeAlert={() => setToastError(null)}
                    headText='Greiðsluupplýsingar vantar'
                    text={<p>Kreditkortaupplýsingar eru ekki fullnægjandi. Vinsamlegast yfirfarið <Link url='/Min-Kelda/Stillingar' linkSize='16'>kortaupplýsingar</Link>.</p>}
                />
            );
        }
        return (
            <Alert
                type={(precookedToastMessage === 'network error')
                    ? 'error'
                    : 'alert'
                }
                withCloseIcon
                closeAlert={() => setToastError(null)}
                headText={(precookedToastMessage === 'bad request')
                    ? ErrorMessages.ErrorInRequest
                    : (precookedToastMessage === 'response not ok')
                    ? ErrorMessages.RequestFailed
                    : ErrorMessages.Other
                }
            />
        );
    }
    
    const displaySearchConfirmModalWithReference = (ref?: string) => (typeof searchModalDataSource === 'string')
        ? (
            <SearchConfirmModalWithReference
                show
                close={() => resetState(null)}
                submit={(ref) => {
                    setModalReference(ref)
                    loginCheck();
                }}
                dataSourceText={searchModalDataSource}
                initialReference={ref}
            />
        ) : (
            <SearchConfirmModalWithReference
                show
                close={() => resetState(null)}
                submit={(ref) => {
                    setModalReference(ref)
                    loginCheck();
                }}
                dataSourceText=''
                customHeader={searchModalDataSource.customHeader}
                customText={searchModalDataSource.customText}
                initialReference={ref}
            />
        );
    
    const displaySearchConfirmModalWithReferenceAndBool = (type: 'bonds' | 'addresses' | 'history', ref?: string, boolVal?: boolean) => (typeof searchModalDataSource === 'string')
        ? (
            <SearchConfirmModalWithReferenceAndBool
                show
                close={() => resetState(null)}
                type={type}
                submit={(ref, bonds) => {
                    setModalReference(ref)
                    setModalBool(bonds)
                    loginCheck();
                }}
                dataSourceText={searchModalDataSource}
                initialReference={ref}
                initialBoolValue={boolVal}
            />
        ) : (
            <SearchConfirmModalWithReferenceAndBool
                show
                close={() => resetState(null)}
                type={type}
                submit={(ref, boolVal) => {
                    setModalReference(ref)
                    setModalBool(boolVal)
                    loginCheck();
                }}
                dataSourceText=''
                customHeader={searchModalDataSource.customHeader}
                customText={searchModalDataSource.customText}
                initialReference={ref}
                initialBoolValue={boolVal}
            />
        );
    
    const ModalsAndToasts: React.FC = () => {
        const getModal = () => {
            if (phase === 'confirm-modal-with-reference') {
                return displaySearchConfirmModalWithReference(modalReferenceRef.current);
            }
            if (phase.startsWith('confirm-modal-with-reference-and')) {
                return displaySearchConfirmModalWithReferenceAndBool(
                    phase.split('confirm-modal-with-reference-and-')[1] as 'bonds' | 'addresses' | 'history',
                    modalReferenceRef.current,
                    modalBoolRef.current
                );
            }
            if (phase === 'login-modal') return <LoginConfirmModal show close={() => resetState(null)} submit={() => purchase()} />;
            return null;
        }
        
        const getToastBody = () => {
            if (toastError === null) return null;
            if (typeof toastError === 'string') return getPrecookedAlert(toastError);
            return (
                <Alert
                    type='alert'
                    withCloseIcon
                    closeAlert={() => setToastError(null)}
                    headText={toastError.headText}
                    text={toastError.text}
                />
            )
        }
        return <div>
            {getModal()}
            <ToastContainer className='toastContainer' >
                <Toast
                    show={(toastError !== null)}
                    autohide
                    delay={TOAST_DELAY}
                    onClose={() => setToastError(null)}
                >
                    {getToastBody()}
                </Toast>
            </ToastContainer>
        </div>
    }


    return {
        isItemLoading: (item: T) => (item === tryToBuyItem),
        tryToBuyItem,
        tryPurchaseItem,
        resetState,
        setToastError,
        ModalsAndToasts
    }
}

export const useBuyingProcessUrl = <T extends any>(
    onPurchaseUrl: (tryToBuyItem: T, modalReference: string, modalBool: boolean) => string,
    onPurchaseCallback: (tryToBuyItem: T, modalReference: string, modalBool: boolean) => void,
    searchModalDataSource: string | ICustomSearchModalInfo,
    resetStateCallback: (() => void) = () => {}
) => {
    const [ tryToBuyItem, setTryToBuyItem ] = useState<T | null>(null);
    const [ phase, setPhase ] = useState<Phase>('initial');
    const [ afterLoginPhase, setAfterLoginPhase ] = useState<ConfirmPhase>('confirm-modal-with-reference');
    const [ toastError, setToastError ] = useState<ToastState>(null);

    const resetState = (toastError: ToastState) => {
        setTryToBuyItem(null);
        setPhase('initial');
        setToastError(toastError);
        resetStateCallback();
    }

    const loginCheck = async (confirmPhase: ConfirmPhase) => {
        setPhase('checking-if-expired');
        try {
            const url = `${GET_KELDAN_API_URL() + '/Home/IsExpired'}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            });
            if (response.ok) {
                const responseBody: {expired: boolean} = await response.json();
                const { expired } = responseBody;
                //engar niðurstöður fundust
                if (expired) {
                    setPhase('login-modal')
                    setAfterLoginPhase(confirmPhase);
                }
                else {
                    setPhase(confirmPhase);
                }
            } else {
                resetState('response not ok');
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            resetState('network error')
        }
    }
    
    const tryPurchaseItem = (item: T, confirmPhase: ConfirmPhase = 'confirm-modal-with-reference') => {
        setTryToBuyItem(item);
        loginCheck(confirmPhase);
    }

    const getPrecookedAlert = (precookedToastMessage: PrecookedToastMessage) => {
        if (precookedToastMessage === 'no card') {
            return (
                <Alert
                    type='alert'
                    withCloseIcon
                    closeAlert={() => setToastError(null)}
                    headText='Greiðsluupplýsingar vantar'
                    text={<p>Kreditkortaupplýsingar eru ekki fullnægjandi. Vinsamlegast yfirfarið <Link url='/Min-Kelda/Stillingar' linkSize='16'>kortaupplýsingar</Link>.</p>}
                />
            );
        }
        return (
            <Alert
                type={(precookedToastMessage === 'network error')
                    ? 'error'
                    : 'alert'
                }
                withCloseIcon
                closeAlert={() => setToastError(null)}
                headText={(precookedToastMessage === 'bad request')
                    ? ErrorMessages.ErrorInRequest
                    : (precookedToastMessage === 'response not ok')
                    ? ErrorMessages.RequestFailed
                    : ErrorMessages.Other
                }
            />
        );
    }
    
    const displaySearchConfirmModalWithReference = () => (typeof searchModalDataSource === 'string')
        ? (
            <SearchConfirmModalWithReference
                show
                close={() => resetState(null)}
                submit={(modalReference: string) => {
                    if (tryToBuyItem) {
                        window.open(onPurchaseUrl(tryToBuyItem, modalReference, false), '_blank');
                        onPurchaseCallback(tryToBuyItem, modalReference, false);
                    }
                    resetState(null);
                }}
                dataSourceText={searchModalDataSource}
            />
        ) : (
            <SearchConfirmModalWithReference
                show
                close={() => resetState(null)}
                submit={(modalReference: string) => {
                    if (tryToBuyItem) {
                        window.open(onPurchaseUrl(tryToBuyItem, modalReference, false), '_blank');
                        onPurchaseCallback(tryToBuyItem, modalReference, false);
                    }
                    resetState(null);
                }}
                dataSourceText=''
                customHeader={searchModalDataSource.customHeader}
                customText={searchModalDataSource.customText}
            />
        );
    
    const displaySearchConfirmModalWithReferenceAndBonds = (type: 'bonds' | 'addresses') => (typeof searchModalDataSource === 'string')
        ? (
            <SearchConfirmModalWithReferenceAndBool
                show
                close={() => resetState(null)}
                type={type}
                submit={(modalReference: string, modalBool: boolean) => {
                    if (tryToBuyItem) {
                        window.open(onPurchaseUrl(tryToBuyItem, modalReference, modalBool), '_blank');
                        onPurchaseCallback(tryToBuyItem, modalReference, modalBool);
                    }
                    resetState(null);
                }}
                dataSourceText={searchModalDataSource}
            />
        ) : (
            <SearchConfirmModalWithReferenceAndBool
                show
                close={() => resetState(null)}
                type={type}
                submit={(modalReference: string, modalBool: boolean) => {
                    if (tryToBuyItem) {
                        window.open(onPurchaseUrl(tryToBuyItem, modalReference, modalBool), '_blank');
                        onPurchaseCallback(tryToBuyItem, modalReference, modalBool);
                    }
                    resetState(null);
                }}
                dataSourceText=''
                customHeader={searchModalDataSource.customHeader}
                customText={searchModalDataSource.customText}
            />
        );
    
    const ModalsAndToasts: React.FC = () => {
        const getModal = () => {
            if (phase === 'confirm-modal-with-reference') {
                return displaySearchConfirmModalWithReference();
            }
            if (phase.startsWith('confirm-modal-with-reference-and')) {
                return displaySearchConfirmModalWithReferenceAndBonds(
                    phase.split('confirm-modal-with-reference-and-')[1] as 'bonds' | 'addresses'
                );
            }
            if (phase === 'login-modal') {
                return <LoginConfirmModal show close={() => resetState(null)} submit={() => setPhase(afterLoginPhase)} />;
            }
            return null;
        }
        const getToastBody = () => {
            if (toastError === null) return null;
            if (typeof toastError === 'string') return getPrecookedAlert(toastError);
            return (
                <Alert
                    type='alert'
                    withCloseIcon
                    closeAlert={() => setToastError(null)}
                    headText={toastError.headText}
                    text={toastError.text}
                />
            )
        }
        return <div>
            {getModal()}
            <ToastContainer className='toastContainer' >
                <Toast
                    show={(toastError !== null)}
                    autohide
                    delay={TOAST_DELAY}
                    onClose={() => setToastError(null)}
                >
                    {getToastBody()}
                </Toast>
            </ToastContainer>
        </div>
    }


    return {
        isItemLoading: (item: T) => (item === tryToBuyItem),
        tryToBuyItem,
        tryPurchaseItem,
        resetState,
        setToastError,
        ModalsAndToasts
    }
}