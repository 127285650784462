// Package imports:
import React, { useState } from 'react';
import cx from 'classnames';
// Component imports:
import Button from '../../../ui-elements/Button/Button';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Table from '../../../ui-elements/Table/Table';
import VerticalTable from '../../../ui-elements/Table/VerticalTable';
import Link from '../../../ui-elements/Link/Link';
import Radio from '../../../ui-elements/Radio/Radio';
// Service imports:
import { convertToPercentage, formatNumber, getTableDate, insertDash, isCompany } from '../../../services/utils';
// Type imports:
import { CompanyDetailedOverview } from '../../../types/CompanyTypes';
import { IEventReport } from '../../../types/KeldanTypes';

const EXAMPLE_DATA: IEventReport<CompanyDetailedOverview> = {
    eventDate: "31.03.2023 16:40:18",
    overview: {
        "addresses": false,
        "procuration": [{
            "address": "Kelduvogi 20",
            "ssn": "0910791239",
            "name": "Kelda Kóðadóttir",
            "city": "Reykjavík",
            "zip": "104"
        }],
        "managers": [{
            "address": "Kelduvogi 20",
            "ssn": "0910791239",
            "name": "Kelda Kóðadóttir",
            "city": "Reykjavík",
            "zip": "104"
        }],
        "substitutes": [],
        "directors": [{
            "address": "Kelduvogi 20",
            "ssn": "1901892349",
            "name": "Jón Kelddal",
            "city": "Reykjavík",
            "zip": "104"
        }, {
            "address": "Dæmanesi 6",
            "ssn": "0101010100",
            "name": "Sýnir Dæmason",
            "city": "Reykjavík",
            "zip": "102"
        }],
        "isats": [{
            "isat": "62.01.0",
            "description": "Hugbúnaðargerð",
            "description_en": "Computer programming activities"
        }],
        "boardChairman": {
            "address": "Kóðagerði 17",
            "ssn": "1706911239",
            "name": "Dæmi Sýnis",
            "city": "Kópavogur",
            "zip": "200"
        },
        "company": {
            "acceptance": "Meirihluti stjórnar",
            "name": "Keldan á Íslandi ehf.",
            "currency": "ISK",
            "capital": 1234567.0,
            "restrictions": "1",
            "ssn": "5101050510",
            "duty": "0",
            "address": "Borgartúni 25",
            "zip": "105",
            "city": "Reykjavík",
            "form": null,
            "formDescription": "Einkahlutafélag (ehf)",
            "description": "Tilgangur félagsins er hugbúnaðargerð, almenn upplýsingamiðlun, tölvuvinnsla, ráðgjöf og skyld starfsemi ásamt almennum fjárfestingum.",
            "dateAccepted": 1615766400000,
            "lastChange": 1617753600000,
            "dateRejected": -62135769600000,
            "datePayed": -62135769600000,
            "lastChangeRSK": 1622471885000,
            "dateListed": 1232366496000,
            "lastBoardMeeting": 1596153600000,
            "dateRegistered": 1104756510000,
            "dateNotified": 1597708800000
        },
        "accountants": [{
            "address": "Keldugötu 2",
            "ssn": "2109876549",
            "name": "Dæmhildur Sýnmundsdóttir",
            "city": "Reykjavík",
            "zip": "101"
        }, {
            "address": "Dæmatúni 123",
            "ssn": "5601782349",
            "name": "Endurskoðendadæmi ehf.",
            "city": "Reykjavík",
            "zip": "105"
        }],
        "board": [{
            "address": "Kelduvogi 20",
            "ssn": "1901892349",
            "name": "Jón Kelddal",
            "city": "Reykjavík",
            "zip": "104"
        }, {
            "address": "Dæmanesi 6",
            "ssn": "0101010100",
            "name": "Sýnir Dæmason",
            "city": "Reykjavík",
            "zip": "102"
        }, {
            "address": "Kóðagerði 17",
            "ssn": "1706911239",
            "name": "Dæmi Sýnis",
            "city": "Kópavogur",
            "zip": "200"
        }],
        "founders": [{
            "address": "Kelduvogi 20",
            "ssn": "0910791239",
            "name": "Kelda Kóðadóttir",
            "city": "Reykjavík",
            "zip": "104"
        }],
        "members": [],
        "owners": [{
            "nationality": "Ísland",
            "busetuLand": "Ísland",
            "kt_fyrirtaekis": "5101050510",
            "ktFyrirtaekis": null,
            "companySSN": "5101050510",
            "name": "Kelda Kóðadóttir",
            "faedingarManudur": "1979-10",
            "id": "173097",
            "ownedPercentage": 54.3
        }]
    },
    userName: '',
    productName: '',
    reply: ''
};

const MembersReportExample: React.FC = () => {
    const data = EXAMPLE_DATA;

    const [type, setType] = useState('basic');
    
    const displayAddress = (address: string | undefined, zip: string | undefined, city: string | undefined) => {
        return `${address ?? '-'}, ${zip ?? '-'} ${city ?? '-'}` ;
    }

    const displayTableRow = (
        index: number,
        title?: string,
        flex?: number,
        text?: string,
        address?: string,
        zip?: string,
        city?: string,
        ssn?: string
    ) => (
        <tr
            key={index}
            className='lineContainer'
        >
            <th>
                <strong>{(index !== 0 ) ? '' : title}</strong>
            </th>
            <td style={{'flex': flex}}>
                <div className="text-wrapper">
                    {(text)
                        ? (
                            <p className={cx({'nameAndSocial': ssn})}>
                                {text}
                                {ssn && ', ' + insertDash(ssn)}
                            </p>
                        )
                        : (
                            <p>-</p>
                        )
                    }
                    {(type === 'addresses') && <p className='address'>{displayAddress(address, zip, city)}</p>}
                </div>
                {(ssn) && (
                    (!isCompany(ssn))
                    ? (
                        <Button 
                            size='sm'
                            buttonType='buy'
                            disabled
                        >
                            Kaupa
                        </Button>
                    )
                    : (
                        <Button 
                            size='sm'
                            buttonType='secondary'
                            disabled
                        >
                            Skoða
                        </Button>
                    )
                )}
            </td>
        </tr>
    );

    return (
        <>
        <div className='radios-group'>
            <ul>
                <li>
                    <Radio
                        id="basic"
                        name="basic"
                        value="basic"
                        defaultValue={type}
                        onChange={(e) => setType(e.target.value)}
                    >
                        KYC
                    </Radio>
                </li>
                <li>
                    <Radio
                        id="addresses"
                        name="addresses"
                        value="addresses"
                        defaultValue={type}
                        onChange={(e) => setType(e.target.value)}
                    >
                        KYC með heimilisföngum
                    </Radio>
                </li>
                <li>
                    <Radio
                        id="owners"
                        name="owners"
                        value="owners"
                        defaultValue={type}
                        onChange={(e) => setType(e.target.value)}
                    >
                        KYC með endanlegum eigendum
                    </Radio>
                </li>
            </ul>
        </div>
        <DisplayBox
            title='Grunnupplýsingar'
            className='KCL_ViewCompanyMembersReport'
            asideComponent={
                <div className='report-aside-component'>
                    <div className='link-wrapper'>
                        <Link
                            targetBlank
                            linkSize='15'
                            url='#'
                            disabled
                            icon='pdf'
                        >
                            Sækja skýrslu
                        </Link>
                    </div>
                    <div className='italic-text'>
                        Skýrsla keypt: {data.eventDate}
                    </div>
                </div>
            }
            grayBackground
        >
            <div className='vertical-table-wrapper'>
                <VerticalTable
                    fallbackSymbol
                    data={[data.overview]}
                    columns={[{
                        title: 'Nafn',
                        renderCell: ({company: {name}}) => name
                    }, {
                        title: 'Kennitala',
                        renderCell: ({company: {ssn}}) => insertDash(ssn),
                    }, {
                        title: 'Lögheimili',
                        renderCell: ({company: {address, zip, city}}) =>  displayAddress(address, zip, city) ,
                    }, {
                        title: 'Síðast breytt í fyrirtækjaskrá RSK',
                        renderCell: ({company: {lastChangeRSK}}) => getTableDate(new Date(lastChangeRSK), 'DD/MM/YYYY', '.'),
                    }, {
                        title: 'Atvinnugrein (ISAT)',
                        renderCell: ({isats}) => isats ? `${isats[0].isat ?? '-'} ${isats[0].description ?? isats[0].description_en ?? '-'} ` : '-',
                    }, {
                        title: 'Tilgangur',
                        renderCell: ({company: {description}}) => description,
                    }, {
                        title: 'Rekstrarform',
                        renderCell: ({company :{formDescription, form}}) => `${formDescription ?? '-'} (${form ?? '-'})`,
                    }, {
                        title: 'Stofnað',
                        renderCell: ({company: {dateRegistered}}) => getTableDate(new Date(dateRegistered), 'DD/MM/YYYY', '.'),
                    }, {
                        title: 'Hlutafé',
                        renderCell: ({company: {capital, currency}}) => `${formatNumber(capital, '-', 0)} ${currency ?? '-'}`,
                    }, {
                        title: 'Dags. samþykkta',
                        renderCell: ({company: {dateAccepted}}) => getTableDate(new Date(dateAccepted), 'DD/MM/YYYY', '.'),
                    }]}
                    tableSize="lg"
                />

                <VerticalTable
                    fallbackSymbol
                    horizontalFlexSize={2}
                    tableSize="lg"
                    data={[data.overview]}
                    columns={[{
                        title: 'Stjórnarformaður',
                        renderCell: ({boardChairman}) =>
                            (boardChairman === null || [boardChairman].length === 0)
                            ? '-' 
                            : [boardChairman].map((item, index) => displayTableRow(index, 'Stjórnarformaður', 2, item.name, item.address, item.zip, item.city, item.ssn)),
                        overrideDefaultStyle: true,
                    }, {
                        title: 'Meðstjórnendur',
                        overrideDefaultStyle: true,
                        renderCell: ({directors}) =>
                            (directors === null || directors.length === 0)
                            ? '-'
                            : directors.map((item, index) => displayTableRow(index, 'Meðstjórnendur', 2, item.name, item.address, item.zip, item.city, item.ssn)),
                    }, {
                        title: 'Varamenn',
                        overrideDefaultStyle: true,
                        renderCell: ({substitutes}) =>
                            (substitutes === null || substitutes.length === 0)
                            ? '-'
                            : substitutes.map((item, index) => displayTableRow(index, 'Varamenn', 2, item.name, item.address, item.zip, item.city, item.ssn)),
                    }, {
                        title: 'Síðasta breyting á stjórn',
                        renderCell: ({company: {lastBoardMeeting}}) => getTableDate(new Date(lastBoardMeeting), 'DD/MM/YYYY', '.'),
                    }, {
                        title: 'Firmað rita',
                        renderCell: ({company: {acceptance}}) => acceptance,
                    }, {
                        title: 'Prókúruumboð',
                        overrideDefaultStyle: true,
                        renderCell: ({procuration}) =>
                            (procuration === null || procuration.length === 0)
                            ? '-'
                            : procuration.map((item, index) => displayTableRow(index, 'Prókúruumboð', 2, item.name, item.address, item.zip, item.city, item.ssn)),
                    }, {
                        title: 'Framkvæmdastjórn',
                        overrideDefaultStyle: true,
                        renderCell: ({managers}) => 
                            (managers === null || managers.length === 0)
                            ? '-'
                            : managers.map((item, index) => displayTableRow(index, 'Framkvæmdastjórn', 2, item.name, item.address, item.zip, item.city, item.ssn)),
                    }, {
                        title: 'Endurskoðendur',
                        overrideDefaultStyle: true,
                        renderCell: ({accountants}) =>
                            (accountants === null || accountants.length === 0)
                            ? '-'
                            : accountants.map((item, index) => displayTableRow(index, 'Endurskoðendur', 2, item.name, item.address, item.zip, item.city, item.ssn)),
                    }, {
                        title: 'Hömlur á meðferð hlutabréfa',
                        renderCell: ({company: {restrictions}}) => restrictions === '1' ? 'Já' : 'Nei',
                    }, {
                        title: 'Innlausnarskylda',
                        renderCell: ({company:{duty}}) => duty === '1' ? 'Já' : 'Nei',
                    }, {
                        title: 'Stofnendur',
                        overrideDefaultStyle: true,
                        renderCell: ({founders}) =>
                            (founders === null || founders.length === 0)
                            ? '-'
                            : founders.map((item, index) => displayTableRow(index, 'Stofnendur', 2, item.name, item.address, item.zip, item.city, item.ssn)),
                    }]}
                />
            </div>

            {(type === 'owners') && (
                 <div>
                    <h4>Raunverulegir eigendur</h4>
                    <Table 
                        data={data.overview.owners}
                        columns={[{
                            title: 'Nafn',
                            renderCell: ({name}) => name,
                            textAlign: 'left',
                        }, {
                            title: 'Fæðingarár/mán.',
                            renderCell: ({faedingarManudur}) => faedingarManudur,
                            textAlign: 'right',
                        }, {
                            title: 'Búsetuland',
                            renderCell: ({busetuLand}) => busetuLand,
                            textAlign: 'right',
                        }, {
                            title: 'Ríkisfang',
                            renderCell: ({nationality}) => nationality,
                            textAlign: 'right',
                        }, {
                            title: 'Eignarhlutur',
                            renderCell: ({ownedPercentage}) => convertToPercentage(ownedPercentage / 100, true, 2),
                            textAlign: 'right',
                        }]}
                        tableSize='lg'
                    />
                </div>
            )}
        </DisplayBox>
        </>
    );
}

export default MembersReportExample;