const weekdays = ['Sunnudagur', 'Mánudagur', 'Þriðjudagur', 'Miðvikudagur', 'Fimmtudagur', 'Föstudagur', 'Laugardagur'];
const CalendarWeekdays = () => {
    return (
        <div className='KCL_calendarWeekdays calendar-row'>
           { weekdays.map((weekday, index) => (
                <div key={index} className="calendar-col calendar-cell weekday_cell">
                    <span className="weekday">{weekday}</span>
                </div>
            ))}
        </div>
    );
}

export default CalendarWeekdays;