// Package imports:
import React, { useMemo, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import Bugsnag from "@bugsnag/js";
// Component imports:
import Alert from "../../../ui-elements/Alert/Alert";
import Button from "../../../ui-elements/Button/Button";
import Input from "../../../ui-elements/Input/Formik/Input";
import LockIcon from "../../../ui-elements/LockIcon/LockIcon";
import Tooltip from "../../../ui-elements/Tooltip/Tooltip";
import SearchResults from "../../../ui-elements/SearchResults/SearchResults";
import DocumentListTable from "../ViewDocumentList/DocumentListTable";
import Table from "../../../ui-elements/Table/Table";
import PropertyOverviewTable from "./PropertyOverviewTable";
import Link from "../../../ui-elements/Link/Link";
import DisplayBox from "../../../ui-elements/DisplayBox/DisplayBox";
// Service imports:
import { GET_KELDAN_API_URL } from "../../../services/config";
import { useBuyingProcess } from "../../../services/buyhook";
import { ErrorMessages } from "../../../services/errorMessages";
// Type imports:
import { IKeldanApiResponse, OwnedReport } from "../../../types/KeldanTypes";
import { IPropertyDocument, IPropertyDocumentListResult, IPropertySearchResult, IPropertySearchViewModel } from "../../../types/PropertyTypes";

interface IBuyFasteignaSkra {
    whereClick: 'form' | 'list'
    fastanr?: string,
    heitisnr?: string
}

const DocumentSearch: React.FC<IPropertySearchViewModel> = ({
    isAuthenticated,
    documentsAllowed
}) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [documentList, setDocumentList] = useState<IPropertyDocument[]>();
    const [docListPropertyNumber, setDocListPropertyNumber] = useState<string>();
    const [ownedDocuments, setOwnedDocuments] = useState<OwnedReport[]>([]);
    const [properties, setProperties] = useState<IPropertySearchResult>();
    const [nameNumber, setNameNumber] = useState<string>();
    const [searchResults, setSearchResults] = useState<IPropertySearchResult>();
    const [isSearchFormLoading, setIsSearchFormLoading] = useState(false);

    const searchResultsRef = useRef<HTMLDivElement | null>(null);

    const { tryPurchaseItem, tryToBuyItem, resetState, setToastError, ModalsAndToasts } = useBuyingProcess(async (values: IBuyFasteignaSkra, modalReference) => {
        if (values.whereClick === 'form') {
            clearSearchResults();
        }
        try {
            const { fastanr, heitisnr } = values;
            let url = '';
            let query = new URLSearchParams();
            if (fastanr) {
                url = `${GET_KELDAN_API_URL()}/Property/BuyDocumentList`;
                query = new URLSearchParams({
                    fastanr,
                    reference: modalReference
                })
            } else if (heitisnr) {
                url = `${GET_KELDAN_API_URL()}/Property/DocumentSearchHeiti`;
                query = new URLSearchParams({
                    term: heitisnr,
                    reference: modalReference
                })
            }

            const response = await fetch(`${url}?${query.toString()}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });
            if (!response.ok) {
                resetState('response not ok');
                return;
            }
            if (response.redirected) {
                window.location.href = response.url;
                return;
            }
            const responseBody: IKeldanApiResponse<IPropertyDocumentListResult | IPropertySearchResult> & {owned: OwnedReport[]} = await response.json();
            const { message, result, owned } = responseBody;
            if (result) {
                if (fastanr) {
                    setDocumentList((result as IPropertyDocumentListResult).response.documents.documents);
                    setDocListPropertyNumber((result as IPropertyDocumentListResult).propertyNumber);
                    setOwnedDocuments(owned);
                } else if (heitisnr) {
                    setProperties(result as IPropertySearchResult);
                    setNameNumber(heitisnr);
                }
            } else {
                if (message === ErrorMessages.NoCard) {
                    resetState('no card')
                } else if (message) {
                    resetState({
                        type: 'alert',
                        headText: message
                    });
                } else {
                    resetState('bad request');
                }
            }
        } catch (e) {
            resetState('network error');
        }
    }, 'fasteignaskrá');

    const handleSearch = async (values: ISearchFormValues) => {
        clearSearchResults();
        try {
            const { heiti } = values;
            const requestQuery = new URLSearchParams({
                term: heiti
            });
            const url = `${GET_KELDAN_API_URL()}/Property/PropertySearchAll`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            // Done after the request for better user experience.
            setSearchTerm(heiti);
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const responseBody: IKeldanApiResponse<IPropertySearchResult> = await response.json();
                    const { message, result } = responseBody;
                    if (result) {
                        setSearchResults(result);
                        searchResultsRef.current?.scrollIntoView();
                    } else if (message === ErrorMessages.NoCard) {
                        setToastError('no card');
                    } else if (message) {
                        setToastError({
                            type: 'alert',
                            headText: message
                        })
                    } else {
                        setToastError('bad request');
                    }
                }
            } else {
                setToastError('response not ok');
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setToastError('network error');
        }
        // Reset all loading states.
        setIsSearchFormLoading(false)
    }

    const displayForms = (status: 'enabled' | 'disabled') => {
        return <div>
            <Formik
                initialValues={{
                    fastanr: '',
                    tilvisun: '',
                    bonds: false
                }}
                validationSchema={Yup.object({
                    fastanr: Yup.string().required('Fastanúmer má ekki vera tómt'),
                    tilvisun: Yup.string()
                })}
                onSubmit={({ fastanr, tilvisun }) => tryPurchaseItem(
                    { fastanr, whereClick: 'form' },
                    'confirm-modal-with-reference',
                    tilvisun
                )}
                component={(props: { values: IPurchasingFormValues }) => (
                    <PurchasingSubFormComponent
                        loading={(
                            tryToBuyItem !== null
                            && tryToBuyItem.whereClick === 'form'
                        )}
                        values={props.values}
                        disabled={status === 'disabled'}
                    />
                )}
            />
            <p className='paragraph'>Einnig er hægt að leita eftir fasteign</p>
            <Formik
                initialValues={{
                    heiti: ''
                }}
                validationSchema={Yup.object({
                    heiti: Yup.string().required('Leit má ekki vera tóm')
                })}
                onSubmit={(values) => {
                    setIsSearchFormLoading(true);
                    handleSearch(values)
                }}
                component={(props: { values: ISearchFormValues }) => (
                    <SearchSubFormComponent
                        loading={isSearchFormLoading}
                        values={props.values}
                        disabled={status === 'disabled'}
                    />
                )}
            />
        </div>
    }

    const clearSearchResults = () => { 
        setSearchResults(undefined);
        setDocumentList(undefined);
        setProperties(undefined);
        setOwnedDocuments([]);
        setSearchTerm('');
        setNameNumber(undefined);
        setDocListPropertyNumber(undefined);
    }

    const showResults = useMemo(() => {
        return <SearchResults
            title={`Leitarniðurstöður fyrir ${docListPropertyNumber ?? nameNumber ?? searchTerm}`}
            show={searchResults !== undefined || documentList !== undefined}
            closeResults={clearSearchResults}
            ref={searchResultsRef}
            component={documentList && documentList?.length > 0
                ? <>
                    {properties?.response?.properties?.properties &&
                        <Link
                            className="back-link"
                            linkSize="18"
                            onClick={() => {setDocumentList(undefined); setDocListPropertyNumber(undefined)}}
                            icon="back"
                        >
                            Til baka
                        </Link>
                    }
                    <DisplayBox>
                        <DocumentListTable doclist={documentList} owned={ownedDocuments} fastanr={docListPropertyNumber ?? ''} />
                    </DisplayBox>
                </>
                : properties?.response?.properties?.properties
                ? <>
                    <Link
                        className="back-link"
                        linkSize="18"
                        onClick={() => {setProperties(undefined); setNameNumber(undefined)}}
                        icon="back"
                    >
                        Til baka
                    </Link>
                    <DisplayBox>
                        <PropertyOverviewTable propertylist={properties} setDocumentList={setDocumentList} setOwnedDocuments={setOwnedDocuments} />
                    </DisplayBox>
                </>
                : searchResults?.response?.addresses
                ? <DisplayBox>
                    <Table
                        tableSize="lg"
                        data={searchResults.response.addresses}
                        columns={[{
                            title: 'Heitisnúmer',
                            renderCell: ({address: {name_number}}) => name_number,
                            textAlign: 'left'
                        }, {
                            title: 'Heiti',
                            renderCell: ({address: {street}}) => street,
                            textAlign: 'left'
                        }, {
                            title: 'Húsnúmer',
                            renderCell: ({address: {house_number}}) => house_number,
                            textAlign: 'left'
                        }, {
                            title: 'Sveitarfélag',
                            renderCell: ({address: {county}}) => county,
                            textAlign: 'left'
                        }, {
                            title: 'Byggð',
                            renderCell: ({address: {city}}) => city,
                            textAlign: 'left'
                        }, {
                            title: 'Yfirlitsmynd',
                            renderCell: ({ address: {name_number} }) => (
                                <Button
                                    buttonType="buy"
                                    size='sm'
                                    showLoader={(
                                        tryToBuyItem !== null
                                        && tryToBuyItem.heitisnr === name_number
                                        && tryToBuyItem.whereClick === 'list'
                                    )}
                                    onClick={() => name_number && tryPurchaseItem({
                                        heitisnr: name_number,
                                        whereClick: 'list'
                                    }, 'confirm-modal-with-reference')}
                                >
                                    Kaupa
                                </Button>
                            )
                        }]}
                    />
                </DisplayBox>
                : <Alert type='info' headText="Engar niðurstöður fundust" />
            }
        />
    }, [searchResults, properties, documentList, ownedDocuments, searchTerm, nameNumber, docListPropertyNumber, tryToBuyItem, tryPurchaseItem])

    const isDisabled = useMemo(() => ( !isAuthenticated || !documentsAllowed ), [isAuthenticated, documentsAllowed]);
    
    return (
        <div className='KCL_property-search-form'>
            <div className="shell">
                <h3>
                    {!isAuthenticated && <LockIcon size='lg' />}
                    <span>Uppfletting í þinglýstum skjölum</span>
                </h3>
                <div className='property-form-description paragraph'>
                    Með því að slá inn fastanúmer er hægt að kaupa lista yfir þinglýst skjöl fasteignar í Fasteignaskrá. <Link linkSize="18" url="/Verdskra#verdskra">Sjá verðskrá.</Link>
                </div>
                {!isDisabled
                    ? displayForms('enabled')
                    : <Tooltip
                        tooltip={
                            <span>
                                Þú hefur ekki aðgang að þinglýstum skjölum.
                            </span>
                        }
                        text={displayForms('disabled')}
                    />
                }
            </div>
            {isDisabled && <div className="shell" style={{marginTop: '30px'}}>
                    <p className='paragraph'>
                        Vantar þig aðgang að þinglýstum skjölum? <Link url="/Hafa-Samband" linkSize='18'>Hafðu samband.</Link>
                    </p>
                </div>
            }
            {showResults}
            <ModalsAndToasts />
        </div>
    );
}

export default DocumentSearch;

interface ISearchFormValues {
    heiti: string
}

interface ISearchSubFormProps {
    values: ISearchFormValues,
    loading: boolean,
    disabled: boolean
}

const SearchSubFormComponent: React.FC<ISearchSubFormProps> = ({
    values,
    loading,
    disabled
}) => {
    return (
        <Form>
            <div className="form__body">
                <div className="form__section">
                    <div className="form__row">
                        <div className="form__col">
                            <Input
                                placeholder="Leita eftir götuheiti, fastanúmeri, heitisnúmeri eða landnúmeri"
                                name="heiti"
                                id="heiti"
                                value={values.heiti}
                                disabled={disabled}
                            />
                        </div>
                        <div className="form__col actions">
                            <div className="form__actions">
                                <Button
                                    showLoader={loading}
                                    buttonType="primary"
                                    size="lg"
                                    disabled={disabled}
                                >
                                    Leita
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
}

interface IPurchasingFormValues {
    fastanr: string,
    tilvisun: string
}

interface IPurchasingSubFormProps {
    values: IPurchasingFormValues,
    loading: boolean,
    disabled: boolean
}

const PurchasingSubFormComponent: React.FC<IPurchasingSubFormProps> = ({
    values,
    loading,
    disabled
}) => {
    return (
        <Form>
            <div className="form__body">
                <div className="form__section">
                    <div className="form__row">
                        <div className="form__col">
                            <Input
                                label="Fastanúmer eignar"
                                name="fastanr"
                                id="fastanr"
                                value={values.fastanr}
                                disabled={disabled}
                            />
                        </div>
                        <div className="form__col">
                            <Input
                                label="Tilvísun á reikning"
                                name="tilvisun"
                                id="tilvisun"
                                value={values.tilvisun}
                                disabled={disabled}
                            />
                        </div>
                        <div className="form__col actions">
                            <div className="form__actions">
                                <Button
                                    showLoader={loading}
                                    buttonType="buy"
                                    size="lg"
                                    disabled={disabled}
                                >
                                    Kaupa
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
}

