// Package imports:
import React from 'react';
// Component imports:
import ConstructionReportBody from './ConstructionReportBody';
// Type imports:
import { IEventReport } from '../../../types/KeldanTypes';
import { ConstructionsSearchResult, IConstructionsReport } from '../../../types/VehicleTypes';
import ConstructionsOwnedReportBody from './ConstructionsOwnedReportBody';

const EXAMPLE_DATA: IEventReport<ConstructionsSearchResult> = {
    eventDate: '09.12.2022 14:09:54',
    overview: {
        "data": {
            "insurance": "",
            "importedUsed": true,
            "code": "AB1234",
            "regDate": {
                "dateTime": {
                    "date": {
                        "year": 2004,
                        "month": 10,
                        "day": 19
                    },
                    "time": {
                        "hour": 0,
                        "minute": 0,
                        "second": 0,
                        "nano": 0
                    },
                    "offset": null
                }
            },
            "type": "Liebherr",
            "make": "50K",
            "category": "Kranar",
            "subcategory": "Byggingarkranar",
            "owner": "Dæmi ehf.",
            "caretaker": "Dæmi ehf.",
            "ownerSsn": "4512673489",
            "caretakerSsn": "4512673489",
            "importer": "Óþekktur",
            "status": "Í notkun",
            "vin": "12345",
            "country": "Þýskaland",
            "model": 2003,
            "technical": {
                "className": "TaekniKranarDto"
            },
            "ownedHistory": [{
                "ssn": "6510763219",
                "name": "Kodiak byggingaverktakar ehf",
                "code": "AB1234",
                "dateTraded": {
                    "dateTime": {
                        "date": {
                            "year": 2004,
                            "month": 10,
                            "day": 19
                        },
                        "time": {
                            "hour": 0,
                            "minute": 0,
                            "second": 0,
                            "nano": 0
                        },
                        "offset": null
                    }
                },
                "dateRegistered": {
                    "dateTime": {
                        "date": {
                            "year": 2004,
                            "month": 10,
                            "day": 19
                        },
                        "time": {
                            "hour": 0,
                            "minute": 0,
                            "second": 0,
                            "nano": 0
                        },
                        "offset": null
                    }
                },
                "date": {
                    dateTime: {
                        date: {
                            day: 0,
                            month: 0,
                            year: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            nano: 0,
                            second: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                }
            }, {
                "ssn": "5612782349",
                "name": "Dæmi um fyrirtæki ehf.",
                "code": "AB1234",
                "dateTraded": {
                    "dateTime": {
                        "date": {
                            "year": 2008,
                            "month": 8,
                            "day": 15
                        },
                        "time": {
                            "hour": 0,
                            "minute": 0,
                            "second": 0,
                            "nano": 0
                        },
                        "offset": null
                    }
                },
                "dateRegistered": {
                    "dateTime": {
                        "date": {
                            "year": 2004,
                            "month": 10,
                            "day": 19
                        },
                        "time": {
                            "hour": 0,
                            "minute": 0,
                            "second": 0,
                            "nano": 0
                        },
                        "offset": null
                    }
                },
                "date": {
                    dateTime: {
                        date: {
                            day: 0,
                            month: 0,
                            year: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            nano: 0,
                            second: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                }
            }, {
                "ssn": "5101050510",
                "name": "Keldan á Íslandi ehf.",
                "code": "AB1234",
                "dateTraded": {
                    "dateTime": {
                        "date": {
                            "year": 2017,
                            "month": 4,
                            "day": 19
                        },
                        "time": {
                            "hour": 16,
                            "minute": 7,
                            "second": 13,
                            "nano": 0
                        },
                        "offset": null
                    }
                },
                "dateRegistered": {
                    "dateTime": {
                        "date": {
                            "year": 2004,
                            "month": 10,
                            "day": 19
                        },
                        "time": {
                            "hour": 0,
                            "minute": 0,
                            "second": 0,
                            "nano": 0
                        },
                        "offset": null
                    }
                },
                "date": {
                    dateTime: {
                        date: {
                            day: 0,
                            month: 0,
                            year: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            nano: 0,
                            second: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                }
            }, {
                "ssn": "4512673489",
                "name": "Dæmi ehf.",
                "code": "AB1234",
                "dateTraded": {
                    "dateTime": {
                        "date": {
                            "year": 2022,
                            "month": 6,
                            "day": 30
                        },
                        "time": {
                            "hour": 12,
                            "minute": 31,
                            "second": 19,
                            "nano": 0
                        },
                        "offset": null
                    }
                },
                "dateRegistered": {
                    "dateTime": {
                        "date": {
                            "year": 2004,
                            "month": 10,
                            "day": 19
                        },
                        "time": {
                            "hour": 0,
                            "minute": 0,
                            "second": 0,
                            "nano": 0
                        },
                        "offset": null
                    }
                },
                "date": {
                    dateTime: {
                        date: {
                            day: 0,
                            month: 0,
                            year: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            nano: 0,
                            second: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                }
            }],
            "caretakerHistory": [],
            "operationsHistory": []
        }
    },
    userName: 'atli@kodi.is',
    productName: 'Vinnuvélar',
    reply: ''
}

const EXAMPLE_DATA_OWNED: IConstructionsReport = {
    "eventDate": "09.02.2023 10:13:39",
    "constructionReport": {
        "ssn": "4512673489",
        "currentOwned": [
            "AB0123",
            "CD4567",
            "EF8901",
            "GH2345",
            "IJ6789",
            "KL9876",
            "MN5432",
            "OP1098"
        ],
        "currentCaretaker": [
            "AB0123",
            "CD4567",
            "EF8901",
            "GH2345",
            "IJ6789",
            "KL9876",
            "MN5432",
            "OP1098"
        ],
        "ownedHistory": [{
            "ssn": "4512673489",
            "name": "Dæmi ehf.",
            "code": "MN5432",
            "dateTraded": {
                "dateTime": {
                    "date": {
                        "year": 2015,
                        "month": 11,
                        "day": 26
                    },
                    "time": {
                        "hour": 11,
                        "minute": 27,
                        "second": 23,
                        "nano": 0
                    },
                    "offset": null
                }
            },
            "dateRegistered": {
                "dateTime": {
                    "date": {
                        "year": 2015,
                        "month": 11,
                        "day": 26
                    },
                    "time": {
                        "hour": 11,
                        "minute": 27,
                        "second": 21,
                        "nano": 0
                    },
                    "offset": null
                }
            }
        }, {
            "ssn": "4512673489",
            "name": "Dæmi ehf.",
            "code": "IJ6789",
            "dateTraded": {
                "dateTime": {
                    "date": {
                        "year": 2016,
                        "month": 5,
                        "day": 30
                    },
                    "time": {
                        "hour": 14,
                        "minute": 8,
                        "second": 58,
                        "nano": 0
                    },
                    "offset": null
                }
            },
            "dateRegistered": {
                "dateTime": {
                    "date": {
                        "year": 2016,
                        "month": 5,
                        "day": 30
                    },
                    "time": {
                        "hour": 14,
                        "minute": 8,
                        "second": 56,
                        "nano": 0
                    },
                    "offset": null
                }
            }
        }, {
            "ssn": "4512673489",
            "name": "Dæmi ehf.",
            "code": "GH2345",
            "dateTraded": {
                "dateTime": {
                    "date": {
                        "year": 2017,
                        "month": 2,
                        "day": 7
                    },
                    "time": {
                        "hour": 8,
                        "minute": 19,
                        "second": 39,
                        "nano": 0
                    },
                    "offset": null
                }
            },
            "dateRegistered": {
                "dateTime": {
                    "date": {
                        "year": 2017,
                        "month": 2,
                        "day": 7
                    },
                    "time": {
                        "hour": 8,
                        "minute": 19,
                        "second": 36,
                        "nano": 0
                    },
                    "offset": null
                }
            }
        }, {
            "ssn": "4512673489",
            "name": "Dæmi ehf.",
            "code": "KL9876",
            "dateTraded": {
                "dateTime": {
                    "date": {
                        "year": 2019,
                        "month": 1,
                        "day": 30
                    },
                    "time": {
                        "hour": 15,
                        "minute": 20,
                        "second": 45,
                        "nano": 0
                    },
                    "offset": null
                }
            },
            "dateRegistered": {
                "dateTime": {
                    "date": {
                        "year": 2016,
                        "month": 2,
                        "day": 29
                    },
                    "time": {
                        "hour": 15,
                        "minute": 55,
                        "second": 50,
                        "nano": 0
                    },
                    "offset": null
                }
            }
        }, {
            "ssn": "4512673489",
            "name": "Dæmi ehf.",
            "code": "OP1098",
            "dateTraded": {
                "dateTime": {
                    "date": {
                        "year": 2020,
                        "month": 2,
                        "day": 26
                    },
                    "time": {
                        "hour": 11,
                        "minute": 37,
                        "second": 36,
                        "nano": 0
                    },
                    "offset": null
                }
            },
            "dateRegistered": {
                "dateTime": {
                    "date": {
                        "year": 2015,
                        "month": 10,
                        "day": 14
                    },
                    "time": {
                        "hour": 16,
                        "minute": 34,
                        "second": 30,
                        "nano": 0
                    },
                    "offset": null
                }
            }
        }, {
            "ssn": "4512673489",
            "name": "Dæmi ehf.",
            "code": "AB0123",
            "dateTraded": {
                "dateTime": {
                    "date": {
                        "year": 2021,
                        "month": 7,
                        "day": 12
                    },
                    "time": {
                        "hour": 9,
                        "minute": 24,
                        "second": 41,
                        "nano": 0
                    },
                    "offset": null
                }
            },
            "dateRegistered": {
                "dateTime": {
                    "date": {
                        "year": 2021,
                        "month": 5,
                        "day": 12
                    },
                    "time": {
                        "hour": 8,
                        "minute": 10,
                        "second": 28,
                        "nano": 0
                    },
                    "offset": null
                }
            }
        }, {
            "ssn": "4512673489",
            "name": "Dæmi ehf.",
            "code": "CD4567",
            "dateTraded": {
                "dateTime": {
                    "date": {
                        "year": 2021,
                        "month": 11,
                        "day": 1
                    },
                    "time": {
                        "hour": 14,
                        "minute": 27,
                        "second": 2,
                        "nano": 0
                    },
                    "offset": null
                }
            },
            "dateRegistered": {
                "dateTime": {
                    "date": {
                        "year": 2020,
                        "month": 12,
                        "day": 14
                    },
                    "time": {
                        "hour": 11,
                        "minute": 53,
                        "second": 15,
                        "nano": 0
                    },
                    "offset": null
                }
            }
        }, {
            "ssn": "4512673489",
            "name": "Dæmi ehf.",
            "code": "EF8901",
            "dateTraded": {
                "dateTime": {
                    "date": {
                        "year": 2021,
                        "month": 11,
                        "day": 1
                    },
                    "time": {
                        "hour": 14,
                        "minute": 28,
                        "second": 38,
                        "nano": 0
                    },
                    "offset": null
                }
            },
            "dateRegistered": {
                "dateTime": {
                    "date": {
                        "year": 2020,
                        "month": 12,
                        "day": 14
                    },
                    "time": {
                        "hour": 11,
                        "minute": 59,
                        "second": 44,
                        "nano": 0
                    },
                    "offset": null
                }
            }
        }],
        "caretakerHistory": [{
                "ssn": "4512673489",
                "name": "Dæmi ehf.",
                "code": "CD4567",
                "dateTraded": {
                    dateTime: {
                        date: {
                            year: 0,
                            month: 0,
                            day: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                },
                "dateRegistered": {
                    dateTime: {
                        date: {
                            year: 0,
                            month: 0,
                            day: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                },
                "date": {
                    "dateTime": {
                        "date": {
                            "year": 2015,
                            "month": 11,
                            "day": 26
                        },
                        "time": {
                            "hour": 11,
                            "minute": 27,
                            "second": 23,
                            "nano": 0
                        },
                        "offset": null
                    }
                }
            }, {
                "ssn": "4512673489",
                "name": "Dæmi ehf.",
                "code": "GH2345",
                "dateTraded": {
                    dateTime: {
                        date: {
                            year: 0,
                            month: 0,
                            day: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                },
                "dateRegistered": {
                    dateTime: {
                        date: {
                            year: 0,
                            month: 0,
                            day: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                },
                "date": {
                    "dateTime": {
                        "date": {
                            "year": 2016,
                            "month": 5,
                            "day": 30
                        },
                        "time": {
                            "hour": 14,
                            "minute": 8,
                            "second": 58,
                            "nano": 0
                        },
                        "offset": null
                    }
                }
            }, {
                "ssn": "4512673489",
                "name": "Dæmi ehf.",
                "code": "IJ6789",
                "dateTraded": {
                    dateTime: {
                        date: {
                            year: 0,
                            month: 0,
                            day: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                },
                "dateRegistered": {
                    dateTime: {
                        date: {
                            year: 0,
                            month: 0,
                            day: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                },
                "date": {
                    "dateTime": {
                        "date": {
                            "year": 2017,
                            "month": 2,
                            "day": 7
                        },
                        "time": {
                            "hour": 8,
                            "minute": 19,
                            "second": 39,
                            "nano": 0
                        },
                        "offset": null
                    }
                }
            }, {
                "ssn": "4512673489",
                "name": "Dæmi ehf.",
                "code": "EF8901",
                "dateTraded": {
                    dateTime: {
                        date: {
                            year: 0,
                            month: 0,
                            day: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                },
                "dateRegistered": {
                    dateTime: {
                        date: {
                            year: 0,
                            month: 0,
                            day: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                },
                "date": {
                    "dateTime": {
                        "date": {
                            "year": 2019,
                            "month": 1,
                            "day": 30
                        },
                        "time": {
                            "hour": 15,
                            "minute": 20,
                            "second": 45,
                            "nano": 0
                        },
                        "offset": null
                    }
                }
            }, {
                "ssn": "4512673489",
                "name": "Dæmi ehf.",
                "code": "AB0123",
                "dateTraded": {
                    dateTime: {
                        date: {
                            year: 0,
                            month: 0,
                            day: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                },
                "dateRegistered": {
                    dateTime: {
                        date: {
                            year: 0,
                            month: 0,
                            day: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                },
                "date": {
                    "dateTime": {
                        "date": {
                            "year": 2020,
                            "month": 2,
                            "day": 26
                        },
                        "time": {
                            "hour": 11,
                            "minute": 37,
                            "second": 36,
                            "nano": 0
                        },
                        "offset": null
                    }
                }
            }, {
                "ssn": "4512673489",
                "name": "Dæmi ehf.",
                "code": "OP10987",
                "dateTraded": {
                    dateTime: {
                        date: {
                            year: 0,
                            month: 0,
                            day: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                },
                "dateRegistered": {
                    dateTime: {
                        date: {
                            year: 0,
                            month: 0,
                            day: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                },
                "date": {
                    "dateTime": {
                        "date": {
                            "year": 2021,
                            "month": 7,
                            "day": 12
                        },
                        "time": {
                            "hour": 9,
                            "minute": 24,
                            "second": 41,
                            "nano": 0
                        },
                        "offset": null
                    }
                }
            }, {
                "ssn": "4512673489",
                "name": "Dæmi ehf.",
                "code": "KL9876",
                "dateTraded": {
                    dateTime: {
                        date: {
                            year: 0,
                            month: 0,
                            day: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                },
                "dateRegistered": {
                    dateTime: {
                        date: {
                            year: 0,
                            month: 0,
                            day: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                },
                "date": {
                    "dateTime": {
                        "date": {
                            "year": 2021,
                            "month": 11,
                            "day": 1
                        },
                        "time": {
                            "hour": 14,
                            "minute": 27,
                            "second": 2,
                            "nano": 0
                        },
                        "offset": null
                    }
                }
            }, {
                "ssn": "4512673489",
                "name": "Dæmi ehf.",
                "code": "MN5432",
                "dateTraded": {
                    dateTime: {
                        date: {
                            year: 0,
                            month: 0,
                            day: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                },
                "dateRegistered": {
                    dateTime: {
                        date: {
                            year: 0,
                            month: 0,
                            day: 0
                        },
                        time: {
                            hour: 0,
                            minute: 0,
                            second: 0,
                            nano: 0
                        },
                        offset: {
                            totalSeconds: 0
                        }
                    }
                },
                "date": {
                    "dateTime": {
                        "date": {
                            "year": 2021,
                            "month": 11,
                            "day": 1
                        },
                        "time": {
                            "hour": 14,
                            "minute": 28,
                            "second": 38,
                            "nano": 0
                        },
                        "offset": null
                    }
                }
            }
        ]
    }
}

interface IProps {
    type: 'profile' | 'owned'
}

const ConstructionReportExample: React.FC<IProps> = ({
    type
}) => {
    return (
        type === 'profile'
            ? <ConstructionReportBody data={EXAMPLE_DATA} isExample />
            : <ConstructionsOwnedReportBody constructionReport={EXAMPLE_DATA_OWNED} isExample />
    );
}

export default ConstructionReportExample;