// Package imports:
import React, { useEffect, useState } from "react";
// Component imports:
import Button from '../../../ui-elements/Button/Button';
import Modal from '../../../ui-elements/Modal/Modal'; 
import GreidsluupplysingarChangeForm from './GreidsluupplysingarChangeForm';
import GreidsluupplysingarCardForm from './GreidsluupplysingarCardForm';
// Type imports:
import { MyCardViewModel, MySettingsViewModel } from "../../../types/MyKeldaTypes";

type FormProps = React.FormHTMLAttributes<HTMLFormElement>;
interface IProps {
    model: MySettingsViewModel | null
}
type Props = FormProps & IProps;

const Greidsluupplysingar: React.FC<Props> = ({
    className,
    model,
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [newCard, setNewCard] = useState<MyCardViewModel>();
    const [ contactResponse, setContactResponse ] = useState<JSX.Element>(<></>)
    
    const closeModal = () => setModalOpen(false);

    const openModal = () => {
        setModalOpen(true);
    };
    const updateCreditCard = (e: MyCardViewModel) => {
        setNewCard(e)
    }

    useEffect(() => {},[newCard])

    const displayPaymentInfo = () => {
        if (!model?.settingsResponse?.customerIsCardPayable) {
            return <p className="paragraph-small">
                <span>Greiðslumáti:</span>
                <span>Reikningur</span>
            </p>
        }
        if (model?.settingsResponse?.cardUsable && model.card) {
            return <>
                <p className="paragraph-small">
                    <span>Greiðslumáti:</span>
                    Kreditkort
                </p>
                {model.isAdmin && <>
                    <p className="paragraph-small">
                        <span>Kreditkort:</span>
                        {
                            //if new card is added display new card number 
                            (newCard)
                                ? (<span>******** {newCard.cardNumber?.substring(12)}</span>)
                                // not new card display
                                : (model?.card?.lastFour)
                                ? ((<span>******** {model.card.lastFour}</span>))
                                : (<span>Ekkert kreditkort skráð</span>)
                        }
                    </p>
                    <Button buttonType="primary" onClick={openModal}>Skrá nýtt kort</Button>
                </>}
            </>
        }
        return <div className="form">
            <GreidsluupplysingarCardForm
                setContactResponse={setContactResponse}
                setCreditCardInfo={(e) => updateCreditCard(e)}
                isAdmin={model.isAdmin}
            />
        </div>
    }

    return (
        <div className="Greidsluupplysingar">
            <div>
                {contactResponse && (
                    <div className="form__row">
                        <div className="form__col">
                            {contactResponse}
                        </div>
                    </div>
                )}
            </div>
            <div className="section__body">
                <div className="left-section">
                    <GreidsluupplysingarChangeForm setContactResponse={setContactResponse} model={model}/>
                </div>
                <div className="right-section">
                    <h3>Greiðsluupplýsingar</h3>
                    {displayPaymentInfo()}
                </div>
            </div>

            <Modal
                show={modalOpen}
                onHide={closeModal}
                hasCloseButton
                variant='shaded-inside'
                body={
                    <div className="form">
                        <h3>Skrá nýtt kort</h3>
                        <GreidsluupplysingarCardForm 
                            setContactResponse={setContactResponse} 
                            setCreditCardInfo={(e) => updateCreditCard(e)}
                            isAdmin={model?.isAdmin ?? false}
                        />
                    </div>
                }
            />
        </div >
    )
}

export default Greidsluupplysingar;