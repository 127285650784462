// Package imports:
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import Button from '../../../ui-elements/Button/Button';
// Service imports:
import { gtmPush } from '../../../services/utils';

const LoggedOut: React.FC = () => {
    return (
        <ul className="KCL_logged-out">
            <li>
                <a
                    href={`/Innskraning?ReturnUrl=${encodeURIComponent(window.location.pathname)}`}
                    onClick={() => gtmPush('buttonClick', 'innskraning', 'show_form')}
                >
                    <FontAwesomeIcon icon={faUser} className='user-icon' />
                    <span className='text-span'>
                        Innskrá
                    </span>
                </a>
            </li>
            <li>
                <Button
                    anchorProps={{ href: "/Verdskra" }}
                    onClick={() => gtmPush('buttonClick', 'nyskra', 'show_form')}
                >
                    Nýskrá
                </Button>
            </li>
        </ul>
    );
}

export default LoggedOut;
