// Package imports:
import React, { useEffect } from 'react';
// Component imports:
import ColoredNumber from '../../ui-elements/ColoredNumber/ColoredNumber';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import LmdSingleLineTable from '../../ui-elements/Table/LmdSingleLineTable';
// Service imports:
import { formatNumber, convertToPercentage, getTableDate } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdFundPrice } from '../../types/FundsTypes';

interface IOwnProps {
    symbol: string | null,
    setFundName(name: string | undefined, type: string | undefined): void,
    setFundIssuerName(name: string | undefined): void,
    setFundPdfUrl(name: string | undefined): void,

}
type Props = IDefaultProps & IOwnProps & React.HTMLAttributes<HTMLDivElement>;

const SingleFund: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    symbol = 'frjalsi_1',
    setFundName,
    setFundIssuerName,
    setFundPdfUrl,
    ...props
}) => {
    const [fund] = useApiLmdData<IApiLmdFundPrice[]>(
        `/v1/market_data/v1/funds/${symbol}/prices/`,
        accessToken,
        refreshRateMs
    );

    useEffect(() => {
        const { data } = fund;
        if (data !== null) {
            //setting the title of the singleFundPage
            setFundName(data[0]?.Name?.toString(), data[0]?.FundType?.toString());
            setFundIssuerName(data[0]?.IssuerName ?? '');
            setFundPdfUrl(data[0]?.PdfUrl ?? '');
        }
    }, [fund]);

    const handleData = () => {
        const { data, error } = fund;
        if (data === null) {
            return {
                error,
                data: null
            }
        } else {
            return {
                error,
                data: data[0]
            }
        }
    }

    return (
        <DisplayBox {...props}>
            <LmdSingleLineTable 
                apiData={handleData()}
                columns={[{
                    title: 'Mynt',
                    renderCell: ( {Currency} ) => (Currency),
                    textAlign: 'left'
                }, {
                    title: 'Gengi',
                    renderCell: ( {LastPrice} ) => (formatNumber(LastPrice, '-', 2)),
                    textAlign: 'left'
                }, {
                    title: 'Dagsetning',
                    renderCell: ( {ValueDate } ) => (ValueDate && getTableDate(ValueDate.toString(), 'DD/MM/YYYY', '.')),
                    textAlign: 'left'
                }, {
                    title: '1 Ár',
                    renderCell: ( {Change12m} ) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Change12m === null) ? 'neutral' : (Change12m < 0) ? 'neg' : (Change12m > 0) ? 'pos' : 'neutral'}
                        >
                            {convertToPercentage(Change12m, true, 2)}
                        </ColoredNumber>
                    ),
                    textAlign: 'left'
                }, {
                    title: '2 Ár',
                    renderCell: ( {Change24m} ) => (
                        <ColoredNumber  
                            hasArrow
                            sign={(Change24m === null) ? 'neutral' : (Change24m < 0) ? 'neg' : (Change24m > 0) ? 'pos' : 'neutral'}
                        >
                            {convertToPercentage(Change24m, true, 2)}
                        </ColoredNumber>
                    ),
                    textAlign: 'left'
                }, {
                    title: '3 Ár',
                    renderCell: ( {Change36m} ) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Change36m === null) ? 'neutral' : (Change36m < 0) ? 'neg' : (Change36m > 0) ? 'pos' : 'neutral'}
                        >
                            {convertToPercentage(Change36m, true, 2)}
                        </ColoredNumber>
                    ),
                    textAlign: 'left'
                }, {
                    title: '4 Ár',
                    renderCell: ( {Change48m} ) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Change48m === null) ? 'neutral' : (Change48m < 0) ? 'neg' : (Change48m > 0) ? 'pos' : 'neutral'}
                        >
                            {convertToPercentage(Change48m, true, 2)}
                        </ColoredNumber>
                    ),
                    textAlign: 'left'
                }, {
                    title: '5 Ár',
                    renderCell: ( {Change60m} ) => (
                        <ColoredNumber
                            hasArrow
                            sign={(Change60m === null) ? 'neutral' : (Change60m < 0) ? 'neg' : (Change60m > 0) ? 'pos' : 'neutral'}
                        >
                            {convertToPercentage(Change60m, true, 2)}
                        </ColoredNumber>
                    ),
                    textAlign: 'left'
                }]}
                tableSize='lg'
            />
        </DisplayBox>
    );
}

export default SingleFund;