// Package imports:
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';

interface IProps {
    size?: 'sm' | 'lg'
}

const LockIcon: React.FC<IProps> = ({
    size = 'sm'
}) => {
    return (
        <div className={cx('KCL_lock-icon', size)}>
            <FontAwesomeIcon icon={faLock} className="icon-fa" />
        </div>
    );
}

export default LockIcon;