// Package imports:
import React from 'react';
import cx from 'classnames';
// Component imports:
import AdRotator from '../../components/Ad/AdRotator';
import HistoryButtonDropDown from '../../components/HistoryButtonDropDown/HistoryButtonDropDown';

interface IProps {
    title: string | JSX.Element,
    summary?: JSX.Element,
    description?: JSX.Element,
    actions?: JSX.Element,
    rightSide?: JSX.Element,
    textAlign?: 'left' | 'right' | 'center' | 'left-to-center',
    hasAds?: 'main' | 'market',
    showRecentReports?: boolean
}

const HeadSection: React.FC<IProps> = ({
    title,
    summary,
    description,
    actions,
    rightSide,
    textAlign = 'left',
    hasAds,
    showRecentReports = false
}) => {
    const getAds = () => {
        if (!hasAds) return null;
        if (hasAds === 'main') {
            return window.matchMedia('(max-width: 1023px)').matches
                ? <AdRotator location="Head400x80" />
                : <AdRotator location="Head1360x180" />
        }
        return <AdRotator location="MarketHead1018x360" />
    }
    return (
        <div className="KCL_head-section">
            {/* ADS SECTION */}
            {getAds()}

            {/* HEADER SECTION */}
            <div className={cx(
                'section__head--title',
                `${textAlign}-align`
            )}>
                {(typeof(title) !== 'string')
                    ? (<>{title}</>)
                    : (<h1>{title}</h1>)
                }
                {showRecentReports && <HistoryButtonDropDown />}
            </div>
            <div className={cx('section__head--content', `${textAlign}-align`)}>
                <div className='section__head--main'>
                    {summary && (
                        <div className="section__head--summary summary-paragraph">
                            {summary}
                        </div>
                    )}
                    {description && (
                        <div className="section__head--description paragraph">
                            {description}
                        </div>
                    )}
                    {actions && (
                        <div className="section__head--actions">
                            {actions}
                        </div>
                    )}
                </div>
                {rightSide && (
                    <div className="section__head--right">
                        {rightSide}
                    </div>
                )}
            </div>
        </div>
    );
}

export default HeadSection;