// Package imports:
import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpLong, faDownLong } from '@fortawesome/pro-solid-svg-icons';

export type Sign = 'pos' | 'neg' | 'neutral';
export type BackgroundSign = 'pos' | 'neg' ;

interface IProps {
    hasArrow?: boolean,
    background?: BackgroundSign,
    sign?: Sign,
    displayInline?: boolean,
    constantBackground?: BackgroundSign,
}

const ColoredNumber: React.FC<IProps>= ({
    children,
    hasArrow,
    sign = 'neutral',
    background ,
    displayInline,
    constantBackground
}) => {

    return (
        <span
            className={cx(
                'KCL_colored-number',
                sign,
                (background !== undefined || background !== null) ? `background-${background}` : null,
                (constantBackground) ? `constant-background-${constantBackground}` : null,
                {'display-inline' : displayInline}
            )}
        >
            {((hasArrow && sign !== 'neutral') && (
                <FontAwesomeIcon
                    className={cx('arrow', sign)}
                    icon={(sign === 'pos') ? faUpLong : faDownLong}
                />
            ))}
            {children}
        </span>
    );
}

export default ColoredNumber;