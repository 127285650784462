// Package imports:
import React, { useState } from "react";
import Bugsnag from "@bugsnag/js";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
// Component imports:
import Modal from "../../ui-elements/Modal/Modal";
import Button from "../../ui-elements/Button/Button";
import Link from "../../ui-elements/Link/Link";
import Input from "../../ui-elements/Input/Formik/Input";
import Alert from "../../ui-elements/Alert/Alert";
// Service imports:
import { GET_KELDAN_API_URL } from "../../services/config";
import { ErrorMessages } from "../../services/errorMessages";

interface IProps {
    show: boolean,
    close(): void,
    submit(): void
};

interface IFormProps {
    values: IFormValues
}
interface IFormValues {
    password: string
}

const LoginConfirmModal: React.FC<IProps> = ({
    show,
    close,
    submit
}) => {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values: {password: string}) => {
        setLoading(true);
        setError(null);
        try {
            const requestQuery = new URLSearchParams({
                password: values.password
            });
            const url = `${GET_KELDAN_API_URL() + '/Home/RenewLogin'}`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: requestQuery
            });
            if (response.ok) {
                setLoading(false);
                const responseBody: { success: string } = await response.json();
                const { success } = responseBody;
                if (success === 'Ok') {
                    submit();
                } else if (success === 'Wrong password') {
                    setError('Rangt lykilorð');
                } else {
                    window.location.href = '/Innskraning';
                }
            } else {
                setLoading(false);
                setError(ErrorMessages.RequestFailed);
            }
        } catch (e) {
            if (e instanceof Error) Bugsnag.notify(e);
            setLoading(false);
            setError(ErrorMessages.NetworkError);
        }
    }

    const form: React.FC<IFormProps> = ({
        values
    }) => {
        return <Form>
            <div className="form__section">
                <div className="form__body">
                    <div className="form__row">
                        <div className="form__col">
                            <Input
                                label="Lykilorð"
                                type="password"
                                name="password"
                                id="password"
                                value={values.password}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="form__actions">
                <Link url="/Nytt-Lykilord" linkSize="16">Gleymt lykilorð</Link>
                <Button
                    type='submit'
                    buttonType="primary"
                    size='lg'
                    showLoader={loading}
                >
                    Staðfesta
                </Button>
            </div>
        </Form>
    }

    return (
        <Modal
            show={show}
            onHide={close}
            header='Staðfestu innskráningu'
            body={
                <>
                    <p className="paragraph">
                        Liðið hafa yfir 24 klst. frá síðustu innskráningu. Vinsamlegast staðfestu innskráningu.
                    </p>
                    <Formik
                        initialValues={{
                            password: ''
                        }}
                        validationSchema={Yup.object({
                            password: Yup.string().required('Sláðu inn lykilorð')
                        })}
                        onSubmit={handleSubmit}
                        component={form}
                    />
                    {error && <Alert type="error" headText={error}></Alert>}
                </>
            }
        />
    );
}

export default LoginConfirmModal;