// Package imports:
import React from 'react';
// Component imports:
import VehicleReportBody from '../VehicleReportBody';
// Type imports:
import { IEventReport } from '../../../types/KeldanTypes';
import { VehicleSearchResult } from '../../../types/VehicleTypes';

const EXAMPLE_DATA: IEventReport<VehicleSearchResult> = {
    eventDate: '20.03.2023 12:30:00',
    overview: {
        car: {
            make: 'KODI',
            vehcom: 'KELDAN HYBRID',
            modelyear: '2016',
            regno: 'KEL11',
            permno: 'KEL11',
            manufacturer: 'KODI ÍSLANDI',
            country: 'Ísland',
            productyear: '2015',
            firstregdate: '13.05.2016',
            nextinspectiondate: '01.07.2024',
            color: 'Blár',
            vehiclestatus: 'Í lagi',
            hasEncumbrances: '0',
            platestatus: 'Á ökutæki',
            insurancestatus: '1',
            insurancecompany: 'Keldutryggingar ehf',
            vin: 'VNKELD4N10A210787',
            typeno: 'VNKKD3D30050',
            typeapproval: '1330',
            typeapprovalextension: '50',
            eutypeapproval: 'e11*2007/46*0152*08',
            taxgroup: 'Ökutæki án skrattflokks',
            preregdate: '01.12.2015',
            deregdate: '-',
            reregdate: '-',
            rebuilt: '0',
            offroad: '0',
            regtype: 'Almenn merki',
            hasdisasters: '0',
            fixed: '0',
            disastertype: '-',
            owners: [{ 
                fullname: 'Keldubílaeigandi ehf',
                persidno: '7107171710',
                address: 'Bílaland 71',
                postalcode: '108',
                purchasedate: '10.02.2020',
                receptiondate: '12.02.2020',
                current: '1'
            }, {
                fullname: 'Dæmi ehf',
                persidno: '4512673489',
                address: 'Dæmistún 3',
                postalcode: '105',
                purchasedate: '11.11.2016',
                receptiondate: '14.11.2016',
                current: '0'
            }],
            technical: {
                vehgroup: 'Fólksbifreið (M1)',
                mass: {
                    massinro: '1118',
                    massladen: '1565',
                    masscapacity: '447',
                    massoftrunbr: '0',
                    massoftrbr: '0',
                    massdaxle1: '1565'
                },
                size: {
                    length: '3950',
                    width: '1695',
                    height: '1510'
                },
                doorsno: '4',
                passbydr: '1',
                pass: '4',
                seatno: '5',
                noofgears: '-',
                engine: 'Bensín/Rafmagn',
                maxnetpow: '54.0',
                workingpr: 'Rafkveikja',
                enginecode: '1NZ-FXE',
                capacity: '1497',
                co2: '75.0',
                tyre: {
                    tyreaxle1: '175/65R15 84H'
                },
                axle: {
                    wheelaxle1: '15X5J 39'
                },
                typeofbody: 'AF'
            },
            inspections: [{
                station: 'MGB skoðun Holtagörðum',
                type: 'Aðalskoðun',
                date: '01.07.2022',
                odometer: '47251',
                result: 'Án athugasemda'
            }, {
                station: 'MGB skoðun Hátúni',
                type: 'Aðalskoðun',
                date: '01.07.2020',
                odometer: '22113',
                result: 'Án athugasemda'
            }, {
                station: 'AG bílar ehf.',
                type: 'Fulltrúaskoðun',
                date: '13.05.2016',
                odometer: '15',
                result: 'Án athugasemda'
            }],
            operators: [{
                fullname: 'Kodiak bílaleiga ehf.',
                persidno: '7003973459',
                startdate: '13.05.2016',
                enddate: '24.10.2016'
            }, {
                fullname: 'Keldan á Íslandi ehf.',
                persidno: '5101050510',
                startdate: '01.12.2015',
                enddate: '13.05.2016'
            }]
        }
    },
    userName: 'martha@kodi.is',
    productName: 'Ökutæki',
    reply: ''
}

const VehicleProfileExample: React.FC = () => {
    return (
        <VehicleReportBody data={EXAMPLE_DATA} isExample />
    );
}

export default VehicleProfileExample;