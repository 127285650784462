// Package imports:
import React from 'react';
// Component imports:
import AssetsReportBody from './AssetsReportBody';
// Type imports:
import { IPropertyReport } from '../../../types/PropertyTypes';


const EXAMPLE_DATA: IPropertyReport = {
    "eventDate": "14.02.2023 09:33:01",
    "properties": [{
        "name": "Keldubakki 13, Reykjavík",
        "description": "skrifstofa 050101",
        "source": "Staðfest í Þinglýsingarhluta LF",
        "registry": {
            "type": "F",
            "typeName": "Fasteign",
            "id": "2086420"
        },
        "allowance": null,
        "history": [{
            "recipient": "4512673489",
            "office": "411",
            "document": "A-123456/1992",
            "published": "25/08/1992",
            "type": "KAS",
            "typeName": "Kaupsamningur",
            "status": "A",
            "statusName": "Aflýst",
            "properDate": "25.08.1992"
        }, {
            "recipient": "4512673489",
            "office": "411",
            "document": "A-234567/1993",
            "published": "05/11/1993",
            "type": "AFS",
            "typeName": "Afsal",
            "status": "Y",
            "statusName": "Yfirstrikað",
            "properDate": "05.11.1993"
        }]
    }, {
        "name": "Dæmisbraut 5, Biskupstungur",
        "description": "Sumarbústaður 010101",
        "source": "Staðfest í Þinglýsingarhluta LF",
        "registry": {
            "type": "F",
            "typeName": "Fasteign",
            "id": "2345678"
        },
        "allowance": [{
            "recipient": "4512673489",
            "office": "433",
            "document": "A-00H123/2000",
            "published": "12/05/2000",
            "type": "LOD",
            "typeName": "Lóðarleigusamningur",
            "status": "Þ",
            "statusName": "Þinglýst",
            "properDate": "12.05.2000"
        }],
        "history": null
    }, {
        "name": "Keldubakki 13, Reykjavík",
        "description": "verslun 050102",
        "source": "Staðfest í Þinglýsingarhluta LF",
        "registry": {
            "type": "F",
            "typeName": "Fasteign",
            "id": "2109876"
        },
        "allowance": null,
        "history": [{
            "recipient": "4512673489",
            "office": "411",
            "document": "A-023456/2001",
            "published": "04/10/2001",
            "type": "AFS",
            "typeName": "Afsal",
            "status": "Y",
            "statusName": "Yfirstrikað",
            "properDate": "04.10.2001"
        }, {
            "recipient": "4512673489",
            "office": "411",
            "document": "B-000321/1997",
            "published": "30/12/1996",
            "type": "KAS",
            "typeName": "Kaupsamningur",
            "status": "A",
            "statusName": "Aflýst",
            "properDate": "30.12.1996"
        }]
    }, {
        "name": "Kóðabraut 1, Biskupstungur",
        "description": "Sumarbústaður 010101",
        "source": "Staðfest í Þinglýsingarhluta LF",
        "registry": {
            "type": "F",
            "typeName": "Fasteign",
            "id": "2468024"
        },
        "allowance": [{
            "recipient": "4512673489",
            "office": "433",
            "document": "A-000321/2003",
            "published": "07/11/2002",
            "type": "LOD",
            "typeName": "Lóðarleigusamningur",
            "status": "Þ",
            "statusName": "Þinglýst",
            "properDate": "07.11.2002"
        }],
        "history": null
    }, {
        "name": "Keldubakki 9, Reykjavík",
        "description": "Samkomustaður 010202",
        "source": "Staðfest í Þinglýsingarhluta LF",
        "registry": {
            "type": "F",
            "typeName": "Fasteign",
            "id": "3456789"
        },
        "allowance": null,
        "history": [{
            "recipient": "4512673489",
            "office": "411",
            "document": "A-009876/2001",
            "published": "02/05/2001",
            "type": "AFS",
            "typeName": "Afsal",
            "status": "Y",
            "statusName": "Yfirstrikað",
            "properDate": "02.05.2001"
        }, {
            "recipient": "4512673489",
            "office": "411",
            "document": "A-013579/2000",
            "published": "16/08/2000",
            "type": "KAS",
            "typeName": "Kaupsamningur",
            "status": "A",
            "statusName": "Aflýst",
            "properDate": "16.08.2000"
        }]
    }],
    "showHistory": true
}

const AssetsReportExample: React.FC = () => {
    return <AssetsReportBody propertyReport={EXAMPLE_DATA} isExample />
}

export default AssetsReportExample;