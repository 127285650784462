// Package imports:
import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Label from '../../../ui-elements/Label/Label';
import Link from '../../../ui-elements/Link/Link';
import Loading from '../../../ui-elements/Loading/Loading';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { getTableDate } from '../../../services/utils';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { ICompanyViewModel, KeldanFile, ReportsSearchModel } from '../../../types/CompanyTypes';
import { Fetched } from '../../../types/Types';

interface IOwnProps {
    limit?: number,
    large?: boolean,
}

type Props = ICompanyViewModel & IOwnProps;

const CompanyStatements: React.FC<Props> = ({
    id,
    isAuthenticated,
    limit,
    large,
    ...props
}) => {
    const [data, setData] = useState<Fetched<ReportsSearchModel>>(null);

    const fetchData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Company/GetCompanyPapersAndStatements/${id}`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                if (response.redirected) {
                    window.location.href = response.url;
                } else {
                    const body = await response.json();
                    setData(body);
                }
            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setData(new Error(ErrorMessages.NetworkError));
        }
    }

    useEffect(() => {
        fetchData();
    }, [isAuthenticated]);

    const sortFiles = (files: KeldanFile[]) => {
        return files.sort((a, b) => {
            if (a.year === null && b.year === null) return 0;
            if (a.year === null) return 1;
            if (b.year === null) return -1;
            if (a.year < b.year) return 1;
            if (a.year > b.year) return -1;
            return 0;
        });
    }

    const filesToShow = useMemo(() => {
        if (data instanceof Error) return null;
        if (data?.files === undefined || data.files === null) return null;
        return sortFiles(data?.files)?.slice(0, limit);
    }, [data]);

    const getReportLink = (file: KeldanFile) => {
        if (data instanceof Error) return undefined;
        if (isAuthenticated) {
            return `/Pdf/Arsreikningar-Saekja?fileId=${file.receiptId}&ssn=${id}`;
        }
        return `/Innskraning?ReturnUrl=${window.location.pathname}`;
    }

    return (
        <DisplayBox 
            className='KCL_company-statements'
            title="Ársreikningar"
            asideComponent={<Label labelType='origin' text='RSK' />}
            footerLeft={!large
                ? <Link
                    linkSize='14'
                    url={`/Fyrirtaeki/Arsreikningar/${id}`}
                    icon='forward'
                >
                    Fleiri ársreikningar
                </Link>
                : undefined
            }
        >
            {data instanceof Error
                ? <Alert type='error' headText={data.message} />
                : data === null || data === undefined
                ? <Loading />
                : filesToShow?.length === 0
                ? <Alert type='info' headText='Engin gögn í boði' />
                : <>
                    {filesToShow?.map((file, index) => (
                        <div key={index} className={cx('company-statement-line', {'large': large})}>
                            <span>
                                <Link
                                    linkSize='15'
                                    url={getReportLink(file)}
                                    targetBlank
                                    icon='pdf'
                                >
                                    {file.type} {file.year}
                                </Link>
                            </span>
                            <span className='extra-info'>Skilað {getTableDate(file.returned, 'DD/MM/YYYY', '.')}</span>
                        </div>
                    ))}
                </>
            }
        </DisplayBox>
    );
}

export default CompanyStatements;