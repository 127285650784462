// Package imports:
import React, { useContext, useEffect, useState } from 'react';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Button from '../../../ui-elements/Button/Button';
import Label from '../../../ui-elements/Label/Label';
import Link from '../../../ui-elements/Link/Link';
import Loading from '../../../ui-elements/Loading/Loading';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { useBuyingProcess } from '../../../services/buyhook';
import { useApiLmdDataMappedByString, useFetchedApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { Fetched } from '../../../types/Types';
import { ICompanyViewModel, AvailableReports, CompanyInfo } from '../../../types/CompanyTypes';
import { IKeldanApiResponse, OwnedReport } from '../../../types/KeldanTypes';
// Context imports:
import {AccessTokenContext} from '../../../contexts/AccessTokenContext';

const CompanyReportsList: React.FC<ICompanyViewModel> = ({
    id,
    isAuthenticated,
    isSubscriber,
}) => {
    const accessToken = useContext(AccessTokenContext);

    const companyInfo = useFetchedApiLmdData<CompanyInfo[]>(`/v1/market_data/v1/company_reports/company_info_by_ssn?ssn=${id}`, accessToken);
    const [ companyInfoId, setCompanyInfoId, availableReports ] = useApiLmdDataMappedByString<AvailableReports[]>(
        '',
        (companyInfoId) => `/v1/market_data/v1/company_reports/available_reports/?id=${companyInfoId}`,
        accessToken
    );
    useEffect(() => {

        if (companyInfoId !== '') return;
        if (companyInfo === null || companyInfo instanceof Error) return;
        if (companyInfo && companyInfo.length > 0) {
            setCompanyInfoId(companyInfo[0].Id.toString());
        }
    }, [ companyInfo ]);

    const [ownedReports, setOwnedReports] = useState<Fetched<OwnedReport[]>>(null);

    const { tryPurchaseItem, isItemLoading, resetState, setToastError, ModalsAndToasts } = useBuyingProcess(async (availableReport: AvailableReports) => {
        try {
            const { CompanyId, FiscalYear, PeriodName } = availableReport;
            const url = `${GET_KELDAN_API_URL()}/Company/BuyCompanyReport?id=${CompanyId}&year=${FiscalYear}&period=${PeriodName}`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${accessToken}`
            };
            const response = await fetch(url, {
                headers
            });
            if (response.ok) {
            
                if (response.redirected) {
                    window.location.href = response.url;
                    return;
                }
                const responseBody: IKeldanApiResponse = await response.json();
                if(responseBody.message) {
                    resetState({
                        type: 'alert',
                        headText: responseBody.message
                    });
                }
            } else {
                resetState('response not ok');
            }
        } catch (e) {
            resetState('network error');
        }
    }, {
        customHeader: 'Kaupa gögn frá ríkisskattstjóra',
        customText: <>Þú ert að fara kaupa gögn frá ríkisskattstjóra. Gögn eru samkvæmt <Link linkSize='18' url="/Verdskra#verdskra">verðskrá Keldunnar</Link></>
    });

    //fetches owned reports
    const fetchOwnedReports = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Company/GetOwnedReports/${companyInfoId}`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                const responseBody: IKeldanApiResponse<OwnedReport[]> = await response.json();
                //log out redirect
                if (response.redirected) {
                    window.location.href = response.url;
                    return;
                }
                if(responseBody.success){
                    if(responseBody.result) setOwnedReports(responseBody.result);
                }
                else {
                    const {message} = responseBody;
                    if(message) {
                        setToastError({
                            type: 'alert',
                            headText: message
                        });
                    }
                    else {
                        setToastError('bad request');
                    }
                }
            } else {
                setToastError('response not ok');
            }
        } catch (e) {
            setToastError('network error');
        }
    }

    useEffect(() => {
        if (isAuthenticated) fetchOwnedReports();
    }, [companyInfoId]);

    //checks if report is in ownedReports array
    const getOwned = (report: AvailableReports) => {
        if (ownedReports instanceof Error || ownedReports === null || !isAuthenticated) return null;
        for (const ownedReport of ownedReports)
        {
            if (report.Id && report.FiscalYear) {
                if (
                    ownedReport.tokens.includes(report.Id.toString())
                    && ownedReport.tokens.includes(report.FiscalYear?.toString())
                    && ownedReport.tokens.includes(report.PeriodName)
                ) {
                    return ownedReport;
                }
            }
        }
        return null;
    }

    const getReportLine = (availableReport: AvailableReports, index: number) => {
        const { ForGroup, FiscalYear, CompanyId, PeriodName } = availableReport;
        const owned = getOwned(availableReport);
        //CASE1: er í áskrift = allar skýrslur ókeypis
        if (isSubscriber) return (
            <div key={index} className="line-wrapper">
                <div className='line-text'>
                    <span>
                        {(ForGroup) ? "Samstæðureikningur" : "Ársreikningur"}
                    </span>
                    <span>
                        {FiscalYear}
                    </span>
                </div>
                <Button
                    size='sm'
                    buttonType='secondary'
                    anchorProps={{href : `/Company/BuyCompanyReport?id=${CompanyId}&year=${FiscalYear}&period=${PeriodName}`}}
                >
                    Skoða
                </Button>
            </div>
        );
        //CASE2: er innskráður ekki í áskrift => kaupa takkar - skoða takkar fyrir skýrslur sem hafa verið keyptar
        if (isAuthenticated) return (
            <div key={index} className="line-wrapper">
                <div className='line-text'>
                    <span>
                        {(ForGroup) ? "Samstæðureikningur" : "Ársreikningur"}
                    </span>
                    <span>
                        {FiscalYear}
                    </span>
                </div>
                {(owned)
                    //user owns this report
                    ? <Button
                        size='sm'
                        buttonType='secondary'
                        anchorProps={{href : `/Fyrirtaeki/Innslegnir-Arsreikningar-Skoda/id=${id}&eventId=${owned.balls}`}}>Skoða</Button>
                    : <Button
                        size='sm'
                        buttonType='buy'
                        showLoader={isItemLoading(availableReport)}
                        onClick={() => tryPurchaseItem(availableReport)}
                    >
                        Kaupa
                    </Button>
                }
            </div>
        );
        //CASE3: ekki innskráður => bara kaupa takkar. Sem redirecta á login
        return (
            <div key={index} className="line-wrapper">
                <div className='line-text'>
                    <span>
                        {(ForGroup) ? "Samstæðureikningur" : "Ársreikningur"}
                    </span>
                    <span>
                        {FiscalYear}
                    </span>
                </div>
                <Button
                    size='sm'
                    buttonType='buy'
                    anchorProps={{href: `/Innskraning?ReturnUrl=${window.location.pathname}`}}
                >
                    Kaupa
                </Button>
            </div>
        );
    }

    const { data, error } = availableReports;

    return (
        <DisplayBox 
            className='KCL_company-reports-list'
            title="Innslegnir ársreikningar"
            asideComponent={<Label labelType='origin' text='RSK' />}
        >
            {(data === null)
                ? ((error === null || companyInfoId === '') // Empty companyinfoid means it is still loading, the request with empty string will be an error.
                    ? <Loading />
                    : <Alert type='error' headText={error.message} />
                )
                : (data.length === 0)
                ? <Alert type='info' headText='Engin gögn í boði' />
                : data.map((availableReport, index) => getReportLine(availableReport, index))
            }
            <ModalsAndToasts />
        </DisplayBox>
    );
}

export default CompanyReportsList;