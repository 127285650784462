// Package imports:
import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { format } from 'date-fns';
import isIsLocale from 'date-fns/locale/is';
import enGBLocale from 'date-fns/locale/en-GB';
import DOMPurify from 'dompurify';
// Component imports:
import Alert from '../../ui-elements/Alert/Alert';
import Loading from '../../ui-elements/Loading/Loading';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import LanguagePicker from '../../ui-elements/LanguagePicker/LanguagePicker';
import Label from '../../ui-elements/Label/Label';
import NewsOrgInfo from '../NewsOrgInfo/NewsOrgInfo';
import Avatar from '../../ui-elements/Avatar/Avatar';
import NewsAttachments from '../NewsAttachments/NewsAttachments';
// Service imports:
import { ErrorMessages } from '../../services/errorMessages';
import { GET_NEWS_LMD_URL } from '../../services/config';
// Type imports:
import { Fetched } from '../../types/Types';
import { INewsLmdResponse } from '../../types/NewsTypes';

const ViewNewsArticle: React.FC<{id: string}> = ({
    id
}) => {
    const [data, setData ] = useState<Fetched<INewsLmdResponse>>();
    const [newsId, setNewsId] = useState(id);
    const [lang, setLang] = useState('IS');
    const [logo, setLogo] = useState('');

    const fetchData = async () => {
        try {
            const url = `${GET_NEWS_LMD_URL()}/search/get/${newsId}`
            
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                const responseBody: Fetched<INewsLmdResponse> = await response.json();
                setData(responseBody)

            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setData(new Error(ErrorMessages.OtherShort));
        }
    }
    useEffect(() => {
        fetchData()
    }, [newsId])

    const feedItem = useMemo(() => {
        if (!data || data instanceof Error || data.feedItems === null || data.feedItems.length === 0) {
            return null
        }
        return data?.feedItems[0]
    }, [data])

    const cleanHtml = useMemo(() => {
        return DOMPurify.sanitize(feedItem?.bodyHtml ?? '')
    }, [feedItem])
    
    const displayBody = () => {
        if (data instanceof Error) {
            return (
                <Alert type={'error'} headText={data.message} />
            )
        }
        if (feedItem === null) {
            return (
                (data && data.feedItems && data.feedItems.length === 0)
                    ? <Alert type='alert' headText='Ekki fundust gögn fyrir þessa frétt' />
                    : <Loading />
            )
        }
        return (<>
            <HeadSection
                title={
                    <h1>
                        <Avatar
                            dataLink={logo}
                            size='lg'
                            color
                            dropShadow
                        />
                        {feedItem.issuerSource}
                    </h1>
                }
            />
            <div className='grid-items'>
                <div className='grid-item grid-item--3of4'>
                    <DisplayBox
                        title={feedItem.title ?? ''}
                        asideComponent={ 
                            feedItem.writtenInLang.length > 1
                                ? <LanguagePicker
                                    availableLanguages={
                                        feedItem.writtenInLang.map(l => ({ lang: l.lang, value: l.documentId }))
                                    }
                                    defaultValue={newsId}
                                    onChange={(val) => {
                                        setNewsId(val ?? '')
                                    }}
                                    setLanguage={setLang}
                                />
                                : undefined
                        }
                        displayBoxSize='xxl'
                        footerLeft={
                            <div className='origin-wrapper'>
                                <span className='origin-label'>
                                    {lang === 'IS' ? 'UPPRUNI' : 'SOURCE'}
                                </span>
                                {feedItem.source === 'KELDAN_NEWSWIRE'
                                    ? <span className='keldan-logo'>
                                        <img
                                            src="https://cdn.livemarketdata.com/KeldanLogos/KeldanLogo_Colored_LightBg.png"
                                            alt="Keldan logo"
                                            width="100"
                                            height="19"
                                        />
                                    </span>
                                    : <span className='extra-info'>{feedItem.fullSource}</span>
                                }
                            </div>
                        }
                        footerRight={
                            <div className='share-wrapper'>
                                <span className='share-label'>
                                    {lang === 'IS' ? 'DEILA' : 'SHARE'}
                                </span>
                                <div className='share-icons'>
                                    <a
                                        href={`mailto:?subject=${encodeURIComponent(feedItem.title ?? '')}&body=${encodeURIComponent(window.location.href)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </a>
                                    <a
                                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(feedItem.title ?? '')}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon icon={faLinkedinIn} />
                                    </a>
                                    <a
                                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </a>
                                    <a
                                        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(feedItem.title ?? '')}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon icon={faXTwitter} />
                                    </a>
                                </div>
                            </div>
                        }
                    >
                        <div className='time-and-category'>
                            {format(
                                new Date(feedItem.publish_timestamp ?? 0),
                                'PPPpp',
                                { locale: lang === 'IS' ? isIsLocale : enGBLocale }
                            )}
                            {feedItem.sourceTags === 'knw:ftil' &&
                                <Label
                                    labelType='news-release'
                                    text='Fréttatilkynning'
                                    size='lg'
                                />
                            }
                        </div>
                        <div className='news-body'>
                            <p className='paragraph'>{feedItem.body}</p>
                            <div className="news-entry paragraph" dangerouslySetInnerHTML={{__html: cleanHtml}} />
                        </div>
                    </DisplayBox>
                </div>
                <div className='grid-item grid-item--1of4'>
                    {feedItem.attachments.length > 0 &&
                        <NewsAttachments attachments={feedItem.attachments} />
                    }
                    <NewsOrgInfo
                        name={feedItem.issuerSource ?? ''}
                        setLogoUrl={(url) => setLogo(url ?? '')}
                    />
                </div>
            </div>
        </>)
    }
    
    return <div className='main KCL_view-news-article'>
        <div className="main__inner-fluid">
            <div className="shell">
                {displayBody()}
            </div>
        </div>
    </div>
}

export default ViewNewsArticle;
