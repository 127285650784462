// Package imports:
import React from 'react';
// Component imports:
import PropertyReportBody from './PropertyReportBody';
// Type imports:
import { IEventReport } from "../../../types/KeldanTypes";
import { IPropertySearchResult, IPropertyDetail, IAddress } from "../../../types/PropertyTypes";
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';

const EXAMPLE_DATA: IEventReport<IPropertySearchResult> = {
    "userName": "martha@kodi.is",
    "productName": "Fasteignir",
    "reply": "",
    "eventDate": "27.03.2023 14:35:45",
    "overview": {
        "property": {
            "property": {
                "property": [{
                    "units": [{
                        "insurance_code": "KEL",
                        "insurance": "Keldutryggingar",
                        "details": [{
                            "unit_nr": "2382494",
                            "display_address": "Kelduvogur 20",
                            "area_unit": "m²",
                            "building_status_code": "7",
                            "building_status": "Fullg. notaein.",
                            "evaluation_status_code": "7",
                            "evaluation_status": "Fullg. notaein.",
                            "building_material_code": "1",
                            "building_material": "Steypt",
                            "building_year": "2021",
                            "rooms": "3",
                            "house_estimation": 59780000,
                            "house_estimation_next_year": 0,
                            "land_estimation": 7070000,
                            "land_estimation_next_year": 0,
                            "property_estimation": 66850000,
                            "property_estimation_next_year": 0,
                            "reform_price": 41956000,
                            "reform_price_calc": 80355000,
                            "burn_estimation": 50750000,
                            "building_index": "908.20",
                            "building_index_date": new Date("2023-03-27T00:00:00+00:00"),
                            "price_group_code": "3",
                            "price_group": "Sambýlishús",
                            "unit_number": "1234567",
                            "property_number": "1234567",
                            "land_number": "225190",
                            "name_number": "7654321",
                            "tag": "010601",
                            "description": "Íbúð",
                            "usage": "501",
                            "display_usage": "Íbúð",
                            "area": "86.3"
                        }, {
                            "unit_nr": "2382525",
                            "display_address": "Kelduvogur 20",
                            "area_unit": "m²",
                            "building_status_code": "7",
                            "building_status": "Fullg. notaein.",
                            "evaluation_status_code": "7",
                            "evaluation_status": "Fullg. notaein.",
                            "building_material_code": "1",
                            "building_material": "Steypt",
                            "building_year": "2021",
                            "rooms": "0",
                            "house_estimation": 0,
                            "house_estimation_next_year": 0,
                            "land_estimation": 0,
                            "land_estimation_next_year": 0,
                            "property_estimation": 0,
                            "property_estimation_next_year": 0,
                            "reform_price": 5383000,
                            "reform_price_calc": 10309000,
                            "burn_estimation": 6540000,
                            "building_index": "908.20",
                            "building_index_date": new Date("2023-03-27T00:00:00+00:00"),
                            "price_group_code": "3",
                            "price_group": "Sambýlishús",
                            "unit_number": "1234567",
                            "property_number": "1234567",
                            "land_number": "225190",
                            "name_number": "7654321",
                            "tag": "01B21",
                            "description": "Stæði í bílageymslu",
                            "usage": "372",
                            "display_usage": "Stæði í bílageymslu",
                            "area": "0.0",
                        }],
                        "unit_number": "1234567",
                        "display_unit_number": "N1234567",
                        "property_number": "1234567",
                        "land_number": "225190",
                        "name_number": "7654321",
                        "tag": "010601",
                        "description": "Íbúðareign",
                        "usage": "501",
                        "display_usage": "Íbúð",
                        "area": "86.3"
                    }],
                    "owners": [{
                        "buy_date": new Date("2021-09-06T00:00:00+00:00"),
                        "receive_date":  new Date("2021-02-19T00:00:00+00:00"),
                        "allowance_type": "A",
                        "display_allowance_type": "Afsal",
                        "display_allowance": "Afsal",
                        "owned_part": 50.0,
                        "office_number": "441",
                        "document_number": "I-010357/2021",
                        "property_number": "1234567",
                        "owner_type": "F",
                        "display_owner_type": "Fasteignaeigandi",
                        "ssn": "1901892349",
                        "name": "Jón Kelddal",
                        "status": "Þ",
                        "display_status": "Þinglýst"
                    }, {
                        "buy_date": new Date("2021-09-06T00:00:00+00:00"),
                        "receive_date": new Date("2021-02-19T00:00:00+00:00"),
                        "allowance_type": "A",
                        "display_allowance_type": "Afsal",
                        "display_allowance": "Afsal",
                        "owned_part": 50.0,
                        "office_number": "441",
                        "document_number": "I-010357/2021",
                        "property_number": "1234567",
                        "owner_type": "F",
                        "display_owner_type": "Fasteignaeigandi",
                        "ssn": "0910791239",
                        "name": "Kelda Kóðadóttir",
                        "status": "Þ",
                        "display_status": "Þinglýst"
                    }],
                    "property_number": "1234567",
                    "display_property_number": "F1234567",
                    "type": "L",
                    "display_type": "Lóð",
                    "land_number": "225190",
                    "name_number": "7654321"
                }],
                "address": {
                    "name_number": "7654321",
                    "display_address": "Kelduvogur 20",
                    "county_number": "0",
                    "display_county_number": "0000",
                    "county": "Reykjavíkurborg",
                    "city": "Reykjavík",
                    "land_number": "225190",
                    "zip": "104",
                    "address_number": "31721",
                    "street": "Kelduvogur",
                    "house_number": "20"
                }
            }
        },
        "step": 3,
        "bonds": false,
        "reference": ""
    },
    "result": 0,
    "descr": null,
    "exception": null,
    "msg": null,
    "frontmsg": null,
    "errorCode": 0,
    "errorMessage": null
}

const PropertyReportExample: React.FC = () => {
    const property: (IPropertyDetail | null) = EXAMPLE_DATA.overview.property?.property?.property ? EXAMPLE_DATA.overview.property?.property?.property[0] : null;
    const address: (IAddress | null) = EXAMPLE_DATA.overview.property?.property?.address ?? null;
    return <>
        <HeadSection
            title={`${address?.display_address}, ${address?.city}`}
        />
        <div className='info-col-wrapper'>
            <div className='info-col'>
                <div className='info-item'>
                    <span className='title'>
                        Fastanúmer
                    </span>
                    <span className='value'>
                        {property?.property_number}
                    </span>
                </div>
            </div>
            <div className='info-col'>
                <div className='info-item'>
                    <span className='title'>
                        Merking
                    </span>
                    <span className='value'>
                        {property?.units[0].tag}
                    </span>
                </div>
            </div>
            <div className='info-col'>
                <div className='info-item'>
                    <span className='title'>
                        Heitisnúmer
                    </span>
                    <span className='value'>
                        {property?.name_number}
                    </span>
                </div>
            </div>
            <div className='info-col'>
                <div className='info-item'>
                    <span className='title'>
                        Skýring
                    </span>
                    <span className='value'>
                        {property?.units[0].description}
                    </span>
                </div>
            </div>
        </div>
        <PropertyReportBody report={EXAMPLE_DATA} isExample />
    </>
}

export default PropertyReportExample;