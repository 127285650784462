// Package imports:
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../../ui-elements/Link/Link';
import Table from '../../../ui-elements/Table/Table';
import Loading from '../../../ui-elements/Loading/Loading';
import Alert from '../../../ui-elements/Alert/Alert';
import VerticalTable from '../../../ui-elements/Table/VerticalTable';
import Button from '../../../ui-elements/Button/Button';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { convertToPercentage, formatNumber, getTableDate, insertDash, isCompany } from '../../../services/utils';
import { useBuyingProcess } from '../../../services/buyhook';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { CompanyDetailedOverview, ICompanyReportModel } from '../../../types/CompanyTypes';
import { IKeldanApiResponse } from '../../../types/KeldanTypes';
import { Fetched } from '../../../types/Types';

const boardMemberTypes = ['4', '5', '6', '7'];

interface CompanyDetailedOverviewWrapper {
    eventDate: string,
    overview: CompanyDetailedOverview
}
const ViewMembersReport: React.FC<ICompanyReportModel>= ({
    id,
    eventId
}) => {
    const [ data, setData] = useState<Fetched<CompanyDetailedOverviewWrapper>>(null);
    const [ boardOnly, setBoardOnly] = useState<boolean>(false);
    const [ ssn, setSSN ] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${GET_KELDAN_API_URL()}/Company/GetMemberReport/${id}?eventId=${eventId}`;
                const response = await fetch(url);
                if (!response.ok) {
                    setData(new Error(ErrorMessages.RequestFailed));
                    return;
                }
                if (response.redirected) {
                    window.location.href = response.url;
                    return;
                }
                const responseBody: IKeldanApiResponse<CompanyDetailedOverviewWrapper> = await response.json();
                if (responseBody.success && responseBody.result) {
                    setData(responseBody.result);
                    if (responseBody.result.overview.board !== null) {
                        const i = responseBody.result.overview.board.findIndex(x => boardMemberTypes.includes(x.ssn[0]));
                        setBoardOnly(i !== -1);
                    }
                } else if (responseBody.message) {
                    setData(new Error(responseBody.message));
                } else {
                    setData(new Error(ErrorMessages.OtherShort));
                }
            } catch (e) {
                setData(new Error(ErrorMessages.NetworkError));
            }
        }
        fetchData();
    }, []);
    
    const { tryPurchaseItem, isItemLoading, resetState, ModalsAndToasts } = useBuyingProcess(
        async () => {
            try {
                const headers = {
                    'Content-Type': 'application/json; charset=utf-8'
                };
                const url = `${GET_KELDAN_API_URL()}/Einstaklingar/Hlutafelagathatttaka-Stakt-Kaupa/${ssn}`;
                const response = await fetch(url, {
                    method: 'POST',
                    headers
                });
                if (response.ok) {
                    if (response.redirected) {
                        window.location.href = response.url;
                        return;
                    }
                    const responseBody: IKeldanApiResponse = await response.json();
                    if(responseBody.message) {
                        resetState({
                            type: 'alert',
                            headText: responseBody.message
                        });
                    } else {
                        resetState('bad request')
                    }
                } else {
                    resetState('response not ok');
                }
            } catch (e) {
                resetState('network error');
            }
        },
        'hlutafélagaskrá',
        () => setSSN(null)
    );

    const displayAddress = (address: string | undefined, zip: string | undefined, city: string | undefined) => {
        return `${address ?? '-'}, ${zip ?? '-'} ${city ?? '-'}` ;
    }

    const getNumberOfCompanies = (ssn?: string) => {
        if (ssn === undefined || data instanceof Error) return null;
        return data?.overview?.members.find(member => member.ssn === ssn)?.nrOfCompanies ?? null;
    }

    const displayTableRow = (
        index: number,
        title?: string,
        flex?: number,
        text?: string,
        addresses?: boolean,
        address?: string,
        zip?: string,
        city?: string,
        ssn?: string
    ) => {
        const nrOfCompanies = getNumberOfCompanies(ssn);
        return (
            <tr
                key={index}
                className='lineContainer'
            >
                <th>
                    <strong>{(index !== 0 ) ? '' : title}</strong>
                </th>
                <td style={{'flex': flex}}>
                    <div className="text-wrapper">
                        {(text)
                            ? (
                                <p className={cx({'nameAndSocial': ssn})}>
                                    {text}
                                    {ssn && ', ' + insertDash(ssn)}
                                </p>
                            )
                            : (
                                <p>-</p>
                            )
                        }
                        {(addresses) && <p className='address'>{displayAddress(address, zip, city)}</p>}
                    </div>
                    {(ssn) && (
                        (!isCompany(ssn))
                        ? (
                            <div className='button-wrapper'>
                                <Button 
                                    showLoader={isItemLoading(`${ssn};${title}`)}
                                    size='sm'
                                    buttonType='buy'
                                    onClick={() => {
                                        if (title) {
                                            tryPurchaseItem(`${ssn};${title}`);
                                            setSSN(ssn);
                                        }
                                    }}
                                >
                                    Kaupa
                                </Button>
                                {nrOfCompanies !== null && nrOfCompanies !== -1 &&
                                    <span className='company-count extra-info'>
                                        {nrOfCompanies} {nrOfCompanies % 10 === 1 && nrOfCompanies % 100 !== 11 ? 'félag' : 'félög'}
                                    </span>
                                }
                            </div>
                        )
                        : (
                            <Button 
                                size='sm'
                                buttonType='secondary'
                                anchorProps={{href: `/Fyrirtaeki/Yfirlit/${ssn}`}}
                            >
                                Skoða
                            </Button>
                        )
                    )}
                </td>
            </tr>
        )
    }
    
    if (!(data instanceof Error) && data?.overview?.company?.name) {
        document.title = `${data.overview.company.name} (${id}) | Hlutafélagaskrá`
    } else {
        document.title = `Hlutafélagaskrá`
    }
    if (data instanceof Error) return <Alert type='error' headText={data.message} />
    if (data === null) return <Loading />

    return (
        <DisplayBox
            title='Grunnupplýsingar'
            className='KCL_ViewCompanyMembersReport'
            asideComponent={(data === null || data instanceof Error)
                ? undefined
                : (
                    <div className='report-aside-component'>
                        <div className='link-wrapper'>
                            <Link
                                targetBlank
                                linkSize='15'
                                url={(data.overview)
                                    ? `/Pdf/Hlutafelagaskra?ssn=${id}&eventId=${eventId}`
                                    : '#'
                                }
                                icon='pdf'
                            >
                                Sækja skýrslu
                            </Link>
                        </div>
                        <div className='italic-text'>
                            Skýrsla keypt: {data.eventDate}
                        </div>
                    </div>
                )
            }
        >
            <div className='vertical-table-wrapper'>
                <VerticalTable
                    fallbackSymbol
                    data={[data.overview]}
                    columns={[{
                        title: 'Nafn',
                        renderCell: ({company}) => company?.name
                    }, {
                        title: 'Kennitala',
                        renderCell: ({company}) => insertDash(company?.ssn),
                    }, {
                        title: 'Lögheimili',
                        renderCell: ({company}) => displayAddress(company?.address, company?.zip, company?.city),
                    }, {
                        title: 'Síðast breytt í fyrirtækjaskrá RSK',
                        renderCell: ({company}) => getTableDate(new Date(company?.lastChangeRSK), 'DD/MM/YYYY', '.'),
                    }, {
                        title: 'Atvinnugrein (ISAT)',
                        renderCell: ({isats}) => isats ? `${isats[0].isat ?? '-'} ${isats[0].description ?? isats[0].description_en ?? '-'} ` : '-',
                    }, {
                        title: 'Tilgangur',
                        renderCell: ({company}) => company?.description,
                    }, {
                        title: 'Rekstrarform',
                        renderCell: ({company}) => `${company?.formDescription ?? '-'} (${company?.form ?? '-'})`,
                    }, {
                        title: 'Stofnað',
                        renderCell: ({company}) => getTableDate(new Date(company?.dateRegistered), 'DD/MM/YYYY', '.'),
                    }, {
                        title: 'Hlutafé',
                        renderCell: ({company}) => `${formatNumber(company?.capital, '-', 0)} ${company?.currency ?? '-'}`,
                    }, {
                        title: 'Dags. samþykkta',
                        renderCell: ({company}) => getTableDate(new Date(company?.dateAccepted), 'DD/MM/YYYY', '.'),
                    }]}
                    tableSize="lg"
                    headerTitle='&nbsp;'
                />

               <VerticalTable
                    fallbackSymbol
                    horizontalFlexSize={2}
                    tableSize="lg"
                    data={[data.overview]}
                    headerTitle='Hlutafélagaþátttaka'
                    columns={boardOnly
                        ? [{
                            title: 'Síðasta breyting á stjórn',
                            renderCell: ({company}) => getTableDate(new Date(company?.lastBoardMeeting), 'DD/MM/YYYY', '.'),
                        }, {
                            title: 'Firmað rita',
                            renderCell: ({company}) => company?.acceptance,
                        }, {
                            title: 'Prókúruumboð',
                            overrideDefaultStyle: true,
                            renderCell: ({addresses, procuration}) =>
                                (procuration === null || procuration.length === 0)
                                ? '-'
                                : procuration.map((item, index) => displayTableRow(index, 'Prókúruumboð', 2, item.name, addresses, item.address, item.zip, item.city, item.ssn)),
                        }, {
                            title: 'Framkvæmdastjórn',
                            overrideDefaultStyle: true,
                            renderCell: ({addresses, managers}) => 
                                (managers === null || managers.length === 0)
                                ? '-'
                                : managers.map((item, index) => displayTableRow(index, 'Framkvæmdastjórn', 2, item.name, addresses, item.address, item.zip, item.city, item.ssn)),
                        }, {
                            title: 'Stjórn',
                            overrideDefaultStyle: true,
                            renderCell: ({addresses, board}) =>
                                (board === null || board.length === 0)
                                ? '-'
                                : board.map((item, index) => displayTableRow(index, 'Stjórn', 2, item.name, addresses, item.address, item.zip, item.city, item.ssn)),
                        }, {
                            title: 'Endurskoðendur',
                            overrideDefaultStyle: true,
                            renderCell: ({addresses, accountants}) =>
                                (accountants === null || accountants.length === 0)
                                ? '-'
                                : accountants.map((item, index) => displayTableRow(index, 'Endurskoðendur', 2, item.name, addresses, item.address, item.zip, item.city, item.ssn)),
                        }, {
                            title: 'Hömlur á meðferð hlutabréfa',
                            renderCell: ({company}) => company?.restrictions && company.restrictions === '1' ? 'Já' : 'Nei',
                        }, {
                            title: 'Innlausnarskylda',
                            renderCell: ({company}) => company?.duty && company.duty === '1' ? 'Já' : 'Nei',
                        }, {
                            title: 'Stofnendur',
                            overrideDefaultStyle: true,
                            renderCell: ({addresses, founders}) =>
                            (founders === null || founders.length === 0)
                            ? '-'
                            : founders.map((item, index) => displayTableRow(index, 'Stofnendur', 2, item.name, addresses, item.address, item.zip, item.city, item.ssn)),
                        }]
                        : [{
                            title: 'Stjórnarformaður',
                            renderCell: ({addresses, boardChairman}) =>
                                (boardChairman === null || [boardChairman].length === 0)
                                ? '-' 
                                : [boardChairman].map((item, index) => displayTableRow(index, 'Stjórnarformaður', 2, item.name, addresses, item.address, item.zip, item.city, item.ssn)),
                            overrideDefaultStyle: true,
                        }, {
                            title: 'Meðstjórnendur',
                            overrideDefaultStyle: true,
                            renderCell: ({addresses, directors}) =>
                                (directors === null || directors.length === 0)
                                ? '-'
                                : directors.map((item, index) => displayTableRow(index, 'Meðstjórnendur', 2, item.name, addresses, item.address, item.zip, item.city, item.ssn)),
                        }, {
                            title: 'Varamenn',
                            overrideDefaultStyle: true,
                            renderCell: ({addresses, substitutes}) =>
                                (substitutes === null || substitutes.length === 0)
                                ? '-'
                                : substitutes.map((item, index) => displayTableRow(index, 'Varamenn', 2, item.name, addresses, item.address, item.zip, item.city, item.ssn)),
                        }, {
                            title: 'Síðasta breyting á stjórn',
                            renderCell: ({company}) => getTableDate(new Date(company?.lastBoardMeeting), 'DD/MM/YYYY', '.'),
                        }, {
                            title: 'Firmað rita',
                            renderCell: ({company}) => company?.acceptance,
                        }, {
                            title: 'Prókúruumboð',
                            overrideDefaultStyle: true,
                            renderCell: ({addresses, procuration}) =>
                                (procuration === null || procuration.length === 0)
                                ? '-'
                                : procuration.map((item, index) => displayTableRow(index, 'Prókúruumboð', 2, item.name, addresses, item.address, item.zip, item.city, item.ssn)),
                        }, {
                            title: 'Framkvæmdastjórn',
                            overrideDefaultStyle: true,
                            renderCell: ({addresses, managers}) => 
                                (managers === null || managers.length === 0)
                                ? '-'
                                : managers.map((item, index) => displayTableRow(index, 'Framkvæmdastjórn', 2, item.name, addresses, item.address, item.zip, item.city, item.ssn)),
                        }, {
                            title: 'Endurskoðendur',
                            overrideDefaultStyle: true,
                            renderCell: ({addresses, accountants}) =>
                                (accountants === null || accountants.length === 0)
                                ? '-'
                                : accountants.map((item, index) => displayTableRow(index, 'Endurskoðendur', 2, item.name, addresses, item.address, item.zip, item.city, item.ssn)),
                        }, {
                            title: 'Hömlur á meðferð hlutabréfa',
                            renderCell: ({company}) => company?.restrictions && company.restrictions === '1' ? 'Já' : 'Nei',
                        }, {
                            title: 'Innlausnarskylda',
                            renderCell: ({company}) => company?.duty && company.duty === '1' ? 'Já' : 'Nei',
                        }, {
                            title: 'Stofnendur',
                            overrideDefaultStyle: true,
                            renderCell: ({addresses, founders}) =>
                            (founders === null || founders.length === 0)
                            ? '-'
                            : founders.map((item, index) => displayTableRow(index, 'Stofnendur', 2, item.name, addresses, item.address, item.zip, item.city, item.ssn)),
                        }]
                    }
                />
            </div>

            {(data.overview.owners !== null) && (
                 <div>
                    <h4>Raunverulegir eigendur</h4>
                    <Table 
                        data={data.overview.owners}
                        columns={[{
                            title: 'Nafn',
                            renderCell: ({name}) => name,
                            textAlign: 'left',
                        }, {
                            title: 'Fæðingarár/mán.',
                            renderCell: ({faedingarManudur}) => faedingarManudur,
                            textAlign: 'right',
                        }, {
                            title: 'Búsetuland',
                            renderCell: ({busetuLand}) => busetuLand,
                            textAlign: 'right',
                        }, {
                            title: 'Ríkisfang',
                            renderCell: ({nationality}) => nationality,
                            textAlign: 'right',
                        }, {
                            title: 'Eignarhlutur',
                            renderCell: ({ownedPercentage}) => convertToPercentage(ownedPercentage / 100, true, 2),
                            textAlign: 'right',
                        }]}
                        tableSize='lg'
                    />
                </div>
            )}
            <ModalsAndToasts />
        </DisplayBox>
    );
}

export default ViewMembersReport;