// Package imports:
import React, { Fragment, useEffect, useState } from 'react';
import cx from 'classnames';
// Component imports:
import Alert from '../../../ui-elements/Alert/Alert';
import Label from '../../../ui-elements/Label/Label';
import Link from '../../../ui-elements/Link/Link';
import Loading from '../../../ui-elements/Loading/Loading';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Button from '../../../ui-elements/Button/Button';
import ExampleReportButton from '../../ExampleReportButton/ExampleReportButton';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { useBuyingProcess } from '../../../services/buyhook';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched } from '../../../types/Types';
import { IKeldanApiResponse, OwnedReport } from '../../../types/KeldanTypes';
import { ICompanyViewModel } from '../../../types/CompanyTypes';

const limit = 100;
type PurchasingOptions = 'KYC' | 'KYC með heimilisföngum' | 'KYC með raunverulegum eigendum'
interface IKycPurchasingOption {
    purchaseOption: PurchasingOptions,
    title: string,
    typeName: string
}

const CompanyMembers: React.FC<ICompanyViewModel & {large?: boolean}> = ({
    id,
    isAuthenticated,
    isSubscriber,
    large
}) => {
    const [data, setData] = useState<Fetched<OwnedReport[]>>([]);

    const fetchData = async () => {
        // trigger loading
        setData(null)

        try {
            const url = (large) 
                ? `${GET_KELDAN_API_URL()}/Company/GetCompanyMembersReports/?id=${id}&limit=${limit}`
                : `${GET_KELDAN_API_URL()}/Company/GetCompanyMembersReports/${id}`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (!response.ok) {
                setData(new Error(ErrorMessages.RequestFailed));
                return;
            }
            if (response.redirected) {
                window.location.href = response.url;
            }
            const responseBody: IKeldanApiResponse<OwnedReport[]> = await response.json();
            if (responseBody.success) {
                if (responseBody.result) setData(responseBody.result);
            } else {
                const { message } = responseBody;
                if (message) {
                    setData(new Error(message));
                } else {
                    setData(new Error(ErrorMessages.ErrorInRequest));
                }
            }
        } catch (e) {
            setData(new Error(ErrorMessages.OtherShort));
        }
    }

    useEffect(() => {
        if (isAuthenticated) fetchData();
    }, [isAuthenticated]);

    const { tryPurchaseItem, isItemLoading, resetState, ModalsAndToasts } = useBuyingProcess(async (item: PurchasingOptions, reference: string) => {
        try {
            const baseurl = `${GET_KELDAN_API_URL()}/Fyrirtaeki/Hlutafelagaskra-Kaupa/${id}`;
            const query = new URLSearchParams({
                'addresses': 'false',
                'owners': 'false',
                'json': 'true',
                'reference': reference
            });

            if (item === 'KYC með heimilisföngum') query.set('addresses', 'true');
            else if (item === 'KYC með raunverulegum eigendum') query.set('owners', 'true');

            const url = `${baseurl}?${query.toString()}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });

            if (!response.ok) {
                resetState('response not ok');
                return;
            }
            if (response.redirected) {
                window.location.href = response.url;
                return;
            }
            const responseBody: IKeldanApiResponse<OwnedReport[]> = await response.json();
            if (responseBody.success) {
                if (responseBody.result) setData(responseBody.result);
            } else {
                const { message } = responseBody;
                if (message === ErrorMessages.NoCard) {
                    resetState('no card');
                } else if (message) {
                    resetState({ headText: message, type: 'alert' });
                } else {
                    resetState('bad request');
                }
            }
        } catch (e) {
            resetState('network error');
        }
    }, 'hlutafélagaskrá');

    const formatDate = (date: string) => {
        const newDate = date.split(' ')[0].split('/');
        if (newDate.length === 3) {
            return `${newDate[0].padStart(2, '0')}.${newDate[1].padStart(2, '0')}.${newDate[2].substring(2)}`;
        }
        return '-';
    }

    const KYC_PURCHASE_POSSIBILITIES: IKycPurchasingOption[] = [{
        purchaseOption: 'KYC',
        title: 'Gildandi skráning (KYC)',
        typeName: 'Hlutafélög '
    }, {
        purchaseOption: 'KYC með heimilisföngum',
        title: 'Gildandi skráning (KYC) m/heimilisföngum',
        typeName: 'Hlutafélög með heimilisföngum'
    }, {
        purchaseOption: 'KYC með raunverulegum eigendum',
        title: 'Gildandi skráning (KYC) m/raunverulegum eigendum',
        typeName: 'Hlutafélög með raunverulegum eigendum'
    }]

    const getPurchaseButton = (purchaseOption: PurchasingOptions) => {
        if (isSubscriber) {
            let query = '';
            if (purchaseOption === 'KYC með heimilisföngum') query = '?addresses=true';
            if (purchaseOption === 'KYC með raunverulegum eigendum') query = '?owners=true';
            return (
                <Button
                    size='sm'
                    buttonType='primary'
                    anchorProps={{href: `/Fyrirtaeki/Hlutafelagaskra-Kaupa/${id}${query}`}}
                >
                    Sækja
                </Button>
            );
        }
        if (isAuthenticated) {
            return (
                <Button
                    showLoader={isItemLoading(purchaseOption)}
                    size='sm'
                    buttonType='buy'
                    onClick={() => tryPurchaseItem(purchaseOption, 'confirm-modal-with-reference')}
                >
                    Kaupa
                </Button>
            );
        }
        return (
            <Button
                size='sm'
                buttonType='buy'
                anchorProps={{href: `/Innskraning?ReturnUrl=${window.location.pathname}`}}
            >
                Kaupa
            </Button>
        );
    }
    const displayOwnedReports = (typeName: string) => {
        if (data instanceof Error) {
            return <Alert type='error' headText={data.message} />;
        }
        if (data === null) {
            return <Loading />;
        }
        if (!isAuthenticated) {
            return null;
        }
        return data.filter(x => x.typeName !== null && x.typeName.replace('  ', ' ') === typeName).splice(0,(large) ? limit : 2).map((item) => (
            <div key={item.balls} className={cx('company-members-line', {'large': large})}>
                <span className='company-members-line-text'>
                    Skráning {isSubscriber ? 'sótt:' : 'keypt:'} {formatDate(item.date)}
                </span>
                <Button
                    size='sm'
                    buttonType='secondary'
                    anchorProps={{href: `/Fyrirtaeki/Hlutafelagaskra-Skoda/${id}?eventId=${item.balls}`}}
                >
                    Skoða
                </Button>
            </div>
        ))
    }
    const displaySection = (purchaseOption: PurchasingOptions, title: string, typeName: string) => {
        return <Fragment key={purchaseOption}>
            <div className={cx('company-members-line', {'large': large})}>
                <strong><span className='company-members-line-text'>{title}</span></strong>
                {getPurchaseButton(purchaseOption)}
            </div>
            {displayOwnedReports(typeName)}
        </Fragment>
    }
    
    return (
        <DisplayBox 
            className='KCL_company-members'
            title="Hlutafélagaskrá"
            description='Nýjustu upplýsingar um prókúruhafa, framkvæmdastjóra, stjórn o.fl..'
            asideComponent={<Label labelType='origin' text='RSK' />}
            footerLeft={!large
                ? <Link
                    linkSize='14'
                    url={`/Fyrirtaeki/Hlutafelagaskra/${id}`}
                    className='more-link'
                    icon='forward'
                >
                    Fleiri skráningar
                </Link>
                : undefined
            }
            footerRight={!large
                ? <ExampleReportButton size='sm' reportType='company-members' />
                : undefined
            }
        >
            {KYC_PURCHASE_POSSIBILITIES.map(({ purchaseOption, title, typeName }) => (
                displaySection(purchaseOption, title, typeName)
            ))}

            <ModalsAndToasts />
        </DisplayBox>
    );
}

export default CompanyMembers;