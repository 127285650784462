// Package imports:
import React from 'react';
import OverlayTrigger, { OverlayTriggerType } from 'react-bootstrap/OverlayTrigger';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';
import BootstrapTooltip from 'react-bootstrap/Tooltip';
import cx from 'classnames';


interface IProps {
    text: string | JSX.Element,
    tooltip: JSX.Element,
    trigger?: OverlayTriggerType | OverlayTriggerType[],
    noUnderline?: boolean
}

const Tooltip: React.FC<IProps> = ({
    text,
    tooltip,
    trigger,
    noUnderline
}) => {
    const displayTooltip = (props: OverlayInjectedProps) => {
        props = {...props, placement: 'auto' };
        return <BootstrapTooltip
            // Component gets KCL_ prefix since it is injected at bottom of DOM.
            className={cx('KCL_react-tooltip')}
            {...props}
        >
            {/* Invisible content so */}
            <div className='invisible-content'>
                {tooltip}
            </div>
            <div className='tooltip-content-wrapper'>
                {tooltip}
            </div>
        </BootstrapTooltip>
    }

    return (
        <span className="KCL_tooltip">
            <OverlayTrigger
                delay={{ show: 200, hide: 300 }}
                trigger={trigger ?? ['hover', 'focus']}
                overlay={displayTooltip}
            >
                <span className={cx('display-text', { 'no-underline': noUnderline })}>
                    {text}
                </span>
            </OverlayTrigger>
        </span>
    )
}

export default Tooltip;