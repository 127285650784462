// Package imports:
import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
// Component imports:
import ColumnChart, { IColumnChart } from '../../Charts/ColumnChart/ColumnChart';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Alert from '../../../ui-elements/Alert/Alert';
import ChartLoading from '../../../ui-elements/ChartLoading/ChartLoading';
import BubbleChart from '../../Charts/BubbleChart/BubbleChart';
import PieChart from '../../Charts/PieChart/PieChart';
import Table from '../../../ui-elements/Table/Table';
import Loading from '../../../ui-elements/Loading/Loading';
import Tooltip from '../../../ui-elements/Tooltip/Tooltip';
import Link from '../../../ui-elements/Link/Link';
import Fyrirvari from '../../Fyrirvari/Fyrirvari';
// Service imports:
import { convertToPercentage, displayQuantityShorthand } from '../../../services/utils';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { ComparableData, IViewCompanyCompare } from '../../../types/CompanyTypes';


const HIGHER_DATA_INDEX = 3;
const LOWER_DATA_INDEX = 2;

const COLORS = [ "#4569EE", "#6CD3FF", "#94EFB3", "#FFF06B", "#FF7697", "#B07EF0", "#48A85D", "#DB0033", "#EC9718", "#1D3097" ]

type ICompanyCompareTable = (ComparableData & {last_year?: ComparableData, fallbackSymbol?: boolean })

interface IProps {
    id?: string,
    eventId?: string,
    data: IViewCompanyCompare | null,
    isExample?: boolean
}

const ViewCompanyCompareBody: React.FC<IProps> = ({
    id,
    eventId,
    data,
    isExample = false
}) => {
    const [sortedData, setSortedData] = useState<ICompanyCompareTable[]>([]);

    const [ disclaimerOpen, setDisclaimerOpen ] = useState(false);
    const openDisclaimer = () => setDisclaimerOpen(true);
    const closeDisclaimer = () => setDisclaimerOpen(false);

    const calcAvg = (a: number | null, b: number | null) => {
        if(b === 0) return 0;
        if(a === null || b === null) return 0;
        return a/b;
    }

    const sortFiscalYear = (a: ComparableData, b: ComparableData) => {
        if (a.fiscal_year === null && b.fiscal_year === null) return 0;
        else if (a.fiscal_year === null) return 1;
        else if (b.fiscal_year === null) return -1;
        else return b.fiscal_year - a.fiscal_year;
    }
    
    const sortIncomeIsk = (a: ComparableData, b: ComparableData) => {
        if (a.income_isk === null && b.income_isk === null) return 0;
        else if (a.income_isk === null || a.income_isk === undefined) return 1;
        else if (b.income_isk === null || b.income_isk === undefined) return -1;
        else return b.income_isk - a.income_isk;
    }
    
    const combinedData = useMemo(() => {
        if (data === null || data.companyData === undefined || data.companyData.length === 0) return null
        if (data.companyData[0]) data.comparablesData[data.companyData[0].name] = [...data.companyData]
        return data.comparablesData
    }, [ data ])

    const sortData = (data: IViewCompanyCompare | null) => {
        if(data?.comparablesData === undefined ?? data === null) return <></>;
        const dataForTable: ICompanyCompareTable[] = [];
        for(let item of Object.values(data.comparablesData)){
            //sort for every entry
            item.sort(sortFiscalYear)
            //push only highest two entries for every company
            dataForTable.push({...item[0], last_year: item[1]});
        }

        dataForTable.sort(sortIncomeIsk)
        setSortedData(dataForTable)
    }

    useEffect(() => {
        sortData(data);
    }, [data])

    const reksturData = useMemo(() => {
        const reksturTable: ICompanyCompareTable[] = [...sortedData];
        if (data && data.totals) {
            const {totals} = data;
            //samtals row higher
            const higherSamtalsRow: ICompanyCompareTable = {
                name: 'Samtals',
                company_id: '',
                fiscal_year: totals.max_year,
                income_isk: totals.income_total[HIGHER_DATA_INDEX],
                income_delta: totals.income_delta_current[HIGHER_DATA_INDEX],
                income_delta_last: totals.income_delta_last[HIGHER_DATA_INDEX],
                expenses_isk: totals.expenses_total[HIGHER_DATA_INDEX],
                ebitda_isk: totals.ebitda_total[HIGHER_DATA_INDEX],
                ebit_isk: totals.ebit_total[HIGHER_DATA_INDEX],
                profit_isk: totals.profit_total[HIGHER_DATA_INDEX],
                expenses_delta_current: totals.expenses_delta_current[HIGHER_DATA_INDEX],
                expenses_delta_last: totals.expenses_delta_last[HIGHER_DATA_INDEX],
                fallbackSymbol: true,
            }
            //samtals row lower
            const lowerSamtalsRow: ICompanyCompareTable = {
                name: '',
                company_id: '',
                fiscal_year: totals.max_year - 1,
                income_isk: totals.income_total[LOWER_DATA_INDEX],
                income_delta: totals.income_delta_current[LOWER_DATA_INDEX],
                income_delta_last: totals.income_delta_last[LOWER_DATA_INDEX],
                expenses_isk: totals.expenses_total[LOWER_DATA_INDEX],
                ebitda_isk: totals.ebitda_total[LOWER_DATA_INDEX],
                ebit_isk: totals.ebit_total[LOWER_DATA_INDEX],
                profit_isk: totals.profit_total[LOWER_DATA_INDEX],
                expenses_delta_current: totals.expenses_delta_current[LOWER_DATA_INDEX],
                expenses_delta_last: totals.expenses_delta_last[LOWER_DATA_INDEX],
                fallbackSymbol: true,
            }
            //add samtals row
            reksturTable.push(higherSamtalsRow)
            reksturTable.push(lowerSamtalsRow)

            //now do the same for Meðaltal Row
            const higherMedaltalRow: ICompanyCompareTable = {
                name: 'Meðaltal',
                company_id: '',
                fiscal_year: totals.max_year,
                income_isk: calcAvg(totals.income_total[HIGHER_DATA_INDEX], totals.income_count[HIGHER_DATA_INDEX]),
                income_delta: null,
                expenses_isk:calcAvg(totals.expenses_total[HIGHER_DATA_INDEX], totals.expenses_count[HIGHER_DATA_INDEX]),
                ebitda_isk: calcAvg(totals.ebitda_total[HIGHER_DATA_INDEX], totals.ebitda_count[HIGHER_DATA_INDEX]),
                ebit_isk: calcAvg(totals.ebit_total[HIGHER_DATA_INDEX], totals.ebit_count[HIGHER_DATA_INDEX]),
                profit_isk: calcAvg(totals.profit_total[HIGHER_DATA_INDEX], totals.profit_count[HIGHER_DATA_INDEX]),
            }
            //samtals row lower
            const lowerMedaltalRow: ICompanyCompareTable = {
                name: '',
                company_id: '',
                fiscal_year: totals.max_year - 1,
                income_isk: calcAvg(totals.income_total[LOWER_DATA_INDEX], totals.income_count[LOWER_DATA_INDEX]),
                income_delta: null,
                expenses_isk: calcAvg(totals.expenses_total[LOWER_DATA_INDEX], totals.expenses_count[LOWER_DATA_INDEX]),
                ebitda_isk: calcAvg(totals.ebitda_total[LOWER_DATA_INDEX], totals.ebitda_count[LOWER_DATA_INDEX]),
                ebit_isk: calcAvg(totals.ebit_total[LOWER_DATA_INDEX], totals.ebit_count[LOWER_DATA_INDEX]),
                profit_isk: calcAvg(totals.profit_total[LOWER_DATA_INDEX], totals.profit_count[LOWER_DATA_INDEX]),
            }
            //add meðaltal row
            reksturTable.push(higherMedaltalRow)
            reksturTable.push(lowerMedaltalRow)
            return reksturTable
            
        }
        return reksturTable
    }, [sortedData])
    
    const efnahagurData = useMemo(() => {
        const dataForTable: ICompanyCompareTable[] = [...sortedData];
        if (data && data.totals) {
            const {totals} = data;

            const higherSamtalsRow: ICompanyCompareTable = {
                name: 'Samtals',
                company_id: '',
                fiscal_year: totals.max_year,
                assets_isk: totals.assets_total[HIGHER_DATA_INDEX],
                liabilities_isk: totals.liabilities_total[HIGHER_DATA_INDEX],
                equity_isk: totals.equity_total[HIGHER_DATA_INDEX],
                profit_isk: null,
                fallbackSymbol: true,
            }
            //samtals row lower
            const lowerSamtalsRow: ICompanyCompareTable = {
                name: '',
                company_id: '',
                fiscal_year: totals.max_year - 1,
                assets_isk: totals.assets_total[LOWER_DATA_INDEX],
                liabilities_isk: totals.liabilities_total[LOWER_DATA_INDEX],
                equity_isk: totals.equity_total[LOWER_DATA_INDEX],
                profit_isk: null,
                fallbackSymbol: true,
            }
            //add samtals row
            dataForTable.push(higherSamtalsRow)
            dataForTable.push(lowerSamtalsRow)

            //now do the same for Meðaltal Row
            const higherMedaltalRow: ICompanyCompareTable = {
                name: 'Meðaltal',
                company_id: '',
                fiscal_year: totals.max_year,
                assets_isk: calcAvg(totals.assets_total[HIGHER_DATA_INDEX], totals.assets_count[HIGHER_DATA_INDEX]),
                liabilities_isk: calcAvg(totals.liabilities_total[HIGHER_DATA_INDEX], totals.liabilities_count[HIGHER_DATA_INDEX]),
                equity_isk: calcAvg(totals.equity_total[HIGHER_DATA_INDEX], totals.equity_count[HIGHER_DATA_INDEX]),
                roe_ratio: calcAvg(totals.roe_ratio_profit_total[HIGHER_DATA_INDEX], totals.roe_ratio_equity_total[HIGHER_DATA_INDEX]),
            }
            //samtals row lower
            const lowerMedaltalRow: ICompanyCompareTable = {
                name: '',
                company_id: '',
                fiscal_year: totals.max_year - 1,
                assets_isk: calcAvg(totals.assets_total[LOWER_DATA_INDEX], totals.assets_count[LOWER_DATA_INDEX]),
                liabilities_isk: calcAvg(totals.liabilities_total[LOWER_DATA_INDEX], totals.liabilities_count[LOWER_DATA_INDEX]),
                equity_isk: calcAvg(totals.equity_total[LOWER_DATA_INDEX], totals.equity_count[LOWER_DATA_INDEX]),
                roe_ratio: calcAvg(totals.roe_ratio_profit_total[LOWER_DATA_INDEX], totals.roe_ratio_equity_total[LOWER_DATA_INDEX]),
            }
            //add meðaltal row
            dataForTable.push(higherMedaltalRow)
            dataForTable.push(lowerMedaltalRow)
            // setEfnahagurData(dataForTable)
            return dataForTable
        }
        return dataForTable
    }, [sortedData])

    const calcDeltaPercentage = (a: number | null, b:number | null) => {
        if (a === null || b === null) return '-';
        return `${convertToPercentage(((b - a) / a), true, 0)}`
    }
    
    const getCompanyColor = (compName: string) => {
        const index = sortedData.findIndex(x => x.name === compName);
        if (index !== -1 && index < sortedData.length) return COLORS[index]
        else return '#000'
    }
    const ebitdaGraph = useMemo(() => {
        const dataForTable: IColumnChart[] = [];
        if (combinedData === null ) return null
        if (data?.totals) {
            for (let item of Object.keys(combinedData)) {
                if (data.totals) {
                    dataForTable.push({
                        name: item,
                        color: getCompanyColor(item),
                        type: 'column',
                        data: data.totals.years.map((xItem) => {
                            const tmp = combinedData[item].filter(x => x.fiscal_year === xItem);
                            if (tmp[0] !== null  && tmp[0] !== undefined && tmp[0].ebitda_isk && tmp[0].income_isk) {
                                return {
                                    x: new Date(xItem.toString()).getTime(),
                                    y: calcAvg(tmp[0].ebitda_isk, tmp[0].income_isk) * 100
                                }
                            } else {
                                return {
                                    x: new Date(xItem.toString()).getTime(),
                                    y: undefined
                                }
                            }
                        })
                    })
                }
            }
        }
        return dataForTable.reverse()
    },[sortedData])
    
    const ROEGraph = useMemo(() => {
        const dataForTable: IColumnChart[] = [];
        if (combinedData === null) return null
        if (data?.totals) {
            for (let item of Object.keys(combinedData)) {
                if (data.totals) {
                    dataForTable.push({
                        name: item,
                        color: getCompanyColor(item),
                        type: 'column',
                        data: data.totals.years.map((xItem) => {
                            const tmp = combinedData[item].filter(x => x.fiscal_year === xItem);
                            if (tmp[0] !== null  && tmp[0] !== undefined && tmp[0].profit_isk && tmp[0].equity_isk) {
                                return {
                                    x: new Date(xItem.toString()).getTime(),
                                    y: (tmp[0].profit_isk / tmp[0].equity_isk) * 100
                                }
                            } else {
                                return {
                                    x: new Date(xItem.toString()).getTime(),
                                    y: undefined
                                }
                            }
                        })
                    })
                }
            }
        }
        return dataForTable.reverse()
    },[sortedData])

    const gainGraph = useMemo(() => {
        const dataForTable: IColumnChart[] = [];
        if (combinedData === null) return null
        if (data?.totals) {
            for (let item of Object.keys(combinedData)) {
                if (data.totals) {
                    dataForTable.push({
                        name: item,
                        color: getCompanyColor(item),
                        type: 'column',
                        data: data.totals.years.map((xItem) => {
                            const tmp = combinedData[item].filter(x => x.fiscal_year === xItem);
                            if(tmp[0] !== null  && tmp[0] !== undefined && tmp[0].profit_isk && tmp[0].income_isk){
                                return {
                                    x: new Date(xItem.toString()).getTime(),
                                    y: (tmp[0].profit_isk / tmp[0].income_isk) * 100
                                }
                            } else {
                                return {
                                    x: new Date(xItem.toString()).getTime(),
                                    y: undefined
                                }
                            }
                        })
                    })
                }
            }
        }
        return dataForTable.reverse()
    },[sortedData])

    const getYear = (type: 'new' | 'old') => {
        const index = (type === 'new') ? 3 : 2
        if (data === null || data.totals === undefined || data.totals === null) return null
        else return data.totals.years[index]
    }

    const handlePieChartData = (year: 'new' | 'old', type: 'income' | 'assets') => {
        const dataToReturn: { name: string; y: number | undefined; color: string; }[] = [];
        // year is new and type is income
        const newYear = getYear(year)

        switch (type) {
            case 'income':
                switch (year) {
                    case 'new':
                        sortedData
                            .filter(i => i.fiscal_year === newYear)
                            .forEach(item => {
                                dataToReturn.push({
                                    name: item.name,
                                    y: item.income_isk ?? undefined,
                                    color: getCompanyColor(item.name)
                                })
                            })
                        break;
                    case 'old':
                        sortedData
                            .filter(i => i.fiscal_year === newYear || i.last_year?.fiscal_year === newYear)
                            .forEach(item => {
                                dataToReturn.push({
                                    name: item.name,
                                    y: (item.fiscal_year === newYear) ? (item.income_isk ?? undefined) : (item.last_year?.income_isk ?? undefined),
                                    color: getCompanyColor(item.name)
                                })
                            })
                        break;
                }
                break;
            case 'assets':
                switch (year) {
                    case 'new':
                        sortedData
                            .filter(i => i.fiscal_year === newYear)
                            .forEach(item => {
                                dataToReturn.push({
                                    name: item.name,
                                    y: item.assets_isk ?? undefined,
                                    color: getCompanyColor(item.name)
                                })
                            })
                        break;
                    case 'old':
                        sortedData
                            .filter(i => i.fiscal_year === newYear || i.last_year?.fiscal_year === newYear)
                            .forEach(item => {
                                dataToReturn.push({
                                    name: item.name,
                                    y: (item.fiscal_year === newYear) ? item.assets_isk ?? undefined : item.last_year?.assets_isk ?? undefined,
                                    color: getCompanyColor(item.name)
                                })
                            })
                        break;
                }
                break;
        }
        return dataToReturn
    }

    const renderCompanyNameCell = (name: string, company_id: string) => {
        if (name === 'Samtals') {
            return <Tooltip text='Samtals' tooltip={<>Tölur fyrir þau fyrirtæki sem eru með þetta ár lagðar saman.</>} />
        }
        if (name === 'Meðaltal') {
            return <Tooltip text='Meðaltal' tooltip={<>Vegið meðaltal fyrir þau fyrirtæki sem eru með upplýsingar</>} />
        }
        return <Link linkSize='15' url={`/Fyrirtaeki/Yfirlit/${company_id}`} disabled={isExample}>{name}</Link>
    }
    
    const displayReksturData = () => {
        if (sortedData.length === 0) return <Loading />
        if (reksturData === null) return <Alert type='alert' headText={ErrorMessages.OtherShort} />
        return <Table
            tableSize='lg'
            data={reksturData}
            rowClassName={({ company_id, name }) => cx({
                'is-special-row': company_id === id,
                'extra-border-bottom': name === 'Samtals' || name === 'Meðaltal',
                'thickerText': company_id === id || name === 'Samtals' || name === 'Meðaltal' || name === ''
            })}
            columns={[{
                title: 'Fyrirtæki',
                renderCell: ({name, company_id}) => renderCompanyNameCell(name, company_id),
                textAlign: 'left',
            }, {
                title: <Tooltip
                    text='Ár'
                    tooltip={<>Nýjustu upplýsingar fyrir hvert fyrirtæki.</>}
                />,
                renderCell: ({fiscal_year}) => fiscal_year
            }, {
                title: 'Tekjur',
                renderCell: ({income_isk}) => (income_isk) ? displayQuantityShorthand(income_isk, 1) : '-'
            }, {
                title: <Tooltip
                    text='Δ'
                    tooltip={<>Breyting frá fyrra ári í %</>}
                />,
                renderCell: ({income_isk, last_year, income_delta, income_delta_last}) => {
                    if (income_delta && income_delta_last) {
                        return calcDeltaPercentage(income_delta_last, income_delta)
                    }
                    if (last_year?.income_isk && income_isk) {
                        return calcDeltaPercentage(last_year.income_isk, income_isk)
                    }
                    return '-'
                }
            }, {
                title: 'Gjöld',
                renderCell: ({expenses_isk}) => (expenses_isk) ? displayQuantityShorthand(expenses_isk, 1) : '-'
            }, {
                title: <Tooltip
                    text='Δ'
                    tooltip={<>Breyting frá fyrra ári í %</>}
                />,
                renderCell: ({ expenses_isk, last_year, expenses_delta_current, expenses_delta_last}) => {
                    if (expenses_delta_current && expenses_delta_last) {
                        return calcDeltaPercentage(expenses_delta_last, expenses_delta_current).replace('--', '')
                    }
                    if (last_year?.expenses_isk && expenses_isk) {
                        return calcDeltaPercentage(last_year.expenses_isk, expenses_isk).replace('--', '')
                    }
                    return '-'
                }
            }, {
                title: 'EBITDA',
                renderCell: ({ebitda_isk}) => (ebitda_isk) ? displayQuantityShorthand(ebitda_isk, 1) : '-'
            }, {
                title: 'EBIT',
                renderCell: ({ebit_isk}) => (ebit_isk) ? displayQuantityShorthand(ebit_isk, 1) : '-'
            }, {
                title: 'Afkoma',
                renderCell: ({profit_isk}) => (profit_isk) ? displayQuantityShorthand(profit_isk, 1) : '-'
            }, {
                title: 'EBITDA/TEKJUR',
                renderCell: ({ebitda_isk, income_isk, fallbackSymbol}) => !fallbackSymbol && income_isk && ebitda_isk ? `${Math.round((100 * ebitda_isk) / income_isk)}%` : '-'
            }]}
        />
    }

    const displayEfnahagurData = () => {
        if (sortedData.length === 0) return <Loading />
        if (efnahagurData === null) return <Alert type='alert' headText={ErrorMessages.OtherShort} />
        return <Table
            tableSize='lg'
            data={efnahagurData}
            rowClassName={({ company_id, name }) => cx({
                'is-special-row': company_id === id,
                'extra-border-bottom': name === 'Samtals' || name === 'Meðaltal',
                'thickerText': company_id === id || name === 'Samtals' || name === 'Meðaltal' || name === ''
            })}
            columns={[{
                title: 'Fyrirtæki',
                renderCell: ({name, company_id}) => renderCompanyNameCell(name, company_id),
                textAlign: 'left',
            }, {
                title: <Tooltip
                    text='Ár'
                    tooltip={<>Nýjustu upplýsingar fyrir hvert fyrirtæki.</>}
                />,
                renderCell: ({fiscal_year}) => fiscal_year
            }, {
                title: 'Eignir',
                renderCell: ({assets_isk}) => assets_isk ? displayQuantityShorthand(assets_isk, 1) : '-'
            }, {
                title: 'Skuldir',
                renderCell: ({liabilities_isk}) => liabilities_isk ? displayQuantityShorthand(liabilities_isk, 1) : '-'
            }, {
                title: 'Eigið fé',
                renderCell: ({equity_isk}) => equity_isk ? displayQuantityShorthand(equity_isk, 1) : '-'
            }, {
                title: 'Eiginfjár­hlutfall',
                renderCell: ({fallbackSymbol, assets_isk, equity_isk}) => !fallbackSymbol && assets_isk && equity_isk ? `${Math.round((100 * equity_isk) / assets_isk)}%` : '-'
            }, {
                title: 'ROE',
                renderCell: ({fallbackSymbol, profit_isk, equity_isk,roe_ratio}) => {
                    if (roe_ratio) {
                        return convertToPercentage(roe_ratio, true, 0)
                    }
                    if (!fallbackSymbol && profit_isk && equity_isk) {
                        return `${Math.round((100 * profit_isk) / equity_isk)}%`
                    }
                    return '-'
                }
            }]}
        />
    }

    const displayEbitdaGraph = () => {
        if (ebitdaGraph === null) return <ChartLoading />
        if (ebitdaGraph.length === 0) return <Alert type='alert' headText='Engin gögn fundust' />
        return <ColumnChart data={ebitdaGraph} grayBackground={isExample} />
    }

    const displayROEGraph = () => {
        if (ROEGraph === null) return <ChartLoading />
        if (ROEGraph.length === 0) return <Alert type='alert' headText='Engin gögn fundust' />
        return <ColumnChart data={ROEGraph} grayBackground={isExample} />
    }

    const displayGainGraph = () => {
        if (gainGraph === null) return <ChartLoading />
        if (gainGraph.length === 0) return <Alert type='alert' headText='Engin gögn fundust' />
        return <ColumnChart data={gainGraph} grayBackground={isExample} />
    }

    return (
        <DisplayBox 
            className='KCL_view-company-compare'
            title="Rekstur"
            asideComponent={
                <>
                    <Link
                        url={`/Excel/Fyrirtaeki-Samanburdur?ssn=${id}&eventId=${eventId}`}
                        disabled={isExample}
                        icon='excel'
                    >
                        Gögn í Excel
                    </Link>
                    <Fyrirvari
                        show={disclaimerOpen}
                        open={openDisclaimer}
                        close={closeDisclaimer}
                    >
                        <p className='paragraph'>
                            Keldan vinnur gögn úr opinberum upplýsingum eftir bestu getu. 
                            Keldan ber ekki ábyrgð á röngum upplýsingum sem geta komið til vegna innsláttarvillna eða annarra þátta.
                            Ábendingar um villur eða rangar upplýsingar eru vel þegnar með tölvupósti á <Link linkSize='18' url='mailto:help@keldan.is'>help@keldan.is.</Link>
                        </p>
                    </Fyrirvari>
                </>
            }
            grayBackground={isExample}
        >
            {displayReksturData()}

            {/* GRAPHS */}
            <div className='graph pieChart-container'>
                <div className=' pieChart-wrapper'>
                    <h4>Tekjuskipting {getYear('new')}</h4>
                    <PieChart type='income' data={handlePieChartData('new', 'income')} grayBackground={isExample} />
                </div>
                <div className=' pieChart-wrapper'>
                    <h4>Tekjuskipting {getYear('old')}</h4>
                    <PieChart type='income' data={handlePieChartData('old', 'income')} grayBackground={isExample} />
                </div>
            </div>
            <div className="graph">
                <h4>Efnahagur</h4>
                {displayEfnahagurData()}
            </div>
            {/* GRAPHS */}
            <div className='graph pieChart-container'>
                <div className=' pieChart-wrapper'>
                    <h4>Eignaskipting {getYear('new')}</h4>
                    <PieChart type='assets' data={handlePieChartData('new', 'assets')} grayBackground={isExample} />
                </div>
                <div className=' pieChart-wrapper'>
                    <h4>Eignaskipting {getYear('old')}</h4>
                    <PieChart type='assets' data={handlePieChartData('old', 'assets')} grayBackground={isExample} />
                </div>
            </div>
            <div className="graph">
                <h4>Tekjur - Afkoma - Ávöxtun eigin fjár</h4>
                <BubbleChart
                    data={sortedData.map((item, index) =>  {
                        if (item.equity_isk === undefined || item.equity_isk === null || item.profit_isk === null || item.profit_isk === undefined) {
                            return({
                                name: item.name,
                                color: getCompanyColor(item.name),
                                type: 'bubble',
                                data: [{
                                    x: 1,
                                    y: 1,
                                    z: 1, 
                                }]
                            })
                        } else {
                            return({
                                name: item.name,
                                color: getCompanyColor(item.name),
                                type: 'bubble',
                                data: [{
                                    x: item.profit_isk ?? 1,
                                    y: item.income_isk ?? 1,
                                    z: Math.round((item.profit_isk / item.equity_isk) * 100),
                                }]
                            })
                        }
                    })}
                    grayBackground={isExample}
                />
            </div>
            <div className="graph">
                <h4>EBITDA/Tekjur</h4>
                {displayEbitdaGraph()}
            </div>
            <div className="graph">
                <h4>Ávöxtun eigin fjár</h4>
                {displayROEGraph()}
            </div>
            <div className="graph">
                <h4>Hagnaðarhlutfall</h4>
                {displayGainGraph()}
            </div>
        </DisplayBox>
    );
}

export default ViewCompanyCompareBody;