// Package imports:
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
// Component imports:
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Link from '../../../ui-elements/Link/Link';
import Table from '../../../ui-elements/Table/Table';
import ErrorAlert from '../../ErrorAlert/ErrorAlert';
import Loading from '../../../ui-elements/Loading/Loading';
import HeadSection from '../../../ui-elements/HeadSection/HeadSection';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { insertDash } from '../../../services/utils';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched } from '../../../types/Types';
import { IKeldanApiResponse } from '../../../types/KeldanTypes';
import { KeldanProperty } from '../../../types/PropertyTypes';

interface IPropertyReport {
    ssn: string,
    eventDate: string,
    properties: KeldanProperty[],
    showHistory?: boolean
}

const ViewPropertiesReport: React.FC<{ id: string }>= ({
    id
}) => {
    const [ propertyReport, setPropertyReport] = useState<Fetched<IPropertyReport>>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${GET_KELDAN_API_URL()}/Individual/GetPropertiesReport/${id}`;
                const response = await fetch(url);
                if (response.ok) {
                    const responseBody: IKeldanApiResponse<IPropertyReport> = await response.json();
                    if (responseBody.result) {
                        setPropertyReport(responseBody.result);
                    } else if (responseBody.message) {
                        setPropertyReport(new Error(responseBody.message));
                    } else {
                        setPropertyReport(new Error(ErrorMessages.OtherShort));
                    }
                } else {
                    setPropertyReport(new Error(ErrorMessages.RequestFailed));
                }
            } catch (e) {
                setPropertyReport(new Error(ErrorMessages.NetworkError));
            }
        }
        fetchData();
    }, []);

    const displayProperties = (type: 'owned' | 'no longer owned') => {
        if (propertyReport === null) return <Loading />;
        if (propertyReport instanceof Error) return <ErrorAlert error={propertyReport} />;
        const filteredProperties = (type === 'owned')
            ? propertyReport.properties.filter(property => property.allowance !== null)
            : propertyReport.properties.filter(property => property.history !== null);
        
        return filteredProperties.map(({
            name,
            description,
            allowance,
            history,
            registry
        }, index) => (
            <div
                className={cx('property-card', { 'first': (index === 0) })}
                key={index}
            >
                <div className='title'>
                    {name}
                </div>
                <div className='information'>
                    <div className='description'>
                        Lýsing:
                        <span>{description}</span>
                    </div>
                    <div className='registry-id'>
                        Fastanúmer:
                        <span>{registry.id}</span>
                    </div>
                </div>
                <Table
                    data={
                        [...((type === 'owned' ? allowance : history) ?? [])]
                        .sort((a, b) => {
                            const aParts = a.properDate.split('.');
                            const bParts = b.properDate.split('.');
                            const aDate = new Date(parseInt(aParts[2]), parseInt(aParts[1])-1, parseInt(aParts[0]));
                            const bDate = new Date(parseInt(bParts[2]), parseInt(bParts[1])-1, parseInt(bParts[0]));
                            if (aDate < bDate) return -1;
                            if (aDate > bDate) return 1;
                            return 0;
                    })
                    }
                    tableSize='lg'
                    columns={[{
                        title: 'Tegund skjals',
                        textAlign: 'left',
                        renderCell: ({ typeName, type }) => typeName ?? type
                    },{
                        title: 'Staða skjals',
                        textAlign: 'left',
                        renderCell: ({ statusName, status }) => statusName ?? status
                    },{
                        title: 'Númer skjals',
                        renderCell: ({ document }) => document
                    },{
                        title: 'Útgáfudagur',
                        renderCell: ({ properDate }) => properDate
                    }]}
                />
            </div>
        ));
    }

    const displayBody = () => {
        if (propertyReport === null) return <Loading />;
        if (propertyReport instanceof Error) return <ErrorAlert error={propertyReport} />;
        return <>
            <div className='info-col-wrapper'>
                <div className='info-col'>
                    <div className='info-item'>
                        <span className='title'>Kennitala</span>
                        <span className='value'>{insertDash(propertyReport.ssn)}</span>
                    </div>
                </div>
            </div>
            <DisplayBox
                title='Fasteignir í eigu'
                className='KCL_ViewIndividualAssetsReport'
                asideComponent={
                    <div className='report-aside-component'>
                        <div className='link-wrapper'>
                            <Link
                                targetBlank
                                linkSize='15'
                                url={`/Pdf/Properties?ssn=${propertyReport.ssn}&eventId=${id}`}
                                icon='pdf'
                            >
                                Sækja skýrslu
                            </Link>
                        </div>
                        <div className='italic-text'>
                            Skýrsla keypt: {propertyReport.eventDate}
                        </div>
                    </div>
                }
            >
                <div className='main'>
                    {displayProperties('owned')}
                    {propertyReport !== null && !(propertyReport instanceof Error) && propertyReport.showHistory &&
                        <>
                            <h4 className="middle-h4">Fasteignir ekki lengur í eigu</h4>
                            {displayProperties('no longer owned')}
                        </>
                    }
                </div>
            </DisplayBox>
        </>
    }

    return (
        <div className='main KCL_individual-profile assets-report'>
            <div className='shell'>
                <HeadSection
                    title="Fasteignir - skýrsla"
                    showRecentReports
                />
                {displayBody()}
            </div>
        </div>
    );
}

export default ViewPropertiesReport;