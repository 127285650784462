// Package imports:
import React, { useState } from "react";
// Component imports:
import Modal from "../../ui-elements/Modal/Modal";
import Link from "../../ui-elements/Link/Link";
import Tabs from "../../ui-elements/Tabs/Tabs";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import Alert from "../../ui-elements/Alert/Alert";
import { LinkedIndividual, PepCompanyResult, Source } from "../../types/ForeignCompanyPepTypes";
import { formatCategories } from "./PepPage";

interface IProps {
  show: boolean;
  close(): void;
  data: PepCompanyResult | undefined;
}

const customStyle = {
  ...vscDarkPlus,
  property: {
    fontWeight: "bold",
  },
};
const getHostWithoutWWWAndTLD = (url: string | undefined, removeTLD: boolean = true): string => {
  try {
    if (!url) return '';
    const { hostname } = new URL(url);
    let host = hostname.replace(/^www\./, ''); // Remove 'www.' if it exists
    if(removeTLD) {
      host = host.split('.').slice(0, -1).join('.'); // Remove the last part (TLD)
    }
    return host;
  } catch (error) {
    console.error('Invalid URL:', error);
    return '';
  }
};
const ForeignCompanyPepModal: React.FC<IProps> = ({ show, close, data }) => {
  const acceptedKeys: (keyof PepCompanyResult)[] = [
    "category",
    "categories",
    "hitRating",
    "keyWordHitRating",
    "matchedFields",
    "lookupType",
    "subcategory",
    "companyName",
    "position",
    "generalInfo",
    "sources",
    "primaryLocation",
    "linkedIndividuals",
  ];

  const renderValue = (value: any, key?: keyof PepCompanyResult) => {

    //special formatin forr text
    if (key === "categories") return formatCategories(value);
    //boolean
    if (key === "deceased") return value.toString();
    if (key === "generalInfo") return <Link url={value.website} linkSize="14" targetBlank>{getHostWithoutWWWAndTLD(value.website, false)}</Link>;
    if (key === "sources") {
      return <div style={{
          display: "flex",
          flexDirection: "column",
          gap: '2px'
        }}>{
          value.slice(0,5).map((source: Source, index: number) => {
            return (
            <Link key={index} url={source.url} linkSize="14" targetBlank>
              {getHostWithoutWWWAndTLD(source.url)}
            </Link>
        )})
      }
      </div> 
    }
    return value === "" || value === null ? "-" : value;
  };
  const renderTitle = (key?: keyof PepCompanyResult) => {
    if(key === "generalInfo") return "Website";
    return key;
  };
console.log(data);
  const modalBody = () => {
    if (!data) return <Alert type={"alert"} headText={"Engin gögn fundust"} />;
    return (
      <Tabs
        tabs={[
          {
            id: "tab1",
            label: "Niðurstöður",
            component: (
              <div className="KCL_foreignPepModal">
                {Object.keys(data).map((key, index) => {
                  if (acceptedKeys.includes(key as keyof PepCompanyResult)) {
                  if (key === "linkedIndividuals") {
                    return <ExpandableRow
                        title={key}
                        items={data[key]}
                      />
                  }
                    return (
                      <div className="item_container" key={index}>
                        <span className="item_key">
                          {renderTitle(key as keyof PepCompanyResult)}
                        </span>
                        <span className="item_value">
                          {renderValue(
                            data[key as keyof PepCompanyResult],
                            key as keyof PepCompanyResult
                          )}
                        </span>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            ),
          },
          {
            id: "tab2",
            label: "JSON",
            component: (
              <div style={{ overflow: "auto" }}>
                <SyntaxHighlighter language="json" style={customStyle}>
                  {JSON.stringify(data, null, 2)}
                </SyntaxHighlighter>
              </div>
            ),
          },
        ]}
      />
    );
  };
  return <Modal show={show} onHide={close} hasCloseButton body={modalBody()} />;
};

export default ForeignCompanyPepModal;


interface ExpandableRowProps {
  items: LinkedIndividual[];
  title: string;
}

const ExpandableRow: React.FC<ExpandableRowProps> = ({ items, title }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isExpandable = items.length > 2;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
      <div className={` item_container individualRow ${isExpanded ? 'expanded' : 'collapsed'} ${!isExpandable && 'notExpandable'}`} onClick={toggleExpand}>
        <span className="item_key">
          {title}
        </span>
        <div className="item_value">
            {items.slice(0, !isExpanded ? 2 : undefined).map((individual, index) => ( 
              <div key={index}>
                <div style={{fontWeight: 500}}>
                  {individual.firstName && <span>{individual.firstName}&nbsp;</span>}
                  {individual.middleName && <span>{individual.middleName}&nbsp;</span>}
                  {individual.lastName && <span>{individual.lastName}</span>}
                </div>
                <div>
                  {individual.description && <span>{individual.description}</span>}
                </div>
              </div> ))}
        </div>
      </div>
  );
};
