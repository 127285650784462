// Package imports:
import React, { useEffect, useRef, useState } from "react";
import Highcharts, { PlotSeriesOptions, SeriesOptionsType, TooltipFormatterCallbackFunction, YAxisOptions } from 'highcharts/highstock';
import cx from 'classnames';
// Component imports:
import ChartLoading from "../../../ui-elements/ChartLoading/ChartLoading";
// Service imports:
import { SHORT_MONTHS } from "../../../services/config";

interface IOwnProps {
    title?: string,
    height?: number,
    tooltipFormatter: TooltipFormatterCallbackFunction,
    yAxes: YAxisOptions[],
    series: SeriesOptionsType[],
    startRange?: number,
    seriesOptions?: PlotSeriesOptions
}

type Props = IOwnProps & React.HTMLAttributes<HTMLDivElement>;

const DefaultChart: React.FC<Props> = ({
    title,
    height = 500,
    tooltipFormatter,
    yAxes,
    series,
    startRange = 4,
    seriesOptions,
    className,
    ...props
}) => {
    const [selectedRange, setSelectedRange] = useState(startRange);

    const chartRef = useRef<HTMLDivElement>(null);

    const defaultYAxis: YAxisOptions = {
        minTickInterval: 1,
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1,
        gridLineColor: '#D3D3D6',
        opposite: false
    }

    const defaultSeriesOptions: PlotSeriesOptions = {
        color: '#4569EE',
        enableMouseTracking: true,
        marker: {
            enabled: false
        },
        animation: false,
        turboThreshold: 0,
        dataGrouping: {
            enabled: false
        }
    }

    const completeYAxes = yAxes.map((yAxis) => ({ ...defaultYAxis, ...yAxis }))
    const completeSeriesOptions = seriesOptions ? { ...defaultSeriesOptions, ...seriesOptions } : defaultSeriesOptions

    useEffect(() => {
        if (!chartRef.current) return;
        Highcharts.setOptions({
            lang: {
                shortMonths: SHORT_MONTHS,
                resetZoom: 'Til baka',
                resetZoomTitle: ''
            }
        });

        Highcharts.stockChart(chartRef.current, {
            chart: {
                height: height,
                marginTop: 80,
                reflow: true,
                style: {
                    fontFamily: "Roboto"
                },
                zoomType: "xy",
                selectionMarkerFill: '#4569EE18',
                resetZoomButton: {
                    position: {
                        x: 0,
                        y: -75
                    },
                    theme: {
                        r: 6,
                        height: 28
                    }
                },
                plotBackgroundColor: {
                    linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                    stops: [
                        [0.05, '#FFF'],
                        [0.95, '#F8F8FA']
                    ]
                }
            },
            exporting: {
                buttons: {
                    contextButton: {
                        x: 30,
                        y: 36
                    }
                }
            },
            credits: {
                enabled: false
            },
            title: {
                text: '',
                align: 'center',
                x: 60,
                margin: -14
            },
            navigator: {
                enabled: true,
                adaptToUpdatedData: false,
                handles: {
                    height: 22,
                    width: 6
                },
                maskFill: '#D4DEFF80',
                series: {
                    lineWidth: 0,
                    opacity: 0
                },
                height: 20,
                xAxis: {
                    labels: {
                        style: {
                            color: '#7B7C83',
                            fontSize: '10px'
                        },
                        y: -6
                    },
                    gridLineWidth: 0,
                    minTickInterval: 1000*60*60*24*365,
                    lineWidth: 0
                },
                yAxis: {
                    lineWidth: 0
                },
                outlineWidth: 0
            },
            scrollbar: {
                enabled: false
            },
            tooltip: {
                enabled: true,
                formatter: tooltipFormatter,
                borderWidth: 0,
                shadow: false,
                useHTML: true,
                shared: true
            },
            xAxis: [{
                type: 'datetime',
                title: {
                    text: null
                },
                minTickInterval: 1000*60*60*24*30,
                tickWidth: 0
            }],
            yAxis: completeYAxes,
            plotOptions: {
                series: completeSeriesOptions
            },
            legend: {
                enabled: true,
                align: 'left',
                verticalAlign: 'top',
                itemStyle: {
                    color: '#232530',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '26px'
                },
                x: 5,
                y: 10
            },
            rangeSelector: {
                allButtonsEnabled: true,
                buttonPosition: {
                    align: 'right',
                    x: 0,
                    y: -80
                },
                buttons: [{
                    type: 'month',
                    count: 1,
                    text: '1 mán.',
                    title: '1 mánuður',
                    events: {
                        click: () => setSelectedRange(0)
                    }
                }, {
                    type: 'month',
                    count: 3,
                    text: '3 mán.',
                    title: '3 mánuðir',
                    events: {
                        click: () => setSelectedRange(1)
                    }
                }, {
                    type: 'month',
                    count: 6,
                    text: '6 mán.',
                    title: '6 mánuðir',
                    events: {
                        click: () => setSelectedRange(2)
                    }
                }, {
                    type: 'ytd',
                    text: 'Þetta ár',
                    title: 'Þetta ár',
                    events: {
                        click: () => setSelectedRange(3)
                    }
                }, {
                    type: 'year',
                    count: 1,
                    text: '1 ár',
                    title: '1 ár',
                    events: {
                        click: () => setSelectedRange(4)
                    }
                }, {
                    type: 'year',
                    count: 3,
                    text: '3 ár',
                    title: '3 ár',
                    events: {
                        click: () => setSelectedRange(5)
                    }
                }, {
                    type: 'year',
                    count: 5,
                    text: '5 ár',
                    title: '5 ár',
                    events: {
                        click: () => setSelectedRange(6)
                    }
                }],
                buttonSpacing: 5,
                buttonTheme: {
                    r: 6,
                    height: 26,
                    width: 50
                },
                dropdown: 'never',
                inputEnabled: false,
                selected: selectedRange,
                labelStyle: {
                    display: 'none'
                }
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 369
                    },
                    chartOptions: {
                        rangeSelector: {
                            dropdown: 'always'
                        }
                    }
                }, {
                    condition: {
                       callback: function() {
                           return window.matchMedia('(max-width: 767px)').matches;
                       }
                    },
                    chartOptions: {
                        chart: {
                            spacingLeft: 0,
                            spacingRight: 5,
                            marginTop: 60
                        },
                        legend: {
                            x: 10,
                            y: -20,
                            itemStyle: {
                                fontSize: '12px'
                            }
                        },
                        navigator: {
                            margin: 13
                        },
                        rangeSelector: {
                            buttonPosition: {
                                y: -60
                            },
                            buttonTheme: {
                                width: 36,
                                height: 24
                            }
                        },
                        xAxis: {
                            labels: {
                                style: {
                                    fontSize: '9px'
                                }
                            }
                        },
                        yAxis: yAxes.map(() => ({
                            labels: {
                                style: {
                                    fontSize: '9px'
                                },
                                x: -1
                            },
                            title: {
                                offset: 27,
                                x: 0,
                                style: {
                                    fontSize: '10px'
                                }
                            }
                        }))
                    }
                }]
            },
            series: series
        }, () => {});
    }, [chartRef.current, series]);

    return (
        <div
            className={cx(className)}
            {...props}
        >
            <div ref={chartRef} className="chart-container">
                <ChartLoading />
            </div>
        </div>
    )
}

export default DefaultChart;