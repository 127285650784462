// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import LoginComp from '../../components/Login/Login';

interface IOwnProps {
    username?: string | null;
    password?: string | null;
    redirectUrl?: string | null;
    error?: string | null;
    unconfirmed?: boolean;
}

const Login: React.FC<IOwnProps> = ({
    username = null,
    password = null,
    redirectUrl = null,
    error = null,
    unconfirmed = false
}) => {
    const props = {
        username,
        password,
        redirectUrl,
        error,
        unconfirmed
    }

    return (
        <Entrypoint>
            <LoginComp {...props} />
        </Entrypoint>
    );
}

export default Login;