// Package imports:
import React, { useEffect, useMemo, useRef, useState } from "react";
// Component imports:
import AdRotator from "../../Ad/AdRotator";
import Alert from "../../../ui-elements/Alert/Alert";
import Button from "../../../ui-elements/Button/Button";
import DisplayBox from "../../../ui-elements/DisplayBox/DisplayBox";
import Loading from "../../../ui-elements/Loading/Loading";
import SmallSearch from "../../SmallSearch/SmallSearch";
import Table from "../../../ui-elements/Table/Table";
// Context imports:
import { companies } from "./DefaultCompaniesForSearch";
// Service imports:
import { ErrorMessages } from "../../../services/errorMessages";
import { GET_KELDAN_API_URL } from "../../../services/config";
import { insertDash } from "../../../services/utils";
// Type imports:
import { Fetched } from "../../../types/Types";
import {
    ICompanySearchResults,
    ICompanySearchResultsItem,
} from "../../../types/SearchTypes";

function pickRandomCompanies(
    companies: ICompanySearchResultsItem[],
    count: number
): ICompanySearchResultsItem[] {
    const shuffled = companies.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
}
const CompanySearch: React.FC = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const term = urlParams.get("search");
    // Autocomplete request variables:
    const [searchInput, setSearchInput] = useState(term);
    // const randomCompanies = pickRandomCompanies(companies, 10);
    const [randomCompanies] = useState<ICompanySearchResultsItem[]>(
        pickRandomCompanies(companies, 10)
    );
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] =
    useState<Fetched<ICompanySearchResultsItem[]>>(null);
    useEffect(() => {
        // Clear the previous timer if it exists
        let timerId: NodeJS.Timeout | null = null;

        const companySearch = async () => {
            if (searchInput === null || searchInput.length < 3) {
                setSearchResults(null);
                return;
            }

            try {
                setLoading(true);
                setSearchResults(null);

                const url = `${GET_KELDAN_API_URL()}/Company/SearchRskConnector?searchTerm=${encodeURIComponent(
                    searchInput.trim()
                )}`;
                const response = await fetch(url);

                if (response.ok) {
                    const body: ICompanySearchResults = await response.json();
                    const companies: ICompanySearchResultsItem[] = body.results ?? [];
                    setSearchResults(companies.length === 0 ? [] : companies);
                } else {
                    setSearchResults(new Error(ErrorMessages.RequestFailed));
                }
            } catch (error) {
                setSearchResults(new Error(ErrorMessages.NetworkError));
            } finally {
                setLoading(false);
            }
        };

        // Set a new timer to call the API after 1/4 second
        timerId = setTimeout(companySearch, 250);

        // Clear the timer when the component unmounts or when searchInput changes
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [searchInput]);

    const searchResultRef = useRef<HTMLDivElement>(null);

    interface Column {
        title: string;
        renderCell: (company: ICompanySearchResultsItem) => string | JSX.Element;
        textAlign: "left" | "right";
    }

    const columns: Column[] = [{
        title: "Nafn",
        renderCell: ({ name }: ICompanySearchResultsItem) => name ?? "",
        textAlign: "left",
    }, {
        title: "Kennitala",
        renderCell: ({ ssn }: ICompanySearchResultsItem) => insertDash(ssn),
        textAlign: "left",
    }, {
        title: "",
        renderCell: ({ ssn }: ICompanySearchResultsItem) => (
            <a className="anchorWrapForButton" href={`/Fyrirtaeki/Yfirlit/${ssn}`}>
                <span>
                    <Button buttonType="secondary" size="sm">
                        Skoða
                    </Button>
                </span>
            </a>
        ),
        textAlign: "right",
    }];

    const displayResult = useMemo(() => {
        if (loading || (!searchResults && searchInput?.trim())) {
            return <Loading />;
        }

        let data: ICompanySearchResultsItem[] | undefined;

        if (searchInput === null || searchInput === "" || searchInput.length < 3) {
            data = randomCompanies;
        } else if (searchResults instanceof Error) {
            return <Alert type={"alert"} headText={searchResults.message} />;
        } else if (searchResults === null || searchResults.length === 0) {
            return <h4>Engar leitarniðurstöður fundust fyrir "{searchInput}"</h4>;
        } else {
            data = searchResults;
        }
        const sortedData = data.sort((a, b) => {
            const nameA = a.name || '';
            const nameB = b.name || '';
            return nameA.localeCompare(nameB);
        });
        return (
            <Table
                tableSize="lg"
                data={sortedData}
                columns={columns}
            />
        );
    }, [searchResults, loading]); // Only recompute the memoized value when searchResults or loading changes.

    return (
        <div className="KCL_company-search-tab">
            <div className="shell">
                {searchInput === null ||
                    searchInput === "" ||
                    searchInput.length < 3 ? (
                        <>
                            <h3>Fyrirtækjaleit</h3>
                            <p className="paragraph">
                                Hér er hægt að fletta upp fjárhagsgögnum nær allra íslenskra
                                fyrirtækja eftir nafni eða kennitölu.
                            </p>
                        </>
                    ) : (
                        <h3>Leitarniðurstöður fyrir "{searchInput}"</h3>
                    )
                }
            </div>
            <div className="content_wrapper">
                <div className="shell">
                    <div className="result_wrapper">
                        <DisplayBox>
                            <SmallSearch
                                search={searchInput ?? ""}
                                setSearch={(e) => {
                                    setSearchInput(e);
                                    window.history.pushState(
                                        null,
                                        "",
                                        "/Fyrirtaeki/Leit?search=" + e
                                    );
                                }}
                                inputSize="lg"
                                placeHolder="Leitaðu eftir fyrirtækjum..."
                                id="Search_page"
                                onSubmit={() => { searchResultRef.current?.scrollIntoView() }}
                            />
                            <div className="table-section" ref={searchResultRef}>{displayResult}</div>
                        </DisplayBox>
                    </div>
                    <AdRotator location="Market310x400" />
                </div>
            </div>
        </div>
    );
};

export default CompanySearch;
