// Package imports:
import React from "react";
// Component imports:
import ViewMembershipBody from "./ViewMembershipBody";
// Type imports:
import { IndividualMembership } from "../../../types/IndividualTypes";
import { IEventReport } from "../../../types/KeldanTypes";

 const EXAMPLE_DATA: IEventReport<IndividualMembership> = {
    "userName": "martha@kodi.is",
    "productName": "Aðild",
    "eventDate": "03.04.2023 14:39:30",
    "reply": "",
    "overview": {
        "ssn": null,
        "includeAddress": true,
        "name": null,
        "address": null,
        "zip": null,
        "city": null,
        "member": {
            "address": "Kelduvogi 20",
            "ssn": "0910791239",
            "name": "Kelda Kóðadóttir",
            "city": "Reykjavík",
            "zip": "104",
            "list": [{
                "unlisted": false,
                "date_unlisted": 0,
                "state_unlisted": "",
                "memberSsn": "0910791239",
                "companySsn": "4512673489",
                "position": 1,
                "description_pos": "stjórnarformaður",
                "type": 1,
                "description_type": "Stjórn",
                "name": "Dæmi ehf."
            }, {
                "unlisted": false,
                "date_unlisted": 0,
                "state_unlisted": "",
                "memberSsn": "0910791239",
                "companySsn": "5612782349",
                "position": 3,
                "description_pos": "meðstjórnandi",
                "type": 1,
                "description_type": "Stjórn",
                "name": "Dæmi um fyrirtæki ehf."
            }, {
                "unlisted": false,
                "date_unlisted": 0,
                "state_unlisted": "",
                "memberSsn": "0910791239",
                "companySsn": "5101050510",
                "position": -1,
                "description_pos": "",
                "type": 4,
                "description_type": "Framkvæmdastjórn",
                "name": "Keldan á Íslandi ehf."
            }, {
                "unlisted": false,
                "date_unlisted": 0,
                "state_unlisted": "",
                "memberSsn": "0910791239",
                "companySsn": "5101050510",
                "position": -1,
                "description_pos": "",
                "type": 5,
                "description_type": "Prókúruhafi",
                "name": "Keldan á Íslandi ehf."
            }, {
                "unlisted": false,
                "date_unlisted": 0,
                "state_unlisted": "",
                "memberSsn": "0910791239",
                "companySsn": "5101050510",
                "position": -1,
                "description_pos": "",
                "type": 6,
                "description_type": "Stofnandi",
                "name": "Keldan á Íslandi ehf."
            }, {
                "unlisted": true,
                "date_unlisted": 991008000000,
                "state_unlisted": "Félagsslit",
                "memberSsn": "0910791239",
                "companySsn": "4310987659",
                "position": -1,
                "description_pos": "",
                "type": 6,
                "description_type": "Stofnandi",
                "name": "Sýni þér dæmi ehf (samruni)"
            }, {
                "unlisted": true,
                "date_unlisted": 991008000000,
                "state_unlisted": "Félagsslit",
                "memberSsn": "0910791239",
                "companySsn": "4310987659",
                "position": 3,
                "description_pos": "meðstjórnandi",
                "type": 1,
                "description_type": "Stjórn",
                "name": "Sýni þér dæmi ehf (samruni)"
            }, {
                "unlisted": false,
                "date_unlisted": 0,
                "state_unlisted": "",
                "memberSsn": "0910791239",
                "companySsn": "7003973459",
                "position": -1,
                "description_pos": "",
                "type": 4,
                "description_type": "Framkvæmdastjórn",
                "name": "Kodiak bílaleiga ehf."
            }, {
                "unlisted": false,
                "date_unlisted": 0,
                "state_unlisted": "",
                "memberSsn": "0910791239",
                "companySsn": "7003973459",
                "position": -1,
                "description_pos": "",
                "type": 5,
                "description_type": "Prókúruhafi",
                "name": "Kodiak bílaleiga ehf."
            }, {
                "unlisted": false,
                "date_unlisted": 0,
                "state_unlisted": "",
                "memberSsn": "0910791239",
                "companySsn": "7003973459",
                "position": 2,
                "description_pos": "stjórnarmaður",
                "type": 1,
                "description_type": "Stjórn",
                "name": "Kodiak bílaleiga ehf."
            }]
        }
    },
    "result": 0,
    "descr": null,
    "exception": null,
    "msg": null,
    "frontmsg": null,
    "errorCode": 0,
    "errorMessage": null
}

const MembershipReportExample: React.FC = () => {
    return <ViewMembershipBody report={EXAMPLE_DATA} isExample />
}

export default MembershipReportExample;