// Package imports:
import React, { useMemo } from 'react';
// Component imports:
import Flag, { FlagNames } from '../../../ui-elements/Flag/Flag';
import ColoredNumber from '../../../ui-elements/ColoredNumber/ColoredNumber';
import Alert from '../../../ui-elements/Alert/Alert';
import Loading from '../../../ui-elements/Loading/Loading';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import IntradayChart from '../IntradayChart/IntradayChart';
// Service imports:
import { formatNumber, convertToPercentage, getAPItoday } from '../../../services/utils';
import { currencyFractionDigits } from '../../../services/config';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../../types/Types';
import {  IApiLmdCurrency } from '../../../types/CurrencyTypes';

export type CurrenciesChartType = 'KIB' | 'USD' | 'EUR' | 'GBP' | 'CAD' | 'JPY' | 'CHF' | 'DKK' | 'SEK' | 'NOK' | 'PLN' | 'XDR';

export interface ICurrencyInputState {
    value: number | null,
    currency: string | null,
    averagerate: number | null
}

interface IOwnProps {
    currencySymbol: CurrenciesChartType
}
type Props = IDefaultProps & IOwnProps & React.HTMLAttributes<HTMLDivElement>;

const CurrencyIntradayChart: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    currencySymbol,
    ...props
}) => {
    const TODAY = getAPItoday();
    const [{data, error}] = useApiLmdData<IApiLmdCurrency[]>(
        `/v1/market_data/v1/currency_cross/base_currency/ISK/cross_currency/${currencySymbol}/intraday_timeseries?from_date=${TODAY}&to_date=${TODAY}`,
        accessToken,
        refreshRateMs
    );

    const { AverageRate, ChangePer } = useMemo(() => {
        const filtered = data?.filter(
            datum => datum.Datetimerate !== null
                && Date.parse(datum.Datetimerate.replace(/ /g, "T")) <= Date.now()
        );
        return {
            AverageRate: filtered ? filtered[filtered.length-1]?.Averagerate : null,
            ChangePer: filtered ? filtered[filtered.length-1]?.ChangePer : null
        };
    }, [data]);

    if (error instanceof Error) {
        return (
            <DisplayBox
                className='KCL_currency-intraday-chart'
                {...props}
            > 
                <div className='head__upper'>
                    <Flag name={currencySymbol as FlagNames} />
                    <h4 className='currency-symbol'>{currencySymbol}</h4>
                    <Alert type='error' headText={error.message}/>
                </div>
            </DisplayBox>
        )
    } else if (data === null) {
        return (
            <DisplayBox
                className='KCL_currency-intraday-chart'
                {...props}
            > 
                <div className='head__upper'>
                    <Flag name={currencySymbol as FlagNames} />
                    <h4 className='currency-symbol'>{currencySymbol}</h4>
                    <Loading />
                </div>
            </DisplayBox>
        );
    } else if (data.length === 0) {
        return (
            <DisplayBox
                className='KCL_currency-intraday-chart'
                {...props}
            > 
                <div className='head__upper'>
                    <Flag name={currencySymbol as FlagNames} />
                    <Alert type={'alert'} headText={'Ekki fundust gögn fyrir daginn í dag.'} />
                </div>
            </DisplayBox>
        );
    }

    return (
        <DisplayBox 
            className='KCL_currency-intraday-chart'
            {...props}
        >
            <div className='intraday-chart__head'>
                <div className='head__upper'>
                    <Flag name={currencySymbol as FlagNames} />
                    <h4>
                        <span className='currency-symbol'>{currencySymbol}</span>
                        <span className='currency-averageRate'>
                            {formatNumber(AverageRate, '-', currencyFractionDigits(currencySymbol))}
                        </span>
                    </h4>
                    <span className='currency-changePer'>
                        <ColoredNumber
                            hasArrow
                            sign={(ChangePer === null)
                                ? 'neutral'
                                : (ChangePer < 0)
                                ? 'neg'
                                : (ChangePer > 0)
                                ? 'pos'
                                : 'neutral'
                            }
                        >
                            {convertToPercentage(ChangePer, true, 2)}
                        </ColoredNumber>
                    </span>
                </div>
                <div className='head__down'>
                    <span className='currency-description'>
                        {data.length > 0
                            ? data[0].Description?.replace(' / Almenntgengi Íslandsbanka', '')
                            : ''
                        }
                    </span>
                </div>
            </div>
            <IntradayChart
                data={data}
            />
        </DisplayBox>
    );
}

export default CurrencyIntradayChart;