// Package imports:
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
// Service imports:
import { gtmPush } from '../../services/utils';

interface ITab {
    id: string,
    label: string,
    component: JSX.Element,
    hasTwinkle?: boolean,
}

type TabsSize = 'sm' | 'lg';

interface IProps {
    tabs: ITab[],
    tabsSize?: TabsSize,
    saveTabToUrl?: boolean,
    redirectToTab?: number
    clearRedirect?: () => void,
    grayAndShell?: boolean,
    shellHead?: boolean,
    onTabChange?: (i: number) => void
}

const Tabs: React.FC<IProps> = ({
    tabs,
    tabsSize = 'sm',
    saveTabToUrl,
    redirectToTab,
    clearRedirect,
    grayAndShell = false,
    shellHead = false,
    onTabChange
}) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [indexesOfOpenedTabs, setIndexesOfOpenedTabs] = useState<number[]>([]);

    useEffect(() => {
        if (saveTabToUrl) {
            const hashId = window.location.hash.slice(1);
            const hashTabIndex = tabs.findIndex(tab => tab.id === hashId);
            if (hashTabIndex === -1) {
                const activeTab = tabs[activeTabIndex];
                if (activeTab) window.history.replaceState(true, '', `#${activeTab.id}`);
            } else {
                setActiveTabIndex(hashTabIndex)
            }
        }
    }, []);

    useEffect (() => {
        if (redirectToTab) {
            setActiveTabIndex(redirectToTab)
            if (clearRedirect) clearRedirect();
        }
    }, [redirectToTab])

    useEffect(() => {
        if (!indexesOfOpenedTabs.includes(activeTabIndex)) {
            const indexesCopy = [...indexesOfOpenedTabs];
            indexesCopy.push(activeTabIndex)
            setIndexesOfOpenedTabs(indexesCopy);
        }
        if (onTabChange) {
            onTabChange(activeTabIndex);
        }
        
        if (saveTabToUrl) {
            const activeTab = tabs[activeTabIndex];
            if (activeTab) window.history.replaceState(true, '', `#${activeTab.id}`);
        }
    }, [activeTabIndex]);
    
	function locationHashChanged() {
		window.scrollTo(0, 0);
        const hashId = window.location.hash.slice(1);
        const hashTabIndex = tabs.findIndex(tab => tab.id === hashId);
        if (hashTabIndex !== -1) {
            setActiveTabIndex(hashTabIndex)
        }
	}
    if (saveTabToUrl) {
        window.onhashchange = locationHashChanged;
    }

    return (
        <div className={cx("KCL_tabs", tabsSize)}>
            <div className={cx("tabs__head", { 'shell': grayAndShell || shellHead })}>
                <nav className="tabs-nav">
                    <ul>
                        {tabs.map((tab, index) => (
                            <li
                                key={tab.id}
                                className={cx({'is-active': activeTabIndex === index})}
                            >
                                <button
                                    id={tab.id}
                                    onClick={() => {
                                        setActiveTabIndex(index);
                                        gtmPush('TabClick', tab.id, 'tabChanged');
                                    }}
                                >
                                    <div className='label-and-twinkle'>
                                        {tab.label}
                                        {tab.hasTwinkle && (
                                            <div className='tab-twinkle' />
                                        )}
                                        <div className='tab-underline' />
                                    </div>
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            <div className={cx("tabs__body", { 'section--gray': grayAndShell })}>
                {tabs.map((tab, index) =>
                    !indexesOfOpenedTabs.includes(index)
                        ? null
                        : <div
                            key={tab.id}
                            className={cx('tab', { 'is-active': activeTabIndex === index, 'shell': grayAndShell })}
                        >
                            {tab.component}
                        </div>
                )}
            </div>
        </div>
    )
}
export default Tabs;