// Package imports:
import React from 'react';
// Component imports:
import Button from '../../ui-elements/Button/Button';
import Link from '../../ui-elements/Link/Link';
import TextImageBlock from '../TextImageBlock/TextImageBlock';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';

const About: React.FC = () => {
    return (
        <div className='main KCL_about-page'>
            <div className="main__inner-fluid grid-items">
                <div className="shell">
                    <HeadSection
                        title="Um Kelduna"
                        summary={
                            <p>Keldan ehf. er þjónustufyrirtæki í fararbroddi í upplýsingamiðlun, sem veitir aðgang að öllum helstu skrám sem reknar eru af opinberum aðilum á Íslandi.</p>
                        }
                        actions={
                            <Button
                                buttonType="primary"
                                size="lg"
                                anchorProps={{
                                    href: '/Askrift'
                                }}
                            >
                                Komdu í áskrift
                            </Button>
                        }
                        textAlign='center'
                    />

                    <div className="section__body">
                        <TextImageBlock
                            imgSrc="https://cdn.livemarketdata.com/KeldanImages/kodikeldan.jpg"
                            imgSide="right"
                        >
                            <h2 id="About_KeldanAndKodi">Keldan og Kóði</h2>
                            <div className="text-image-block__entry">
                                <p className='paragraph'>Keldan var stofnuð haustið 2009 en Kóði ehf. keypti rekstur hennar árið 2013.</p>
                                <p className='paragraph'>Kóði stendur að baki KODIAK viðskiptakerfanna, Hluthafaskrá.is, IPO.is og Live Market Data ásamt því að reka Kelduna.</p>
                                <p className='paragraph'>Vöruframboð Kóða er sífellt að aukast en áherslan er alltaf á að gera fjármálakerfið skilvirkara.</p>
                            </div>
                            <Link
                                url="https://kodi.is"
                                targetBlank
                                linkSize='16'
                                icon='forward'
                            >
                                Fara á vef Kóða ehf.
                            </Link>
                        </TextImageBlock>

                        <div className="text-image-block-list">
                            <TextImageBlock
                                imgSide="right"
                            >
                                <h2 id="About_KeldanProducts">Vörur frá Keldunni</h2>
                                <div className="text-image-block__entry">
                                    <p className='paragraph'>
                                        Keldan á og rekur Keldan App Premium þar sem notandi getur keypt áskrift og fengið rauntímagögn, og Vaktarinn.is, sem er greiningartól sem býður upp á vöktun 
                                        og greiningu á þeim leitarorðum sem notandi kýs að vakta.
                                    </p>
                                </div>
                                <div className="text-image-block__buttons">
                                    <Button
                                        buttonType="secondary"
                                        size="lg"
                                        anchorProps={{
                                            href: '/Keldan-Markadir'
                                        }}
                                    >
                                        Keldan Markaðir
                                    </Button>
                                    <Button
                                        buttonType="secondary"
                                        size="lg"
                                        anchorProps={{
                                            href: '/Vaktarinn'
                                        }}
                                    >
                                        Vaktarinn.is
                                    </Button>
                                </div>
                            </TextImageBlock>

                            <TextImageBlock>
                                <h2 id="About_ExcellentCompanies">Fyrirmyndarfyrirtæki í rekstri</h2>
                                <div className="text-image-block__entry">
                                    <p className='paragraph'>Keldan, í samstarfi við Viðskiptablaðið, tekur saman lista á hverju rekstrarári yfir þau fyrirtæki sem teljast til fyrirmyndar í rekstri.</p>
                                    <p className='paragraph'>Listinn hefur verið tekinn saman síðan árið 2017 og hafa mörg hundruð fyrirtækja hlotið viðurkenninguna.</p>
                                </div>
                                <div className="text-image-block__buttons">
                                    <Button
                                        buttonType="secondary"
                                        size="lg"
                                        anchorProps={{
                                            href: '/Listar/Fyrirmyndarfyrirtaeki'
                                        }}
                                    >
                                        Nánar um Fyrirmyndarfyrirtæki
                                    </Button>
                                </div>
                            </TextImageBlock>
                        </div>
                    </div>
                </div>
                <div className="section section--gray section--logos">
                    <div className="shell">
                        <div className="section__inner">
                            <div className="section__head">
                                <h2 id="About_KeldanLogos">Merki Keldunnar</h2>
                                <p className='summary-paragraph'>
                                    Hér fæst merki Keldunnar í vektor grafík fyrir prent og PNG fyrir skjámiðla. Sjá einnig um notkun merkisins í hönnunarstaðli Keldunnar.
                                </p>
                                <Link
                                    url="https://cdn.livemarketdata.com/KeldanLogos/Um_notkun_merkis_Keldunnar.pdf"
                                    targetBlank
                                    linkSize='15'
                                    icon='pdf'
                                >
                                    Hönnunarstaðall Keldunnar
                                </Link>
                            </div>

                            <div className="section__body">
                                <DisplayBox
                                    title='Fyrir prent'
                                >
                                    <ul className="list-files">
                                        <li>
                                            <Link
                                                url="https://cdn.livemarketdata.com/KeldanLogos/KeldanLogo_Colored_DarkBg.pdf"
                                                targetBlank
                                                linkSize='15'
                                                icon='pdf'
                                            >
                                                Merki Keldunnar í lit fyrir dökkan bakgrunn
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                url="https://cdn.livemarketdata.com/KeldanLogos/KeldanLogo_Colored_LightBg.pdf"
                                                targetBlank
                                                linkSize='15'
                                                icon='pdf'
                                            >
                                                Merki Keldunnar í lit fyrir ljósan bakgrunn
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                url="https://cdn.livemarketdata.com/KeldanLogos/KeldanLogo_Black.pdf"
                                                targetBlank
                                                linkSize='15'
                                                icon='pdf'
                                            >
                                                Merki Keldunnar í svörtu
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                url="https://cdn.livemarketdata.com/KeldanLogos/KeldanLogo_White.pdf"
                                                targetBlank
                                                linkSize='15'
                                                icon='pdf'
                                            >
                                                Merki Keldunnar í hvítu
                                            </Link>
                                        </li>
                                    </ul>
                                </DisplayBox>

                                <DisplayBox
                                    title='Fyrir skjámiðla'
                                >
                                    <ul className="list-files">
                                        <li>
                                            <Link
                                                url="https://cdn.livemarketdata.com/KeldanLogos/KeldanLogo_Colored_DarkBg.png"
                                                targetBlank
                                                linkSize='15'
                                                icon='image'
                                            >
                                                Merki Keldunnar í lit fyrir dökkan bakgrunn
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                url="https://cdn.livemarketdata.com/KeldanLogos/KeldanLogo_Colored_LightBg.png"
                                                targetBlank
                                                linkSize='15'
                                                icon='image'
                                            >
                                                Merki Keldunnar í lit fyrir ljósan bakgrunn
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                url="https://cdn.livemarketdata.com/KeldanLogos/KeldanLogo_Black.png"
                                                targetBlank
                                                linkSize='15'
                                                icon='image'
                                            >
                                                Merki Keldunnar í svörtu
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                url="https://cdn.livemarketdata.com/KeldanLogos/KeldanLogo_White.png"
                                                targetBlank
                                                linkSize='15'
                                                icon='image'
                                            >
                                                Merki Keldunnar í hvítu
                                            </Link>
                                        </li>
                                    </ul>
                                </DisplayBox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;