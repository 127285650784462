// Package imports:
import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import LmdTable from '../../../ui-elements/Table/LmdTable';
import Link from '../../../ui-elements/Link/Link';
import CalculatedColoredNumber from '../../../ui-elements/CalculatedColoredNumber/CalculatedColoredNumber';
import Avatar, { AvatarNames } from '../../../ui-elements/Avatar/Avatar';
import MiniGraph from '../../../ui-elements/MiniGraph/MiniGraph';
// import Tooltip from '../../../ui-elements/Tooltip/Tooltip';
// Service imports:
import { convertToPercentage, displayQuantityShorthand, formatNumber } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IApiLmdSnapshot } from '../../../types/HlutabrefTypes';
import { IDefaultProps } from '../../../types/Types';

type IProps = IDefaultProps;

const FirstNorth: React.FC<IProps>= ({
    refreshRateMs,
    accessToken,
}) => {
    const [ firstNorth, prevFirstNorth ] = useApiLmdData<IApiLmdSnapshot[]>(
        '/v1/market_data/v1/keldan/exchange/FNIS/market/*/symbol/*/delayed_equities',
        accessToken,
        refreshRateMs
    );

    return (
        <div className='KCL_FirstNorth'>
            <LmdTable 
                apiData={firstNorth}
                columns={[{
                    title: 'Auðkenni',
                    renderCell: ({symbol, notifications}) => (
                        <div className="avatar-and-text">
                            <a href={`/Markadir/Hlutabref/${symbol}`} aria-label={`Hlutabréf - ${symbol}`}>
                                {(symbol === null)
                                    ? null
                                    : (
                                        <Avatar
                                            size='sm'
                                            backgroundGrey
                                            name={symbol.toUpperCase() as AvatarNames}
                                        />
                                    )
                                }
                            </a>
                            <Link url={`/Markadir/Hlutabref/${symbol}`} linkSize='13'>
                                {symbol}
                            </Link>
                            {/* {notifications !== undefined && notifications !== null &&
                                <Tooltip
                                    text={<FontAwesomeIcon icon={faExclamationCircle} />}
                                    tooltip={<>{notifications}</>}
                                    noUnderline
                                />
                            } */}
                        </div>
                    ),
                    textAlign: 'left',
                    simpleSortable: ({symbol}) => symbol
                }, {
                    title: 'Verð',
                    renderCell: ({last_price, symbol}) => {
                        return (
                            <td style={{ padding: 0 }}>
                                <CalculatedColoredNumber 
                                    hasBackground
                                    currentValue={last_price}
                                    previousValue={prevFirstNorth.data?.find(pd => pd.symbol === symbol)?.last_price ?? null}
                                >
                                    {formatNumber(last_price)}
                                </CalculatedColoredNumber>
                            </td>
                        )
                    },
                    overrideTd: true,
                    simpleSortable: ({last_price}) => last_price
                }, {
                    title: 'Breyting',
                    renderCell: ({intraday_per_change, symbol}) => {
                        return (
                            <td style={{ padding: 0 }}>
                                <CalculatedColoredNumber
                                    hasColor
                                    hasBackground
                                    currentValue={intraday_per_change}
                                    previousValue={prevFirstNorth.data?.find(pd => pd.symbol === symbol)?.intraday_per_change ?? null}
                                >
                                    {convertToPercentage(intraday_per_change, true, 2)}
                                </CalculatedColoredNumber>
                            </td>
                        )
                    },
                    overrideTd: true,
                    simpleSortable: ({intraday_per_change}) => intraday_per_change
                }, {
                    title: 'Sl. 3 Mán',
                    renderCell: ({symbol}) => (symbol) && <MiniGraph symbol={symbol} />
                }, {
                    title: 'Kaup',
                    renderCell: ({bid, symbol}) => {
                        return (
                            <td style={{ padding: 0 }}>
                                <CalculatedColoredNumber
                                    hasBackground
                                    currentValue={bid}
                                    previousValue={prevFirstNorth.data?.find(pd => pd.symbol === symbol)?.bid ?? null}
                                >
                                    {formatNumber(bid)}
                                </CalculatedColoredNumber>
                            </td>
                        )
                    },
                    overrideTd: true,
                    simpleSortable: ({bid}) => bid
                }, {
                    title: 'Sala',
                    renderCell: ({ask, symbol}) => {
                        return (
                            <td style={{ padding: 0}}>
                                <CalculatedColoredNumber
                                    hasBackground
                                    currentValue={ask}
                                    previousValue={prevFirstNorth.data?.find(pd => pd.symbol === symbol)?.ask ?? null}
                                >
                                    {formatNumber(ask)}
                                </CalculatedColoredNumber>
                            </td>
                        )
                    },
                    overrideTd: true,
                    simpleSortable: ({ask}) => ask
                }, {
                    title: 'Velta',
                    renderCell: ({intraday_accumulated_turnover}) => displayQuantityShorthand(intraday_accumulated_turnover),
                    simpleSortable: ({intraday_accumulated_turnover}) => intraday_accumulated_turnover
                }]}
            />
        </div>
    );
}

export default FirstNorth;