// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import SignUpPage from '../../components/SignUp/SignUpPages/SignUpPage';

interface IProps {
    plan?: 'free' | 'premium1' | 'premium2'
}

const SignUp: React.FC<IProps> = ({
    plan
}) => {
    return (
        <Entrypoint>
            <SignUpPage subscriptionChoice={plan} />
        </Entrypoint>
    );
}

export default SignUp;