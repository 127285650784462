// Package imports:
import React, { useMemo } from 'react';
// Component imports:
import Alert from '../../ui-elements/Alert/Alert';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Table from '../../ui-elements/Table/Table';
import ColoredNumber from '../../ui-elements/ColoredNumber/ColoredNumber';
import Loading from '../../ui-elements/Loading/Loading';
// Service imports:
import { formatNumber } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
//Types imports:
import { IApiKodixMbo } from '../../types/MarketTypes';
import { IDefaultProps } from '../../types/Types';

interface ISimplifiedData {
    askTotalAmount: number | null,
    askVolume: number | null,
    askPrice: number | null,
    bidPrice: number | null,
    bidVolume: number | null,
    bidTotalAmount: number | null,
}

type CrossCurrencySymbols = 'EURISK' | 'USDISK';

interface IProps {
    crossCurrency: CrossCurrencySymbols,
}

type Props = IProps & IDefaultProps & React.HTMLAttributes<HTMLDivElement>

const MarketDepth: React.FC<Props> = ({
    crossCurrency,
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [data] = useApiLmdData<IApiKodixMbo[]>(
        `/v1/market_data/v1/kodix/market_by_order/symbol/${crossCurrency}:KELD/`,
        accessToken,
        refreshRateMs
    );

    const bidSort = (a: IApiKodixMbo, b: IApiKodixMbo) => {
        if (a.price === null && b.price === null) return 0;
        if (a.price === null) return 1;
        if (b.price === null) return -1;
        return b.price - a.price;
    }
    const askSort = (a: IApiKodixMbo, b: IApiKodixMbo) => {
        if (a.price === null && b.price === null) return 0;
        if (a.price === null) return -1;
        if (b.price === null) return 1;
        return a.price - b.price;
    }

    const marketDepthData = useMemo(() => {
        //IKNOW THIS IS DISGUSTING
        let arrayToReturn: ISimplifiedData[] = [];
        if (data.data === null) return null;
        if (data.error instanceof Error) return data.error;

        //If deleted_timestamp exist -> than this offer is no longer available and should not be displayed.
        const filteredMarketDepth = data.data.filter(x => x.deleted_timestamp === null);
        if (filteredMarketDepth.length === 0) return []; // No offers to display
        //split arrays into askArray and bidArray because they are sorted differently
        const askArray = filteredMarketDepth.filter(x => x.side === 'ask');
        const bidArray = filteredMarketDepth.filter(x => x.side === 'bid');
        //Sorting
        bidArray.sort(bidSort)
        askArray.sort(askSort)
        //construction of the array that will be displayed. 

        // the table is of the format  |Ask total amount |Ask quantity |Ask price |Bid price |Bid quantity |Bid total amount.
        // ...The format of the data has to be constructed this way... 
        for ( let i = 0; i <= Math.max(askArray.length, bidArray.length) - 1 ; i++ ) {
            let askTA = null, askQ = null, askP = null, bidTA = null, bidQ = null, bidP = null;

            if (askArray.length > i) {
                askQ = askArray[i].quantity;
                askP = askArray[i].price;
                askTA = askQ * askP;
            }
            if (bidArray.length > i) {
                bidQ = bidArray[i].quantity;
                bidP = bidArray[i].price;
                bidTA = bidQ * bidP;
            }
            arrayToReturn.push({
                askTotalAmount: askTA ,
                askVolume: askQ ,
                askPrice: askP ,
                bidPrice: bidP ,
                bidVolume: bidQ,
                bidTotalAmount: bidTA,
            })
        }
        return arrayToReturn;
    }, [data]);

    const displayBody = () => {
        if (marketDepthData instanceof Error) {
            return <Alert type='error' headText={marketDepthData.message} />
        }
        if (marketDepthData === null) {
            return <Loading />
        }
        if (marketDepthData.length === 0) {
            return <span>Engar Pantanir</span>
        }
        return <Table 
            data={marketDepthData} 
            columns={[{
                title: 'ISK',
                renderCell: ({bidTotalAmount}) => formatNumber(bidTotalAmount),
            }, {
                title: 'Mynt',
                renderCell: ({bidVolume}) => formatNumber(bidVolume),
            }, {
                title: 'Kaup',
                renderCell: ({bidPrice}) => (
                    (bidPrice) 
                    ? ( <td style={{padding: 0}}>
                            <ColoredNumber
                                constantBackground='pos'
                                sign='pos'
                            >
                                {formatNumber(bidPrice, '', 2)}
                            </ColoredNumber>
                        </td>
                        )
                        : <td></td> 
                ),
                overrideTd: true,
            }, {
                title: 'Sala',
                renderCell: ({askPrice}) =>  (
                    (askPrice) 
                    ? ( <td style={{padding: 0}}>
                            <ColoredNumber
                            constantBackground='neg'
                            sign='neg'
                        >
                            {formatNumber(askPrice, '', 2)}
                            </ColoredNumber>
                        </td>
                        )
                        : <td></td> 
                ),
                overrideTd: true,
            }, {
                title: 'Mynt',
                renderCell: ({askVolume}) => formatNumber(askVolume,''),
            }, {
                title: 'ISK',
                renderCell: ({askTotalAmount}) => formatNumber(askTotalAmount,''),
            },]}
            tableSize='lg'
        />
    }

    return (
        <div className="KCL_marketDepth">
            <DisplayBox
                title={(crossCurrency === 'EURISK')
                        ? 'Tilboðsbók EUR/ISK'
                        : 'Tilboðsbók USD/ISK'}
                {...props}
            >
                    {displayBody()}
            </DisplayBox>
        </div>
    )
}

export default MarketDepth;
