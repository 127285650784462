// Package imports:
import React from 'react'
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
// Component imports:
import Input from '../../ui-elements/Input/Input';

interface Props {
    placeHolder?: string,
    search: string,
    setSearch: (search: string) => void
    inputSize?: 'sm' | 'lg',
    disabled?: boolean,
    id?: string,
    onSubmit?: (search: string) => void
}

const SmallSearch: React.FC<Props> = ({
    placeHolder,
    search,
    setSearch,
    inputSize = 'lg',
    disabled = false,
    id,
    onSubmit
}) => {
    return (
        <div className={cx('KCL_small-search', inputSize)} id={id}>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    if (onSubmit)
                        onSubmit(search);
                }}
                autoComplete='off'
            >
                <Input
                    inputType='input'
                    name="search"
                    id="search"
                    value={search}
                    placeholder={placeHolder ? placeHolder : 'Leitaðu í fréttum'}
                    className="search-field"
                    onChange={e => setSearch(e.target.value)}
                    inputSize={inputSize}
                    disabled={disabled}
                />
                <button
                    type="submit"
                    className="search__submit"
                    title="Leita"
                >
                    <FontAwesomeIcon
                        className='search-icon'
                        icon={faSearch}
                    />
                </button>
                <button
                    type="button"
                    className="search__clear"
                    title="Hreinsa leit"
                    onClick={() => setSearch('')}
                >
                    <FontAwesomeIcon
                        className='clear-icon'
                        icon={faTimesCircle}
                    />
                </button>
            </form>
        </div>
    )
}

export default SmallSearch;
