// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import ProfilePage from '../../components/Individual/Thjodskra/Profile';
// Type imports:
import { IdIndividualViewModel } from '../../types/IndividualTypes';

const IndividualProfile: React.FC = ({...props}) => {
    return (
        <Entrypoint>
            <ProfilePage model={props as IdIndividualViewModel} />
        </Entrypoint>
    );
}

export default IndividualProfile;