// Package imports:
import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import FilterItem, { IStyleProps, IValueProps } from './FilterItem/FilterItem';
// Service imports:
import { isElementSubChild } from '../../services/utils';

interface IProps {
    itemStyle: IStyleProps,
    itemValues: IValueProps[],
    columnStyle: 'grid' | 'flow',
    toggleAll?: (() => void) | null,
    notAllSelectedBubble?: boolean,
    heading?: string
};

const Filter: React.FC<IProps> = ({
    itemStyle,
    itemValues,
    columnStyle,
    toggleAll = null,
    notAllSelectedBubble,
    heading = 'Veitur'
}) => {
    const filterRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen(!isOpen);

    useEffect(() => {
        const outOfFilterClickListener = (e: MouseEvent) => {
            const { target } = e;
            const filterElement = filterRef.current;
            if (target instanceof Element && filterElement) {
                // If user clicks on element, which is not in filter, then close filter.
                if (!isElementSubChild(target, filterElement)) {
                    setIsOpen(false)
                }
            } else {
                setIsOpen(false)
            }
        }
        document.addEventListener('click', outOfFilterClickListener);
        return () => document.removeEventListener('click', outOfFilterClickListener);
    })
    
    const allSelected = () => {
        return itemValues.every(obj => obj.hasOwnProperty('selected') && obj.selected === true);
    }

    return (
        <div className="KCL_filter" ref={filterRef}>
            <button
                className="settingsButton"
                onClick={toggleOpen}
                title="Sía"
            >
                {notAllSelectedBubble && !allSelected() &&
                    <FontAwesomeIcon className='circle' icon={faCircle} />
                }
                <FontAwesomeIcon icon={faCog} />
            </button>
            <div className={cx('filter-list', {'is-open': isOpen})}>
                <button
                    className="closeButton"
                    onClick={toggleOpen}
                    title="Loka"
                >
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <div className="list-wrapper">
                    <h4 style={{margin: 0}}>{heading}</h4>
                    <ul className={`list-${columnStyle}`}>
                        {toggleAll !== null
                        ? <li>
                            <FilterItem
                                size={itemStyle.size}
                                showCheck={itemStyle.showCheck}
                                text="Velja allt"
                                selected={itemValues.every((val) => val.selected)}
                                toggleSelected={toggleAll}
                            />
                        </li>
                        : null}
                        {itemValues.map((value, index) => (
                            <li key={index}>
                                <FilterItem
                                    size={itemStyle.size}
                                    showCheck={itemStyle.showCheck}
                                    text={value.text}
                                    selected={value.selected}
                                    disabled={value.disabled}
                                    locked={value.locked}
                                    toggleSelected={value.toggleSelected}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}


export default Filter;