// Package imports:
import React from "react";
// Component imports:
import DisplayBox from "../../ui-elements/DisplayBox/DisplayBox";
import LmdTable from "../../ui-elements/Table/LmdTable";
// Service imports:
import {
    convertToPercentage,
    formatNumber,
} from "../../services/utils";
import { useApiLmdData } from "../../services/apiHooks";
import { CUSTOM_REFRESH_RATES } from "../../services/config";
// Type imports:
import { IDefaultProps } from "../../types/Types";
import { IApiLmdIndexStatisticListShort } from "../../types/MarketTypes";
import {
    firstTableSymbols,
    nameChanger,
    secondTableSymbols,
} from "../MultipleIndices/MultipleIndices";
import ColoredNumber from "../../ui-elements/ColoredNumber/ColoredNumber";

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const Visitolur: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [indexStatistics] = useApiLmdData<IApiLmdIndexStatisticListShort[]>(
        `v1/market_data/v1/keldan/indexes/index_statistics_list`,
        accessToken,
        CUSTOM_REFRESH_RATES["VISITOLUR"]
      );

    const handleData = () => {
        const { data, error } = indexStatistics;
        if (data === null) return indexStatistics;
        return {
            data: {
                "Norrænar hlutabréfavísitölur": data.filter((x) =>
                    firstTableSymbols.includes(x.Symbol)
                ),
                "Íslenskar hlutabréfavísitölur": data.filter((x) =>
                    secondTableSymbols.includes(x.Symbol)
                ),
            },
            error
        };
    };
    return (
        <DisplayBox
            className="KCL_visitolur"
            title="Helstu vísitölur"
            anchorProps={{ href: "/Markadir/Visitolur" }}
            displayBoxSize="sm"
            {...props}
        >
            <LmdTable
                id={props.id}
                apiData={handleData()}
                columns={[{
                    title: "Vísitala",
                    renderCell: ({ Symbol }) => (
                        <div className="avatar-and-text">{nameChanger(Symbol, "13")}</div>
                    ),
                    textAlign: "left"
                }, {
                    title: "Gildi",
                    renderCell: ({ LastValue }) => formatNumber(LastValue, "-", 2)
                }, {
                    title: "Dagsbr.",
                    renderCell: ({ YesterdaysValueChangePer }) => (
                        <ColoredNumber
                            sign={
                                YesterdaysValueChangePer === null
                                    ? "neutral"
                                    : YesterdaysValueChangePer < 0
                                    ? "neg"
                                    : YesterdaysValueChangePer > 0
                                    ? "pos"
                                    : "neutral"
                            }
                        >
                            {convertToPercentage(YesterdaysValueChangePer, true, 2)}
                        </ColoredNumber>
                    )
                }, {
                    title: "1 Mán.",
                    renderCell: ({ Weeks4ValueChangePer }) => (
                        <ColoredNumber
                            sign={
                                Weeks4ValueChangePer === null
                                    ? "neutral"
                                    : Weeks4ValueChangePer < 0
                                    ? "neg"
                                    : Weeks4ValueChangePer > 0
                                    ? "pos"
                                    : "neutral"
                            }
                        >
                            {convertToPercentage(Weeks4ValueChangePer, true, 2)}
                        </ColoredNumber>
                    )
                }, {
                    title: "YTD",
                    renderCell: ({ YearValueChangePer }) => (
                        <ColoredNumber
                            sign={
                                YearValueChangePer === null
                                    ? "neutral"
                                    : YearValueChangePer < 0
                                    ? "neg"
                                    : YearValueChangePer > 0
                                    ? "pos"
                                    : "neutral"
                            }
                        >
                            {convertToPercentage(YearValueChangePer, true, 2)}
                        </ColoredNumber>
                    )
                }, {
                    title: "1 Ár",
                    renderCell: ({ Months12ValueChangePer }) => (
                        <ColoredNumber
                            sign={
                                Months12ValueChangePer === null
                                    ? "neutral"
                                    : Months12ValueChangePer < 0
                                    ? "neg"
                                    : Months12ValueChangePer > 0
                                    ? "pos"
                                    : "neutral"
                            }
                        >
                            {convertToPercentage(Months12ValueChangePer, true, 2)}
                        </ColoredNumber>
                    )
                }]}
                expandableRowConfig={{
                    defaultExpandedIndexes: [0, 1],
                    showNumberOfItemsInExpandable: true,
                }}
            />
        </DisplayBox>
    );
};

export default Visitolur;
