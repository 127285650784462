// Package imports:
import React, { useState } from 'react';
import { NumberFormatValues } from 'react-number-format';
// Component imports:
import LmdTable from '../../../ui-elements/Table/LmdTable';
import Flag, {FlagNames} from '../../../ui-elements/Flag/Flag';
import ColoredNumber from '../../../ui-elements/ColoredNumber/ColoredNumber';
import Input from '../../../ui-elements/Input/Input';
import Link from '../../../ui-elements/Link/Link';
import MiniGraph from '../../../ui-elements/MiniGraph/MiniGraph';
import Label from '../../../ui-elements/Label/Label';
// Service imports:
import { formatNumber, convertToPercentage, getAPItoday } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../../types/Types';
import { IApiLmdCurrency } from '../../../types/CurrencyTypes';

const CURRENCIES_TO_SHOW = ['ISK', 'EUR', 'USD', 'GBP', 'JPY', 'CHF', 'DKK', 'SEK', 'NOK', 'CAD', 'PLN'];

export interface ICurrencyInputState {
    value: number | null,
    currency: string | null,
    averagerate: number | null
}

interface IOwnProps {
    basecurrency?: string
}
type Props = IDefaultProps & IOwnProps;

const Landsbankinn: React.FC<Props> = ({
    accessToken,
    refreshRateMs
}) => {
    const [ currencies ] = useApiLmdData<IApiLmdCurrency[]>(
        `/v1/market_data/v1/keldan/base_currency/NBI/cross_currency/[${CURRENCIES_TO_SHOW.join(';')}]/sources/NBI/rate_types/almenntgengi/currency_rate?date_rate=${getAPItoday()}`,
        accessToken,
        refreshRateMs
    );
    const [ input, setInput ] = useState<ICurrencyInputState | null>({
        value: 1000,
        currency: 'ISK',
        averagerate: 1,
    });
    const [ focusedSymbol, setFocusedSymbol ] = useState<string | null>(null);

    const handleData = () => {
        const { data, error } = currencies;
        if (data === null) {
            return currencies;
        } else {
            let newData: {[key: string]: IApiLmdCurrency} = {'ISK': {Crosscurrency: 'ISK', Averagerate: 1, Buyrate: 1, Sellrate: 1, ChangePer: 0, Description: '', Datetimerate: ''}};
            data.forEach((element) => {
                if (newData[element.Crosscurrency] === undefined) {
                    newData[element.Crosscurrency] = element;
                }
            });
            return {
                error,
                data: CURRENCIES_TO_SHOW.map((symbol) => newData[symbol]).filter((element) => element !== undefined)
            }
        }
    };

    return <>
        <div className='KCL_landsbankinn-currencies'>
            <LmdTable
                apiData={handleData()}
                columns={[{
                    title: 'Mynt',
                    renderCell: ({Crosscurrency}) => (
                        <div className="avatar-and-text">
                            <a href={`/Markadir/Gjaldmidlar/CBI${Crosscurrency}`} aria-label={`Gjaldmiðlar - ${Crosscurrency}`}>
                                <Flag name={Crosscurrency as FlagNames} />
                            </a>
                            <Link
                                url={`/Markadir/Gjaldmidlar/CBI${Crosscurrency}`}
                                linkSize='13'
                            >
                                {Crosscurrency}
                            </Link>
                        </div>
                    ),
                    textAlign: 'left',
                    simpleSortable: ({Crosscurrency}) => Crosscurrency
                }, {
                    title: 'Breyting',
                    renderCell: ({ChangePer}) => (
                        <ColoredNumber
                            sign={(ChangePer === null) ? 'neutral' : (ChangePer < 0) ? 'neg' : (ChangePer > 0) ? 'pos' : 'neutral'}
                        >
                            {convertToPercentage(ChangePer, true, 2)}
                        </ColoredNumber>
                    ),
                    simpleSortable: ({ChangePer}) => ChangePer
                }, {
                    title: 'Sl. 3 Mán',
                    renderCell: ({Crosscurrency}) => (Crosscurrency && Crosscurrency !== 'ISK') && (
                        <MiniGraph
                            symbol={Crosscurrency}
                            folder='Currencies/NBX'
                        />
                    )
                }, {
                    title: 'Kaup',
                    renderCell: ({Buyrate}) => formatNumber(Buyrate, '-', 2),
                    simpleSortable: ({Buyrate}) => Buyrate
                }, {
                    title: 'Sala',
                    renderCell: ({Sellrate}) => formatNumber(Sellrate, '-', 2),
                    simpleSortable: ({Sellrate}) => Sellrate
                }, {
                    title: 'Myntbreyta',
                    renderCell: ({Crosscurrency, Averagerate}) => (
                        <Input
                            currency={Crosscurrency ?? ''}
                            inputType="numberFormat"
                            inputSize="sm"
                            textAlign="right"
                            autoComplete="off"
                            value={
                                (input === null)
                                ? undefined // No input, display nothing.
                                : (Crosscurrency === input.currency)
                                ? (input.value ?? undefined) // Inputting currency, show value.
                                : (!Averagerate || (input.value === null) || (input.averagerate === null))
                                ? undefined // Not enough data, display nothing.
                                : (((input.value) * (input.averagerate)) / Averagerate)
                            }
                            thousandSeparator='.'
                            decimalSeparator=','
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            allowLeadingZeros={false}
                            onClick={e => e.currentTarget.select()}
                            onFocus={() => setFocusedSymbol(Crosscurrency)}
                            onValueChange={(values: NumberFormatValues) => {
                                if (focusedSymbol === Crosscurrency) {
                                    setInput({
                                        value: values.floatValue || null,
                                        currency: Crosscurrency,
                                        averagerate: Averagerate
                                    });
                                }
                            }}
                            aria-label={`${Crosscurrency} gildi`}
                        />
                    )
                }]}
            />
        </div>
        <div className='display-box__footer'>
            <div></div>
            <div>
                <Label
                    text="Landsbankinn"
                    url="https://landsbankinn.is"
                    labelType="origin"
                />
            </div>
        </div>
    </>;
}

export default Landsbankinn;