// Package imports:
import React from 'react';
// Component imports:
import GangverdReportBody from './GangverdReportBody';
// Service imports:
// Type imports:
import { IEventReport } from '../../../types/KeldanTypes';
import { IGangverdSearchResult } from '../../../types/PropertyTypes';

const EXAMPLE_DATA: IEventReport<IGangverdSearchResult> = {
    "userName": "martha@kodi.is",
    "productName": "Fasteignir",
    "reply": "",
    "eventDate": "30.03.2023 13:54:43",
    "overview": {
        "gangverd": {
            "type": "gangverd",
            "info": {
                "city": "Reykjavík",
                "zip": 104,
                "fnr": 1234567,
                "address": "Kelduvogur 20",
                "lat": 64.1322836,
                "fmr": 66850000,
                "latestval": 71518563,
                "aptid": "01-0402",
                "lon": -21.84606312
            },
            "core": {
                "garage": 0,
                "cellar": 0,
                "type": "apartment",
                "buildingyear": 2021,
                "totalsize": 86.3,
                "inspection": 1,
                "area": "Laugarneshverfi/Vogar",
                "storage": 6.7,
                "size": 79.6
            },
            "extra": {
                "toilets": 1,
                "attic": 0,
                "material": 0,
                "bathtubs": 0,
                "showers": 1,
                "model": 1
            },
            "estimateSeries": {
                "name": "Verðmat",
                "data": [
                    [ 1522540800000, 51962298.74739131 ],
                    [ 1525132800000, 52725631.610840574 ],
                    [ 1527811200000, 52329831.25965474 ],
                    [ 1530403200000, 52744573.8222249 ],
                    [ 1533081600000, 53082000.902734846 ],
                    [ 1535760000000, 53607774.85571036 ],
                    [ 1538352000000, 53553109.285836436 ],
                    [ 1541030400000, 54461405.34700179 ],
                    [ 1543622400000, 53556019.58010899 ],
                    [ 1546300800000, 53665667.92959965 ],
                    [ 1548979200000, 53626225.75750347 ],
                    [ 1551398400000, 53275181.681434214 ],
                    [ 1554076800000, 54161746.207843 ],
                    [ 1556668800000, 53937478.4839083 ],
                    [ 1559347200000, 54670356.11218135 ],
                    [ 1561939200000, 54686751.86468966 ],
                    [ 1564617600000, 54326197.17284827 ],
                    [ 1567296000000, 54644294.41230925 ],
                    [ 1569888000000, 53081520.21554395 ],
                    [ 1572566400000, 53180278.38318361 ],
                    [ 1575158400000, 52671472.33355264 ],
                    [ 1577836800000, 53406882.5163875 ],
                    [ 1580515200000, 53855288.36914644 ],
                    [ 1583020800000, 54222741.30590398 ],
                    [ 1585699200000, 54752078.81698121 ],
                    [ 1588291200000, 54284714.77389178 ],
                    [ 1590969600000, 53690372.74955491 ],
                    [ 1593561600000, 53419908.76693983 ],
                    [ 1596240000000, 54045412.86038776 ],
                    [ 1598918400000, 53980327.01970446 ],
                    [ 1601510400000, 54460034.66326263 ],
                    [ 1604188800000, 54533859.93892827 ],
                    [ 1606780800000, 54597219.90893368 ],
                    [ 1609459200000, 54599120.34565985 ],
                    [ 1612137600000, 55750862.35299353 ],
                    [ 1614556800000, 55841852.13639276 ],
                    [ 1617235200000, 55197900.726106815 ],
                    [ 1619827200000, 56432060.61601566 ],
                    [ 1622505600000, 57025429.0272212 ],
                    [ 1625097600000, 58086714.11191568 ],
                    [ 1627776000000, 57778698.82624856 ],
                    [ 1630454400000, 60650096.97613222 ],
                    [ 1633046400000, 61732288.00496807 ],
                    [ 1635724800000, 62240212.92748648 ],
                    [ 1638316800000, 61810726.90622567 ],
                    [ 1640995200000, 59437328.36436916 ],
                    [ 1643673600000, 61089641.340077534 ],
                    [ 1646092800000, 63427993.60699093 ],
                    [ 1648771200000, 67045264.01730167 ],
                    [ 1651363200000, 68318218.13894252 ],
                    [ 1654041600000, 68987018.04604737 ],
                    [ 1656633600000, 69075411.11671378 ],
                    [ 1659312000000, 70289275.35937706 ],
                    [ 1661990400000, 72131590.17532226 ],
                    [ 1664582400000, 71980465.7110154 ],
                    [ 1667260800000, 71809677.56761217 ],
                    [ 1669852800000, 69001395.32096207 ],
                    [ 1672531200000, 70199223.95058766 ],
                    [ 1675209600000, 70954809.58181475 ],
                    [ 1677628800000, 71518563.26134315 ]
                ],
                "colorMap": []
            },
            "ciSeries": {
                "name": "95% CI",
                "data": [
                    [ 1522540800000, 43015255.22438654, 62770300.374328464 ],
                    [ 1525132800000, 43645786.81155652, 63694400.57902645 ],
                    [ 1527811200000, 43329247.545772135, 63200064.5008002 ],
                    [ 1530403200000, 43665905.74558763, 63710806.41031356 ],
                    [ 1533081600000, 43945828.679517105, 64117548.91201439 ],
                    [ 1535760000000, 44403752.53573233, 64719609.512010254 ],
                    [ 1538352000000, 44354253.44322479, 64659762.966178946 ],
                    [ 1541030400000, 45116805.99234495, 65741459.46576292 ],
                    [ 1543622400000, 44372582.73826141, 64640078.540927425 ],
                    [ 1546300800000, 44455644.87031527, 64783761.94365249 ],
                    [ 1548979200000, 44418276.93547098, 64742990.66513837 ],
                    [ 1551398400000, 44142520.10222927, 64297302.84126864 ],
                    [ 1554076800000, 44895131.57011923, 65341043.66530549 ],
                    [ 1556668800000, 44703348.14238505, 65079053.49585419 ],
                    [ 1559347200000, 45313154.787949726, 65959826.708591014 ],
                    [ 1561939200000, 45324356.760281324, 65983083.7826892 ],
                    [ 1564617600000, 45034027.96593644, 65535681.18524877 ],
                    [ 1567296000000, 45307313.4117361, 65905450.7311762 ],
                    [ 1569888000000, 44032343.44963756, 63990411.7666577 ],
                    [ 1572566400000, 44109713.67451064, 64116081.77241885 ],
                    [ 1575158400000, 43681720.47767196, 63511326.189688094 ],
                    [ 1577836800000, 44300032.43747263, 64385846.76309424 ],
                    [ 1580515200000, 44661744.6746306, 64941307.29674431 ],
                    [ 1583020800000, 44971108.61996985, 65377656.120787814 ],
                    [ 1585699200000, 45415825.95964741, 66007610.15432155 ],
                    [ 1588291200000, 45013314.249092855, 65465747.3514555 ],
                    [ 1590969600000, 44524512.66436153, 64743125.83085259 ],
                    [ 1593561600000, 44311119.01085051, 64401141.66309791 ],
                    [ 1596240000000, 44851344.65837376, 65124171.27062503 ],
                    [ 1598918400000, 44814219.25707554, 65021231.061482236 ],
                    [ 1601510400000, 45221461.34360215, 65586013.529909424 ],
                    [ 1604188800000, 45280665.96217096, 65677962.47350204 ],
                    [ 1606780800000, 45335866.9429131, 65750511.080729015 ],
                    [ 1609459200000, 45329497.942937694, 65764327.376237705 ],
                    [ 1612137600000, 46282973.93330439, 67155551.79279128 ],
                    [ 1614556800000, 46363750.19890556, 67257554.37480469 ],
                    [ 1617235200000, 45844433.04461428, 66459721.32764933 ],
                    [ 1619827200000, 46863216.967010155, 67954734.46928497 ],
                    [ 1622505600000, 47347704.721847735, 68681250.22833711 ],
                    [ 1625097600000, 48224084.62433734, 69966415.7983131 ],
                    [ 1627776000000, 47957907.604386374, 69610585.71598312 ],
                    [ 1630454400000, 50329532.43630568, 73086994.55671418 ],
                    [ 1633046400000, 51227252.92679606, 74391562.39305043 ],
                    [ 1635724800000, 51646402.701413885, 75007046.04064909 ],
                    [ 1638316800000, 51301455.87294963, 74472856.48457645 ],
                    [ 1640995200000, 49341183.88049217, 71599335.9958797 ],
                    [ 1643673600000, 50694537.96693607, 73616299.28441906 ],
                    [ 1646092800000, 52630759.90709495, 76440286.63295326 ],
                    [ 1648771200000, 55641185.15461895, 80786694.50800756 ],
                    [ 1651363200000, 56687927.407842256, 82334619.4349389 ],
                    [ 1654041600000, 57246908.25620265, 83134771.88298653 ],
                    [ 1656633600000, 57323737.27259576, 83236241.17969103 ],
                    [ 1659312000000, 58316006.78181029, 84720859.72950016 ],
                    [ 1661990400000, 59847023.97672128, 86937761.57097548 ],
                    [ 1664582400000, 59723631.99290946, 86752718.66569984 ],
                    [ 1667260800000, 59567122.59493276, 86568388.19344088 ],
                    [ 1669852800000, 57239907.55933971, 83179598.97653282 ],
                    [ 1672531200000, 58233994.33617743, 84622926.85637262 ],
                    [ 1675209600000, 58830752.99936725, 85577436.05365275 ],
                    [ 1677628800000, 59307742.060014255, 86243460.1841848 ]
                ],
                "colorMap": []
            },
            "similarSeries": {
                "name": "Lik sala",
                "data": [{
                    "date2": 1666915200000,
                    "lat": 64.1314263035582,
                    "pdiff": -22,
                    "rooms": 2,
                    "address": "Barðavogur 38",
                    "date": "12345",
                    "price": 56000000,
                    "buildingyear": 1950,
                    "lon": -21.8507669149936,
                    "size": 79.8,
                    "name": "Barðavogur 38",
                    "x": 1666915200000,
                    "y": 56000000
                }, {
                    "date2": 1646352000000,
                    "lat": 64.1311017577349,
                    "pdiff": -39,
                    "rooms": 2,
                    "address": "Barðavogur 42",
                    "date": "12345",
                    "price": 38500000,
                    "buildingyear": 1957,
                    "lon": -21.8504993659649,
                    "size": 66.6,
                    "name": "Barðavogur 42",
                    "x": 1646352000000,
                    "y": 38500000
                }, {
                    "date2": 1630972800000,
                    "lat": 64.1314263035582,
                    "pdiff": -21,
                    "rooms": 2,
                    "address": "Barðavogur 38",
                    "date": "12345",
                    "price": 48000000,
                    "buildingyear": 1950,
                    "lon": -21.8507669149936,
                    "size": 79.8,
                    "name": "Barðavogur 38",
                    "x": 1630972800000,
                    "y": 48000000
                }, {
                    "date2": 1614816000000,
                    "lat": 64.1324590872423,
                    "pdiff": -19,
                    "rooms": 1,
                    "address": "Barðavogur 26",
                    "date": "12345",
                    "price": 45200000,
                    "buildingyear": 1951,
                    "lon": -21.8517905964146,
                    "size": 73.8,
                    "name": "Barðavogur 26",
                    "x": 1614816000000,
                    "y": 45200000
                }, {
                    "date2": 1590105600000,
                    "lat": 64.1314488431993,
                    "pdiff": -15,
                    "rooms": 1,
                    "address": "Kuggavogur 19",
                    "date": "12345",
                    "price": 46400000,
                    "buildingyear": 2019,
                    "lon": -21.8475901496804,
                    "size": 79.8,
                    "name": "Kuggavogur 19",
                    "x": 1590105600000,
                    "y": 46400000
                }, {
                    "date2": 1585094400000,
                    "lat": 64.1314488431993,
                    "pdiff": -13,
                    "rooms": 2,
                    "address": "Kuggavogur 19",
                    "date": "12345",
                    "price": 47000000,
                    "buildingyear": 2019,
                    "lon": -21.8475901496804,
                    "size": 96.5,
                    "name": "Kuggavogur 19",
                    "x": 1585094400000,
                    "y": 47000000
                }, {
                    "date2": 1578268800000,
                    "lat": 64.1317660817085,
                    "pdiff": 8,
                    "rooms": 3,
                    "address": "Kuggavogur 15",
                    "date": "12345",
                    "price": 57500000,
                    "buildingyear": 2019,
                    "lon": -21.8470029334364,
                    "size": 105.1,
                    "name": "Kuggavogur 15",
                    "x": 1578268800000,
                    "y": 57500000
                }, {
                    "date2": 1575590400000,
                    "lat": 64.1316102254713,
                    "pdiff": -3,
                    "rooms": 2,
                    "address": "Kuggavogur 17",
                    "date": "12345",
                    "price": 51000000,
                    "buildingyear": 2019,
                    "lon": -21.8472768885003,
                    "size": 90.9,
                    "name": "Kuggavogur 17",
                    "x": 1575590400000,
                    "y": 51000000
                }, {
                    "date2": 1574121600000,
                    "lat": 64.1316102254713,
                    "pdiff": 8,
                    "rooms": 3,
                    "address": "Kuggavogur 17",
                    "date": "12345",
                    "price": 57500000,
                    "buildingyear": 2019,
                    "lon": -21.8472768885003,
                    "size": 104.9,
                    "name": "Kuggavogur 17",
                    "x": 1574121600000,
                    "y": 57500000
                }, {
                    "date2": 1571875200000,
                    "lat": 64.1314488431993,
                    "pdiff": -7,
                    "rooms": 3,
                    "address": "Kuggavogur 19",
                    "date": "12345",
                    "price": 49500000,
                    "buildingyear": 2019,
                    "lon": -21.8475901496804,
                    "size": 97.6,
                    "name": "Kuggavogur 19",
                    "x": 1571875200000,
                    "y": 49500000
                }, {
                    "date2": 1571011200000,
                    "lat": 64.1317660817085,
                    "pdiff": 5,
                    "rooms": 3,
                    "address": "Kuggavogur 15",
                    "date": "12345",
                    "price": 56000000,
                    "buildingyear": 2019,
                    "lon": -21.8470029334364,
                    "size": 104.8,
                    "name": "Kuggavogur 15",
                    "x": 1571011200000,
                    "y": 56000000
                }, {
                    "date2": 1570752000000,
                    "lat": 64.1316102254713,
                    "pdiff": 5,
                    "rooms": 3,
                    "address": "Kuggavogur 17",
                    "date": "12345",
                    "price": 55500000,
                    "buildingyear": 2019,
                    "lon": -21.8472768885003,
                    "size": 104.4,
                    "name": "Kuggavogur 17",
                    "x": 1570752000000,
                    "y": 55500000
                }, {
                    "date2": 1568764800000,
                    "lat": 64.1314488431993,
                    "pdiff": -18,
                    "rooms": 2,
                    "address": "Kuggavogur 19",
                    "date": "12345",
                    "price": 44900000,
                    "buildingyear": 2019,
                    "lon": -21.8475901496804,
                    "size": 80.5,
                    "name": "Kuggavogur 19",
                    "x": 1568764800000,
                    "y": 44900000
                }, {
                    "date2": 1567728000000,
                    "lat": 64.1314488431993,
                    "pdiff": -14,
                    "rooms": 2,
                    "address": "Kuggavogur 19",
                    "date": "12345",
                    "price": 46900000,
                    "buildingyear": 2019,
                    "lon": -21.8475901496804,
                    "size": 83,
                    "name": "Kuggavogur 19",
                    "x": 1567728000000,
                    "y": 46900000
                }, {
                    "date2": 1566864000000,
                    "lat": 64.1316102254713,
                    "pdiff": 3,
                    "rooms": 3,
                    "address": "Kuggavogur 17",
                    "date": "12345",
                    "price": 55900000,
                    "buildingyear": 2019,
                    "lon": -21.8472768885003,
                    "size": 104.8,
                    "name": "Kuggavogur 17",
                    "x": 1566864000000,
                    "y": 55900000
                }, {
                    "date2": 1563926400000,
                    "lat": 64.1316102254713,
                    "pdiff": -7,
                    "rooms": 2,
                    "address": "Kuggavogur 17",
                    "date": "12345",
                    "price": 50900000,
                    "buildingyear": 2019,
                    "lon": -21.8472768885003,
                    "size": 91.1,
                    "name": "Kuggavogur 17",
                    "x": 1563926400000,
                    "y": 50900000
                }, {
                    "date2": 1562716800000,
                    "lat": 64.1316102254713,
                    "pdiff": -9,
                    "rooms": 3,
                    "address": "Kuggavogur 17",
                    "date": "12345",
                    "price": 49900000,
                    "buildingyear": 2019,
                    "lon": -21.8472768885003,
                    "size": 90.2,
                    "name": "Kuggavogur 17",
                    "x": 1562716800000,
                    "y": 49900000
                }, {
                    "date2": 1562112000000,
                    "lat": 64.1317660817085,
                    "pdiff": -9,
                    "rooms": 2,
                    "address": "Kuggavogur 15",
                    "date": "12345",
                    "price": 49900000,
                    "buildingyear": 2019,
                    "lon": -21.8470029334364,
                    "size": 91.1,
                    "name": "Kuggavogur 15",
                    "x": 1562112000000,
                    "y": 49900000
                }, {
                    "date2": 1562025600000,
                    "lat": 64.1314488431993,
                    "pdiff": -1,
                    "rooms": 2,
                    "address": "Kuggavogur 19",
                    "date": "12345",
                    "price": 54400000,
                    "buildingyear": 2019,
                    "lon": -21.8475901496804,
                    "size": 97.2,
                    "name": "Kuggavogur 19",
                    "x": 1562025600000,
                    "y": 54400000
                }, {
                    "date2": 1561507200000,
                    "lat": 64.1316102254713,
                    "pdiff": 4,
                    "rooms": 3,
                    "address": "Kuggavogur 17",
                    "date": "12345",
                    "price": 56900000,
                    "buildingyear": 2019,
                    "lon": -21.8472768885003,
                    "size": 105.1,
                    "name": "Kuggavogur 17",
                    "x": 1561507200000,
                    "y": 56900000
                }, {
                    "date2": 1561420800000,
                    "lat": 64.1317660817085,
                    "pdiff": -12,
                    "rooms": 3,
                    "address": "Kuggavogur 15",
                    "date": "12345",
                    "price": 47900000,
                    "buildingyear": 2019,
                    "lon": -21.8470029334364,
                    "size": 89.3,
                    "name": "Kuggavogur 15",
                    "x": 1561420800000,
                    "y": 47900000
                }, {
                    "date2": 1560988800000,
                    "lat": 64.1314488431993,
                    "pdiff": -20,
                    "rooms": 3,
                    "address": "Kuggavogur 19",
                    "date": "12345",
                    "price": 43900000,
                    "buildingyear": 2019,
                    "lon": -21.8475901496804,
                    "size": 79.8,
                    "name": "Kuggavogur 19",
                    "x": 1560988800000,
                    "y": 43900000
                }, {
                    "date2": 1457913600000,
                    "lat": 64.1324590872423,
                    "pdiff": -13,
                    "rooms": 1,
                    "address": "Barðavogur 26",
                    "date": "12345",
                    "price": 33200000,
                    "buildingyear": 1951,
                    "lon": -21.8517905964146,
                    "size": 73.8,
                    "name": "Barðavogur 26",
                    "x": 1457913600000,
                    "y": 33200000
                }, {
                    "date2": 1444348800000,
                    "lat": 64.1314263035582,
                    "pdiff": -21,
                    "rooms": 2,
                    "address": "Barðavogur 38",
                    "date": "12345",
                    "price": 28500000,
                    "buildingyear": 1950,
                    "lon": -21.8507669149936,
                    "size": 79.8,
                    "name": "Barðavogur 38",
                    "x": 1444348800000,
                    "y": 28500000
                }, {
                    "date2": 1399939200000,
                    "lat": 64.1314263035582,
                    "pdiff": -19,
                    "rooms": 2,
                    "address": "Barðavogur 38",
                    "date": "12345",
                    "price": 24000000,
                    "buildingyear": 1950,
                    "lon": -21.8507669149936,
                    "size": 79.8,
                    "name": "Barðavogur 38",
                    "x": 1399939200000,
                    "y": 24000000
                }, {
                    "date2": 1355270400000,
                    "lat": 64.1314263035582,
                    "pdiff": -15,
                    "rooms": 3,
                    "address": "Barðavogur 38",
                    "date": "12345",
                    "price": 21500000,
                    "buildingyear": 1950,
                    "lon": -21.8507669149936,
                    "size": 102.4,
                    "name": "Barðavogur 38",
                    "x": 1355270400000,
                    "y": 21500000
                }],
                "colorMap": [
                    "#8989ff",
                    "#2f2fff",
                    "#8f8fff",
                    "#9999ff",
                    "#afafff",
                    "#b9b9ff",
                    "#ffd3d3",
                    "#efefff",
                    "#ffd3d3",
                    "#d9d9ff",
                    "#ffe3e3",
                    "#ffe3e3",
                    "#9f9fff",
                    "#b3b3ff",
                    "#ffefef",
                    "#d9d9ff",
                    "#cfcfff",
                    "#cfcfff",
                    "#f9f9ff",
                    "#ffe9e9",
                    "#bfbfff",
                    "#9393ff",
                    "#b9b9ff",
                    "#8f8fff",
                    "#9999ff",
                    "#afafff"
                ]
            }
        },
    },
    "result": 0,
    "descr": null,
    "exception": null,
    "msg": null,
    "frontmsg": null,
    "errorCode": 0,
    "errorMessage": null
}

const GangverdReportExample: React.FC = () => {
    return (
        <GangverdReportBody report={EXAMPLE_DATA} isExample />
    );
}

export default GangverdReportExample;