// Package imports:
import React from 'react';
import cx from 'classnames';

type ListItem = string | JSX.Element;

interface IProps {
    items: ListItem[],
    size?: 'sm' | 'lg'
}

const BulletList: React.FC<IProps> = ({
    items,
    size = 'lg'
}) => {
    return (
        <ul className={cx('KCL_bullet-list', size)}>
            {items.map((item, index) => (
                <li
                    className={cx('bullet-list-item', `paragraph${size === 'sm' ? '-small' : ''}`)}
                    key={index}
                >
                    {item}
                </li>
            ))}
        </ul>
    );
}

export default BulletList;