import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { getSubscriptionPrice } from '../../services/utils';
import Link from '../../ui-elements/Link/Link';
export const pricingPageData = {
    pricingPlansTable: [{
        label: "Verð á mánuði" ,
        forFreeUser: "0 kr.",
        forSubscriber: getSubscriptionPrice(true)
    }, {
        label: "Fjöldi aðganga",
        forFreeUser: "1",
        forSubscriber: "4"
    }, {
        label: "Skannaðir ársreikningar",
        forFreeUser: <FontAwesomeIcon icon={faCheck} className="icon-fa-check"/>,
        forSubscriber: <FontAwesomeIcon icon={faCheck} className="icon-fa-check"/>
    }, {
        label: "Innslegnir ársreikningar",
        forFreeUser: "",
        forSubscriber: <FontAwesomeIcon icon={faCheck} className="icon-fa-check"/>
    }, {
        label: "Raunverulegir eigendur",
        forFreeUser: "",
        forSubscriber: <FontAwesomeIcon icon={faCheck} className="icon-fa-check"/>
    }, {
        label: "Fyrirtækjavakt",
        forFreeUser: "",
        forSubscriber: <FontAwesomeIcon icon={faCheck} className="icon-fa-check"/>
    }, {
        label: "Fréttavakt",
        forFreeUser: "",
        forSubscriber: <FontAwesomeIcon icon={faCheck} className="icon-fa-check"/>
    }, {
        label: "Samanburðar skýrslur",
        forFreeUser: "",
        forSubscriber: <FontAwesomeIcon icon={faCheck} className="icon-fa-check"/>
    }, {
        label: "Verðmat fyrirtækja",
        forFreeUser: "",
        forSubscriber: <FontAwesomeIcon icon={faCheck} className="icon-fa-check"/>
    }],
    pepAndDdPrices: [{
        name: "Stjórnmálaleg tengsl (PEP) eftir kennitölu",
        subPrice: -1,
        freePrice: null
    }, {
        name: "Uppfletting í alþjóðlegum válistum - einstaklingar",
        subPrice: 195,
        freePrice: null
    }, {
        name: "Uppfletting í alþjóðlegum válistum - fyrirtæki",
        subPrice: 255,
        freePrice: null
    }, {
        name: <Link url="/Areidanleikakannanir">Áreiðanleikakönnun á lögaðilum + PEP uppfletting</Link>,
        subPrice: 1190,
        freePrice: null
    }, {
        name: <Link url="/Areidanleikakannanir">Áreiðanleikakönnun á einstaklingum + PEP uppfletting</Link>,
        subPrice: 590,
        freePrice: null
    }]
}