// Package imports:
import React from 'react';
// Component imports:
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Loading from '../../ui-elements/Loading/Loading';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
// Service imports:
import { convertToPercentage, getTableDate } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
import { CUSTOM_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdCbiRateSnapshot } from '../../types/RatesTypes';

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const VextirSedlabanka: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [rates] = useApiLmdData<IApiLmdCbiRateSnapshot[]>(
        `/v1/market_data/v1/keldan/rates/central_bank_iceland/snapshot`,
        accessToken,
        CUSTOM_REFRESH_RATES['VEXTIRSEDLABANKA']
    );

    const displayStatBoxes = () => {
        const { data, error } = rates;
        if (data === null) {
            if (error === null) return <Loading />
            return <ErrorAlert error={error} />
        }

        return (
            <div className="stat-boxes">
                {data.map((rate, index) => (
                    <div className="stat-box" key={index}>
                        <div className="stat-box__content">
                            <strong>{convertToPercentage((rate.RateValue === null) ? null : (rate.RateValue/100), true)}</strong>
                            <span>{index !== 4 ? rate.DisplayName : rate.DisplayName?.replace('Viðskiptareikningur', 'Viðskiptareikn.')}</span>
                        </div>
                        <div className="stat-box__foot">
                            {getTableDate(rate.ValueDate, 'DD/MM/YY', '.')}
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <DisplayBox
            title="Vextir Seðlabanka Íslands"
            className='KCL_vextir-sedlabanka'
            displayBoxSize="sm"
            {...props}
        >
            {displayStatBoxes()}
        </DisplayBox>
    );
}

export default VextirSedlabanka;