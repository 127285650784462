// Package imports:
import React, { memo, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import cx from 'classnames';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLock } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import Link from '../../ui-elements/Link/Link';
import Label from '../../ui-elements/Label/Label';
// Service imports:
import { gtmPush } from '../../services/utils';

interface IProps {
    title: string | null,
    description: string,
    date: Date | string | null | JSX.Element,
    source: string | null | undefined,
    fullSource: string | null | undefined,
    link: string | null
    isNew: boolean, // the yellow notifier next to the title
    isHighlighted: boolean, // The highlighted color.
    isSeen: boolean,
    paywall: boolean,
    onOpen(): void
}

const NewsItem: React.FC<IProps>= ({
    title,
    description,
    date,
    source,
    fullSource,
    link,
    isNew,
    isHighlighted,
    isSeen,
    paywall,
    onOpen
}) => {
    const [ isOpen, setIsOpen ] = useState(false);

    const expandNews = () => {
        setIsOpen(!isOpen);
        onOpen();
    }

    useEffect(() => {
        setIsOpen(false);
    }, [title]);

    return (
        <div
            className={cx('news-item', {
                'is-open': isOpen,
                'is-seen': isSeen,
                'is-highlighted': isHighlighted
            })}
        >
            <div
                className="news-item__head"
                onClick={() => {expandNews(); gtmPush('NewsItemClick', title ?? 'Title is null', (!isOpen) ? 'Opened' : 'Closed')}}
            >
                <div
                    className="news-item__trigger"
                >
                    <span className="news-item__title">
                        {/* {paywall && <FontAwesomeIcon icon={faLock} className='news-lock-icon' />} */}
                        {title}
                    </span>
                </div>


                <div className="news-item__meta">
                    <div className="notify-wrapper">
                        {!isSeen && isNew && (
                            <div className="news-item__notify"></div>
                        )}
                    </div>

                    <span className='news-item__source'>{source}</span>

                    <span className="news-item__time">{date}</span>
                </div>
            </div>

            <AnimateHeight
                className="news-item__content"
                duration={300}
                height={isOpen ? 'auto' : 0}
            >
                <div className="news-item__body">
                    <div className="news-item__entry" dangerouslySetInnerHTML={{__html: description}} />

                    <div className="news-item__actions">
                        <Link
                            targetBlank
                            url={link ?? '#'}
                            linkSize='12'
                            icon='forward'
                            onClick={() => gtmPush('NewsItemClick', title ?? 'Title is null', 'Read')}
                        >
                            Skoða frétt
                        </Link>
                        <Label labelType='origin' text={fullSource ?? ''} size='sm' />
                    </div>
                </div>
            </AnimateHeight>
        </div>
    )
}

export default memo(NewsItem);