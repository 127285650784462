// Package imports:
import React from 'react';
// Component imports:
import LmdTable from '../../ui-elements/Table/LmdTable';
import ColoredNumber from '../../ui-elements/ColoredNumber/ColoredNumber';
import CryptoLogo, { CryptoLogoNames } from '../../ui-elements/CryptoLogo/CryptoLogo';
import DisplayBox from '../../ui-elements/DisplayBox/DisplayBox';
import Label from '../../ui-elements/Label/Label';
import MiniGraph from '../../ui-elements/MiniGraph/MiniGraph';
// Service imports:
import { convertToPercentage, formatNumber, getTableDate } from '../../services/utils';
import { useApiLmdData } from '../../services/apiHooks';
import { CUSTOM_REFRESH_RATES } from '../../services/config';
// Type imports:
import { IDefaultProps } from '../../types/Types';
import { IApiLmdCrypto } from '../../types/CurrencyTypes';

const CURRENCIES_TO_SHOW = ['BTC', 'ETH', 'BNB', 'SOL', 'XRP', 'ADA', 'DOT', 'DOGE'];
type crypto = 'BTC' | 'ETH' | 'BNB' | 'SOL' | 'XRP' | 'ADA' | 'DOT' | 'DOGE';

export interface ICurrencyInputState {
    value: number | null,
    currency: string | null,
    averagerate: number | null
}

type Props = IDefaultProps & React.HTMLAttributes<HTMLDivElement>;

const Rafmyntir: React.FC<Props> = ({
    accessToken,
    refreshRateMs,
    ...props
}) => {
    const [ currencies ] = useApiLmdData<IApiLmdCrypto[]>(
        `/v1/market_data/v1/keldan/crypto/snapshot`,
        accessToken,
        CUSTOM_REFRESH_RATES['RAFMYNTIR']
    );

    const handleData = () => {
        const { data, error } = currencies;
        if (data === null) {
            return currencies;
        }
        const filteredData = data.filter(x => CURRENCIES_TO_SHOW.includes(x.base ?? ''))
        filteredData.sort((a, b) => {
            if (a.base === null && b.base === null) return 0;
            if (a.base === null) return -1;
            if (b.base === null) return 1;
            return CURRENCIES_TO_SHOW.indexOf(a.base) - CURRENCIES_TO_SHOW.indexOf(b.base);
        })
        return {
            error,
            data: filteredData
        }
    };
    const showDataBaseTimestamp = () => {
        if(currencies.data) {
            const timeStamp = currencies.data.find(x => x.base === 'BTC');
            if(timeStamp?.databasetimestamp){
                //date is the same as now -> return time 
                if(new Date(timeStamp.databasetimestamp.toString()).getDate() === new Date().getDate()) return getTableDate(timeStamp.databasetimestamp, 'HH:MM','.')
                //else return date
                return getTableDate(timeStamp?.databasetimestamp, 'DD/MM/YY', '.');
            }
        } else {
            return '-'
        }
    }
    
    const nameOfCrypto = (str: crypto | null) => {
        switch(str){
            case 'BTC': return 'Bitcoin';
            case 'ETH': return 'Ethereum';
            case 'BNB': return 'Binance Coin';
            case 'SOL': return 'Solana';
            case 'XRP': return 'XRP'
            case 'ADA': return 'Cardano';
            case 'DOT': return 'Polkadot';
            case 'DOGE': return 'Dogecoin';
            default: return str;
        }
    }
    return (
        <DisplayBox
            title="Rafmyntir"
            className='KCL_rafmyntir'
            // anchorProps={{ href: '/Markadir/Gjaldmidlar' }}
            displayBoxSize="sm"
            asideComponent={<span className='extra-info'>Síðast uppfært: {showDataBaseTimestamp()}</span>}
            footerRight={ 
                <Label
                    text="CoinGecko"
                    labelType="origin"
                    url='https://www.coingecko.com'
                />
            }
            // {...props}
        >
            <LmdTable
                apiData={handleData()}
                columns={[{
                    title: 'Mynt',
                    renderCell: ({base}) => (
                        <div className="avatar-and-text">
                            <CryptoLogo name={base as CryptoLogoNames} />
                            <span>{nameOfCrypto(base as crypto)}</span>
                        </div>
                    ),
                    textAlign: 'left',
                    simpleSortable: ({base}) => base
                }, {
                    title: 'Verð (USD)',
                    renderCell: ({averagerate}) => formatNumber(averagerate, '-', 2), 
                    simpleSortable: ({averagerate}) => averagerate
                }, {
                    title: 'Breyting',
                    renderCell: ({change_per}) => (
                        <ColoredNumber
                            sign={(change_per === null) ? 'neutral' : (change_per < 0) ? 'neg' : (change_per > 0) ? 'pos' : 'neutral'}
                        >
                            {convertToPercentage((change_per !== null ) ? change_per : 0, true)}
                        </ColoredNumber>
                    ),
                    simpleSortable: ({change_per}) => change_per
                }, {
                    title: 'Sl. 3 Mán',
                    renderCell: ({base}) => (base) && (
                        <MiniGraph
                            folder='Crypto'
                            symbol={base}
                        />
                    )
                }, 
                // {
                //     title: 'Markaðsvirði',
                //     renderCell: ({crosscurrency, sellrate}) => formatNumber(sellrate, '-', currencyFractionDigits(crosscurrency ?? '')),
                //     simpleSortable: ({sellrate}) => sellrate
                // }, {
                //     title: 'Dagsvelta',
                //     renderCell: ({crosscurrency, sellrate}) => formatNumber(sellrate, '-', currencyFractionDigits(crosscurrency ?? '')),
                //     simpleSortable: ({sellrate}) => sellrate
                // },
            ]}
            />
        </DisplayBox>
    );
}

export default Rafmyntir;