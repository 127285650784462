// Package imports:
import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts/highstock';
// Service imports:
import { formatNumber } from '../../../services/utils';
import { useApiLmdData } from '../../../services/apiHooks';
// Type imports:
import { IDefaultProps } from '../../../types/Types';
import { IApiLmdTrade } from '../../../types/HlutabrefTypes';

interface IChartDatum {
    x: number | undefined
    y: number | undefined
};
interface IChartDataInfo {
    name: string,
    data: IChartDatum[]
};

interface IOwnProps {
    symbol: string
}

type Props = IOwnProps & IDefaultProps;

const ShareIntradayChart: React.FC<Props> = ({
    symbol,
    accessToken,
    refreshRateMs
}) => {
    const [apiData] = useApiLmdData<IApiLmdTrade[]>(
        `/v1/market_data/v1/exchanges/*/markets/*/tradables/${symbol}/delayed_latest_trades?limit=500`,
        accessToken,
        refreshRateMs
    );
    const divRef = useRef<HTMLDivElement>(null);

    const sortTrades = (a: IApiLmdTrade, b: IApiLmdTrade) => {
        if (a.time_executed_utc === null && b.time_executed_utc === null) return 0;
        if (a.time_executed_utc === null) return -1;
        if (b.time_executed_utc === null) return 1;
        const parsedA = Date.parse(a.time_executed_utc.replace(/ /g, "T"));
        const parsedB = Date.parse(b.time_executed_utc.replace(/ /g, "T"));
        if (parsedA < parsedB) return -1;
        if (parsedA > parsedB) return 1;
        return 0;
    }

    const handleData = () => {
        if (apiData.data === null || apiData.error instanceof Error) return [];
        const newData = apiData.data.filter((d) => {
            if (d.time_executed_utc === null) return false;
            const TODAY = new Date();
            const date = new Date(d.time_executed_utc.replace(/ /g, "T"));
            return date.getDate() === TODAY.getDate()
                && date.getMonth() === TODAY.getMonth()
                && date.getFullYear() === TODAY.getFullYear();
        }).sort(sortTrades);
        for (let i = newData.length; i > 0; i--) {
            const time1 = newData[i] && newData[i].time_executed_utc;
            const time2 = newData[i-1] && newData[i-1].time_executed_utc;
            if (time1 !== null && time2 !== null
                && Date.parse(time1) === Date.parse(time2)
                && newData[i].price === newData[i-1].price
            ) {
                const newVol = (newData[i-1].volume ?? 0) + (newData[i].volume ?? 0);
                newData[i-1].volume = newVol;
                newData.splice(i, 1);
            }
        }
        return newData;
    };

    useEffect(() => {
        const chartDataInfo: IChartDataInfo = {
            name: symbol,
            data: []
        }
        const chartDataVolumeInfo: IChartDataInfo = {
            name: '',
            data: []
        }

        for (let datum of handleData()) {
            chartDataInfo.data.push({
                x: datum.time_executed_utc ? Date.parse(datum.time_executed_utc.replace(/ /g, "T")) : undefined,
                y: datum.price ?? undefined
            });
            chartDataVolumeInfo.data.push({
                x: datum.time_executed_utc ? Date.parse(datum.time_executed_utc.replace(/ /g, "T")) : undefined,
                y: datum.volume ?? undefined
            })
        }

        Highcharts.setOptions({
            lang: {
                resetZoom: 'Til baka',
                resetZoomTitle: ''
            }
        });

        if (divRef.current !== null) {
            Highcharts.stockChart(divRef.current, {
                chart: {
                    height: 400,
                    marginTop: 20,
                    reflow: true,
                    style: {
                        fontFamily: "Roboto"
                    },
                    zoomType: "xy",
                    selectionMarkerFill: '#4569EE18',
                    resetZoomButton: {
                        position: {
                            x: 0,
                            y: -75
                        },
                        theme: {
                            r: 6,
                            height: 28
                        }
                    },
                    plotBackgroundColor: {
                        linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                        stops: [
                            [0.05, '#FFF'],
                            [0.95, '#F8F8FA']
                        ]
                    },
                    animation: false
                },
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: '',
                    align: 'center',
                    x: 60,
                    margin: -14
                },
                navigator: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                tooltip: {
                    enabled: true,
                    formatter: function() {
                        return `
                            <div class="chart-tooltip">
                                ${this.points?.reduce(function (s, point) {
                                    if (point.series.name === 'Verð') {
                                        return `${s}<p class="tooltip-value"><span style="color: ${point.color}">Verð</span>: ${formatNumber(point.y, null, 3)}</p>`
                                    } else {
                                        return `${s}<p class="tooltip-volume"><span style="color: ${point.color}">Magn</span>: ${formatNumber(point.y, null, 0)}</p>`
                                    }
                                }, `<p class="tooltip-date">${'Klukkan: ' + ((new Date(this.x).getHours()).toString()).padStart(2, '0') + ':' + ((new Date(this.x).getMinutes()).toString()).padStart(2, '0') }</p>`)}
                            </div>`;
                    },
                    borderWidth: 0,
                    shadow: false,
                    useHTML: true,
                    shared: true
                },
                xAxis: [{
                    type: 'datetime',
                    title: {
                        text: ''
                    },
                    minTickInterval: 10,
                    tickWidth: 0,
                    ordinal: false
                }],
                yAxis: [{
                    title: {
                        text: 'Verð',
                        offset: 52,
                        x: 5
                    },
                    gridLineDashStyle: 'Dash',
                    gridLineWidth: 1,
                    gridLineColor: '#D3D3D6',
                    opposite: false,
                    height: '70%',
                    labels: {
                        x: -11,
                        y: 2
                    },
                    showLastLabel: true
                }, {
                    title: {
                        text: 'Magn',
                        offset: 52,
                        x: 5
                    },
                    minTickInterval: 1,
                    gridLineDashStyle: 'Dash',
                    gridLineWidth: 1,
                    gridLineColor: '#D3D3D6',
                    opposite: false,
                    top: '76%',
                    height: '24%',
                    offset: 0,
                    labels: {
                        x: -11,
                        y: 2
                    }
                }],
                rangeSelector: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        color: '#4569EE',
                        enableMouseTracking: true,
                        marker: {
                            enabled: false
                        },
                        animation: false
                    }
                },
                legend: {
                    enabled: false,
                    align: 'left',
                    verticalAlign: 'top',
                    itemStyle: {
                        color: '#232530',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '26px'
                    },
                    y: 10
                },
                responsive: {
                    rules: [{
                        condition: {
                            callback: function() {
                                return window.matchMedia('(max-width: 767px)').matches;
                            }
                        },
                        chartOptions: {
                            chart: {
                                spacingLeft: 0,
                                spacingRight: 5
                            },
                            xAxis: {
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    }
                                }
                            },
                            yAxis: [{
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    },
                                    x: -1
                                },
                                title: {
                                    offset: 27,
                                    x: 0,
                                    style: {
                                        fontSize: '10px'
                                    }
                                }
                            }, {
                                labels: {
                                    style: {
                                        fontSize: '9px'
                                    },
                                    x: -1
                                },
                                title: {
                                    offset: 27,
                                    x: 0,
                                    style: {
                                        fontSize: '10px'
                                    }
                                }
                            }]
                        }
                    }]
                },
                series: [{
                    type: 'line',
                    id: 'price',
                    name: 'Verð',
                    data: chartDataInfo.data,
                    tooltip: {
                        valueDecimals: 2
                    },
                    yAxis: 0
                }, {
                    type: 'column',
                    id: 'volume',
                    name: 'Magn',
                    data: chartDataVolumeInfo.data,
                    yAxis: 1,
                    color: '#393A45',
                    tooltip: {
                        valueDecimals: 2
                    },
                    linkedTo: 'price'
                }]
            }, () => {});
        }
    }, [apiData, divRef.current]);

    return (
        <div className='KCL_share-intraday-chart'>
            <div ref={divRef}></div>
        </div>
    );
}

export default ShareIntradayChart;