// Package imports:
import React, { useMemo } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import Loading from '../../../ui-elements/Loading/Loading';
import Table from '../../../ui-elements/Table/Table';
import Tooltip from '../../../ui-elements/Tooltip/Tooltip';
// Service imports:
import { convertToPercentage, formatNumber } from '../../../services/utils';
// Type imports:
import { ICompanyValuation } from '../../../types/CompanyTypes';

const EQUALS_ICON = <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none" className="math-icon equals-icon">
    <path d="M10.2156 5.08172V5.79086C10.2156 6.18975 9.89344 6.5 9.4792 6.5H1.37863C0.964394 6.5 0.642212 6.18975 0.642212 5.79086V5.08172C0.642212 4.68282 0.964394 4.37257 1.37863 4.37257H9.4792C9.89344 4.37257 10.2156 4.68282 10.2156 5.08172Z" fill="#232530"/>
    <path d="M10.2156 1.20914V1.91828C10.2156 2.31717 9.89344 2.62742 9.4792 2.62742H1.37863C0.964394 2.62742 0.642212 2.31717 0.642212 1.91828V1.20914C0.642212 0.810249 0.964394 0.5 1.37863 0.5H9.4792C9.89344 0.5 10.2156 0.810249 10.2156 1.20914Z" fill="#232530"/>
</svg>;
const PLUS_ICON = <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none" className="math-icon">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.84521 10.5H5.58595C6.00261 10.5 6.32669 10.1635 6.32669 9.73077V6.61111H9.44635C9.87904 6.61111 10.2156 6.28704 10.2156 5.87037V5.12963C10.2156 4.71296 9.87904 4.38889 9.44635 4.38889H6.32669V1.26923C6.32669 0.836538 6.00261 0.5 5.58595 0.5H4.84521C4.42854 0.5 4.10447 0.836538 4.10447 1.26923L4.10446 4.38889H0.984807C0.552115 4.38889 0.215576 4.71296 0.215576 5.12963V5.87037C0.215576 6.28704 0.552115 6.61111 0.984807 6.61111H4.10446L4.10446 9.73077C4.10446 10.1635 4.42854 10.5 4.84521 10.5Z" fill="#232530"/>
</svg>;
const MULTIPLY_ICON = <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none" className="math-icon">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.48992 7.77436L1.0137 8.29814C1.30833 8.59277 1.77545 8.58396 2.08141 8.278L4.28666 6.07276L6.49186 8.27796C6.79782 8.58392 7.26495 8.59274 7.55957 8.29811L8.08336 7.77432C8.37798 7.4797 8.36917 7.01257 8.06321 6.70661L5.858 4.50141L8.06463 2.29479C8.37059 1.98883 8.3794 1.5217 8.08477 1.22708L7.56099 0.703292C7.26636 0.408665 6.79924 0.417478 6.49328 0.723438L4.28666 2.93006L2.08 0.723402C1.77404 0.417443 1.30692 0.408629 1.01229 0.703257L0.488506 1.22704C0.193878 1.52167 0.202692 1.98879 0.508651 2.29475L2.71531 4.50141L0.510066 6.70665C0.204106 7.01261 0.195293 7.47973 0.48992 7.77436Z" fill="#232530"/>
</svg>;
const DIVIDE_ICON = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="19" viewBox="0 0 12 19" fill="none" className="math-icon">
    <path d="M10.569 0.333333L11.1464 0.666667C11.4712 0.854167 11.3872 1.5829 10.9545 2.33235L2.49298 16.9882C2.06029 17.7376 1.47116 18.1747 1.1464 17.9872L0.56905 17.6538C0.24429 17.4663 0.328238 16.7376 0.76093 15.9882L9.22247 1.33235C9.65516 0.582901 10.2443 0.145833 10.569 0.333333Z" fill="#232530"/>
</svg>;
const MINUS_ICON = <svg xmlns="http://www.w3.org/2000/svg" width="11" height="3" viewBox="0 0 11 3" fill="none" className="math-icon">
    <path d="M10.3579 1.12963V1.87037C10.3579 2.28703 10.0214 2.61111 9.58868 2.61111H1.12714C0.694449 2.61111 0.35791 2.28703 0.35791 1.87037V1.12963C0.35791 0.71296 0.694449 0.388885 1.12714 0.388885H9.58868C10.0214 0.388885 10.3579 0.71296 10.3579 1.12963Z" fill="#232530"/>
</svg>;

interface ITableLine {
    name: string | JSX.Element,
    value: string | JSX.Element | null,
    rowClass?: string
}

interface ICalculationData {
    header?: JSX.Element,
    title: string,
    value: string | JSX.Element | null,
    formula: JSX.Element,
    hoverFormula?: JSX.Element,
    tableData: ITableLine[]
}

interface IProps {
    data: ICompanyValuation,
    type: 'return-on-equity' | 'return-on-debt' | 'wacc' | 'fcf' | 'valuation' | 'equity-valuation-ebitda' | 'ebitda-valuation' | 'equity-valuation-large',
    tableOpen: boolean,
    setTableOpen?: (open: boolean) => void,
    waccHighlight?: boolean,
    growthHighlight?: boolean,
    industryHighlight?: boolean
}

const CompanyValuationTable: React.FC<IProps> = ({
    data,
    type,
    tableOpen,
    setTableOpen,
    waccHighlight,
    growthHighlight,
    industryHighlight
}) => {
    // only used for EBITDA valuation
    const displayCurrency = data.currency === 'ISK' ? ' kr.' : ` ${data.currency}`;

    const displayData: ICalculationData = useMemo(() => {
        switch (type) {
            case 'return-on-equity':
                return {
                    header: <h6>
                        Eiginfjárkrafa
                        <Tooltip
                            text={<FontAwesomeIcon icon={faInfoCircle} className='icon-fa' />}
                            tooltip={<p>
                                Eiginfjárkrafa stendur fyrir þá ávöxtunarkröfu sem hluthafar félags gera á eigið fé þess.
                                Ávöxtunarkrafan er metin út frá áhættu félagsins en fjárfestar gera hærri ávöxtunarkröfu
                                eftir því sem rekstraráhætta félagsins er hærri.
                            </p>}
                            noUnderline
                        />
                    </h6>,
                    title: 'Eiginfjárkrafa',
                    value: convertToPercentage(data.return_on_equity, true, 2),
                    formula: <><p>{EQUALS_ICON}Áhættulausir vextir</p><p>{PLUS_ICON}Voguð beta{MULTIPLY_ICON}Áhættuálag</p></>,
                    tableData: [{
                        name: `Áhættulausir vextir (Ávöxtunarkrafa ${data.symbol})`,
                        value: convertToPercentage(data.riskfree_last_yield, true, 2)
                    }, {
                        name: <>
                            Voguð beta
                            <Tooltip
                                text={<FontAwesomeIcon icon={faInfoCircle} className='icon-fa' />}
                                tooltip={<p>
                                    Voguð beta sýnir hversu mikið er áætlað að verð fyrirtækis sveiflist í hlutfalli við sveiflur markaðarins. Meiri sveiflur í verði fyrirtækis hækka betagildið og þar af leiðandi eiginfjárkröfuna. Þar sem verðmatið gildir fyrir óskráð fyrirtæki er betan reiknuð út frá skráðum fyrirtækjum á heimsvísu í sömu atvinnugrein.
                                </p>}
                                noUnderline
                            />    
                        </>,
                        value: formatNumber(data.beta, '-', 2)
                    }, {
                        name: <>
                            Áhættuálag markaðarins
                            <Tooltip
                                text={<FontAwesomeIcon icon={faInfoCircle} className='icon-fa' />}
                                tooltip={<p>
                                    Áhættuálag markaðarins er sú ávöxtun sem hlutabréf ná að jafnaði umfram áhættulausa vexti.
                                </p>}
                                noUnderline
                            /> 
                        </>,
                        value: convertToPercentage(data.market_risk_premium, true, 2)
                    }]
                };
            case 'return-on-debt':
                return {
                    header: <h6>
                        Skuldakrafa eftir skatta
                        <Tooltip
                            text={<FontAwesomeIcon icon={faInfoCircle} className='icon-fa' />}
                            tooltip={<p>
                                Skuldakrafa er sú vaxtaprósenta sem félag greiðir af skuldum sínum að frádregnum skattaáhrifum skuldanna.
                            </p>}
                            noUnderline
                        />
                    </h6>,
                    title: 'Skuldakrafa eftir skatta',
                    value: convertToPercentage(data.return_on_debt, true, 2),
                    formula: <><p>{EQUALS_ICON}(Áhættulausir vextir{PLUS_ICON}Álag)</p><p>{MULTIPLY_ICON}(1{MINUS_ICON}Skattahlutfall)</p></>,
                    tableData: [{
                        name: `Áhættulausir vextir (Ávöxtunarkrafa ${data.symbol})`,
                        value: convertToPercentage(data.riskfree_last_yield, true, 2)
                    }, {
                        name: <>
                            Álag miðað við vaxtaþekju
                            <Tooltip
                                text={<FontAwesomeIcon icon={faInfoCircle} className='icon-fa' />}
                                tooltip={<p>
                                    Vaxtaþekja er fundin með því að deila meðal EBIT síðustu 3 ára með fjármagnsgjöldum í nýjasta ársreikningi og segir til um getu félags til að standa við vaxtagreiðslur af lánum.
                                    Því lægri sem vaxtaþekjan er þeim mun meiri byrði eru skuldirnar á rekstri félagsins.
                                    Álag vegna vaxtaþekju er svo í öfugu hlutfalli við vaxtaþekjuna sjálfa, það er að segja lág vaxtaþekja þýðir hærra álag og há vaxtaþekja þýðir lægra álag vegna vaxtaþekju.
                                </p>}
                                noUnderline
                            />
                        </>,
                        value: convertToPercentage(data.interest_coverage_spread, true, 2)
                    }, {
                        name: 'Skattahlutfall',
                        value: convertToPercentage(data.corporate_tax_rate, true, 0)
                    }]
                };
            case 'wacc':
                return {
                    header: <h6>Veginn fjármagnskostnaður (WACC)</h6>,
                    title: 'Veginn fjármagnskostnaður',
                    value: <span className={cx({ 'highlighted': waccHighlight })}>{convertToPercentage(data.wacc, true, 2)}</span>,
                    formula: <><p>{EQUALS_ICON}Meðal eiginfjárhlutfall atvinnugr.{MULTIPLY_ICON}Eiginfjárkrafa</p><p>{PLUS_ICON}Meðal skuldahlutfall atvinnugr.{MULTIPLY_ICON}Skuldakrafa</p></>,
                    tableData: [{
                        name: 'Meðal eiginfjárhlutfall atvinnugreinar',
                        value: convertToPercentage(data.average_equity_ratio, true, 2)
                    }, {
                        name: 'Eiginfjárkrafa',
                        value: convertToPercentage(data.return_on_equity, true, 2),
                        rowClass: 'return-on-equity'
                    }, {
                        name: 'Meðal skuldahlutfall atvinnugreinar',
                        value: convertToPercentage(data.average_debt_ratio, true, 2)
                    }, {
                        name: 'Skuldakrafa eftir skatta',
                        value: convertToPercentage(data.return_on_debt, true, 2),
                        rowClass: 'return-on-debt'
                    }]
                };
            case 'fcf':
                return {
                    header: <h6>Frjálst sjóðstreymi (FCF)</h6>,
                    title: 'Frjálst sjóðstreymi',
                    value: <>{formatNumber(data.free_cashflow, '-', 0)}<span className='value-unit'>kr.</span></>,
                    formula: <><p>{EQUALS_ICON}EBIT{MINUS_ICON}Skattar{PLUS_ICON}Afskriftir{MINUS_ICON}Fjárfestingar</p><p>{PLUS_ICON}Breyting veltufjármuna</p></>,
                    tableData: [{
                        name: 'EBIT',
                        value: formatNumber(data.ebit, '-', 0, ' kr.')
                    }, {
                        name: 'Skattar',
                        value: formatNumber(data.taxes, '-', 0, ' kr.')
                    }, {
                        name: 'Afskriftir',
                        value: formatNumber(data.depreciation ? -1*data.depreciation : null, '-', 0, ' kr.')
                    }, {
                        name: 'Fjárfestingar',
                        value: formatNumber(data.investment, '-', 0, ' kr.')
                    }, {
                        name: <>
                            Breyting veltufjármuna
                            <Tooltip
                                text={<FontAwesomeIcon icon={faInfoCircle} className='icon-fa' />}
                                tooltip={<p>
                                    Veltufjármunir eru seljanlegar eignir sem auðvelt er að breyta í handbært fé og skammtímaskuldir. Breyting á þessum eignum og skuldum á milli ára hefur áhrif á frjálst sjóðstreymi.
                                </p>}
                                noUnderline
                            />
                        </>,
                        value: formatNumber(data.change_in_nwc, '-', 0, ' kr.')
                    }]
                };
            case 'valuation':
                return {
                    header: <h6>Virði fyrirtækis</h6>,
                    title: 'Virði fyrirtækis',
                    value: <span className={cx({ 'highlighted': waccHighlight || growthHighlight })}>
                        {formatNumber(data.company_valuation, '-', 0)}
                        <span className='value-unit'>kr.</span>
                    </span>,
                    formula: <p>{EQUALS_ICON}FCF{MULTIPLY_ICON}(1{PLUS_ICON}Vöxtur){DIVIDE_ICON}(WACC{MINUS_ICON}Vöxtur)</p>,
                    hoverFormula: <><p>{EQUALS_ICON}Frjálst sjóðstreymi{MULTIPLY_ICON}(1{PLUS_ICON}Meðal hagvöxtur)</p><p>{DIVIDE_ICON}(WACC{MINUS_ICON}Meðal hagvöxtur)</p></>,
                    tableData: [{
                        name: 'Frjálst sjóðstreymi (FCF)',
                        value: formatNumber(data.free_cashflow, '-', 0, ' kr.'),
                        rowClass: 'fcf'
                    }, {
                        name: 'Veginn fjármagnskostnaður (WACC)',
                        value: <span className={cx({ 'highlighted': waccHighlight })}>
                            {convertToPercentage(data.wacc, true, 2)}
                        </span>,
                        rowClass: 'wacc'
                    }, {
                        name: 'Vöxtur',
                        value: <span className={cx({ 'highlighted': growthHighlight })}>
                            {convertToPercentage(data.gdp_growth_rate, true, 2)}
                        </span>
                    }]
                };
            case 'equity-valuation-ebitda':
                return {
                    header: <h6>Virði eigin fjár</h6>,
                    title: 'Virði eigin fjár',
                    value: <span className={cx({ 'highlighted': industryHighlight })}>
                        {formatNumber(data.ebitda_equity_valuation, '-', 0)}
                        <span className='value-unit'>{displayCurrency}</span>
                    </span>,
                    formula: <p>{EQUALS_ICON}Virði fyrirtækis{MINUS_ICON}Vaxtaberandi skuldir{PLUS_ICON}Handbært fé</p>,
                    tableData: [{
                        name: 'Virði fyrirtækis',
                        value: <span className={cx({ 'highlighted': industryHighlight })}>
                            {formatNumber(data.ebitda_company_valuation, '-', 0, displayCurrency)}
                        </span>,
                        rowClass: 'valuation'
                    }, {
                        name: 'Vaxtaberandi skuldir',
                        value: formatNumber(data.interest_bearing_debt, '-', 0, displayCurrency)
                    }, {
                        name: 'Handbært fé',
                        value: formatNumber(data.cash, '-', 0, displayCurrency)
                    }]
                };
            case 'ebitda-valuation':
                return {
                    header: <h6>
                        Virði fyrirtækis
                        <Tooltip
                            text={<FontAwesomeIcon icon={faInfoCircle} className='icon-fa' />}
                            tooltip={<p>
                                Hér er EBITDA fyrirtækisins margfölduð með EV/EBITDA hlutfalli skráðra fyrirtækja í sams konar rekstri.
                                Athugið að margfaldarinn gerir ráð fyrir að fyrirtækið sé skráð á markað og kann því að vera villandi.
                            </p>}
                            noUnderline
                        />
                    </h6>,
                    title: 'Virði fyrirtækis',
                    value: <span className={cx({ 'highlighted': industryHighlight })}>
                        {formatNumber(data.ebitda_company_valuation, '-', 0)}
                        <span className='value-unit'>{displayCurrency}</span>
                    </span>,
                    formula: <p>{EQUALS_ICON}EBITDA{MULTIPLY_ICON}EV/EBITDA margfaldari</p>,
                    tableData: [{
                        name: 'EBITDA',
                        value: formatNumber(data.ebitda, '-', 0, displayCurrency)
                    }, {
                        name: 'Atvinnugrein',
                        value: <span className={cx({ 'highlighted': industryHighlight })}>
                            {data.industry_is ?? data.industry ?? '-'}
                        </span>
                    }, {
                        name: 'EV/EBITDA margfaldari',
                        value: <span className={cx({ 'highlighted': industryHighlight })}>
                            {formatNumber(data.ebitda_multiple, '-', 1)}
                        </span>
                    }]
                };
            case 'equity-valuation-large':
                return {
                    title: 'Samtals virði eigin fjár',
                    value: <span className={cx({ 'highlighted': waccHighlight || growthHighlight })}>
                        {formatNumber(data.equity_valuation, '-', 0)}
                        <span className='value-unit'>kr.</span>
                    </span>,
                    formula: <p>{EQUALS_ICON}Virði fyrirtækis{MINUS_ICON}Vaxtaberandi skuldir{PLUS_ICON}Handbært fé</p>,
                    tableData: [{
                        name: 'Virði fyrirtækis',
                        value: formatNumber(data.company_valuation, '-', 0, ' kr.'),
                        rowClass: 'valuation'
                    }, {
                        name: 'Vaxtaberandi skuldir',
                        value: formatNumber(data.interest_bearing_debt, '-', 0, ' kr.')
                    }, {
                        name: 'Handbært fé',
                        value: formatNumber(data.cash, '-', 0, ' kr.')
                    }]
                };
        }
    }, [data, waccHighlight, growthHighlight, industryHighlight]);

    return <>
        {data === null
            ? <Loading />
            : <>
                <div className='head-wrapper'>
                    {displayData.header}
                    <div className={cx('colored-box', type)} onClick={() => setTableOpen ? setTableOpen(!tableOpen) : null}>
                        {setTableOpen && <FontAwesomeIcon icon={tableOpen ? faAngleUp : faAngleDown} className='icon-fa' />}
                        <p className='value'>{displayData.value}</p>
                        <p className='title'>{displayData.title}</p>
                        {displayData.hoverFormula &&
                            <div className='formula hover-formula'>{displayData.hoverFormula}</div>
                        }
                        <div className='formula'>{displayData.formula}</div>
                    </div>
                </div>
                <div className={cx('table-wrapper', {'show': tableOpen})} onClick={() => setTableOpen ? setTableOpen(!tableOpen) : null}>
                    <Table
                        tableSize='lg'
                        data={displayData.tableData}
                        columns={[{
                            title: 'Skýring',
                            renderCell: ({name}) => name,
                            textAlign: 'left'
                        }, {
                            title: type === 'fcf' || type.startsWith('equity-valuation') ? 'Upphæð' : '',
                            renderCell: ({value}) => value,
                            textAlign: 'right'
                        }]}
                        rowClassName={({rowClass}) => rowClass}
                    />
                </div>
            </>
        }
    </>;
}

export default CompanyValuationTable;