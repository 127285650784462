// Package imports:
import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
// Component imports:
import EbitdaMultiplierInfo from './EbitdaMultiplierInfo';
import CompanyValuationTable from './CompanyValuationTable';
import Loading from '../../../ui-elements/Loading/Loading';
import Alert from '../../../ui-elements/Alert/Alert';
import DropSelectSearch from '../../../ui-elements/DropSelect/DropSelectSearch/DropSelectSearch';
// Service imports:
import { formatNumber } from '../../../services/utils';
import { useFetchedApiLmdData } from '../../../services/apiHooks';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { ICompanyIndustry, ICompanyValuation, ICompanyViewModel } from '../../../types/CompanyTypes';

const EbitdaValuation: React.FC<ICompanyViewModel> = ({
    id,
    accessToken
}) => {
    const [industryId, setIndustryId] = useState<number | null>(null);
    const [usingUserIndustry, setUsingUserIndustry] = useState<boolean | null>(null);

    const [params, setParams] = useState<string | null>('');

    const [initialIndustry, setInitialIndustry] = useState<number | null>(null);
    
    const [industryChanged, setIndustryChanged] = useState(false);

    useEffect(() => {
        if (usingUserIndustry !== null) {
            const industryChangeTimer = setTimeout(() => {
                setIndustryChanged(true);

                const industryTimer = setTimeout(() => {
                    setIndustryChanged(false);
                }, 2000);

                return () => clearTimeout(industryTimer);
            }, 200);
            return () => clearTimeout(industryChangeTimer);
        }
    }, [industryId]);

    const timeoutFunctionIdRef = useRef<number | null>(null);
    // Helper delay function.
    const withDelay = (func: () => void) => {
        const timeoutFunctionId = timeoutFunctionIdRef.current;
        if (timeoutFunctionId) window.clearTimeout(timeoutFunctionId);

        timeoutFunctionIdRef.current = window.setTimeout(func, 400);
    }

    useEffect(() => {
        if (!usingUserIndustry) {
            if (params || !data || data instanceof Error) {
                setParams('');
            }
        }
    }, [usingUserIndustry]);

    useEffect(() => {
        const p = usingUserIndustry && industryId !== null
            ? `?industry_id=${industryId}`
            : '';
        withDelay(() => setParams(p));
    }, [industryId, usingUserIndustry]);
    
    const data = useFetchedApiLmdData<ICompanyValuation>(
        `/v1/market_data/v1/company_valuation/${id}/valuation/v2${params}`,
        accessToken
    );

    const industries = useFetchedApiLmdData<ICompanyIndustry[]>(
        'v1/market_data/v1/company_valuation/industries',
        accessToken
    );

    const getIndustriesToDisplay = () => {
        if (industries === null || industries instanceof Error) return [];

        return industries.filter(({EbitdaMultiple}) => EbitdaMultiple !== null).sort((a, b) => {
            const aName = a.NameIs;
            const bName = b.NameIs;
            if (aName === null && bName === null) return 0;
            if (aName === null) return -1;
            if (bName === null) return 1;
            if (aName < bName) return -1;
            if (bName < aName) return 1;
            return 0;
        })
    };

    useEffect(() => {
        if (data === null || data instanceof Error) return;
        if (data.industry_id !== null) {
            setIndustryId(data.industry_id);
            if (initialIndustry === null) setInitialIndustry(data.industry_id);
        }
    }, [data]);

    if (data === null) return <Loading />;
    if (data instanceof Error) return <Alert type='alert' headText={ErrorMessages.OtherShort} />;
    if (data.ebitda === null || data.ebitda <= 0 || data.ebitda_equity_valuation === null || data.ebitda_equity_valuation <= 0) {
        return <Alert type='alert' headText='Ekki tókst að verðmeta fyrirtæki' />;
    }
    
    return (
        <div className='grid-items'>
            <div className='grid-item'>
                <div className='summary-box'>
                    <div className='description'>
                        <h2>Niðurstöður verðmats fyrir <span className='bold-name'>{data.company_name}</span></h2>
                        <p className='paragraph-small'>Hér koma niðurstöður verðmatsins sem er reiknað út frá EBITDA margfaldara fyrirtækja í sömu atvinnugrein.</p>
                        <p className='paragraph-small'>Hægt er að breyta flokkun atvinnugreinar (sjá gildi margfaldarans í sviga) en önnur gildi eru föst.</p>
                        <p className='paragraph-small--bold'>Athugið að margfaldarinn gerir ráð fyrir að fyrirtækið sé skráð á markað og kann því að vera villandi.</p>
                    </div>
                    <div className='grey-box'>
                        <div className={cx('value', { 'highlighted': industryChanged })}>
                            <span className='value-number'>{formatNumber(data.ebitda_equity_valuation, '-', 0)}</span>
                            <span className='value-unit'>{data.currency === 'ISK' ? 'kr.' : data.currency}</span>
                        </div>
                        <div className='title'>Samtals virði eigin fjár</div>
                        <DropSelectSearch
                            label='Atvinnugrein fyrirtækis'
                            size='lg'
                            options={getIndustriesToDisplay().map(({Id, NameIs, EbitdaMultiple}) => ({
                                    id: Id?.toString(),
                                    label: `${NameIs} (x ${formatNumber(EbitdaMultiple, '-', 1)})`,
                                    value: Id?.toString() ?? ''
                                }))
                            }
                            defaultValue={industryId}
                            onChange={(option) => {
                                if (typeof option === 'string') {
                                    setIndustryId(parseInt(option));
                                    setUsingUserIndustry(true);
                                }
                            }}
                        />
                    </div>
                </div>
                <EbitdaMultiplierInfo />
            </div>
            <div className='grid-item model-box'>
                <div className='calculations'>
                    <div className='calculations--line'>
                        <CompanyValuationTable
                            data={data}
                            type='ebitda-valuation'
                            tableOpen={true}
                            industryHighlight={industryChanged}
                        />
                        <CompanyValuationTable
                            data={data}
                            type='equity-valuation-ebitda'
                            tableOpen={true}
                            industryHighlight={industryChanged}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EbitdaValuation;