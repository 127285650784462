// Package imports:
import React from 'react';
// Component imports:
import Modal from '../../ui-elements/Modal/Modal';
import Table from '../../ui-elements/Table/Table';
import Alert from '../../ui-elements/Alert/Alert';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
// Service imports:
import { formatNumber, getTableDate } from '../../services/utils';
// Type imports:
import { getUserDetailsMapId, IInvoice, IInvoiceCustomer, IInvoiceReference, IInvoiceUser, InvoiceIdAndUserIdToIInvoiceUserDetailsMap, InvoiceIdToInvoiceCustomerResult, InvoiceModalInfo } from '../../types/MyKeldaTypes';

interface IOwnProps {
    invoiceModalInfo: InvoiceModalInfo | null
    invoiceUserDetailsMap: InvoiceIdAndUserIdToIInvoiceUserDetailsMap
    invoiceCustomerResultMap: InvoiceIdToInvoiceCustomerResult
    onHide: () => void
}

const ReikningarModal: React.FC<IOwnProps> = ({
    invoiceModalInfo,
    invoiceUserDetailsMap,
    invoiceCustomerResultMap,
    onHide
}) => {
    const getCustomerString = (customerResult: IInvoiceCustomer) => {
        let { name, name2, address, address2, zipCode, city, ssn } = customerResult;
        let customerString = '';
        // `${name} ${addr} ${zip} ${city} ${ssn}`
        if (name) customerString += name;
        if (name2) customerString += ` (${name2})`
        if (address) customerString += ` ${address}`;
        if (address2) customerString += ` (${address2})`
        if (zipCode) customerString += ` ${zipCode}`;
        if (city) customerString += ` ${city}`;
        if (ssn) customerString += ` ${ssn}`;
        return customerString;
    }
    const displayReikningur = (invoice: IInvoice) => {
        const invoiceCustomerResult = invoiceCustomerResultMap[invoice.id];
        if (invoiceCustomerResult === undefined) return null;
        if (invoiceCustomerResult instanceof Error) {
            return (
                <div className='alert-wrapper'>
                    <ErrorAlert
                        error={invoiceCustomerResult}
                    />
                </div>
            );
        }
        // Bakfærsla edge-case:
        if (!Array.isArray(invoiceCustomerResult)) {
            const infoColData = [{
                title: 'Dagsetning',
                value: invoiceCustomerResult.chargeDate?.split(' ')?.[0] // Only keep date, remove hour.
            }, {
                title: 'Bakfærsla nr.',
                value: invoiceCustomerResult.id
            }]

            return (
                <div className='reikningur-modal-body'>
                    <div className='info-col-wrapper'>
                        {infoColData.map((infoCol, index) => (
                            <div className='info-col' key={index}>
                                <div className='info-item'>
                                    <span className="title">{infoCol.title}</span>
                                    <span className="value">{infoCol.value}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='footer-info'>
                        <div className='paragraph--bold'>
                            <span>Samtals</span>
                            <span>{formatNumber(invoiceCustomerResult.amountTotal, '-', 0, ' kr.')}</span>
                        </div>
                        <div>
                            <span>VSK. 24,5%</span>
                            <span>{formatNumber(invoiceCustomerResult.amountVat, '-', 0, ' kr.')}</span>
                        </div>
                        <div>
                            <span>Samtals án VSK.</span>
                            <span>{formatNumber(invoiceCustomerResult.amount, '-', 0, ' kr.')}</span>
                        </div>
                    </div>
                    <div className='alert-wrapper'>
                        <Alert
                            type='info'
                            headText='Leiðrétting gerð.'
                        />
                    </div>
                </div>
            )
        }
        // Extract info from tuple.
        const customerResult = invoiceCustomerResult[0];
        const total = invoiceCustomerResult[1];
        const invoiceLineResults = invoiceCustomerResult[3];

        const infoColData = [{
            title: 'Dagsetning',
            value: total.invoiceDate.split(' ')[0] // Only keep date, remove hour.
        }, {
            title: 'Reikningur nr.',
            value: total.invoiceNr
        }, {
            title: 'Viðskiptavinur',
            value: getCustomerString(customerResult)
        }]

        return (
            <div className='reikningur-modal-body'>
                <div className='info-col-wrapper'>
                    {infoColData.map((infoCol, index) => (
                        <div className='info-col' key={index}>
                            <div className='info-item'>
                                <span className="title">{infoCol.title}</span>
                                <span className="value">{infoCol.value}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='table-wrapper'>
                    <Table
                        data={invoiceLineResults}
                        tableSize='lg'
                        columns={[{
                            title: 'Skýring',
                            renderCell: ({ name }) => name,
                            textAlign: 'left'
                        }, {
                            title: 'Magn',
                            renderCell: ({ count }) => count
                        }, {
                            title: 'Einingaverð',
                            renderCell: ({ amountPer }) => formatNumber(amountPer, '-', 0, ' kr.')
                        }, {
                            title: 'Upphæð',
                            renderCell: ({ sumAmountTotal }) => formatNumber(sumAmountTotal, '-', 0, ' kr.')
                        }]}
                    />
                </div>
                <div className='footer-info'>
                    <div className='paragraph--bold'>
                        <span>Samtals</span>
                        <span>{formatNumber(total.sumAmountTotal, '-', 0, ' kr.')}</span>
                    </div>
                    <div>
                        <span>VSK. 24,5%</span>
                        <span>{formatNumber(total.sumAmountVat, '-', 0, ' kr.')}</span>
                    </div>
                    <div>
                        <span>Samtals án VSK.</span>
                        <span>{formatNumber(total.sumAmount, '-', 0, ' kr.')}</span>
                    </div>
                </div>
                <div className='alert-wrapper'>
                    <Alert
                        type='info'
                        headText='Kreditkort skuldfært eða krafa stofnuð í heimabanka.'
                    />
                </div>
            </div>
        )
    }

    const displayUserDetails = (invoiceUser: IInvoiceUser) => {
        const invoiceUserDetails = invoiceUserDetailsMap[getUserDetailsMapId(invoiceUser)];
        if (invoiceUserDetails === undefined) {
            return 'Loading';
        }
        if (invoiceUserDetails instanceof Error) {
            return 'Error'
        }
        const flattenedRows: Array<{
            title: string | null;
            date: string | null;
            amount: number | null;
            amountTotal: number | null;
            amountVat: number | null;
            isHeaderRow: boolean
        }> = [];
        for (const invoiceUserDetail of invoiceUserDetails) {
            const { name, sumAmount, sumAmountTotal, sumAmountVat, invoiceLine } = invoiceUserDetail;
            flattenedRows.push({
                title: name,
                date: null,
                amount: sumAmount,
                amountTotal: sumAmountTotal,
                amountVat: sumAmountVat,
                isHeaderRow: true
            });
            for (const singleInvoiceLine of invoiceLine) {
                const { description, eventDate, amountTotal, amount, amountVat } = singleInvoiceLine;
                flattenedRows.push({
                    title: description,
                    date: eventDate,
                    amount: amount,
                    amountTotal: amountTotal,
                    amountVat: amountVat,
                    isHeaderRow: false
                }); 
            }
        }
        return (
            <Table
                data={flattenedRows}
                tableSize='lg'
                rowClassName={({ isHeaderRow }) => (isHeaderRow) ? 'is-header-row' : undefined}
                columns={[{
                    title: 'Lýsing',
                    renderCell: ({ title }) => title,
                    textAlign: 'left'
                }, {
                    title: 'Dagsetning',
                    renderCell: ({ date }) => (date) ? getTableDate(date, 'DD/MM/YYYY HH:MM:SS', '.') : null
                }, {
                    title: 'Verð',
                    renderCell: ({amountTotal}) => formatNumber(amountTotal, '-', 0, ' kr.')
                }, {
                    title: 'Verð án vsk.',
                    renderCell: ({amount}) => formatNumber(amount, '-', 0, ' kr.')
                }, {
                    title: 'VSK.',
                    renderCell: ({amountVat}) => formatNumber(amountVat, '-', 0, ' kr.')
                }]}
            />
        )
    }

    const displayReference = (invoiceReference: IInvoiceReference) => {
        return (
            <Table
                data={invoiceReference.invoiceLines}
                tableSize='lg'
                // rowClassName={({ isHeaderRow }) => (isHeaderRow) ? 'is-header-row' : undefined}
                columns={[{
                    title: 'Lýsing',
                    renderCell: ({ description }) => description,
                    textAlign: 'left'
                }, {
                    title: 'Dagsetning',
                    renderCell: ({ eventDate }) => (eventDate) ? getTableDate(eventDate, 'DD/MM/YYYY HH:MM:SS', '.') : null
                }, {
                    title: 'Verð',
                    renderCell: ({amountTotal}) => formatNumber(amountTotal, '-', 0, ' kr.')
                }, {
                    title: 'Verð án vsk.',
                    renderCell: ({amount}) => formatNumber(amount, '-', 0, ' kr.')
                }, {
                    title: 'VSK.',
                    renderCell: ({amountVat}) => formatNumber(amountVat, '-', 0, ' kr.')
                }]}
            />
        );
    }

    const displayModalTitle = () => {
        if (invoiceModalInfo?.modalType === 'Reikningur') {
            if (invoiceModalInfo.invoice.amountTotal < 0) return 'Bakfærsla';
            return 'Reikningur';
        }
        if (invoiceModalInfo?.modalType === 'Notendur') {
            const { invoiceUser } = invoiceModalInfo;
            return `${invoiceUser.userName} - Skýrslur`;
        }
        if (invoiceModalInfo?.modalType === 'Tilvisun') {
            const { invoiceReference } = invoiceModalInfo;
            return `${invoiceReference.ref} - Skýrslur með tilvísun`
        }
        return null;
    }

    // If no modal is selected or data is still being fetched, hold off on showing modal.
    if (invoiceModalInfo === null) return null;
    if (invoiceModalInfo.modalType === 'Reikningur') {
        if (invoiceCustomerResultMap[invoiceModalInfo.invoice.id] === undefined) return null;
    }
    if (invoiceModalInfo.modalType === 'Notendur') {
        if (invoiceUserDetailsMap[getUserDetailsMapId(invoiceModalInfo.invoiceUser)] === undefined) return null;
    }

    const displayBody = () => {
        if (invoiceModalInfo.modalType === 'Reikningur') {
            return displayReikningur(invoiceModalInfo.invoice);
        }
        if (invoiceModalInfo.modalType === 'Notendur') {
            return displayUserDetails(invoiceModalInfo.invoiceUser);
        }
        if (invoiceModalInfo.modalType === 'Tilvisun') {
            return displayReference(invoiceModalInfo.invoiceReference);
        }
    }
    
    return (
        <Modal
            className='KCL_reikningar-modal'
            show
            hasCloseButton
            onHide={onHide}
            header={displayModalTitle()}
            body={displayBody()}
        />
    );
}

export default ReikningarModal;