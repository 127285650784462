// Package imports:
import React from 'react';
// Component imports:
import Entrypoint from '../Entrypoint';
import CompanySubPage from '../../components/Company/CompanySubPage/CompanySubPage';
import CompanyMembers from '../../components/Company/CompanyMembers/CompanyMembers';
// Type imports:
import { ICompanyViewModel } from '../../types/CompanyTypes';

const CompanyMembersPage: React.FC<ICompanyViewModel> = ({
    id,
    isAuthenticated,
    isSubscriber
}) => {
    return (
        <Entrypoint>
            <CompanySubPage 
                id={id}
                title='Hlutafélagaskrá'
                isAuthenticated={isAuthenticated}
                isSubscriber={isSubscriber}
                component={<CompanyMembers id={id} isAuthenticated={isAuthenticated} large />} 
            />
        </Entrypoint>
    );
}

export default CompanyMembersPage;